import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { FaCheck } from "react-icons/fa";
import TextEditor from "../../../components/inputs/_TextEditor";
import MainService from "../../../helpers/services/mainService";
import { ApiService } from "../../../helpers/services/apiSrevice";
import AlertService from "../../../helpers/services/alertService";
import { SelectionProcessContext } from "../SelectionProcess";

function TextImage() {
	const [values, setValues] = useState(null);
	const [firstChage, setFirstChange] = useState(true);

	const buttonRef = useRef();
	const {
		data,
		setData,
		formType,
		changeTabe,
		isFileChange,
		setIsFileChange,
		isConfirm,
		setIsConfirm,
		nextTabe,
		setNextTabe,
	} = useContext(SelectionProcessContext);

	useEffect(() => {
		if (isConfirm.textImage) {
			AlertService.alertConfirm(
				"Did you want to Save?",
				<Row>
					<Col md={12}>
						<span>
							Click Save to keep the changes you have made, or click Discard to
							ignore the changes.
						</span>
					</Col>
				</Row>,
				"Save",
				"Discard",
			)
				.then(() => {
					if (buttonRef && buttonRef.current) {
						buttonRef.current?.click();
						setIsFileChange({
							textImage: false,
							settings: false,
							reOrder: false,
						});
						setIsConfirm({
							textImage: false,
							settings: false,
							reOrder: false,
						});
						changeTabe(nextTabe);
						setFirstChange(true);
					}
				})
				.catch(() => {
					setIsFileChange({
						textImage: false,
						settings: false,
						reOrder: false,
					});
					setIsConfirm({
						textImage: false,
						settings: false,
						reOrder: false,
					});
					setFirstChange(true);
					if (data) {
						setValues(null);
						// setValues(null);
						setTimeout(() => {
							setValues({
								...data,
								// textOrImage: null,
							});
						}, 10);
					}
					changeTabe(nextTabe);
					setNextTabe(null);
				});
		}
	}, [isConfirm]);

	useEffect(() => {
		if (data) {
			setValues(null);
			setFirstChange(true);
			// setValues(null);
			setTimeout(() => {
				setValues({
					...data,
					// textOrImage: null,
				});
			}, 10);
		}
	}, [data]);

	const onEditorChange = event => {
		const newRichText = event.editor.getData();
		if (!firstChage) {
			setIsFileChange(prev => ({
				...prev,
				textImage: true,
			}));
		} else {
			setFirstChange(false);
		}
		setValues(values => ({ ...values, textOrImage: newRichText }));
	};

	const onSubmit = e => {
		e.preventDefault();
		switch (formType) {
			case "section":
				MainService.callApi({
					api: ApiService.sectionUpdate,
					data: values,
					cb: response => {
						if (response && response.success) {
							setData(values);
						}
						if (response && response.success === true) {
							AlertService.alert("success", "Data seved");
						}
					},
				});
				return true;
			case "instruction":
				MainService.callApi({
					api: ApiService.instructionUpdate,
					data: values,
					cb: response => {
						if (response && response.success) {
							setData(values);
						}
						if (response && response.success === true) {
							AlertService.alert("success", "Data seved");
						}
					},
				});
				return true;
			default:
				break;
		}
	};

	return (
		values && (
			<form onSubmit={onSubmit}>
				<Row className="gap-3 mt-3">
					<Col md={12}>
						{values.textOrImage && (
							<TextEditor
								value={values.textOrImage}
								onEditorChange={onEditorChange}
							/>
						)}
					</Col>
					<Col md={12} className="d-flex justify-content-end mt-3">
						<Button
							ref={buttonRef}
							disabled={!isFileChange?.textImage}
							type="submit"
							className={`btn-primary d-flex gap-2 align-items-center justify-content-center`}>
							<FaCheck />
							Save
						</Button>
					</Col>
				</Row>
			</form>
		)
	);
}

export default TextImage;
