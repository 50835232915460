import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Button, Card, Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import PaymentGateWay from "./componenents/PaymentGateWay";
import BillingDetails from "./componenents/BillingDetails";
import SSOCoOrdinators from "./componenents/SSOCoOrdinators";
import SchoolDetails from "./componenents/SchoolDetails";
import CustomBreadcrumb from "../../components/breadcrumbs/Breadcrumb";
import MainService from "../../helpers/services/mainService";
import { ApiService } from "../../helpers/services/apiSrevice";
import { schoolSchema } from "../../helpers/validation/validations";
import { AUD_KEY } from "../../helpers/constants/constants";
import { Link, useNavigate, useParams } from "react-router-dom";
import { defaultBillecharges } from "../../helpers/constants/arreys";

function SchoolForm(props) {
	const [activTabe, setActivTabe] = useState("schoolDetails");
	const { schoolId } = useParams();
	const navigate = useNavigate();
	const [showPaymentGateWay, setShowPaymentGateWay] = useState(false);
	const [errors, setErrors] = useState({});
	const [values, setValues] = useState({
		schoolDetails: {
			languageId: null,
			administeredFrom: 1,
			postCode: "",
			schoolCode: "",
			schoolName: "",
			timeZone: null,
			allowSelectionProcessCopy: false,
		},
		staffProfiles: [
			{
				staffFirstName: "",
				staffLastName: "",
				staffEmail: "",
				isMainCoordinator: true,
				canAdministerCoordinators: false,
				// isCoordinator: true,
				noComms: false,
			},
		],
		paymentGateway: {
			isPaymentGateWay: false,
			gateWayType: null,
			bPointMerchantNumber: "",
			bPointShortName: "",
			bPointUserName: "",
			bPointPassword: "",
			bPointBillerCode: "",
			bPointAuthorisationURL: "",
			bPointPaymentURL: "",
			bPointVerificationURL: "",
			paymentTermsText: "",
			minPaymentCalculationPercentage: false,
			amount: "",
		},
		billingDetails: {
			currencyType: AUD_KEY,
			sendInvoicesTo: "",
			billingNotes: "",
			billableCharges: defaultBillecharges,
			otherCharges: [],
		},
	});

	useEffect(() => {
		if (schoolId) {
			MainService.callApi({
				api: ApiService.getSchool,
				data: schoolId,
				cb: getSchool,
			});
		}
	}, [schoolId]);

	useEffect(() => {
		getValidationErrors();
	}, [values, showPaymentGateWay]);

	const changeData = (data = [], value = []) => {
		if (!data.length) {
			return value;
		}
		let a = value.map(item => {
			const dataItem = data.find(el => el.chargeType === item.chargeType);
			if (dataItem) {
				return dataItem;
			}
			return item;
		});
		return a;
	};

	const getSchool = response => {
		if (response && response.data) {
			if (response.data.paymentGateway) {
				setShowPaymentGateWay(true);
			}
			let data = {
				...response.data,
				billingDetails: {
					...response.data.billingDetails,
					billableCharges: changeData(
						response.data.billingDetails.billableCharges,
						values.billingDetails.billableCharges,
					),
				},
			};
			setValues(data);
		}
	};

	const getValidationErrors = async () => {
		try {
			await schoolSchema.validate(values, {
				context: { showPaymentGateWay },
				abortEarly: false,
			});
			setErrors({});
		} catch (err) {
			if (err instanceof Yup.ValidationError) {
				const validationErrors = err.inner.reduce((acc, curr) => {
					acc[curr.path] = curr.message;
					return acc;
				}, {});
				setErrors(validationErrors);
			}
		}
	};

	const changeTabe = key => {
		setActivTabe(key);
	};

	const changeNextTabe = tabe => {
		switch (tabe) {
			case "schoolDetails":
				setActivTabe("sso_Co_ordinators");
				return;
			case "sso_Co_ordinators":
				setActivTabe("billingDetails");
				return;
			case "billingDetails":
				setActivTabe("paymentGateway");
				return;
			default:
				return;
		}
	};

	const onSubmit = event => {
		if (Object.keys(errors).length) return false;
		event.preventDefault();
		let newdata = { ...values };
		if (!showPaymentGateWay) {
			delete newdata.paymentGateway;
		}
		newdata = {
			...newdata,
			billingDetails: {
				...newdata.billingDetails,
				billableCharges: newdata?.billingDetails.billableCharges?.filter(
					item => item.isBillableCharge,
				),
			},
		};
		MainService.callApi({
			api: !schoolId ? ApiService.schoolCreate : ApiService.schoolUpdate,
			data: newdata,
			cb: response => {
				if (response && response.success === true) {
					navigate("/schools");
				}
			},
		});
	};

	return (
		<>
			<CustomBreadcrumb
				data={[
					{
						name: "School",
						url: "/schools",
					},
					{
						name: values?.schoolDetails?.id
							? `Edit ${values?.schoolDetails?.schoolName}`
							: "Add",
						url: "/",
					},
				]}
			/>
			<Container className="mt-2">
				<Card>
					<Card.Body>
						<form onSubmit={onSubmit}>
							<Row className="pb-4">
								<Col md={12}>
									<Tabs
										activeKey={activTabe}
										onSelect={k => changeTabe(k)}
										className="sso-tabs">
										<Tab
											className="sso-tab"
											eventKey="schoolDetails"
											title="School Details">
											<SchoolDetails
												values={values}
												setValues={setValues}
												shortKey={"schoolDetails"}
												errors={errors}
												setErrors={setErrors}
											/>
										</Tab>
										<Tab
											className="sso-tab"
											eventKey="sso_Co_ordinators"
											title="SSO Co-ordinators">
											<SSOCoOrdinators
												values={values}
												setValues={setValues}
												shortKey={"staffProfiles"}
												errors={errors}
												setErrors={setErrors}
											/>
										</Tab>
										<Tab
											className="sso-tab"
											eventKey={"billingDetails"}
											title={"Billing Details"}>
											<BillingDetails
												values={values}
												setValues={setValues}
												shortKey={"billingDetails"}
												errors={errors}
												setErrors={setErrors}
											/>
										</Tab>
										<Tab
											className="sso-tab"
											eventKey={"paymentGateway"}
											title={"Payment GateWay"}>
											<PaymentGateWay
												values={values}
												setValues={setValues}
												shortKey={"paymentGateway"}
												showPaymentGateWay={showPaymentGateWay}
												setShowPaymentGateWay={setShowPaymentGateWay}
												errors={errors}
												setErrors={setErrors}
											/>
										</Tab>
									</Tabs>
								</Col>
								<Col
									md={12}
									lg={7}
									className="d-flex justify-content-end mt-5 gap-2">
									<Link to={"/schools"} className="btn btn-default">
										Cancel
									</Link>
									<Button
										type="submit"
										disabled={errors && Object.keys(errors).length}
										className="btn-primary">
										Save
									</Button>
								</Col>
							</Row>
						</form>
					</Card.Body>
				</Card>
			</Container>
		</>
	);
}

export default SchoolForm;
