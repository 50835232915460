import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import index from "./store";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/fonts/stylesheet.css";
import "./sass/styles.css";
import "cropperjs/dist/cropper.css";
import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<Provider store={index}>
		<App />
	</Provider>,
);
