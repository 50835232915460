import React, { useContext, useEffect } from "react";
import { Button, Col, Row } from "react-bootstrap";
import MainService from "../../../helpers/services/mainService";
import AlertService from "../../../helpers/services/alertService";
import { ApiService } from "../../../helpers/services/apiSrevice";
import { FaRegTrashCan } from "react-icons/fa6";
import { SelectionProcessContext } from "../SelectionProcess";
import RadioBox from "../../../components/inputs/RadioBox";

function Delete() {
	const {
		activeSection,
		changeDleteId,
		data,
		formType,
		navigation,
		changeActivSection,
		setNavigation,
		changeTabe,
		activTabe,
	} = useContext(SelectionProcessContext);

	useEffect(() => {
		if (activTabe === "delete") {
			changeDleteId(data.id);
		} else {
			changeDleteId(null);
		}
	}, [activTabe]);

	const deleteSection = data => {
		if (!data) return false;

		AlertService.alertConfirm(
			"Please confirm",
			<Row>
				<Col md={12}>
					<span className="text-danger">
						If you proceed, you will delete the Section, and all associated
						Instructions and Selections in this location.
					</span>
				</Col>
				<Col md={12} className="py-3">
					<span className="d-flex justify-content-center">
						{`Are you sure you want to delete the data in ${data.reportName} ?`}
					</span>
				</Col>
			</Row>,
			"Confirm",
			"Cancel",
		)
			.then(() => {
				if (formType === "section") {
					MainService.callApi({
						api: ApiService.sectionDelete,
						data: data.id,
						cb: response => {
							if (response && response.success) {
								updatesection(navigation, data.id);
								changeTabe("textImage");
								// setYears(years => years.filter(el => item.id !== el.id));
							}
						},
					});
				} else if (formType === "instruction") {
					MainService.callApi({
						api: ApiService.instructionDelete,
						data: data.id,
						cb: response => {
							if (response && response.success) {
								updateInstruction(navigation, data.id);
								changeTabe("textImage");
								// setYears(years => years.filter(el => item.id !== el.id));
							}
						},
					});
				} else if (formType === "selection") {
					MainService.callApi({
						api: ApiService.selectionDelete,
						data: data.id,
						cb: response => {
							if (response && response.success) {
								updateSelection(navigation, data.id);
								changeTabe("settings");
								// setYears(years => years.filter(el => item.id !== el.id));
							}
						},
					});
				}
			})
			.catch(() => {});
	};

	const generateData = (data, isInstructions = false) => {
		// Проверка, является ли data массивом
		const isArray = Array.isArray(data) ? data : [data];

		return (
			<ul className={`delete_section_item`}>
				{isArray.map((item, index) => (
					<li key={`${index}-${item?.reportName || item?.courseFullName}`}>
						<h3
							className={`${
								isInstructions
									? "delete_section_item_children"
									: "delete_section_item_main"
							}`}>
							{item.reportName || item.courseFullName}
						</h3>

						{/* Проверка на вложенные instructions */}
						{item.instructions && item.instructions.length > 0 && (
							<ul>{generateData(item.instructions, true)}</ul>
						)}

						{/* Проверка на вложенные selections */}
						{item.selections && item.selections.length > 0 && (
							<ul className="delete_section_item">
								{item.selections.map((selection, i) => (
									<li
										key={`${i}-${selection?.courseFullName}`}
										className="delete_section_item_children d-flex align-items-center gap-1 mb-2">
										{/* <RadioBox
											// id={"paymentReportOnlyWhenSelectionsValid"}
											// name={"paymentReportOnlyWhenSelectionsValid"}
											value={true}
											// label={"Yes"}
											checked={false}
											disabled={true}
											onChange={event => {
												// onChange(event, "paymentReportOnlyWhenSelectionsValid");
											}}
										/> */}
										<span></span>
										{selection.courseFullName}
									</li>
								))}
							</ul>
						)}
					</li>
				))}
			</ul>
		);
	};

	const updatesection = (data, idToRemove) => {
		if (!data) return null;
		const deleteIndex = data.findIndex(element => element.id === idToRemove);

		if (deleteIndex === -1) {
			return false;
		} else if (deleteIndex !== data.length - 1) {
			let newData = data.filter(el => el.id !== idToRemove);
			changeActivSection(newData[deleteIndex], "section", newData, {
				sectionIndex: deleteIndex,
			});
			setNavigation(newData);
		} else {
			let newData = data.filter(el => el.id !== idToRemove);
			changeActivSection(newData[newData.length - 1], "section", newData, {
				sectionIndex: newData.length - 1,
			});
			setNavigation(newData);
		}

		return null; // Если элемент не найден или больше нет инструкций
	};

	const updateInstruction = (data, idToRemove) => {
		if (!data || !idToRemove) return null;
		let sectionIndex = null;
		let findeIndex = null;

		data.some((el, index) => {
			if (el.instructions.length) {
				findeIndex = el.instructions.findIndex(
					instruction => instruction.id === idToRemove,
				);

				if (findeIndex !== -1) {
					sectionIndex = index;
					return true;
				}
				findeIndex = null;
				return false;
			}
		});

		if (sectionIndex !== null && findeIndex !== null) {
			const newData = [...navigation];
			if (!newData) return false;
			const newInstruction = newData[sectionIndex].instructions.filter(
				el => el.id !== idToRemove,
			);
			newData[sectionIndex].instructions = newInstruction;
			if (!newInstruction.length) {
				changeActivSection(newData[sectionIndex], "section", newData, {
					sectionIndex: sectionIndex,
				});
				setNavigation(newData);
			} else if (findeIndex !== newData[sectionIndex].instructions.length) {
				changeActivSection(
					newData[sectionIndex].instructions[findeIndex],
					"instruction",
					newData[sectionIndex],
				);
				setNavigation(newData);
			} else {
				changeActivSection(
					newData[sectionIndex].instructions[
						newData[sectionIndex].instructions.length - 1
					],
					"instruction",
					newData[sectionIndex],
				);
				setNavigation(newData);
			}
			return data;
		} else {
		}
	};

	const updateSelection = (data, idToRemove) => {
		if (!data || !idToRemove) return;
		let sectionIndex = null;
		let instructionIndex = null;
		let findeIndex = null;

		data.some((el, secIndex) => {
			if (el.instructions.length) {
				return el.instructions.some((instruction, instrIndex) => {
					if (instruction.selections.length) {
						findeIndex = instruction.selections.findIndex(
							selection => selection.id === idToRemove,
						);
						if (findeIndex !== -1) {
							sectionIndex = secIndex;
							instructionIndex = instrIndex;
							return true;
						}
					}
					return false;
				});
			}
			return false;
		});

		if (
			sectionIndex !== null &&
			instructionIndex !== null &&
			findeIndex !== -1
		) {
			const newData = JSON.parse(JSON.stringify(navigation));
			newData[sectionIndex].instructions[instructionIndex].selections = newData[
				sectionIndex
			].instructions[instructionIndex].selections.filter(
				el => el.id !== idToRemove,
			);

			const updatedSelections =
				newData[sectionIndex].instructions[instructionIndex].selections;
			if (!updatedSelections.length) {
				changeActivSection(
					newData[sectionIndex].instructions[instructionIndex],
					"instruction",
					newData[sectionIndex],
				);
			} else if (findeIndex < updatedSelections.length) {
				changeActivSection(
					updatedSelections[findeIndex],
					"selection",
					newData[sectionIndex].instructions[instructionIndex],
				);
			} else {
				changeActivSection(
					updatedSelections[updatedSelections.length - 1],
					"selection",
					newData[sectionIndex].instructions[instructionIndex],
				);
			}
			setNavigation(newData);
		} else {
			return null;
		}
	};

	// updateSelection(navigation, data.id);

	return (
		activeSection && (
			<div>
				<div className="delete_section m-2">{generateData(activeSection)}</div>
				<div className="m-2 d-flex justify-content-end">
					<button
						className={`btn btn-red btn-sm `}
						onClick={e => {
							deleteSection(activeSection);
						}}>
						<FaRegTrashCan />
						Delete
					</button>
				</div>
			</div>
		)
	);
}

export default Delete;
