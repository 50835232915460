import React from "react";
import { useDropzone } from "react-dropzone";
import { Link } from "react-router-dom";
import MainService from "../../helpers/services/mainService";

function DropZone({
	boxClassname,
	dropzoneClassName,
	file,
	uploadFaile,
	configs = {},
	title = "Drop file here or click to upload",
	error = false,
	remove = () => {},
}) {
	const { getRootProps, getInputProps } = useDropzone({
		...configs,
	});
	return (
		<div className={`${boxClassname ? boxClassname : ""} `}>
			<div
				{...getRootProps({
					className: `sso_dropzone ${
						dropzoneClassName ? dropzoneClassName : ""
					}`,
				})}>
				{file && uploadFaile ? (
					<div className="d-flex flex-column justify-content-center align-items-center gap-1">
						<div
							className="sso_dropzone_file d-flex flex-column justify-content-center align-items-center gap-2"
							style={{ backgroundImage: `url(${file})` }}>
							<span
								className="sso_dropzone_size"
								dangerouslySetInnerHTML={{
									__html: MainService.checkFileSize(file.size),
								}}></span>
							<span className="sso_dropzone_name">{file?.name}</span>
						</div>
						<Link
							to={"#"}
							className="sso_dropzone_link"
							onClick={e => {
								e.stopPropagation();
								remove();
							}}>
							Remove
						</Link>
					</div>
				) : (
					<>
						<input {...getInputProps()} />

						<h4 className={`${error ? "text-danger" : ""}`}>{title}</h4>
					</>
				)}
			</div>
		</div>
	);
}

export default DropZone;
