import React, { useState } from "react";
import { Button, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import ReactSelectOption from "../../../components/inputs/ReactSelectOption";
import RadioBox from "../../../components/inputs/RadioBox";
import Input from "../../../components/inputs/Input";
import { Link } from "react-router-dom";
import { FaLongArrowAltRight } from "react-icons/fa";
import { FaArrowRightLong } from "react-icons/fa6";
import { HiMiniArrowLongRight } from "react-icons/hi2";

function CapacitiesForm(props) {
	const [values, setValues] = useState({});

	const onChangeRadio = (event, fieldName) => {
		if (event.target.value === "false") {
			setValues(values => ({
				...values,
				[fieldName]: false,
			}));
		} else {
			setValues(values => ({
				...values,
				[fieldName]: true,
			}));
		}
	};

	const renderTooltip = props => (
		<Tooltip id="button-tooltip" {...props}>
			You have already assigned this selection in another rule
		</Tooltip>
	);

	const renderTooltipSave = props => {
		<Tooltip id="button-tooltip" {...props}>
			{props.text}
		</Tooltip>;
	};

	return (
		<form>
			<Row style={{ paddingBottom: 100 }}>
				<Col md={12}>
					<Row>
						<Col md={6}>
							<h3 className="fw-500 fs-24 font-blue-madison">Add New</h3>
							<p className="margin-bottom-10">
								<strong>
									From the list below, select one of the codes from your
									selection process:
								</strong>
							</p>
							<ReactSelectOption />
						</Col>
					</Row>
				</Col>
				<Col md={12} lg={6} className="mt-3 d-flex flex-column gap-3">
					<div className="d-flex align-items-center gap-3 flex-column flex-lg-row">
						<label className="w-lg-50 w-100 sso_label">This limit is</label>
						<div className="d-flex align-items-center gap-3 flex-wrap w-lg-50 w-100">
							<RadioBox
								id={"showOnReportsyes"}
								name={"showOnReports"}
								value={true}
								label={"Individual"}
								checked={values?.showOnReports}
								onChange={event => {
									onChangeRadio(event, "showOnReports");
								}}
							/>
							<RadioBox
								id={"showOnReportsno"}
								name={"showOnReports"}
								value={false}
								label={"Shared"}
								checked={!values?.showOnReports}
								onChange={event => {
									onChangeRadio(event, "showOnReports");
								}}
							/>
						</div>
					</div>
					<div className="d-flex align-items-center gap-3 flex-column flex-lg-row">
						<label className="w-lg-50 w-100 sso_label">
							Capacity is <span className="sso_required">*</span>
						</label>
						<div className="d-flex align-items-center gap-3 flex-wrap w-lg-50 w-100">
							<Input
								required={true}
								labelCol={"col-md-12 col-lg-6"}
								inputCol={"col-md-12 col-lg-6"}
								id={"amount"}
								// value={values[shortKey].amount}
								// errorMessage={errors && errors[`${shortKey}.amount`]}
								// currency={values[shortKey].minPaymentCalculationPercentage}
								// onChange={event => {
								// 	onNumberChange(event, "amount", `${shortKey}.amount`);
								// }}
							/>
						</div>
					</div>
					<div className="d-flex align-items-center gap-3 flex-column flex-lg-row">
						<label className="w-lg-50 w-100 sso_label">Waitlist is</label>
						<div className="d-flex align-items-center gap-3 flex-wrap w-lg-50 w-100">
							<RadioBox
								id={"showOnReportsyes"}
								name={"showOnReports"}
								value={true}
								label={"Permitted"}
								checked={values?.showOnReports}
								onChange={event => {
									onChangeRadio(event, "showOnReports");
								}}
							/>
							<RadioBox
								id={"showOnReportsno"}
								name={"showOnReports"}
								value={false}
								label={"Not Permitted"}
								checked={!values?.showOnReports}
								onChange={event => {
									onChangeRadio(event, "showOnReports");
								}}
							/>
						</div>
					</div>
				</Col>
				<Col md={12} lg={6} className="mt-3 d-flex flex-column gap-3">
					<p className="m-0">
						<strong>Use the arrows to select where the capacity applies</strong>
					</p>
					<Row className="">
						<Col md={8}>
							<ul className="capacities_form_list">
								<li>
									<span className="fs-17">Section 4 : Welcome</span>
								</li>
								<ul>
									<li>
										<div
											className=" d-flex gap-1 align-items-center"
											style={{ marginLeft: 73 }}>
											<div>
												<OverlayTrigger
													placement="top"
													delay={{ show: 250, hide: 400 }}
													overlay={renderTooltip}>
													<span className="cheked_arrow_button_disabled fs-17">
														teszt: refndlk
													</span>
												</OverlayTrigger>
											</div>
										</div>
									</li>
									<li>
										<div
											className=" d-flex gap-1 align-items-center"
											style={{ marginLeft: 25 }}>
											<button
												type="button"
												to={"#"}
												className="cheked_arrow_button active">
												<FaLongArrowAltRight />
											</button>
											<div>
												<span className="fs-17">teszt: refndlk</span>
											</div>
										</div>
									</li>
								</ul>
							</ul>
						</Col>
						<Col md={4} className="d-flex justify-content-end">
							<Link to={"#"}>Deselect All</Link>
						</Col>
					</Row>
				</Col>
				<Col md={12} className="d-flex justify-content-end mt-5 gap-2">
					<Link to={"/schools"} className="btn btn-default">
						Cancel
					</Link>
					<Button
						type="submit"
						// disabled={errors && Object.keys(errors).length}
						className="btn-primary">
						Save
					</Button>
				</Col>
			</Row>
		</form>
	);
}

export default CapacitiesForm;
