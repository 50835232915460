import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import IconInput from "../../components/inputs/IconInput";
import { Link, useNavigate } from "react-router-dom";
import { FaUser } from "react-icons/fa";
import Switch from "../../components/inputs/Switch";
import Input from "../../components/inputs/Input";
import ReactSelectOption from "../../components/inputs/ReactSelectOption";
import CheckBox from "../../components/inputs/CheckBox";
import RadioBox from "../../components/inputs/RadioBox";
import ReadOnlyCheckBox from "../../components/readOnlyCheckBox/ReadOnlyCheckBox";
import ImageCrop from "../../components/imageCrop/ImageCrop";
import Popup from "../../components/modals/Popup";
import AlertService from "./../../helpers/services/alertService";
import MenuAccordion from "../../components/menuAccordion/MenuAccordion";
import TextEditor from "../../components/inputs/_TextEditor";
import DropZone from "../../components/inputs/DropZone";

const acradionData = [
	{
		name: "Access",
		id: 0,
		children: [
			{
				name: "Students ",
				id: 1,
				children: [],
			},
			{
				name: "Staff ",
				id: 2,
				children: [],
			},
		],
	},
	{
		name: "Checklist ",
		id: 3,
		children: [
			{
				name: "Selection Matrix ",
				id: 20,
				children: [
					{
						name: "aaaaj ",
						id: 9,
						children: [
							{
								name: "aaaaa ",
								id: 14,
								children: [],
							},
							{
								name: "bbbbb ",
								id: 15,
								children: [],
							},
						],
					},
					{
						name: "aff ",
						id: 10,
						children: [],
					},
				],
			},
			{
				name: "Global Rules ",
				id: 5,
				children: [],
			},
			{
				name: "Selection Process",
				id: 6,
				children: [],
			},
		],
	},
	{ name: "Students ", children: [], id: 7 },
	{ name: "Results", children: [], id: 8 },
];

function Home(props) {
	const navigate = useNavigate();
	const [isOn, setIsOn] = useState(false);
	const [show, setShow] = useState(false);
	const [active, setActive] = useState(true);
	const [activTabe, setActivTabe] = useState("schoolDetails");

	const changeTabe = key => {
		setActivTabe(key);
	};

	useEffect(() => {
		navigate("/schools");
	}, [])

	return (
		<Container>
			<div className="mt-5">
				<h6>Alerts</h6>
				<Button
					variant="success"
					onClick={() => AlertService.alert("success", "Successfully Updated")}>
					Success
				</Button>
				<Button
					variant="danger"
					onClick={() => AlertService.alert("error", "Successfully Updated")}>
					Danger
				</Button>
				<Button
					variant="info"
					onClick={() => AlertService.alert("info", "Successfully Updated")}>
					Info
				</Button>
				<Button
					variant="warning"
					onClick={() => AlertService.alert("warning", "Successfully Updated")}>
					Warning
				</Button>
			</div>
			<Popup
				show={show}
				action={() => { }}
				handleClose={() => {
					setShow(false);
				}}>
				yes
			</Popup>

			<Row className="gap-3">
				<Col md={12}>
					<button
						onClick={() => {
							setShow(true);
						}}>
						{" "}
						open
					</button>
				</Col>
				<Col md={12}>
					<IconInput icon={<FaUser />} />
				</Col>
				<Col md={12}>
					<Switch
						id={"hola"}
						label={"hola"}
						value={isOn}
						onChange={event => {
							setIsOn(event.target.checked);
						}}
					/>
				</Col>
				<Col md={12}>
					<Input label={"StaffName: "} required={true} small={true} />
				</Col>
				<Col md={12}>
					<ReactSelectOption />
				</Col>
				<Col md={12}>
					<CheckBox
						value={isOn}
						onChange={event => {
							setIsOn(event.target.checked);
						}}
						label={"All selections for a student"}
					/>
				</Col>
				<Col md={12}>
					<RadioBox label={"All selections for a student"} />
				</Col>
				<Col md={12}>
					<ReadOnlyCheckBox
						type="success"
						label={"Compliant with any(all) criteria"}
					/>
					<ReadOnlyCheckBox
						type="danger"
						label={"Not yet compliant with criteria"}
					/>
					<ReadOnlyCheckBox
						type="nothing"
						label={"Section not required for this student"}
					/>
					<ReadOnlyCheckBox
						label={"Section has not yet been accessed (viewed)"}
					/>
				</Col>
				<Col md={12}>
					<ImageCrop />
				</Col>
				<Col md={12}>
					<MenuAccordion
						active={active}
						setActive={setActive}
						children={acradionData}
					/>
				</Col>
				<Col md={12}>
					<Tabs
						activeKey={activTabe}
						onSelect={k => changeTabe(k)}
						className="sso-tabs">
						<Tab
							className="sso-tab"
							eventKey="schoolDetails"
							title="School Details">
							School Details
						</Tab>
						<Tab
							className="sso-tab"
							eventKey="sso_Co-ordinators"
							title="SSO Co-ordinators">
							SSO Co-ordinators
						</Tab>
						<Tab
							className="sso-tab"
							eventKey={"billingDetails"}
							title={"Billing Details"}>
							Billing Details
						</Tab>
						<Tab
							className="sso-tab"
							eventKey={"paymentGateway"}
							title={"Payment GateWay"}>
							Payment GateWa
						</Tab>
					</Tabs>
				</Col>
				<Col md={12}>
					<TextEditor />
				</Col>
			</Row>

			<Link href="#">
				https://client-test.subjectselectiononline.com.au/?school=mind3
			</Link>
			<p className="text-muted">asd asd asd ad</p>
			<DropZone />
		</Container>
	);
}

export default Home;
