//alert keys

export const ERROR_KEY = "error";
export const WARNING_KEY = "warning";
export const SUCCESS_KEY = "success";
export const INFO_KEY = "info";

//-----

//type keys

export const NUMBER_KEY = "number";
export const BOOLEAN_KEY = "boolean";
export const OBJECT_KEY = "object";
export const STRING_KEY = "string";

//-----

//currency types - "$", "€", or "£"

export const AUD_KEY = "$";
export const EUR_KEY = "€";
export const GPB_KEY = "£";

//-----

//bille charges types

export const ANNUAL_SUBSCRIPTION = 1;
export const BUILD_FEES = 2;
export const PAYMENT_GATEWAY = 3;
export const TRAINING = 4;
export const OTHER = 5;

//----

//countries keys
export const AUSTRALIA_KEY = 1;
export const UK_EUROPE_KEY = 2;

//----

//instruction function keys
export const NORMAL_KEY = 0;
export const PARENT_PIN_KEY = 1;
export const APPOINTMENTS_KEY = 2;
export const DISPLAY_RESULT_KEY = 3;
//-----
