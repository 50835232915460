import React, { useEffect, useState } from "react";
import { Card, CardBody, Container, Row, Col, Button } from "react-bootstrap";
import CardTitle from "../../components/cardTitle/CardTitle";
import MenuAccordion from "../../components/menuAccordion/MenuAccordion";
import MainService from "../../helpers/services/mainService";
import { ApiService } from "../../helpers/services/apiSrevice";
import { useMediaQuery } from "@mui/material";
import { FaCheck } from "react-icons/fa";
import AlertService from "../../helpers/services/alertService";
import TextEditor from "../../components/inputs/_TextEditor";
// import TextEditor from "../../components/inputs/TextEditor";

export default function Help() {
	const [active, setActive] = useState(true);
	const [isShowLeftMenu, setIsShowLeftMenu] = useState(false);
	const [pages, setPages] = useState([]);
	const [name, setName] = useState("ACCESS");
	const matches = useMediaQuery("(max-width:576px)");
	const [values, setValues] = useState({
		id: null,
		groupId: null,
		selectionId: null,
		helpContent: null,
	});

	useEffect(() => {
		MainService.callApi({
			api: ApiService.getAllStruct,
			cb: response => {
				if (response && response.data && response.data.length) {
					setPages(response.data);
					setName(response.data[0].name);
					setActive(response.data[0].id);
					if (matches) {
						setIsShowLeftMenu(false);
					}
					MainService.callApi({
						api: ApiService.getHelpById,
						data: response.data[0].id,
						cb: response => {
							if (response && response.data) {
								setValues(response.data);
							} else {
								setValues({
									helpContent: "",
									pageId: +response.data[0].id,
								});
							}
						},
					});
				}
			},
		});
	}, []);

	const show = () => {
		setIsShowLeftMenu(!isShowLeftMenu);
	};

	const changeEventKey = (eventKey, name) => {
		if (!eventKey) return false;
		setName(name);
		setActive(eventKey);
		if (matches) {
			setIsShowLeftMenu(false);
		}
		setValues({
			id: null,
			groupId: null,
			selectionId: null,
			helpContent: null,
		});
		MainService.callApi({
			api: ApiService.getHelpById,
			data: eventKey,
			cb: response => {
				if (response && response.data) {
					setValues(response.data);
				} else {
					setValues({
						helpContent: "",
						pageId: +eventKey,
					});
				}
			},
		});
	};

	const onEditorChange = event => {
		const newRichText = event.editor.getData();
		setValues(values => ({
			...values,
			helpContent: newRichText,
		}));
	};

	const onSave = () => {
		// values.id ? ApiService.updateHelp :
		MainService.callApi({
			api: ApiService.createHelp,
			data: values,
			cb: response => {
				if (response && response.success === true) {
					AlertService.alert("success", "Data seved");
				}
			},
		});
	};

	return (
		<Container
			style={{ overflowX: isShowLeftMenu ? "hidden" : "inherit" }}
			className="pt-2">
			<Row>
				<Col
					md={12}
					className="page-content-inner d-flex position-relative gap-3">
					<div className={`help_sidebar_menu ${isShowLeftMenu ? "show" : ""}`}>
						<Card>
							<CardBody style={{}}>
								<CardTitle title={"PAGES"} />

								<MenuAccordion
									active={active}
									setActive={setActive}
									children={pages}
									setIsShowLeftMenu={setIsShowLeftMenu}
									action={changeEventKey}
								/>
							</CardBody>
						</Card>
					</div>
					<div
						className={`help_content ${isShowLeftMenu ? "show" : ""}`}
						style={{}}>
						<Card>
							<CardBody>
								<CardTitle
									action={() => {
										show();
									}}
									title={`HELP FOR ${name.toUpperCase()}`}
								/>
								<div className={`${isShowLeftMenu ? "content_block" : ""}`}>
									{/* <TextEditor
										value={values.helpContent}
										onEditorChange={onEditorChange}
									/> */}
									{values.helpContent !== null && (
										<TextEditor
											value={values.helpContent}
											onEditorChange={event => {
												onEditorChange(event);
											}}
										/>
									)}

									<div className="d-flex justify-content-end mt-3">
										<Button
											type="button"
											onClick={() => {
												onSave();
											}}
											className="btn-primary d-flex gap-2 align-items-center justify-content-center">
											<FaCheck />
											Save
										</Button>
									</div>
								</div>
							</CardBody>
						</Card>
					</div>
				</Col>
			</Row>
		</Container>
	);
}
