import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Row, Table } from "react-bootstrap";
import CardTitle from "../../components/cardTitle/CardTitle";
import { Link, useParams } from "react-router-dom";
import CustomBreadcrumb from "../../components/breadcrumbs/Breadcrumb";
import { FaAngleRight } from "react-icons/fa";
import MainService from "../../helpers/services/mainService";
import { ApiService } from "../../helpers/services/apiSrevice";

function Teachers(props) {
	const [data, setData] = useState([]);
	const { yearId } = useParams();

	useEffect(() => {
		if (yearId) {
			MainService.callApi({
				api: ApiService.staffProfileAccessGetYear,
				data: yearId,
				cb: response => {
					if (response && response.data) {
						setData(sortDataByCustomOrder(response.data));
					}
				},
			});
		}
	}, [yearId]);

	function sortDataByCustomOrder(data) {
		const order = ["HoLA", "Counseller", "HomeTeacher", "All"];
		return data.sort((a, b) => {
			return order.indexOf(a.userTypeName) - order.indexOf(b.userTypeName);
		});
	}

	return (
		<>
			<CustomBreadcrumb
				boxClassName="shadow"
				data={[
					{
						name: "Checklist",
						url: "/admin/checklist",
					},
					{ name: "Staff", url: "/" },
				]}>
				<div className="d-flex align-items-center gap-2">
					<Link
						to={"#"}
						target="_blank"
						className="btn btn-outline-blue btn-extra_small">
						Help Documentation
					</Link>
				</div>
			</CustomBreadcrumb>
			<Container className="mt-3">
				<Card>
					<CardBody>
						<CardTitle title="Staff for Year 2024" />
						<Row className="mt-3">
							{data &&
								!!data.length &&
								data.map((item, i) => {
									let url =
										item.userTypeName === "HoLA"
											? `hola/${item.userTypeId}`
											: item.userTypeName === "Counseller"
											? `course_counsellors/${item.userTypeId}`
											: item.userTypeName === "HomeTeacher"
											? `home_teachers/${item.userTypeId}`
											: `whole_year_level_access`;
									if (item.name === "All") {
										return (
											<Col md={12} key={i}>
												<Table responsive className="sso_tabel teachers_teble">
													<thead>
														<tr>
															<th></th>
															<th>
																<Link
																	className="in_hover_underline fs-16 fw-700"
																	to={"whole_year_level_access"}>
																	Whole Year Level Access
																</Link>
															</th>
															<th></th>
														</tr>
													</thead>
													<tbody>
														<tr>
															<td> </td> <td> Harut Norikyan </td>{" "}
															<td> 10ENG </td>{" "}
														</tr>
														<tr>
															<td> </td> <td> Hannah Chapeltower </td>{" "}
															<td> 10SCI </td>{" "}
														</tr>
													</tbody>
												</Table>
												<hr />
											</Col>
										);
									}
									return (
										<Col md={12}>
											<Table responsive className="sso_tabel teachers_teble">
												<thead>
													<tr>
														<th>
															{item.fix && (
																<span className="coustem-label">
																	Fix <FaAngleRight />
																</span>
															)}
														</th>
														<th>
															<Link
																className="in_hover_underline fs-16 fw-700"
																to={url}>
																{item.userTypeName}
															</Link>
														</th>
														<th></th>
													</tr>
												</thead>
												<tbody>
													{item.data &&
														!!item.data.length &&
														item.data.map((access, index) => {
															return (
																<tr key={index}>
																	<td> </td>
																	<td> {access.key} </td>
																	<td>
																		{console.log(access.value)}
																		{access.value &&
																			!!access.value.length &&
																			access.value.join(", ")}
																	</td>
																</tr>
															);
														})}
												</tbody>
											</Table>
											<hr />
										</Col>
									);
								})}
							{/* <Col md={12}>
								<Table responsive className="sso_tabel teachers_teble">
									<thead>
										<tr>
											<th>
												<span className="coustem-label">
													Fix <FaAngleRight />
												</span>
											</th>
											<th>
												<Link
													className="in_hover_underline fs-16 fw-700"
													to={"hola"}>
													HoLA
												</Link>
											</th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td> </td> <td> Harut Norikyan </td> <td> 10ENG </td>{" "}
										</tr>
										<tr>
											<td> </td> <td> Hannah Chapeltower </td> <td> 10SCI </td>{" "}
										</tr>
									</tbody>
								</Table>
								<hr />
							</Col>
							<Col md={12}>
								<Table responsive className="sso_tabel teachers_teble">
									<thead>
										<tr>
											<th>
												<span className="coustem-label">
													Fix <FaAngleRight />
												</span>
											</th>
											<th>
												<Link
													className="in_hover_underline fs-16 fw-700"
													to={"course_counsellors"}>
													Course Counsellors
												</Link>
											</th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td> </td> <td> Harut Norikyan </td> <td> 10ENG </td>{" "}
										</tr>
										<tr>
											<td> </td> <td> Hannah Chapeltower </td> <td> 10SCI </td>{" "}
										</tr>
									</tbody>
								</Table>
								<hr />
							</Col>
							<Col md={12}>
								<Table responsive className="sso_tabel teachers_teble">
									<thead>
										<tr>
											<th>
												<span className="coustem-label">
													Fix <FaAngleRight />
												</span>
											</th>
											<th>
												<Link
													className="in_hover_underline fs-16 fw-700"
													to={"home_teachers"}>
													Home Teachers
												</Link>
											</th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td> </td> <td> Harut Norikyan </td> <td> 10ENG </td>{" "}
										</tr>
										<tr>
											<td> </td> <td> Hannah Chapeltower </td> <td> 10SCI </td>{" "}
										</tr>
									</tbody>
								</Table>
								<hr />
							</Col>
							<Col md={12}>
								<Table responsive className="sso_tabel teachers_teble">
									<thead>
										<tr>
											<th></th>
											<th>
												<Link
													className="in_hover_underline fs-16 fw-700"
													to={"whole_year_level_access"}>
													Whole Year Level Access
												</Link>
											</th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td> </td> <td> Harut Norikyan </td> <td> 10ENG </td>{" "}
										</tr>
										<tr>
											<td> </td> <td> Hannah Chapeltower </td> <td> 10SCI </td>{" "}
										</tr>
									</tbody>
								</Table>
								<hr />
							</Col> */}
						</Row>
					</CardBody>
				</Card>
			</Container>
		</>
	);
}

export default Teachers;
