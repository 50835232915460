import React, { useState } from "react";
import CustomBreadcrumb from "../../components/breadcrumbs/Breadcrumb";
import { Link, useParams } from "react-router-dom";
import { Card, CardBody, Col, Container, Row, Table } from "react-bootstrap";
import CardTitle from "../../components/cardTitle/CardTitle";
import RadioBox from "../../components/inputs/RadioBox";
import CheckBox from "../../components/inputs/CheckBox";
import ReactSelectOption from "../../components/inputs/ReactSelectOption";
import {
	DatatableWrapper,
	Filter,
	TableBody,
	TableHeader,
} from "react-bs-datatable";
import { FaDownload } from "react-icons/fa";

function Fees(props) {
	const { yearId } = useParams();
	const [data, setData] = useState([]);

	const columns = [
		{
			prop: "studentCode",
			title: "Student code",
			isFilterable: true,
			isSortable: true,
			cell: row => (
				<div>
					<p className="d-inline-block">{row.studentCode}</p>
				</div>
			),
		},
		{
			prop: "externalCode",
			title: "External code",
			isFilterable: true,
			isSortable: true,
			cell: row => (
				<div>
					<p className="d-inline-block">{row.externalCode}</p>
				</div>
			),
		},
		{
			prop: "studentName",
			title: "Student Name",
			isFilterable: true,
			isSortable: true,
			cell: row => (
				<div>
					<p className="d-inline-block">{row.studentName}</p>
				</div>
			),
		},
		{
			prop: "Instruction",
			title: "instruction",
			isFilterable: true,
			isSortable: true,
			cell: row => (
				<div>
					<p className="d-inline-block">{row.instruction}</p>
				</div>
			),
		},
		{
			prop: "selectionCode",
			title: "Selection Code",
			isFilterable: true,
			isSortable: true,
			cell: row => (
				<div>
					<p className="d-inline-block">{row.selectionCode}</p>
				</div>
			),
		},
		{
			prop: "selectionName",
			title: "Selection Name",
			isFilterable: true,
			isSortable: true,
			cell: row => (
				<div>
					<p className="d-inline-block">{row.selectionName}</p>
				</div>
			),
		},
		{
			prop: "selectionFee",
			title: "Selection Fee",
			isFilterable: true,
			isSortable: true,
			cell: row => (
				<div>
					<p className="d-inline-block">{row.selectionFee}</p>
				</div>
			),
		},
		{
			prop: "totalStudentFees",
			title: "Total Student Fees",
			isFilterable: true,
			isSortable: true,
			cell: row => (
				<div>
					<p className="d-inline-block">{row.totalStudentFees}</p>
				</div>
			),
		},
	];

	return (
		<>
			<CustomBreadcrumb
				boxClassName="shadow"
				data={[
					{
						name: "Checklist",
						url: "/admin/checklist",
					},
					{
						name: "Extracts",
						url: `/admin/extracts/${yearId}`,
					},
					{
						name: "Fees",
						url: "/",
					},
				]}>
				<div className="d-flex align-items-center gap-2">
					<Link
						to={"#"}
						target="_blank"
						className="btn btn-outline-blue btn-extra_small">
						Help Documentation
					</Link>
				</div>
			</CustomBreadcrumb>
			<Container className="mt-3">
				<Card>
					<CardBody>
						<CardTitle title={"Year 2024 FEES"} />
						<Row>
							<Col md={12}>
								<p>Do you need to see all students, or just an individual?</p>
							</Col>
							<Col md={12} lg={6} className="mt-2 d-flex flex-column gap-2">
								<RadioBox
									id={"showOnReportsyes"}
									name={"showOnReports"}
									value={true}
									label={"All Students"}
									size={"lg"}
									// checked={values?.showOnReports}
									// onChange={event => {
									// 	onChangeRadio(event, "showOnReports");
									// }}
								/>
								<RadioBox
									id={"showOnReportsyes"}
									name={"showOnReports"}
									value={true}
									label={"Individual"}
									size={"lg"}
									// checked={values?.showOnReports}
									// onChange={event => {
									// 	onChangeRadio(event, "showOnReports");
									// }}
								/>
							</Col>
							<Col md={12} lg={6} className="mt-2 d-flex flex-column gap-2">
								<ReactSelectOption />
							</Col>
							<Col md={12} className="mt-5">
								<DatatableWrapper
									body={data}
									headers={columns}
									paginationOptionsProps={{
										initialState: {
											rowsPerPage: 100,
											options: [5, 10, 15, 20],
										},
									}}>
									<Row className="justify-content-between">
										<Col md={2} className="mb-3">
											<Filter
												placeholder="search"
												classes={{
													input: "sso_input h_34",
													clearButton: "clearButton",
												}}
											/>
										</Col>
										<Col
											md={5}
											className="d-flex gap-2 justify-content-end flex-md-nowrap flex-wrap mb-3">
											<button
												to={"#"}
												target="_blank"
												className="btn btn-outline-btn-default  btn-sm">
												<FaDownload />
												Export as displayeds
											</button>
										</Col>
										<Col md={12} className="mb-2">
											<Table className="sso_tabel" responsive bordered>
												<TableHeader />
												<TableBody />
											</Table>
										</Col>
									</Row>
								</DatatableWrapper>
							</Col>
						</Row>
					</CardBody>
				</Card>
			</Container>
		</>
	);
}

export default Fees;
