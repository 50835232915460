import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Button, Card, CardBody, CardTitle, OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import { IoLogInOutline } from "react-icons/io5";
import useWindowSize from '../../hooks/useWindowSize';
import useElementDimensionsByClassName from '../../hooks/useElementDimensionsByClassName';
import { IoMenuSharp } from "react-icons/io5";
import { FaInfo } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { IoIosArrowUp } from "react-icons/io";



export default function Student() {
  const [data, setData] = useState([]);
  const [scrolled_75, setScrolled_75] = useState(false);
  const [scrolled_175, setScrolled_175] = useState(false);
  const windowSize = useWindowSize();
  const headerMenuSizes = useElementDimensionsByClassName("page-header-menu-fixed");
  const cardTitleSizes = useElementDimensionsByClassName("card-title");
  const [isActiveCardHeader, setIsActiveCardHeader] = useState(false);
  const [leftMenuTop, setLeftMenuTop] = useState(0);
  const [isShowLeftMenu, setIsShowLeftMenu] = useState(true);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isShowAnswers, setIsShowAnswers] = useState(false);


  const refs = useRef([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await new Array(8).fill("").map((_, index) => ({
        id: index,
        title: `Block_${index}`,
        content: `Content for block ${index}`,
      }));
      setData(response);
    };

    fetchData();
  }, []);

  useEffect(() => {
    refs.current = new Array(data.length).fill(null).map(() => React.createRef());
  }, [data]);

  const handleScroll = useCallback(() => {
    const scrollY = window.scrollY;
    setScrolled_75(scrollY >= 75);
    setScrolled_175(windowSize.width <= 991 ? scrollY >= 75 : scrollY >= 175);

    let closestIndex = null;
    let minDistance = Number.POSITIVE_INFINITY;

    refs.current.forEach((ref, index) => {
      if (ref.current) {
        const { top } = ref.current.getBoundingClientRect();
        const distance = Math.abs(top);

        if (top >= 0 && distance < minDistance) {
          minDistance = distance;
          closestIndex = index;
        }
      }
    });

    if (closestIndex !== null) {
      setActiveIndex(closestIndex);
    }
  }, [windowSize.width]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    setIsShowLeftMenu(windowSize.width > 991);
  }, [windowSize]);

  useEffect(() => {
    setIsActiveCardHeader(windowSize.width <= 991 && headerMenuSizes?.height);
  }, [windowSize, headerMenuSizes]);

  useEffect(() => {
    const headerMenuHeight = headerMenuSizes?.height || 0;
    const cardTitleHeight = isActiveCardHeader ? cardTitleSizes?.height || 0 : 0;
    setLeftMenuTop(headerMenuHeight + cardTitleHeight || 0);
  }, [cardTitleSizes, headerMenuSizes, isActiveCardHeader]);

  return (
    <div className='container-fluid student-page mb-5' style={{ marginTop: scrolled_75 ? "80px" : "" }}>
      <Card className='mt-0 mt-lg-3'>
        <CardTitle
          className={`border-bottom p-3 ${isActiveCardHeader ? "active-card-header" : ""}`}
          style={{ top: isActiveCardHeader ? `${headerMenuSizes.height}px` : "" }}
        >
          <div>
            <div className='d-flex justify-content-between align-items-center'>
              <div className='d-flex align-items-center'>
                <IoMenuSharp size={30} className='me-3 d-block d-lg-none' style={{ cursor: "pointer" }} onClick={() => setIsShowLeftMenu(!isShowLeftMenu)} />
                <h5 className='text-blue mb-0'>Subject Selection</h5>
              </div>
              <div style={{ fontSize: "14px" }}>
                {
                  isShowAnswers ?
                    <p className='mb-0 d-none d-lg-block'
                      style={{ cursor: "pointer" }}
                      onClick={() => setIsShowAnswers(!isShowAnswers)}
                    >
                      Hide Answers
                      <IoLogInOutline size={20} style={{ transform: "rotate(180deg)" }} />
                    </p>
                    :
                    <p className='mb-0 d-none d-lg-block'
                      style={{ cursor: "pointer" }}
                      onClick={() => setIsShowAnswers(!isShowAnswers)}
                    >
                      Show Answers
                      <IoLogInOutline size={20} />
                    </p>
                }
                <p className='mb-0 d-block d-lg-none' style={{ cursor: "pointer" }} onClick={() => setIsShowAnswers(!isShowAnswers)}>Answers</p>
              </div>
            </div>
          </div>
        </CardTitle>
        <CardBody>
          <div className={`d-flex mt-3 mb-5 ${windowSize.width <= 991 && leftMenuTop ? "active-card-body" : ""}`}>
            <div className={`subject-selection-left-block ${isShowLeftMenu ? "show" : "hide"}`}>
              <div className={`subject-selection-left-block-item ${scrolled_175 ? "active" : ""}`} style={{ top: `${leftMenuTop + 40}px` }}>
                <ul className='d-flex flex-column align-items-start'>
                  {data.map((item, index) => (
                    <li key={item.id}>
                      <a href={`#block_${item.id}`} className={`${activeIndex === index ? 'active_link' : ''}`}>Test_{item.id}</a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className='timeline-container'>
              {data.map((item, index) => (
                <div
                  ref={refs.current[index]}
                  key={item.id}
                  id={`block_${item.id}`}
                  className={`timeline-item d-flex ${activeIndex === index ? 'active' : ''}`}
                  style={{ scrollMarginTop: isActiveCardHeader ? "120px" : "50px" }}
                >
                  <div className="timeline-number">{item.id}</div>
                  <div className="timeline-content p-3 w-100">
                    <h3 className='mb-3'>{item.title} start</h3>

                    <div className='row'>
                      <div className='col-md-8'>

                        <div className='student-checkbox d-flex gap-3'>
                          <div className='round'>
                            <input type="checkbox" className='success' id="checkbox" checked />
                            <label for="checkbox"></label>
                          </div>
                          <p>10SCM Armenian</p>
                        </div>

                        <div className='student-checkbox d-flex gap-3'>
                          <div className='round'>
                            <input type="checkbox" className='success' id="checkbox" />
                            <label for="checkbox"></label>
                          </div>
                          <p>10SCM Armenian</p>
                        </div>

                        <div className='student-checkbox d-flex gap-3'>
                          <div className='round'>
                            <input type="checkbox" className='warning' id="checkbox" disabled />
                            <OverlayTrigger
                              placement="top"
                              delay={{ show: 100, hide: 100 }}
                              overlay={<Tooltip>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</Tooltip>}
                            >
                              <label for="checkbox"></label>
                            </OverlayTrigger>
                          </div>
                          <p>10SCM Armenian</p>
                        </div>

                        <div className='student-checkbox d-flex gap-3'>
                          <div className='round'>
                            <input type="checkbox" className='danger' id="checkbox" />
                            <label for="checkbox"></label>
                          </div>
                          <div className='d-flex'>

                            <span>Lorem Ipsum is simply dummy text</span>

                            <div style={{ position: "relative" }}>

                              <OverlayTrigger
                                trigger="hover"
                                placement="right"
                                overlay={
                                  <Popover>
                                    <Popover.Header className='py-1 px-3'>Course Information</Popover.Header>
                                    <Popover.Body className='py-2 px-3'>Lorem ipsum</Popover.Body>
                                  </Popover>
                                }>
                                <Button style={{
                                  padding: 0,
                                  color: "#000",
                                  background: "none",
                                  border: "none",
                                }}>
                                  <FaInfo size={12} className='mx-2 mb-1' style={{ cursor: "pointer" }} />
                                </Button>
                              </OverlayTrigger>
                            </div>

                            <span>(25 places available)</span>
                          </div>
                        </div>

                        <div className='student-input mt-2'>
                          <input type='text' placeholder='placeholder' />
                        </div>
                      </div>
                    </div>
                    <h4 className='mt-3'>{item.title} end</h4>



                  </div>
                </div>
              ))}
            </div>

            <div className={`answers-modal ${windowSize.width < 992 && isShowAnswers ? "show" : ""}`}>
              <div className={`answers-modal-item ${isShowAnswers ? "show" : "hide"} `} style={{ top: scrolled_175 ? `${leftMenuTop + 40}px` : "" }}>
                <div className='modal-header '>
                  <div className='d-flex justify-content-between align-items-center w-100 p-2'>
                    <span></span>
                    <p className='title text-center w-100 mb-0'>Your Selections</p>
                    <IoClose
                      className='close-icon'
                      color='#CCCCCC' size={30} style={{ cursor: "pointer" }}
                      onClick={() => {
                        setIsShowAnswers(false)
                      }}
                    />
                  </div>
                </div>
                <div className='modal-body'>
                  <div className='modal-body-item'>
                    <p className='text mb-0'>
                      You will see all selections here
                    </p>
                  </div>
                </div>
                <div className='modal-footer p-2'>
                  <div className='d-flex justify-content-end gap-2'>
                    <Button variant='outline-light border d-flex gap-1 ' style={{ fontSize: "12px", color: "#000", fontWeight: "300" }}>
                      <span> Generate Selection Report</span>
                      <span> <IoIosArrowUp size={10} className='mb-1' /></span>
                    </Button>
                    <Button variant='outline-light border d-flex gap-1 ' style={{ fontSize: "12px", color: "#000", fontWeight: "300" }}>
                      <span> Generate Payment Report</span>
                      <span> <IoIosArrowUp size={10} className='mb-1' /></span>
                    </Button>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </CardBody>
      </Card>
    </div>
  );
}


