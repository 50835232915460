import React from "react";
import { Link } from "react-router-dom";
import CustomBreadcrumb from "../../components/breadcrumbs/Breadcrumb";
import { Card, CardBody, Col, Container, Row } from "react-bootstrap";
import CardTitle from "../../components/cardTitle/CardTitle";

function ExtractsView(props) {
	return (
		<>
			<CustomBreadcrumb
				boxClassName="shadow"
				data={[
					{
						name: "Checklist",
						url: "/admin/checklist",
					},
					{
						name: "Extracts",
						url: `/`,
					},
				]}>
				<div className="d-flex align-items-center gap-2">
					<Link
						to={"#"}
						target="_blank"
						className="btn btn-outline-blue btn-extra_small">
						Help Documentation
					</Link>
				</div>
			</CustomBreadcrumb>
			<Container className="mt-3">
				<Card>
					<CardBody>
						<CardTitle title={"Year 2024 Extracts"} />
						<Row className="flex-column gap-3">
							<Col md={12}>
								<h4 className="fw-500 fs-18 mt-3">
									This section allows you to view student selection information.
								</h4>
							</Col>
							<Col md={12}>
								<p className="mb-0">
									<strong>Choose the format you would like to see:</strong>
								</p>
							</Col>
							<Col md={12}>
								<div className="d-flex flex-column gap-3">
									<Row className="align-items-center gap-2">
										<Col md={12} lg={2}>
											<Link to={"quick_view"} className="btn btn-blue btn-lg ">
												Quick View
											</Link>
										</Col>
										<Col md={12} lg={6}>
											<span className="text-muted">
												Easily readable data, best for seeing selections totals
												at a glance
											</span>
										</Col>
									</Row>
									<Row className="align-items-center  gap-2">
										<Col md={12} lg={2}>
											<Link to={"fees"} className="btn btn-blue btn-lg ">
												Fees
											</Link>
										</Col>
										<Col md={12} lg={6}>
											<span className="text-muted">
												Get the fees payable by each student, based on their
												selections
											</span>
										</Col>
									</Row>
									<Row className="align-items-center gap-2">
										<Col md={12} lg={2} className="d-flex">
											<Link to={"extracts"} className="btn btn-blue btn-lg ">
												Extracts
											</Link>
										</Col>
										<Col md={12} lg={6}>
											<span className="text-muted">
												Information generated for timetabling software and
												analysis
											</span>
										</Col>
									</Row>
								</div>
							</Col>
						</Row>
					</CardBody>
				</Card>
			</Container>
		</>
	);
}

export default ExtractsView;
