import React, { useState } from "react";
import { Button, Card, CardBody, Col, Row } from "react-bootstrap";
import CardTitle from "../../../components/cardTitle/CardTitle";
import Input from "../../../components/inputs/Input";
import MainService from "../../../helpers/services/mainService";
import { ApiService } from "../../../helpers/services/apiSrevice";
import AlertService from "../../../helpers/services/alertService";
import HelpModal from "../../../components/modals/HelpModal";

function LoginPrompt({ values, setValues }) {
	const [isChange, setIsChange] = useState(false);
	const [openHelpModal, setOpenHelpModal] = useState(false);

	const onSubmit = event => {
		event.preventDefault();
		if (!isChange) return false;
		const formData = new FormData();
		Object.entries(values).forEach(([key, values]) => {
			formData.append(key, values);
		});
		MainService.callApi({
			api: ApiService.updateSchoolBrandingSettings,
			data: formData,
			cb: response => {
				if (response && response.success === true) {
					AlertService.alert("success", "Data seved");
					setIsChange(false);
				}
			},
		});
	};

	const onChange = (event, fieldName) => {
		setIsChange(true);
		setValues(values => ({
			...values,
			[fieldName]: event.target.value,
		}));
	};
	return (
		<Card style={{ minHeight: 478 }}>
			<HelpModal
				show={openHelpModal}
				handleClose={() => {
					setOpenHelpModal(!openHelpModal);
				}}
			/>
			<CardBody>
				<CardTitle
					title="YOU CAN CUSTOMISE THE SSO LOGIN PROMPTS HERE."
					titleClassname={"color_bleck fw-700"}>
					<Button
						className="btn-outline-blue btn-extra_small uppercase"
						onClick={() => {
							setOpenHelpModal(!openHelpModal);
						}}>
						help ?
					</Button>
				</CardTitle>
				<Row>
					<Col md={12} lg={6}>
						<form className="d-flex flex-column gap-3" onSubmit={onSubmit}>
							<Input
								label={"Login Prompt"}
								id={"loginPrompt"}
								value={values.loginPrompt || ""}
								// errorMessage={errors && errors[`${shortKey}.loginPrompt`]}
								onChange={event => {
									onChange(event, "loginPrompt");
								}}
							/>
							<Input
								label={"PIN/Password prompt"}
								id={"passwordPrompt"}
								value={values.passwordPrompt || ""}
								// errorMessage={
								// 	errors && errors[`${shortKey}.bPointMerchantNumber`]
								// }
								onChange={event => {
									onChange(event, "passwordPrompt");
								}}
							/>
							<div className="d-flex justify-content-end">
								<Button
									type="submit"
									disabled={!isChange}
									className="btn-primary ">
									Save
								</Button>
							</div>
						</form>
					</Col>
				</Row>
			</CardBody>
		</Card>
	);
}

export default LoginPrompt;
