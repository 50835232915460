import React, { useState } from "react";
import { Button, Card, CardBody, Col, Row, Tab, Tabs } from "react-bootstrap";
import CardTitle from "../../../components/cardTitle/CardTitle";
import RadioBox from "../../../components/inputs/RadioBox";
import ImageCrop from "../../../components/imageCrop/ImageCrop";
import DropZone from "../../../components/inputs/DropZone";
import MainService from "../../../helpers/services/mainService";
import { ApiService } from "../../../helpers/services/apiSrevice";
import AlertService from "../../../helpers/services/alertService";

function Images({ values, setValues }) {
	const [activTabe, setActivTabe] = useState("logo-banner");

	const changeTabe = key => {
		setActivTabe(key);
	};

	const onSubmit = values => {
		const formData = new FormData();
		Object.entries(values).forEach(([key, values]) => {
			formData.append(key, values);
		});
		MainService.callApi({
			api: ApiService.updateSchoolBrandingSettings,
			data: formData,
			cb: response => {
				if (response && response.data) {
					setValues(values => ({ ...values, ...response.data }));
				}
				if (response && response.success === true) {
					AlertService.alert("success", "Data seved");
				}
			},
		});
	};

	const onSaveLogo = (file, cropedFile) => {
		let newValues = { ...values, logo: file, croppedLogo: cropedFile };

		onSubmit(newValues);
	};
	const onSaveBanner = (file, cropedFile) => {
		let newValues = { ...values, banner: file, croppedBanner: cropedFile };
		onSubmit(newValues);
	};

	const onSaveBackgroundImage = (file, cropedFile) => {
		let newValues = {
			...values,
			backgroundImage: file,
			croppedBackgroundImage: cropedFile,
		};
		onSubmit(newValues);
	};

	const onChange = event => {
		let newValues = {
			...values,
			isBannerActive: event.target.value === "false" ? false : true,
		};
		onSubmit(newValues);
	};

	return (
		<Card style={{ minHeight: 478 }}>
			<CardBody>
				<CardTitle
					title="images"
					titleClassname={"color_bleck fw-700 uppercase"}></CardTitle>
				<Row>
					<Col md={12}>
						<Tabs
							activeKey={activTabe}
							onSelect={k => changeTabe(k)}
							className="sso-tabs">
							<Tab
								className="sso-tab"
								eventKey="logo-banner"
								title="Logo/Banner">
								<Row className="mt-3 gap-3">
									<Col md={12}>
										<Row>
											<Col md={12} className="mt-2">
												<div className="d-flex justify-content-between align-items-center">
													<h3 className="mb-0">Upload Logo</h3>
													<RadioBox
														id={"upload_Logo"}
														name={"isBannerActive"}
														value={false}
														label={"Active"}
														checked={!values?.isBannerActive}
														onChange={event => {
															if (values?.isBannerActive) {
																onChange(event);
															}
														}}
													/>
												</div>
											</Col>
											<Col md={12}>
												<div className="information_block">
													<ul>
														<li>
															Upload the file you want to use (either .jpg or
															.png format)
														</li>
														<li>
															Once the image is uploaded select and drag the
															part of the image you want to use
														</li>
														<li>
															Click save when you have finished selecting the
															area of the image to use
														</li>
														<li>
															Choose which image you would like to use, by
															selecting 'Active' next to the required image
														</li>
														<li>
															Logo image size must be more than 150 x 150 pixels
														</li>
													</ul>
												</div>
											</Col>

											<Col md={12}>
												<ImageCrop
													width={150}
													height={150}
													cropedWidth={316}
													cropedHeight={316}
													stencilProps={{
														handlers: true, // Disable all handlers for resizing
														movable: true, // Disable moving the crop area
														resizable: true, // Disable resizing the crop area
														aspectRatio: 150 / 150, // Lock aspect ratio if needed
													}}
													imagePath={values.logoPath}
													croppedPath={values.croppedLogoPath}
													values={values}
													action={onSaveLogo}
												/>
											</Col>
										</Row>
									</Col>
									<Col md={12}>
										<Row>
											<Col md={12} className="mt-2">
												<div className="d-flex justify-content-between align-items-center">
													<h3 className="mb-0">Upload Banner</h3>
													<RadioBox
														id={"upload_banner"}
														name={"isBannerActive"}
														value={true}
														label={"Active"}
														checked={values.isBannerActive}
														onChange={event => {
															if (!values?.isBannerActive) {
																onChange(event);
															}
														}}
													/>
												</div>
											</Col>
											<Col md={12}>
												<div className="information_block">
													<ul>
														<li>
															Upload the file you want to use (either .jpg or
															.png format)
														</li>
														<li>
															Once the image is uploaded select and drag the
															part of the image you want to use
														</li>
														<li>
															Click save when you have finished selecting the
															area of the image to use
														</li>
														<li>
															Choose which image you would like to use, by
															selecting 'Active' next to the required image
														</li>
														<li>
															Banner image size must be more than 450 x 120
															pixels
														</li>
													</ul>
												</div>
											</Col>

											<Col md={12} className="pb-3">
												<ImageCrop
													width={450}
													height={120}
													cropedWidth={450}
													cropedHeight={120}
													stencilProps={{
														handlers: true, // Disable all handlers for resizing
														movable: true, // Disable moving the crop area
														resizable: true, // Disable resizing the crop area
														aspectRatio: 450 / 120, // Пропорции, чтобы соблюсти соотношение 450x120
													}}
													values={values}
													action={onSaveBanner}
													imagePath={values.bannerPath}
													croppedPath={values.croppedBannerPath}
												/>
											</Col>
										</Row>
									</Col>
								</Row>
							</Tab>
							<Tab className="sso-tab" eventKey="background" title="Background">
								<Row>
									<Col md={12} className="mt-4">
										<div className="d-flex justify-content-between  align-items-center">
											<h3 className="mb-0">Upload Background</h3>
										</div>
									</Col>
									<Col md={12}>
										<div className="information_block">
											<ul>
												<li>
													Upload the file you want to use (either .jpg or .png
													format)
												</li>
												<li>
													Once the image is uploaded select and drag the part of
													the image you want to use
												</li>
												<li>
													Click save when you have finished selecting the area
													of the image to use
												</li>
												<li>
													Choose which image you would like to use, by selecting
													'Active' next to the required image
												</li>
												<li>
													Background image size must be more than 1920 x 1080
													pixels
												</li>
											</ul>
										</div>
									</Col>
									<Col md={12} className="pb-3">
										<ImageCrop
											error={true}
											width={177}
											height={100}
											stencilProps={{
												handlers: true, // Disable all handlers for resizing
												movable: true, // Disable moving the crop area
												resizable: true, // Disable resizing the crop area
												aspectRatio: 1920 / 1080, // Пропорции, чтобы соблюсти соотношение 450x120
												maxWidth: 1920, // Указываем maxWidth
												maxHeight: 1080, // Указываем maxHeight
											}}
											values={values}
											action={onSaveBackgroundImage}
											imagePath={values.backgroundImagePath}
											croppedPath={values.croppedBackgroundImagePath}
											dropZoneTitele={
												"Background image size must be more than 1920x1080"
											}
										/>
									</Col>
									{/* <Col
										md={12}
										lg={6}
										className="d-flex flex-column mt-3 mt-lg-0 gap-3 justify-content-center align-items-center">
										<div className="cropped_img "></div>
										<DropZone
											error={imageError}
											configs={{
												onDrop,
												multiple: false, // Accept only one file
												accept: {
													"image/jpeg": [],
													"image/png": [],
												},
											}}
											boxClassname={"settings_dropzone"}
											title="Background image size must be more than 1920x1080"
										/>
									</Col> */}
								</Row>
							</Tab>
						</Tabs>
					</Col>
				</Row>
			</CardBody>
		</Card>
	);
}

export default Images;
