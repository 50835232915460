import { Container } from "react-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { useNavigate } from "react-router-dom";

function CustomBreadcrumb({ data = [], boxClassName, children }) {
	const navigate = useNavigate();

	if (!data || (data && !data.length)) {
		return null;
	}

	return (
		<div
			className={`header breadcrumb-block border-bottom ${
				boxClassName ? boxClassName : ""
			}`}>
			<Container>
				<div className="page-title d-flex justify-content-between flex-wrap gap-2">
					<Breadcrumb className="d-flex align-items-center ">
						{data.map((item, index) => {
							let isLastIndex = index !== data.length - 1;
							return (
								<div className="d-flex" key={index}>
									<Breadcrumb.Item
										href="#"
										active={!isLastIndex ? true : false}
										onClick={() => {
											if (!isLastIndex) {
												return false;
											}
											navigate(`${item.url}`);
										}}>
										{item.name}
									</Breadcrumb.Item>
									{isLastIndex ? (
										<div className="arrow">
											<MdOutlineKeyboardArrowRight size={24} />
										</div>
									) : null}
								</div>
							);
						})}
					</Breadcrumb>
					{children ? children : null}
				</div>
			</Container>
		</div>
	);
}

export default CustomBreadcrumb;
