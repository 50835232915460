import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import Input from "../../../components/inputs/Input";
import RadioBox from "../../../components/inputs/RadioBox";
import ReactSelectOption from "../../../components/inputs/ReactSelectOption";
import { FaCheck, FaMinus, FaPlus } from "react-icons/fa";
import { SelectionProcessContext } from "../SelectionProcess";
import MainService from "../../../helpers/services/mainService";
import { ApiService } from "../../../helpers/services/apiSrevice";
import AlertService from "../../../helpers/services/alertService";
function SettingsSelection(props) {
	const [values, setValues] = useState({});
	const [allowSelection, setAllowSelection] = useState(null);
	const [doNotAllowSelection, setDoNotAllowSelection] = useState(null);
	const buttonRef = useRef(null);

	const {
		data,
		setData,
		navigation,
		isFileChange,
		setIsFileChange,
		isConfirm,
		setIsConfirm,
		nextTabe,
		setNextTabe,
		changeTabe,
	} = useContext(SelectionProcessContext);

	useEffect(() => {
		if (isConfirm.settings) {
			AlertService.alertConfirm(
				"Did you want to Save?",
				<Row>
					<Col md={12}>
						<span>
							Click Save to keep the changes you have made, or click Discard to
							ignore the changes.
						</span>
					</Col>
				</Row>,
				"Save",
				"Discard",
			)
				.then(() => {
					if (buttonRef && buttonRef.current) {
						setIsFileChange({
							textImage: false,
							settings: false,
							reOrder: false,
						});
						setIsConfirm({
							textImage: false,
							settings: false,
							reOrder: false,
						});
						changeTabe(nextTabe);
						buttonRef.current?.click();
					}
				})
				.catch(() => {
					setIsFileChange({
						textImage: false,
						settings: false,
						reOrder: false,
					});
					setIsConfirm({
						textImage: false,
						settings: false,
						reOrder: false,
					});
					if (data) {
						setValues(null);
						// setValues(null);
						setTimeout(() => {
							setValues({
								...data,
								// textOrImage: null,
							});
						}, 10);
					}
					changeTabe(nextTabe);
					setNextTabe(null);
				});
		}
	}, [isConfirm]);

	useEffect(() => {
		if (data) {
			setValues({
				...data,
			});
		}
	}, [data]);

	const onChange = (event, fieldName) => {
		setValues(values => ({
			...values,
			[fieldName]: event.target.value,
		}));
	};

	const onNumberChange = (event, fieldName, errorfieldName) => {
		if (
			event.target.value.includes("e") ||
			event.target.value.includes(".") ||
			event.target.value.includes("-")
		) {
			return false;
		}

		if (event.target.value === "" || Number.isInteger(+event.target.value)) {
			setValues(values => ({
				...values,
				sectionSettings: {
					...values.sectionSettings,
					[fieldName]: event.target.value,
				},
			}));
		}
	};

	const onSubmit = e => {
		e.preventDefault();
		MainService.callApi({
			api: ApiService.updateSelction,
			data: values,
			cb: response => {
				if (response && response.success) {
					setData(values);
				}
				if (response && response.success === true) {
					AlertService.alert("success", "Data seved");
				}
			},
		});
	};

	const selectValues = useMemo(() => {
		if (navigation && navigation.length) {
			const result = [];

			navigation.forEach(item => {
				if (item.instructions && item.instructions.length) {
					item.instructions.forEach(instruction => {
						if (instruction.selections && instruction.selections.length) {
							instruction.selections.forEach(selection => {
								result.push({
									label: `${item.reportName} > ${instruction.reportName} > ${selection.courseFullName}`,
									value: selection.id,
									selectionId: selection.id,
									sectionName: item.reportName,
									instructionName: instruction.reportName,
									selectionName: selection.courseFullName,
								});
							});
						}
					});
				}
			});
			return result;
		} else {
			return [];
		}
	}, [navigation]);

	const onChangeRadio = (event, fieldName) => {
		setIsFileChange(prev => ({
			...prev,
			settings: true,
		}));
		setValues(values => ({
			...values,
			[fieldName]: JSON.parse(event.target.value),
		}));
	};

	const onSelectOptionChange = (item, fieldName) => {
		if (!item) return false;
		if (fieldName === "doNotAllowSelection") {
			setDoNotAllowSelection(item);
			return;
		} else if (fieldName === "allowSelection") {
			setAllowSelection(item);
			return;
		} else {
			setIsFileChange(prev => ({
				...prev,
				settings: true,
			}));
			setValues(values => ({
				...values,
				[fieldName]: item.value,
			}));
		}
	};

	const onSaveLinked = linkedSelectionType => {
		let linkedData = {};
		if (linkedSelectionType === 0 && allowSelection) {
			linkedData = {
				selectionId: data.id,
				linkedSelectionId: allowSelection.value,
				linkedSelectionType,
			};
		} else if (linkedSelectionType === 1 && doNotAllowSelection) {
			linkedData = {
				selectionId: data.id,
				linkedSelectionId: doNotAllowSelection.value,
				linkedSelectionType,
			};
		} else {
			return false;
		}
		MainService.callApi({
			api: ApiService.linkedSelectionCreate,
			data: linkedData,
			cb: response => {
				if (response && response.data) {
					if (linkedSelectionType === 0) {
						setValues(values => ({
							...values,
							automaticallyLinkedSelections: [
								...values.automaticallyLinkedSelections,
								{ ...response.data, selectionThreeResponse: allowSelection },
							],
						}));
					} else {
						setValues(values => ({
							...values,
							disallowedLinkedSelections: [
								...values.disallowedLinkedSelections,
								{
									...response.data,
									selectionThreeResponse: doNotAllowSelection,
								},
								,
							],
						}));
					}
				}
			},
		});
	};
	return (
		values && (
			<form onSubmit={onSubmit}>
				<Row className="gap-3 mt-3">
					<Col md={12} className="d-flex gap-3 flex-column flex-lg-row">
						<label className="w-lg-50 w-100">Is selection mandatory</label>
						<div
							md={12}
							lg={6}
							className="d-flex gap-4 flex-wrap w-lg-50 w-100">
							<RadioBox
								id={"isMandatory-yes"}
								name={"isMandatory"}
								value={true}
								label={"Yes"}
								checked={values?.isMandatory}
								onChange={event => {
									onChangeRadio(event, "isMandatory");
								}}
							/>
							<RadioBox
								id={"isMandatory-no"}
								name={"isMandatory"}
								value={false}
								label={"No"}
								checked={!values.isMandatory}
								onChange={event => {
									onChangeRadio(event, "isMandatory");
								}}
							/>
						</div>
					</Col>
					<Col md={12} className="d-flex gap-3 flex-column flex-lg-row">
						<label className="w-lg-50 w-100">Is Extra Response required</label>
						<div
							md={12}
							lg={6}
							className="d-flex gap-4 flex-wrap w-lg-50 w-100">
							<RadioBox
								id={"extraResponse-yes"}
								name={"extraResponse"}
								value={true}
								label={"Yes"}
								checked={values?.extraResponse}
								onChange={event => {
									onChangeRadio(event, "extraResponse");
								}}
							/>
							<RadioBox
								id={"extraResponse-no"}
								name={"extraResponse"}
								value={false}
								label={"No"}
								checked={!values.extraResponse}
								onChange={event => {
									onChangeRadio(event, "extraResponse");
								}}
							/>
						</div>
					</Col>
					<Col md={12}>
						<Row className="gap-2">
							<Col md={12}>
								<p class="mb-0">
									<strong>
										If this selection is picked, automatically select
									</strong>
								</p>
							</Col>
							<Col md={12}>
								<label>Selection</label>
							</Col>
							<Col lg={9}>
								<ReactSelectOption
									value={values.allowSelection}
									selectedValue={(() => {
										const selectedItem = {
											...selectValues?.find(
												data => data.value === allowSelection?.value,
											),
										};
										if (Object.keys(selectedItem).length) {
											return selectedItem;
										} else {
											return {
												value: null,
												label: "Choose",
											};
										}
									})()}
									items={selectValues.filter(
										item => item.value !== allowSelection,
									)}
									onChange={item =>
										onSelectOptionChange(item, "allowSelection")
									}
								/>
							</Col>
							<Col lg={2}>
								<button
									className={`btn btn-gren btn-sm`}
									type="button"
									disabled={!allowSelection}
									onClick={() => {
										onSaveLinked(0);
									}}>
									<FaPlus />
									<span>Add</span>
								</button>
							</Col>
						</Row>
					</Col>
					<Col md={12}>
						<Table striped hover responsive className="sso_tabel">
							<thead>
								<tr>
									<th>Section:</th>
									<th>Instruction</th>
									<th>Selection</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody>
								{values.automaticallyLinkedSelections &&
								values.automaticallyLinkedSelections.length ? (
									values.automaticallyLinkedSelections.map((item, index) => {
										return (
											<tr key={index}>
												<td>{item.selectionThreeResponse?.sectionName} </td>
												<td>{item.selectionThreeResponse.instructionName}</td>
												<td>{item.selectionThreeResponse.selectionName}</td>
												<td>
													<button
														type="button"
														onClick={() => {
															MainService.callApi({
																api: ApiService.linkedSelectionDelete,
																data: item.id,
																cb: response => {
																	if (response && response.success) {
																		AlertService.alert(
																			"success",
																			`The "Assigned Selection" deleted successfully`,
																		);

																		setValues(values => ({
																			...values,
																			automaticallyLinkedSelections:
																				values.automaticallyLinkedSelections.filter(
																					el => el.id !== item.id,
																				),
																		}));
																	}
																},
															});
														}}
														className="btn btn-red table_button">
														<FaMinus />
														Remove
													</button>
												</td>
											</tr>
										);
									})
								) : (
									<tr className="tabel_no_data_massage">
										<td colSpan={4}>
											<span className="d-flex justify-content-center">
												No disallowed selections
											</span>
										</td>
									</tr>
								)}
							</tbody>
						</Table>
					</Col>
					<Col md={12}>
						<Row className="gap-2">
							<Col md={12}>
								<p class="mb-0">
									<strong>
										If this selection is picked, do not allow selection of
									</strong>
								</p>
							</Col>
							<Col md={12}>
								<label>Selection</label>
							</Col>
							<Col lg={9}>
								<ReactSelectOption
									value={values.doNotAllowSelection}
									selectedValue={(() => {
										const selectedItem = {
											...selectValues?.find(
												data => data.value === doNotAllowSelection?.value,
											),
										};
										if (Object.keys(selectedItem).length) {
											return selectedItem;
										} else {
											return {
												value: null,
												label: "Choose",
											};
										}
									})()}
									items={selectValues.filter(
										item => item.value !== doNotAllowSelection,
									)}
									onChange={item =>
										onSelectOptionChange(item, "doNotAllowSelection")
									}
								/>
							</Col>
							<Col lg={2}>
								<button
									className={`btn btn-gren btn-sm`}
									type="button"
									disabled={!doNotAllowSelection}
									onClick={() => {
										onSaveLinked(1);
									}}>
									<FaPlus />
									<span>Add</span>
								</button>
							</Col>
						</Row>
					</Col>
					<Col md={12}>
						<Table striped hover responsive className="sso_tabel">
							<thead>
								<tr>
									<th>Section:</th>
									<th>Instruction</th>
									<th>Selection</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody>
								{values.disallowedLinkedSelections &&
								values.disallowedLinkedSelections.length ? (
									values.disallowedLinkedSelections.map((item, index) => {
										return (
											<tr key={index}>
												<td>{item.selectionThreeResponse.sectionName} </td>
												<td>{item.selectionThreeResponse.instructionName}</td>
												<td>{item.selectionThreeResponse.selectionName}</td>
												<td>
													<button
														type="button"
														onClick={() => {
															MainService.callApi({
																api: ApiService.linkedSelectionDelete,
																data: item.id,
																cb: response => {
																	if (response && response.success) {
																		AlertService.alert(
																			"success",
																			`The "Assigned Selection" deleted successfully`,
																		);

																		setValues(values => ({
																			...values,
																			disallowedLinkedSelections:
																				values.disallowedLinkedSelections.filter(
																					el => el.id !== item.id,
																				),
																		}));
																	}
																},
															});
														}}
														className="btn btn-red table_button">
														<FaMinus />
														Remove
													</button>
												</td>
											</tr>
										);
									})
								) : (
									<tr className="tabel_no_data_massage">
										<td colSpan={4}>
											<span className="d-flex justify-content-center">
												No disallowed selections
											</span>
										</td>
									</tr>
								)}
							</tbody>
						</Table>
					</Col>

					<Col className="d-flex justify-content-end mt-3">
						<Button
							ref={buttonRef}
							disabled={!isFileChange?.settings}
							type="submit"
							className="btn-primary d-flex gap-2 align-items-center justify-content-center">
							<FaCheck />
							Save
						</Button>
					</Col>
				</Row>
			</form>
		)
	);
}

export default SettingsSelection;
