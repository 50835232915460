import React from "react";
import { Form, Row } from "react-bootstrap";
import { FaDollarSign, FaPercentage } from "react-icons/fa";

function Textarea({
	id = "",
	onChange,
	boxClasname,
	inputClassName,
	value,
	label,
	type = "text",
	placeholder,
	onKeyDown,
	onFocus,
	onBlur,
	size,
	small,
	required,
	labelCol = "md-12",
	inputCol = "md-12",
	action,
	boxdisabled,
	disabled,
	currency = null,
	errorMessage = "",
	rows = 4,
	labelClassname,
}) {
	return (
		<Form.Group
			className={`w-100 ${boxClasname ? boxClasname : ""}  ${
				boxdisabled ? "boxdisabled" : ""
			}`}>
			<Row className="align-items-center">
				{label && (
					<div className={labelCol}>
						<Form.Label
							className={`sso_label ${labelClassname ? labelClassname : ""}`}
							htmlFor={id}>
							{label}
							{required && <span className="sso_required">*</span>}
						</Form.Label>
					</div>
				)}
				<div className={`${inputCol}  d-flex gap-1 align-items-center`}>
					{currency !== null && !currency && <FaDollarSign />}
					<div className="w-100 position-relative">
						<textarea
							size={size}
							onChange={onChange}
							onBlur={onBlur}
							onKeyDown={onKeyDown}
							onFocus={onFocus}
							rows={rows}
							id={id}
							className={`sso_textarea form-control  ${
								inputClassName ? inputClassName : ""
							} ${inputClassName === "border_none" && !value ? "error" : ""}  ${
								small ? "input-sm" : ""
							}`}
							value={value}
							type={type}
							disabled={disabled}
							placeholder={placeholder}
						/>
						{action && (
							<button
								to={"add"}
								disabled={!value}
								style={{ right: "13px", padding: "6px 10px" }}
								className="btn btn-primary btn-sm border_left_none position-absolute top-50  translate-middle-y"
								onClick={() => {
									action();
								}}>
								Get Info
							</button>
						)}
						{errorMessage && value && value.length && (
							<small className="text-danger d-block mt-1">{errorMessage}</small>
						)}
					</div>
					{currency !== null && currency && <FaPercentage />}
				</div>
			</Row>
		</Form.Group>
	);
}

export default Textarea;
