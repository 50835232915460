import React from "react";
import { FaEllipsisV } from "react-icons/fa";
import { Link } from "react-router-dom";

function CardTitle({ title, children, titleClassname, action, boxClassname }) {
	return (
		<div style={{ marginBottom: "10px" }}>
			<div
				className={`d-flex justify-content-start justify-content-md-between gap-2 flex-wrap align-items-start align-items-md-center flex-wrap ${
					boxClassname ? boxClassname : ""
				}`}>
				<div className="d-flex gap-2 align-items-center">
					{action && (
						<Link to={"#"} onClick={action} className="bleck_icon">
							<FaEllipsisV />
						</Link>
					)}
					<h3
						className={`sso_card_title ${
							titleClassname ? titleClassname : ""
						}`}>
						{title}
					</h3>
				</div>

				{!!children && children}
			</div>
			<div className="sso_line" />
		</div>
	);
}

export default CardTitle;
