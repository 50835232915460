import store from "../../store/index";
import {
	addButtonSpinner,
	addModalSpinner,
	addPageSpinner,
	removeButtonSpinner,
	removeModalSpinner,
	removePageSpinner,
} from "../../store/reducers/SpinnersReducer";
import { v4 as uuid } from "uuid";
import { BOOLEAN_KEY, ERROR_KEY } from "../constants/constants";
import AlertService from "./alertService";
import { object } from "yup";

class MainService {
	static async readFile(
		file,
		validFileTypes = null,
		progressCallback = () => {},
	) {
		const CHUNK_SIZE = 1024 * 1024 * 10000; // 10000 MB -> 10GB
		let offset = 0;
		const fileSize = file.size;
		const chunks = [];
		// Validate file extension
		const fileName = file.name;
		const lastDotIndex = fileName.lastIndexOf(".");
		const fileExtention =
			lastDotIndex !== -1
				? fileName.substring(lastDotIndex + 1).toLowerCase()
				: "";
		const isValid = validFileTypes.includes(fileExtention);
		if (!isValid) {
			throw new Error("Invalid file extension");
		}

		while (offset < fileSize) {
			const end = Math.min(offset + CHUNK_SIZE, fileSize);
			const readChunk = async (file, start, end, cb) => {
				return new Promise((resolve, reject) => {
					const reader = new FileReader();
					const blob = file.slice(start, end);
					reader.onload = () => resolve(reader.result);
					reader.onerror = error => reject(error); // Pass the error to reject()
					reader.readAsArrayBuffer(blob);
					if (cb) {
						reader.onprogress = function (data) {
							if (data.lengthComputable) {
								var progress = parseInt((data.loaded / data.total) * 100, 10);
								cb(progress);
							}
						};
					}
				});
			};

			const chunk = await readChunk(file, offset, end, progressCallback);
			chunks.push(chunk);
			offset = end;
		}

		const videoBlob = new Blob(chunks, { type: file.type });
		const videoUrl = URL.createObjectURL(videoBlob);
		return videoUrl;
	}

	static checkFileSize(size) {
		const bytes = size;
		const kb = bytes / 1024;
		const mb = kb / 1024;

		if (mb < 1) {
			return `<strong>${kb.toFixed(1)}</strong> KB`;
		} else {
			return `<strong>${mb.toFixed(1)}</strong> MB`;
		}
	}

	static isJson(str) {
		try {
			JSON.parse(str);
		} catch (e) {
			return false;
		}
		return true;
	}

	static isValidField(fieldValue, fieldName) {}

	static onEditorChange = ({ event = {}, cb = () => {} }) => {
		const newRichText = event.editor.getData();
		cb(newRichText);
	};

	static onCheckboxChange = ({
		event,
		fieldName,
		setIsInvalidSubmit = () => {},
		setValues = () => {},
	}) => {
		if (typeof event.target.checked === BOOLEAN_KEY) {
			setValues(values => ({
				...values,
				[fieldName]: event.target.checked,
			}));
			setIsInvalidSubmit(false);
		}
	};

	static onNumberChange = ({
		event,
		cb = () => {},
		setIsInvalidSubmit = () => {},
		maxValue = Infinity,
	}) => {
		if (event.target.value.includes("e") || event.target.value.includes(".")) {
			return false;
		}
		if (maxValue && maxValue < event.target.value.length) {
			return false;
		}
		setIsInvalidSubmit(false);
		if (event.target.value === "" || Number.isInteger(+event.target.value)) {
			cb(values => ({
				...values,
				[event.target.name]: event.target.value,
			}));
		}
	};

	static onSelectOptionChange = ({
		item,
		key,
		cb = () => {},
		setIsInvalidSubmit = () => {},
	}) => {
		if (!item) return null;
		setIsInvalidSubmit(false);
		cb(values => ({ ...values, [key]: item.value }));
	};

	static onChange = ({
		event,
		fieldName,
		cb = () => {},
		setIsInvalidSubmit = () => {},
		maxLength = Infinity,
	}) => {
		if (maxLength && maxLength < event.target.value.length) {
			return false;
		}
		cb(values => ({
			...values,
			[fieldName || event.target.name]: event.target.value,
		}));
		setIsInvalidSubmit(false);
	};

	static callApi = ({
		api,
		data,
		cb = () => {},
		buttonSpiner = null,
		isModal = false,
		getFail = null,
	}) => {
		const spinerId = uuid();
		if (buttonSpiner) {
			store.dispatch(addButtonSpinner(buttonSpiner));
		} else if (isModal) {
			store.dispatch(addModalSpinner(spinerId));
		} else {
			store.dispatch(addPageSpinner(spinerId));
		}
		return api(data)
			.then(response => {
				cb(response);
			})
			.catch(error => {
				if (getFail) {
					getFail(error);
				} else {
					if (error && Object.keys(error).length) {
						let responseError = Object.values(error);
						responseError.forEach(item => {
							if (Array.isArray(item)) {
								item.forEach(el => {
									if (el.params && el.params.Message) {
										AlertService.alert(
											AlertService.checkMessageType(
												error.respcode || ERROR_KEY,
											),
											el.params.Message,
										);
									}
								});
							}
						});
					} else {
						AlertService.alert(
							AlertService.checkMessageType(error.respcode || ERROR_KEY),
							error,
						);
					}
				}
			})
			.finally(() => {
				store.dispatch(removeButtonSpinner(spinerId));
				store.dispatch(removePageSpinner(spinerId));
				store.dispatch(removeModalSpinner(spinerId));
			});
	};
}

export default MainService;
