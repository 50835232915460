import React from "react";
import { Modal } from "react-bootstrap";
import { IoClose } from "react-icons/io5";
import { useSelector } from "react-redux";
import ModalLoader from "../loadre/ModalLoader";

function AreYouShure({
	show,
	handleClose,
	title,
	action,
	children,
	size = "md",
	iscloseButoon = true,
	yes = "Save Changes",
	no = "Close",
}) {
	return (
		<Modal
			show={!!show}
			size={size}
			onHide={handleClose}
			className={"sso_modal"}
			dialogClassName={`sso_modal_dialog ${size === "md" ? "width" : ""}`}>
			<Modal.Header className="sso_modal_header">
				<Modal.Title>{title}</Modal.Title>
				<div>
					<button className="btn-close mt-0 pt-0" onClick={handleClose}>
						<IoClose />
					</button>
				</div>
			</Modal.Header>
			{children && <Modal.Body>{children}</Modal.Body>}
			{!!action && (
				<Modal.Footer className="sso_modal_footer">
					{iscloseButoon && (
						<button
							size="sm"
							className="btn btn-default btn-sm"
							variant="secondary"
							onClick={handleClose}>
							{no}
						</button>
					)}
					{action && (
						<button className="btn btn-primary btn-sm" onClick={action}>
							{yes}
						</button>
					)}
				</Modal.Footer>
			)}
		</Modal>
	);
}

export default AreYouShure;
