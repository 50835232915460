import { createSlice } from "@reduxjs/toolkit";

const spinnersSlice = createSlice({
	name: "spinners",
	initialState: {
		pageSpinners: [],
		buttonspinners: [],
		modalSpinners: [],
	},
	reducers: {
		addPageSpinner: (state, { payload }) => {
			state.pageSpinners = [...state.pageSpinners, payload];
		},
		removePageSpinner: (state, { payload }) => {
			state.pageSpinners = state.pageSpinners.filter(el => el !== payload);
		},
		addButtonSpinner: (state, { payload }) => {
			state.pageSpinners = [...state.pageSpinners, payload];
		},
		removeButtonSpinner: (state, { payload }) => {
			state.pageSpinners = state.pageSpinners.filter(el => el !== payload);
		},
		addModalSpinner: (state, { payload }) => {
			state.modalSpinners = [...state.modalSpinners, payload];
		},
		removeModalSpinner: (state, { payload }) => {
			state.modalSpinners = state.modalSpinners.filter(el => el !== payload);
		},
	},
});

export const {
	addPageSpinner,
	removePageSpinner,
	addButtonSpinner,
	removeButtonSpinner,
	addModalSpinner,
	removeModalSpinner,
} = spinnersSlice.actions;

export default spinnersSlice.reducer;
