import React, { useState } from "react";
import CustomBreadcrumb from "../../components/breadcrumbs/Breadcrumb";
import { Link } from "react-router-dom";
import {
	Card,
	CardBody,
	Col,
	Container,
	Row,
	Tab,
	Table,
	Tabs,
} from "react-bootstrap";
import CardTitle from "../../components/cardTitle/CardTitle";
import {
	DatatableWrapper,
	Filter,
	TableBody,
	TableHeader,
} from "react-bs-datatable";
import { MdOutlineCalendarMonth } from "react-icons/md";
import dayjs from "dayjs";
import DateHourMinuteSelector from "../../components/dateTimePicker/DateTimePicker";
import CheckBox from "../../components/inputs/CheckBox";
import Switch from "../../components/inputs/Switch";
import MainService from "../../helpers/services/mainService";
import { ApiService } from "../../helpers/services/apiSrevice";

function Access(props) {
	const [activTabe, setActivTabe] = useState("staff");
	const [data, setData] = useState([]);
	const [open, setOpen] = useState(false);
	const [dateValue, setDateValue] = useState(null);
	const [isStart, setIsStart] = useState(false);
	const [globalAccess, setGlobalAccess] = useState([]);
	const globalAccessGetAll = () => {
		MainService.callApi({
			api: ApiService.globalAccessGetAll,
			cb: response => {
				if (response && response.data && response.data.length) {
					setGlobalAccess(response.data);
				}
			},
		});
	};

	useState(() => {
		globalAccessGetAll();
	}, []);

	const globalAccessUpdate = (event, id) => {
		if (!id) return false;
		const values = {
			id: id,
			status: event.target.checked,
		};
		MainService.callApi({
			api: ApiService.globalAccessUpdate,
			data: values,
			cb: response => {
				if (response && response.success) {
					const newGlobalAccess = globalAccess.map(item => {
						if (item.id === id) {
							return {
								...item,
								status: !event.target.checked,
							};
						}
						return item;
					});
					setGlobalAccess(newGlobalAccess);
				}
			},
		});
	};

	const changeTabe = key => {
		setActivTabe(key);
	};

	const columns = [
		{
			prop: "year",
			title: "Year",
			isFilterable: true,
			isSortable: true,
			cell: row => (
				<div>
					<p className="d-inline-block">{row.code}</p>
				</div>
			),
		},
		{
			prop: "status",
			title: "Status",
			isFilterable: true,
			isSortable: true,
			cell: row => (
				<div>
					{!row.status ? (
						<span className="coustem-label on d-inline-block mx-2">On</span>
					) : (
						<span className="coustem-label off d-inline-block mx-2">Off</span>
					)}
				</div>
			),
		},
		{
			prop: "Schedule",
			title: "Schedule start-end",
			cell: row => (
				<Row>
					<Col md={6}>
						<div className="dete_viwe">
							<label>Start</label>
							<Link
								to={"#"}
								onClick={e => {
									e.preventDefault();
									onOpen(new Date(), true);
								}}>
								<span>
									{dayjs(new Date(), "DD-MM-YYYY HH:mm").format(
										"DD-MM-YYYY HH:mm",
									)}
								</span>
								<MdOutlineCalendarMonth />
							</Link>
						</div>
					</Col>
					<Col md={6}>
						<div className="dete_viwe">
							<label>End</label>
							<Link
								to={"#"}
								onClick={e => {
									e.preventDefault();
									onOpen(new Date(), false);
								}}>
								<span>
									{dayjs(new Date(), "DD-MM-YYYY HH:mm").format(
										"DD-MM-YYYY HH:mm",
									)}
								</span>
								<MdOutlineCalendarMonth />
							</Link>
						</div>
					</Col>
				</Row>
			),
		},
		{
			prop: "viewonly",
			title: "View only",
			cell: row => (
				<div className="d-flex justify-content-center align-items-center">
					<CheckBox
						checked={row.viewonly}
						onChange={event => {
							if (event.target.checked) {
								// setDeleteIds(ids => {
								// 	return [...ids, row.id];
								// });
							} else {
								// setDeleteIds(ids => {
								// 	return ids.filter(el => el !== row.id);
								// });
							}
						}}
					/>
				</div>
			),
		},
	];

	const onOpen = (value, isSatrt = false) => {
		if (!value) return false;
		setOpen(true);
		setDateValue(value);
		setIsStart(isSatrt);
	};

	const onClose = () => {
		setOpen(false);
		setDateValue(null);
		setIsStart(false);
	};

	return (
		<>
			<DateHourMinuteSelector open={open} value={dateValue} onClose={onClose} />

			<CustomBreadcrumb
				boxClassName="shadow"
				data={[{ name: "Access", url: "/" }]}>
				<div className="d-flex align-items-center  gap-2">
					<Link
						to={"#"}
						target="_blank"
						className="btn btn-outline-blue btn-extra_small">
						Help Documentation
					</Link>
				</div>
			</CustomBreadcrumb>
			<Container className="mt-3">
				<Card>
					<CardBody>
						{/* <CardTitle title={"Access"} /> */}
						<Row className="mt-4">
							<Col md={12} className="d-flex gap-1">
								<p>
									<b>
										Access to your school's staff and students is available
										from(s):
									</b>
									<Link
										style={{ display: "inline-block" }}
										to="https://client-test.subjectselectiononline.com.au/?school=mind3"
										target="_blank">
										https://client-test.subjectselectiononline.com.au/?school=mind3
									</Link>
								</p>
							</Col>
							<Col
								md={12}
								className="d-flex align-items-center flex-wrap gap-1 mt-3">
								<p className="mb-0">
									<b>
										Use the following HTML link and image to your school's site:
									</b>
								</p>
								<Link
									to={"#"}
									target="_blank"
									className="btn btn-outline-blue btn-sm">
									Copy HTML Text
								</Link>
								<Link
									to={"#"}
									target="_blank"
									className="btn btn-outline-blue btn-sm">
									Download SSO Logo
								</Link>
							</Col>
							<Col md={12}>
								<hr />
							</Col>
							<Col md={12}>
								<Tabs
									activeKey={activTabe}
									onSelect={k => changeTabe(k)}
									className="sso-tabs">
									{/* <Tab className="sso-tab" eventKey="students" title="Students">
										<div className="mt-5">
											<DatatableWrapper
												body={data}
												headers={columns}
												paginationOptionsProps={{
													initialState: {
														rowsPerPage: 100,
														options: [5, 10, 15, 20],
													},
												}}>
												<Row>
													<Col md={2}>
														<Filter
															placeholder="search"
															classes={{
																input: "input-sm sso_input",
																clearButton: "clearButton",
															}}
														/>
													</Col>
													<Col md={12} className="mb-2">
														<Table className="sso_tabel" responsive bordered>
															<TableHeader />
															<TableBody />
														</Table>
													</Col>
												</Row>
											</DatatableWrapper>
										</div>
									</Tab> */}
									<Tab className="sso-tab" eventKey="staff" title="Staff">
										<div className="mt-5 d-flex flex-column gap-4 w-25 ">
											{globalAccess &&
												!!globalAccess.length &&
												globalAccess.map((item, index) => (
													<Switch
														key={index}
														boxClasname="d-flex gap-4 align-items-center justify-content-between"
														style={{
															width: 90,
														}}
														label={item.userTypeName}
														id={item.userTypeName}
														onChange={event => {
															globalAccessUpdate(event, item.id);
															// onCheckChange(event, "allowSelectionProcessCopy");
														}}
														value={item.status}
													/>
												))}
										</div>
									</Tab>
								</Tabs>
							</Col>
						</Row>
					</CardBody>
				</Card>
			</Container>
		</>
	);
}

export default Access;
