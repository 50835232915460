import React, { useContext, useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import TextImage from "./TextImage";
import SettingsSelection from "./SettingsSelection";
import SettingsInstruction from "./SettingsInstruction";
import SettingsSection from "./SettingsSection";
import ReOrder from "./ReOrder";
import Delete from "./Delete";
import { SelectionProcessContext } from "../SelectionProcess";
import { NORMAL_KEY } from "../../../helpers/constants/constants";
import { useStepContext } from "@mui/material";
import { number } from "yup";

const SelectionProcesTabs = ({ changeTabe, activTabe }) => {
	const {
		data,
		formType,
		isFileChange,
		setIsFileChange,
		isConfirm,
		setIsConfirm,
		nextTabe,
		setNextTabe,
	} = useContext(SelectionProcessContext);
	const [isShowInstructiontextImageTabe, setIsShowInstructiontextImageTabe] =
		useState(true);
	useEffect(() => {
		if (
			data &&
			data?.instructionFunction === NORMAL_KEY &&
			formType === "instruction"
		) {
			setIsShowInstructiontextImageTabe(true);
		} else if (formType === "instruction") {
			setIsShowInstructiontextImageTabe(false);
			changeTabe("settings");
		}
	}, [data, formType]);
	const checkChange = k => {
		let key = null;
		Object.entries(isFileChange).forEach(([el, value]) => {
			if (value) {
				key = el;
			}
		});
		if (key) {
			setIsConfirm(prev => ({
				...prev,
				[key]: true,
			}));
			setNextTabe(k);
		} else {
			// setIsFileChange({
			// 	textImage: false,
			// 	settings: false,
			// 	reOrder: false,
			// });
			// setIsConfirm({
			// 	textImage: false,
			// 	settings: false,
			// 	reOrder: false,
			// });
			changeTabe(k);
			setNextTabe(null);
		}
	};

	if (formType === "section") {
		return (
			<Tabs
				activeKey={activTabe}
				onSelect={k => {
					checkChange(k);
					// changeTabe(k);
				}}
				className="sso-tabs">
				<Tab className="sso-tab" eventKey="textImage" title="Text/Image">
					<TextImage />
				</Tab>
				<Tab className="sso-tab" eventKey="settings" title="Settings">
					<SettingsSection />
				</Tab>
				<Tab className="sso-tab" eventKey={"reOrder"} title={"Re-Order"}>
					<ReOrder />
				</Tab>
				<Tab className="sso-tab" eventKey={"delete"} title={"Delete"}>
					<Delete activTabe={activTabe} />
				</Tab>
			</Tabs>
		);
	}
	if (formType === "instruction") {
		return (
			<Tabs
				activeKey={activTabe}
				onSelect={k => {
					checkChange(k);
				}}
				className="sso-tabs">
				{isShowInstructiontextImageTabe && (
					<Tab className="sso-tab" eventKey="textImage" title="Text/Image">
						<TextImage />
					</Tab>
				)}
				<Tab className="sso-tab" eventKey="settings" title="Settings">
					<SettingsInstruction />
				</Tab>
				<Tab className="sso-tab" eventKey={"reOrder"} title={"Re-Order"}>
					<ReOrder />
				</Tab>
				<Tab className="sso-tab" eventKey={"delete"} title={"Delete"}>
					<Delete activTabe={activTabe} />
				</Tab>
			</Tabs>
		);
	}
	if (formType === "selection") {
		return (
			<Tabs
				activeKey={activTabe}
				onSelect={k => {
					checkChange(k);
				}}
				className="sso-tabs">
				<Tab className="sso-tab" eventKey="settings" title="Settings">
					<SettingsSelection />
				</Tab>
				<Tab className="sso-tab" eventKey={"reOrder"} title={"Re-Order"}>
					<ReOrder />
				</Tab>
				<Tab className="sso-tab" eventKey={"delete"} title={"Delete"}>
					<Delete activTabe={activTabe} />
				</Tab>
				<Tab className="sso-tab" eventKey="connections" title="Connections">
					<TextImage />
				</Tab>
			</Tabs>
		);
	}
	return null;
};

export default SelectionProcesTabs;
