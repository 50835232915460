import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Input from "../../../components/inputs/Input";
import RadioBox from "../../../components/inputs/RadioBox";
import Switch from "../../../components/inputs/Switch";
import ReactSelectOption from "../../../components/inputs/ReactSelectOption";
import listTimezones from "../../../helpers/timezones/timezones";
import {
	AUSTRALIA_KEY,
	UK_EUROPE_KEY,
} from "../../../helpers/constants/constants";
import MainService from "../../../helpers/services/mainService";
import { ApiService } from "../../../helpers/services/apiSrevice";

function SchoolDetails({ values, setValues, shortKey, errors, setErrors }) {
	const [languages, setLanguages] = useState([]);
	useEffect(() => {
		MainService.callApi({
			api: ApiService.getAllActiveaLnguage,
			cb: getLanguages,
		});
	}, []);

	const getLanguages = response => {
		if (response && response.data) {
			setLanguages(response.data);
		}
	};

	const onChange = (event, fieldName, errorfieldName) => {
		if (errorfieldName && errors && errors[errorfieldName]) {
			delete errors[errorfieldName];
			setErrors(errors);
		}
		setValues(values => ({
			...values,
			[shortKey]: {
				...values[shortKey],
				[fieldName]:
					fieldName === "administeredFrom"
						? +event.target.value
						: event.target.value,
			},
		}));
	};

	const onSelectOptionChange = (item, fieldName, errorfieldName) => {
		if (!item) {
			return false;
		}
		if (errorfieldName && errors && errors[errorfieldName]) {
			delete errors[errorfieldName];
			setErrors(errors);
		}

		setValues(values => ({
			...values,
			[shortKey]: {
				...values[shortKey],
				[fieldName]: item.value,
			},
		}));
	};

	const onCheckChange = (event, fieldName) => {
		setValues(values => ({
			...values,
			[shortKey]: {
				...values[shortKey],
				[fieldName]: event.target.checked,
			},
		}));
	};

	return (
		<Row className="mt-5 gap-3">
			<Col md={12} className="mb-3">
				<Row className="align-items-center">
					<Col md={12} lg={3}>
						<label className="sso_label">
							Administered From
							<span className="sso_required">*</span>
						</label>
					</Col>
					<Col md={12} lg={4} className="d-flex gap-3">
						<RadioBox
							id={"Australia"}
							name={"administeredFrom"}
							value={AUSTRALIA_KEY}
							label={"Australia"}
							checked={values[shortKey].administeredFrom === AUSTRALIA_KEY}
							onChange={event => {
								onChange(event, "administeredFrom");
							}}
						/>
						<RadioBox
							id={"UK/Europe"}
							name={"administeredFrom"}
							value={UK_EUROPE_KEY}
							label={"UK/Europe"}
							checked={values[shortKey].administeredFrom === UK_EUROPE_KEY}
							onChange={event => {
								onChange(event, "administeredFrom");
							}}
						/>
					</Col>
				</Row>
			</Col>
			{/* <Col md={12}>
				<Input
					label={"Worktrack Code"}
					required={true}
					labelCol={"col-md-12 col-lg-3"}
					inputCol={"col-md-12 col-lg-4"}
					id={"worktrackCode"}
					value={values[shortKey].worktrackCode || ""}
					onChange={event => {
						onChange(event, "worktrackCode");
					}}
					action={() => {
						getInfo();
					}}
				/>
			</Col> */}
			<Col md={12}>
				<Input
					id={"postCode"}
					label={"Post Code"}
					required={true}
					errorMessage={errors && errors[`${shortKey}.postCode`]}
					labelCol={"col-md-12 col-lg-3"}
					inputCol={"col-md-12 col-lg-4"}
					value={values[shortKey].postCode || ""}
					onChange={event => {
						onChange(event, "postCode", `${shortKey}.postCode`);
					}}
				/>
			</Col>
			<Col md={12}>
				<Input
					label={"School Code"}
					required={true}
					errorMessage={errors && errors[`${shortKey}.schoolCode`]}
					labelCol={"col-md-12 col-lg-3"}
					inputCol={"col-md-12 col-lg-4"}
					value={values[shortKey].schoolCode || ""}
					onChange={event => {
						onChange(event, "schoolCode", `${shortKey}.schoolCode`);
					}}
					id={"schoolCode"}
				/>
			</Col>
			<Col md={12}>
				<Input
					label={"School Name"}
					required={true}
					labelCol={"col-md-12 col-lg-3"}
					inputCol={"col-md-12 col-lg-4"}
					errorMessage={errors && errors[`${shortKey}.schoolName`]}
					value={values[shortKey].schoolName || ""}
					onChange={event => {
						onChange(event, "schoolName", `${shortKey}.schoolCode`);
					}}
					id={"schoolName"}
				/>
			</Col>
			<Col md={12}>
				<Row className="align-items-center">
					<Col md={12} lg={3}>
						<label className="sso_label">
							Time Zone
							<span className="sso_required">*</span>
						</label>
					</Col>
					<Col md={12} lg={4} className="d-flex gap-3">
						<ReactSelectOption
							value={values[shortKey].timeZone}
							errorMessage={errors && errors[`${shortKey}.timeZone`]}
							selectedValue={(() => {
								const selectedItem = {
									...listTimezones.find(
										data => data.label === values[shortKey].timeZone,
									),
								};
								if (Object.keys(selectedItem).length) {
									selectedItem.value = selectedItem.label;
									return selectedItem;
								} else {
									return {
										value: null,
										label: "Choose",
									};
								}
							})()}
							items={listTimezones
								.filter(item => item.value !== values[shortKey].timeZone)
								.map(item => ({
									...item,
									value: item.label,
								}))}
							onChange={item =>
								onSelectOptionChange(item, "timeZone", `${shortKey}.timeZone`)
							}
						/>
					</Col>
				</Row>
			</Col>
			<Col md={12}>
				<Row className="align-items-center">
					<Col md={12} lg={3}>
						<label className="sso_label">
							Select Language
							<span className="sso_required">*</span>
						</label>
					</Col>
					<Col md={12} lg={4} className="d-flex gap-3">
						<ReactSelectOption
							value={values[shortKey].languageId}
							errorMessage={errors && errors[`${shortKey}.languageId`]}
							selectedValue={(() => {
								const selectedItem = {
									...languages.find(
										data => data.id === values[shortKey].languageId,
									),
								};
								if (Object.keys(selectedItem).length) {
									selectedItem.label = selectedItem.name;
									selectedItem.value = selectedItem.id;
									return selectedItem;
								} else {
									return {
										value: null,
										label: "Choose",
									};
								}
							})()}
							items={languages
								.filter(item => item.value !== values[shortKey].languageId)
								.map(item => ({
									...item,
									value: item.id,
									label: item.name,
								}))}
							onChange={item =>
								onSelectOptionChange(
									item,
									"languageId",
									`${shortKey}.languageId`,
								)
							}
						/>
					</Col>
				</Row>
			</Col>
			<Col md={12}>
				<Row className="align-items-center">
					<Col md={12} lg={3}>
						<Form.Label
							htmlFor="allowSelectionProcessCopy"
							className="sso_label">
							Allow Selection Process Copy
							<span className="sso_required">*</span>
						</Form.Label>
					</Col>
					<Col md={12} lg={4} className="d-flex gap-3">
						<div>
							<Switch
								id={"allowSelectionProcessCopy"}
								onChange={event => {
									onCheckChange(event, "allowSelectionProcessCopy");
								}}
								checked={values[shortKey].allowSelectionProcessCopy}
								value={values[shortKey].allowSelectionProcessCopy}
							/>
						</div>
					</Col>
				</Row>
			</Col>
		</Row>
	);
}

export default SchoolDetails;
