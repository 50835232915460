import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import Input from "../../../components/inputs/Input";
import RadioBox from "../../../components/inputs/RadioBox";
import ReactSelectOption from "../../../components/inputs/ReactSelectOption";
import { FaCheck, FaMinus, FaPlus } from "react-icons/fa";
import MainService from "../../../helpers/services/mainService";
import { ApiService } from "../../../helpers/services/apiSrevice";
import AlertService from "../../../helpers/services/alertService";
import { SelectionProcessContext } from "../SelectionProcess";

function SettingsSection({}) {
	const {
		data,
		setData,
		changeTabe,
		isFileChange,
		setIsFileChange,
		isConfirm,
		setIsConfirm,
		nextTabe,
		setNextTabe,
	} = useContext(SelectionProcessContext);
	const [values, setValues] = useState(null);
	const buttonRef = useRef(null);

	useEffect(() => {
		if (data) {
			setValues({
				sectionSettings: {
					minSelections: "",
					maxSelections: "",
					minWeight: "",
					maxWeight: "",
				},
				...data,
			});
		}
	}, [data]);

	// useEffect(() => {
	// 	if (isConfirm.settings) {
	// 		AlertService.alertConfirm(
	// 			"Did you want to Save?",
	// 			<Row>
	// 				<Col md={12}>
	// 					<span>
	// 						Click Save to keep the changes you have made, or click Discard to
	// 						ignore the changes.
	// 					</span>
	// 				</Col>
	// 			</Row>,
	// 			"Save",
	// 			"Discard",
	// 		)
	// 			.then(() => {
	// 				if (buttonRef && buttonRef.current) {
	// 					setIsFileChange({
	// 						textImage: false,
	// 						settings: false,
	// 						reOrder: false,
	// 					});
	// 					setIsConfirm({
	// 						textImage: false,
	// 						settings: false,
	// 						reOrder: false,
	// 					});
	// 					changeTabe(nextTabe);
	// 					buttonRef.current?.click();
	// 				}
	// 			})
	// 			.catch(() => {
	// 				setIsFileChange({
	// 					textImage: false,
	// 					settings: false,
	// 					reOrder: false,
	// 				});
	// 				setIsConfirm({
	// 					textImage: false,
	// 					settings: false,
	// 					reOrder: false,
	// 				});
	// 				if (data) {
	// 					setValues(null);
	// 					// setValues(null);
	// 					setTimeout(() => {
	// 						setValues({
	// 							...data,
	// 							// textOrImage: null,
	// 						});
	// 					}, 10);
	// 				}
	// 				changeTabe(nextTabe);
	// 				setNextTabe(null);
	// 			});
	// 	}
	// }, [isConfirm]);

	useEffect(() => {
		if (isConfirm.settings) {
			AlertService.alertConfirm(
				"Did you want to Save?",
				<Row>
					<Col md={12}>
						<span>
							Click Save to keep the changes you have made, or click Discard to
							ignore the changes.
						</span>
					</Col>
				</Row>,
				"Save",
				"Discard",
			)
				.then(() => {
					if (buttonRef && buttonRef.current) {
						setIsFileChange({
							textImage: false,
							settings: false,
							reOrder: false,
						});
						setIsConfirm({
							textImage: false,
							settings: false,
							reOrder: false,
						});
						changeTabe(nextTabe);
						buttonRef.current?.click();
					}
				})
				.catch(() => {
					setIsFileChange({
						textImage: false,
						settings: false,
						reOrder: false,
					});
					setIsConfirm({
						textImage: false,
						settings: false,
						reOrder: false,
					});
					if (data) {
						setValues(null);
						// setValues(null);
						setTimeout(() => {
							setValues({
								...data,
								// textOrImage: null,
							});
						}, 10);
					}
					changeTabe(nextTabe);
					setNextTabe(null);
				});
		}
	}, [isConfirm]);
	const onChange = (event, fieldName) => {
		setIsFileChange(prev => ({
			...prev,
			settings: true,
		}));
		setValues(values => ({
			...values,
			[fieldName]: event.target.value,
		}));
	};

	const onChangeRadio = (event, fieldName) => {
		setIsFileChange(prev => ({
			...prev,
			settings: true,
		}));
		setValues(values => ({
			...values,
			[fieldName]: JSON.parse(event.target.value),
		}));
	};

	const onNumberChange = (event, fieldName, errorfieldName) => {
		if (
			event.target.value.includes("e") ||
			event.target.value.includes(".") ||
			event.target.value.includes("-")
		) {
			return false;
		}
		setIsFileChange(prev => ({
			...prev,
			settings: true,
		}));

		if (event.target.value === "" || Number.isInteger(+event.target.value)) {
			setValues(values => ({
				...values,
				sectionSettings: {
					...values.sectionSettings,
					[fieldName]: event.target.value,
				},
			}));
		}
	};

	const onSubmit = e => {
		e.preventDefault();
		MainService.callApi({
			api: ApiService.sectionUpdate,
			data: values,
			cb: response => {
				if (response && response.success) {
					setData(values);
				}
				if (response && response.success === true) {
					AlertService.alert("success", "Data seved");
				}
			},
		});
	};

	return (
		values && (
			<form onSubmit={onSubmit}>
				<Row className="gap-3 mt-3">
					<Col md={12}>
						<Input
							label={"Report Name"}
							id={"reportName"}
							value={values?.reportName || ""}
							required={true}
							// errorMessage={errors && errors[`studentCode`]}
							onChange={event => {
								onChange(event, "reportName");
							}}
						/>
					</Col>
					<Col md={12} className="d-flex gap-3 flex-column flex-lg-row">
						<label className="w-lg-50 w-100">Show on reports</label>
						<div className="d-flex align-items-center gap-3 flex-wrap w-lg-50 w-100">
							<RadioBox
								id={"showOnReportsyes"}
								name={"showOnReports"}
								value={true}
								label={"Yes"}
								checked={values?.showOnReports}
								onChange={event => {
									onChangeRadio(event, "showOnReports");
								}}
							/>
							<RadioBox
								id={"showOnReportsno"}
								name={"showOnReports"}
								value={false}
								label={"No"}
								checked={!values?.showOnReports}
								onChange={event => {
									onChangeRadio(event, "showOnReports");
								}}
							/>
						</div>
					</Col>
					{/* <Col md={12}>
					<Row className="gap-2">
						<Col md={12}>
							<label>Selection</label>
						</Col>
						<Col md={12} lg={9}>
							<ReactSelectOption />
						</Col>
						<Col md={12} lg={2}>
							<button
								className={`btn btn-gren btn-sm`}
								type="button"
								onClick={() => {
									// openPopup();
								}}>
								<FaPlus />
								<span>Add</span>
							</button>
						</Col>
					</Row>
				</Col>
				<Col md={12}>
					<Table striped hover responsive className="">
						<thead>
							<tr>
								<th>Section:</th>
								<th>Instruction</th>
								<th>Selection</th>
								<th>Action</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Select something </td>
								<td>Select A</td>
								<td>10VAR - Visual Arts</td>
								<td>
									<button type="button" className="btn btn-red table_button">
										<FaMinus />
										Remove
									</button>
								</td>
							</tr>
						</tbody>
					</Table>
				</Col> */}
					<Col md={12} className="d-flex gap-3 flex-column flex-lg-row">
						<label className="w-lg-50 w-100">
							Set number of selections based on the whole Section
						</label>
						<div className="d-flex align-items-center w-lg-50 w-100">
							<Input
								width={false}
								id={"minSelections"}
								inputClassName={"input-xsmall"}
								// type="number"
								small={true}
								value={values?.sectionSettings.minSelections || ""}
								onChange={event => {
									onNumberChange(event, "minSelections");
								}}
							/>
							<FaMinus />
							<Input
								width={false}
								id={"maxSelections"}
								inputClassName={"input-xsmall"}
								// type="number"
								small={true}
								value={values?.sectionSettings.maxSelections || ""}
								onChange={event => {
									onNumberChange(event, "maxSelections");
								}}
							/>
						</div>
					</Col>
					<Col md={12} className="d-flex gap-3 flex-column flex-lg-row">
						<label className="w-lg-50 w-100">
							Apply a selection weighting count for the whole Section
						</label>
						<div
							md={12}
							lg={6}
							className="d-flex align-items-center w-lg-50 w-100">
							<Input
								width={false}
								id={"minWeight"}
								inputClassName={"input-xsmall"}
								// type="number"
								small={true}
								value={values?.sectionSettings.minWeight || ""}
								onChange={event => {
									onNumberChange(event, "minWeight");
								}}
							/>
							<FaMinus />
							<Input
								width={false}
								id={"maxWeight"}
								inputClassName={"input-xsmall"}
								// type="number"
								small={true}
								value={values?.sectionSettings.maxWeight || ""}
								onChange={event => {
									onNumberChange(event, "maxWeight");
								}}
							/>
						</div>
					</Col>
					<Col md={12} className="d-flex gap-3 flex-column flex-lg-row">
						<label className="w-lg-50 w-100">
							Allow the same code to be selected more than once in this Section
						</label>
						<div
							md={12}
							lg={6}
							className="d-flex gap-4 flex-wrap w-lg-50 w-100">
							<RadioBox
								id={"allowSameCodeMultipleTimesyes"}
								name={"allowSameCodeMultipleTimes"}
								value={true}
								label={"No"}
								checked={values?.allowSameCodeMultipleTimes}
								onChange={event => {
									onChangeRadio(event, "allowSameCodeMultipleTimes");
								}}
							/>
							<RadioBox
								id={"allowSameCodeMultipleTimesno"}
								name={"allowSameCodeMultipleTimes"}
								value={false}
								label={"No"}
								checked={!values?.allowSameCodeMultipleTimes}
								onChange={event => {
									onChangeRadio(event, "allowSameCodeMultipleTimes");
								}}
							/>
						</div>
					</Col>
					<Col className="d-flex justify-content-end mt-3">
						<Button
							ref={buttonRef}
							disabled={!isFileChange?.settings}
							type="submit"
							className="btn-primary d-flex gap-2 align-items-center justify-content-center">
							<FaCheck />
							Save
						</Button>
					</Col>
				</Row>
			</form>
		)
	);
}

export default SettingsSection;
