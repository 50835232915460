import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row, Table } from "react-bootstrap";
import CardTitle from "../../components/cardTitle/CardTitle";
import { FiPlus } from "react-icons/fi";
import { FaRegEdit } from "react-icons/fa";
import { Link } from "react-router-dom";
import Switch from "../../components/inputs/Switch";
import Popup from "../../components/modals/Popup";
import ReactSelectOption from "../../components/inputs/ReactSelectOption";
import MainService from "../../helpers/services/mainService";
import { ApiService } from "../../helpers/services/apiSrevice";
import AlertService from "../../helpers/services/alertService";
import {
	DatatableWrapper,
	Filter,
	TableBody,
	TableHeader,
} from "react-bs-datatable";

function Translate(props) {
	const [languages, setLanguages] = useState([
		{
			name: "English",
			isocode: "eng",
			isocode2: "eng",
		},
		{
			name: "Russian",
			isocode: "rus",
			isocode2: "ru",
		},
	]);
	const [activLangs, setActivLangs] = useState([]);
	const [show, setShow] = useState(false);
	const [values, setValues] = useState(null);

	useEffect(() => {
		MainService.callApi({
			api: ApiService.getAllLnguages,
			cb: response => {
				if (response && response.data && response.data.length) {
					setActivLangs(response.data);
				}
			},
		});
	}, []);

	const onSave = () => {
		if (!values) return false;
		MainService.callApi({
			api: ApiService.createLnguage,
			data: values,
			isModal: true,
			cb: response => {
				if (response && response.data) {
					AlertService.alert("success", "Data seved");
					setActivLangs([...activLangs, response.data]);
					onClose();
					// setLanguages(response.data);
				}
			},
		});
	};

	const changeStatus = item => {
		if (!item) return false;
		MainService.callApi({
			api: !item.isActive
				? ApiService.languageActivate
				: ApiService.languageDeactivate,
			data: item.id,
			cb: response => {
				if (response && response.data === true && response.success === true) {
					AlertService.alert("success", "Data seved");
					let newSchools = activLangs.map(el => {
						if (el.id === item.id) {
							return {
								...el,
								isActive: !item.isActive,
							};
						}
						return el;
					});
					setActivLangs(newSchools);
				}
			},
		});
	};

	const openModal = () => {
		MainService.callApi({
			api: ApiService.libraryLanguages,
			cb: response => {
				if (
					response &&
					response.data &&
					response.data.libraryData &&
					response.data.libraryData.length
				) {
					setLanguages(
						response.data.libraryData.map(el => ({
							name: el.displayName,
							isocode: el.data1,
							isocode2: el.data2,
						})),
					);
				}
				setShow(true);
			},
		});
	};

	const onClose = () => {
		if (values) setValues(null);
		setShow(false);
	};

	const onSelectOptionChange = item => {
		if (!item) return false;
		setValues(item);
	};

	const columns = [
		{
			prop: "name",
			title: "Language",
			isFilterable: true,
			isSortable: true,
			cell: row => <div>{row.name}</div>,
		},
		{
			prop: "isocode2",
			title: "Language Code",
			isFilterable: true,
			isSortable: true,
			cell: row => <div>{row.isocode2}</div>,
		},
		{
			prop: "status",
			title: "Status",
			cell: row => (
				<Switch
					id={`hola${row.id}`}
					value={row.isActive}
					onChange={event => {
						changeStatus(row);
					}}
					on="Active"
					off="Inactive"
					size={"sm"}
				/>
			),
		},

		{
			name: "Action",
			title: "Action",
			prop: "button",
			cell: row => (
				<div className="d-flex justify-content-center">
					<Link
						to={`edit/${row.id}/admin`}
						className="table_link d-flex align-items-center d-flex ">
						<FaRegEdit /> <span>Edit</span>
					</Link>
				</div>
			),
		},
	];

	return (
		<Container className="pt-2">
			<Popup
				show={show}
				title={"Create"}
				handleClose={onClose}
				action={onSave}
				buttonTitle="Save">
				<Row>
					<Col md={12}>
						<ReactSelectOption
							value={values?.isocode2 || ""}
							selectedValue={(() => {
								const selectedItem = {
									...languages.find(
										data => data?.isocode2 === values?.isocode2,
									),
								};
								if (Object.keys(selectedItem).length) {
									selectedItem.value = selectedItem?.isocode2;
									selectedItem.label = selectedItem.name;
									return selectedItem;
								} else {
									return {
										value: null,
										label: "Choose",
									};
								}
							})()}
							items={languages
								.filter(item => item.isocode2 !== values?.isocode2)
								.map(data => ({
									...data,
									label: data.name,
									value: data.isocode2,
								}))}
							onChange={item => onSelectOptionChange(item)}
						/>
					</Col>
				</Row>
			</Popup>
			<Card className="sso_page_contenet">
				<Row>
					<Col md={12}>
						<CardTitle title={"LANGUAGES"}></CardTitle>
					</Col>
				</Row>
				<DatatableWrapper
					body={activLangs}
					headers={columns}
					paginationOptionsProps={{
						initialState: {
							rowsPerPage: 100,
							options: [5, 10, 15, 20],
						},
					}}>
					<Row className="my-3 justify-content-between gap-3">
						<Col md={2}>
							<Filter
								placeholder="search"
								classes={{
									input: "input-sm sso_input",
									clearButton: "clearButton",
								}}
							/>
						</Col>
						<Col md={2} className="d-flex justify-content-end">
							<button
								className="btn btn-primary btn-sm"
								onClick={() => {
									openModal();
								}}>
								<FiPlus />
								Add New
							</button>
						</Col>
						<Col md={12} className="mb-2">
							<Table responsive bordered className="sso_tabel">
								<TableHeader />
								<TableBody />
							</Table>
						</Col>
					</Row>
				</DatatableWrapper>
			</Card>
		</Container>
	);
}

export default Translate;
