import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";

function RefreshButton({
	tooltipTitle = "Refresh Table",
	action,
	children,
	placement = "top",
	isAnimation = true,
}) {
	const { pageSpinners } = useSelector(state => state.spinners);

	const renderTooltip = props => (
		<Tooltip id="button-tooltip" {...props}>
			{tooltipTitle}
		</Tooltip>
	);

	return (
		<OverlayTrigger
			placement={placement}
			delay={{ show: 250, hide: 400 }}
			overlay={renderTooltip}>
			<button
				className={`refresh_button`}
				onClick={() => {
					if (action) action();
				}}>
				{isAnimation ? (
					<span
						className={`${
							pageSpinners && pageSpinners.length ? "rotate" : "rotate"
						}`}>
						{children}
					</span>
				) : (
					children
				)}
			</button>
		</OverlayTrigger>
	);
}

export default RefreshButton;
