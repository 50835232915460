import React from 'react'
import { Card, CardBody, Container } from 'react-bootstrap'
import CardTitle from '../../components/cardTitle/CardTitle'

export default function Enterprise() {
  return (
    <Container>
      <Card>
        <CardBody>

          <CardTitle title="Enterprise">
          </CardTitle>


        </CardBody>
      </Card>
    </Container>
  )
}
