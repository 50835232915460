import React from "react";
import Select from "react-select";

// const NoOptionsMessage = props => {
// 	return (
// 		<components.NoOptionsMessage {...props}>
// 			<span
// 				className="fs14 font_weight400 font_ubuntu"
// 				style={{ color: "#dc3545" }}>
// 				Нет доступных вариантов
// 			</span>
// 		</components.NoOptionsMessage>
// 	);
// };

const ReactSelectOption = ({
	name,
	failedFields,
	isInvalidSubmit,
	value,
	classNamePrefix,
	selectedValue,
	defaultValue,
	placeholder,
	items = [],
	isDisabled,
	isSearchable = true,
	onChange,
	className,
	onFocus,
	isInvalidField,
	isLine = false,
	errorMessage,
}) => {
	let customStyles = {};

	customStyles = {
		control: (provided, state) => ({
			...provided,
			minHeight: "34px",
			width: "100%",
			padding: "0 0 0 12px",
			borderColor: state.isFocused ? "#93a1bb" : "#c2cad8",
			"&:hover": {
				borderColor: "#c2cad8",
			},
		}),
		indicatorSeparator: base => ({
			...base,
			width: "0",
		}),
		dropdownIndicator: base => ({
			...base,
			padding: "6px 12px",
		}),

		indicatorContainer: base => ({
			...base,
		}),

		placeholder: provided => ({
			...provided,
			whiteSpace: "nowrap",
			overflow: "hidden",
			textOverflow: "ellipsis",
		}),
		input: provided => ({
			...provided,
			padding: 0,
			fontSize: "14px", // Change input text size
			fontWeight: 400,
			margin: 0,
		}),
		singleValue: provided => ({
			...provided,
			whiteSpace: "nowrap",
			overflow: "hidden",
			textOverflow: "ellipsis",
		}),
	};

	const failedFieldName = name
		? name.charAt(0).toUpperCase() + name.slice(1)
		: "";
	return (
		<div className="w-100">
			<Select
				className={`basic-single ${className ? className : ""} ${
					(failedFields && failedFields.hasOwnProperty(failedFieldName)) ||
					(isInvalidSubmit &&
						(value === null || value === undefined || value === false)) ||
					(isInvalidField && isLine)
						? "select-fail-laine"
						: (failedFields && failedFields.hasOwnProperty(failedFieldName)) ||
						  (isInvalidSubmit &&
								(value === null || value === undefined || value === false)) ||
						  isInvalidField
						? "select-fail"
						: ""
				}`}
				// components={{ NoOptionsMessage }}
				classNamePrefix={`sso-select ${classNamePrefix ? classNamePrefix : ""}`}
				isSearchable={isSearchable}
				placeholder={placeholder}
				name={name}
				defaultValue={defaultValue}
				value={selectedValue}
				options={items}
				isDisabled={isDisabled}
				onChange={onChange}
				styles={customStyles}
				onFocus={onFocus}
				maxMenuHeight={300}
			/>
			{errorMessage && value && (
				<small className="text-danger d-block mt-1">{errorMessage}</small>
			)}
		</div>
	);
};

export default ReactSelectOption;
