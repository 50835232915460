import React, { useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import CustomBreadcrumb from "../../components/breadcrumbs/Breadcrumb";
import { Card, CardBody, Col, Container, Row, Table } from "react-bootstrap";
import CardTitle from "../../components/cardTitle/CardTitle";
import RadioBox from "../../components/inputs/RadioBox";
import MultiReactSelectOption from "../../components/inputs/MultiReactSelectOption";
import {
	DatatableWrapper,
	Filter,
	TableBody,
	TableHeader,
} from "react-bs-datatable";
import { FaDownload, FaPrint } from "react-icons/fa";
import ReactSelectOption from "../../components/inputs/ReactSelectOption";
import { useReactToPrint } from "react-to-print";
import Input from "../../components/inputs/Input";

const tableTypes = [1, 2, 3, 5, 6, 7];

const toDo = [
	{ id: 1, name: "10ENG" },
	{ id: 3, name: "10SCI" },
	{ id: 3, name: "10SCM" },
	{ id: 4, name: "test" },
	{ id: 5, name: "10Arm" },
	{ id: 6, name: "test-2" },
];

function Extracts(props) {
	const contentRef = useRef(null);
	const reactToPrintFn = useReactToPrint({ contentRef });
	const { yearId } = useParams();
	const [data, setData] = useState([
		// {
		// 	studentCode: 126,
		// 	externalCode: "dsxiun",
		// 	house: "dsxhjn ",
		// },
	]);
	const [values, setValues] = useState({
		type: 1,
	});

	const columns = type => {
		switch (type) {
			case 1:
				return [
					{
						prop: "studentCode",
						title: "Student code",
						isFilterable: true,
						isSortable: true,
						cell: row => (
							<div>
								<p className="d-inline-block">{row.studentCode}</p>
							</div>
						),
					},
					{
						prop: "externalCode",
						title: "External code",
						isFilterable: true,
						isSortable: true,
						cell: row => (
							<div>
								<p className="d-inline-block">{row.externalCode}</p>
							</div>
						),
					},
					{
						prop: "studentName",
						title: "Student Name",
						isFilterable: true,
						isSortable: true,
						cell: row => (
							<div>
								<p className="d-inline-block">{row.studentName}</p>
							</div>
						),
					},
					{
						prop: "house",
						title: "House",
						isFilterable: true,
						isSortable: true,
						cell: row => (
							<div>
								<p className="d-inline-block">{row.house}</p>
							</div>
						),
					},
					{
						prop: "homeGroup",
						title: "Home Group",
						isFilterable: true,
						isSortable: true,
						cell: row => (
							<div>
								<p className="d-inline-block">{row.homeGroup}</p>
							</div>
						),
					},
					{
						prop: "homeTeacher",
						title: "Home Teacher",
						isFilterable: true,
						isSortable: true,
						cell: row => (
							<div>
								<p className="d-inline-block">{row.homeTeacher}</p>
							</div>
						),
					},
					{
						prop: "courseCounsellor",
						title: "Course Counsellor",
						isFilterable: true,
						isSortable: true,
						cell: row => (
							<div>
								<p className="d-inline-block">{row.courseCounsellor}</p>
							</div>
						),
					},
					{
						prop: "section",
						title: "Section",
						isFilterable: true,
						isSortable: true,
						cell: row => (
							<div>
								<p className="d-inline-block">{row.section}</p>
							</div>
						),
					},
					{
						prop: "instruction",
						title: "Instruction",
						isFilterable: true,
						isSortable: true,
						cell: row => (
							<div>
								<p className="d-inline-block">{row.instruction}</p>
							</div>
						),
					},
					{
						prop: "selection",
						title: "Selection",
						isFilterable: true,
						isSortable: true,
						cell: row => (
							<div>
								<p className="d-inline-block">{row.selection}</p>
							</div>
						),
					},
					{
						prop: "preferenceOrder",
						title: "Preference Order",
						isFilterable: true,
						isSortable: true,
						cell: row => (
							<div>
								<p className="d-inline-block">{row.preferenceOrder}</p>
							</div>
						),
					},
					{
						prop: "selectionDateOreTime",
						title: "Selection Date / Time",
						isFilterable: true,
						isSortable: true,
						cell: row => (
							<div>
								<p className="d-inline-block">{row.selectionDateOreTime}</p>
							</div>
						),
					},
					{
						prop: "waitlisted",
						title: "Waitlisted",
						isFilterable: true,
						isSortable: true,
						cell: row => (
							<div>
								<p className="d-inline-block">{row.waitlisted}</p>
							</div>
						),
					},
					{
						prop: "weighting",
						title: "Weighting",
						isFilterable: true,
						isSortable: true,
						cell: row => (
							<div>
								<p className="d-inline-block">{row.weighting}</p>
							</div>
						),
					},
					{
						prop: "fee",
						title: "Fee",
						isFilterable: true,
						isSortable: true,
						cell: row => (
							<div>
								<p className="d-inline-block">{row.fee}</p>
							</div>
						),
					},
				];
			case 2:
				return [
					{
						prop: "fullName",
						title: "FullName",
						isFilterable: true,
						isSortable: true,
						cell: row => (
							<div>
								<p className="d-inline-block">{row.fullName}</p>
							</div>
						),
					},
					{
						prop: "studentCode",
						title: "StudentCode",
						isFilterable: true,
						isSortable: true,
						cell: row => (
							<div>
								<p className="d-inline-block">{row.studentCode}</p>
							</div>
						),
					},
					{
						prop: "subjCode",
						title: "SubjCode",
						isFilterable: true,
						isSortable: true,
						cell: row => (
							<div>
								<p className="d-inline-block">{row.subjCode}</p>
							</div>
						),
					},
					{
						prop: "tTPeriod",
						title: "TTPeriod",
						isFilterable: true,
						isSortable: true,
						cell: row => (
							<div>
								<p className="d-inline-block">{row.tTPeriod}</p>
							</div>
						),
					},
				];
			case 3:
				return [
					{
						prop: "stuCode",
						title: "STUCODE",
						isFilterable: true,
						isSortable: true,
						cell: row => (
							<div>
								<p className="d-inline-block">{row.stuCode}</p>
							</div>
						),
					},
					{
						prop: "studentName",
						title: "Student Name",
						isFilterable: true,
						isSortable: true,
						cell: row => (
							<div>
								<p className="d-inline-block">{row.studentName}</p>
							</div>
						),
					},
					{
						prop: "schYear",
						title: "SCHYEAR",
						isFilterable: true,
						isSortable: true,
						cell: row => (
							<div>
								<p className="d-inline-block">{row.schYear}</p>
							</div>
						),
					},
					{
						prop: "courses",
						title: "COURSES",
						isFilterable: true,
						isSortable: true,
						cell: row => (
							<div>
								<p className="d-inline-block">{row.courses}</p>
							</div>
						),
					},
				];
			case 5:
				return [
					{
						prop: "studentCode",
						title: "Student code",
						isFilterable: true,
						isSortable: true,
						cell: row => (
							<div>
								<p className="d-inline-block">{row.studentCode}</p>
							</div>
						),
					},
					{
						prop: "externalCode",
						title: "External code",
						isFilterable: true,
						isSortable: true,
						cell: row => (
							<div>
								<p className="d-inline-block">{row.externalCode}</p>
							</div>
						),
					},
					{
						prop: "studentName",
						title: "Student Name",
						isFilterable: true,
						isSortable: true,
						cell: row => (
							<div>
								<p className="d-inline-block">{row.studentName}</p>
							</div>
						),
					},
					{
						prop: "house",
						title: "House",
						isFilterable: true,
						isSortable: true,
						cell: row => (
							<div>
								<p className="d-inline-block">{row.house}</p>
							</div>
						),
					},
					{
						prop: "homeGroup",
						title: "Home Group",
						isFilterable: true,
						isSortable: true,
						cell: row => (
							<div>
								<p className="d-inline-block">{row.homeGroup}</p>
							</div>
						),
					},
					{
						prop: "homeTeacher",
						title: "Home Teacher",
						isFilterable: true,
						isSortable: true,
						cell: row => (
							<div>
								<p className="d-inline-block">{row.homeTeacher}</p>
							</div>
						),
					},
					{
						prop: "courseCounsellor",
						title: "Course Counsellor",
						isFilterable: true,
						isSortable: true,
						cell: row => (
							<div>
								<p className="d-inline-block">{row.courseCounsellor}</p>
							</div>
						),
					},
					...toDo.map(el => {
						return {
							prop: el.name,
							title: el.name,
							isFilterable: true,
							isSortable: true,
							cell: row => (
								<div>
									<p className="d-inline-block">{el.name}</p>
								</div>
							),
						};
					}),
				];
			case 6:
				return [
					{
						prop: "studentCode",
						title: "Student code",
						isFilterable: true,
						isSortable: true,
						cell: row => (
							<div>
								<p className="d-inline-block">{row.studentCode}</p>
							</div>
						),
					},
					{
						prop: "studentName",
						title: "Student Name",
						isFilterable: true,
						isSortable: true,
						cell: row => (
							<div>
								<p className="d-inline-block">{row.studentName}</p>
							</div>
						),
					},
					{
						prop: "externalCode",
						title: "External code",
						isFilterable: true,
						isSortable: true,
						cell: row => (
							<div>
								<p className="d-inline-block">{row.externalCode}</p>
							</div>
						),
					},
					{
						prop: "studentEmail",
						title: "Student Email",
						isFilterable: true,
						isSortable: true,
						cell: row => (
							<div>
								<p className="d-inline-block">{row.studentEmail}</p>
							</div>
						),
					},
					{
						prop: "yearLevel",
						title: "Year Level",
						isFilterable: true,
						isSortable: true,
						cell: row => (
							<div>
								<p className="d-inline-block">{row.yearLevel}</p>
							</div>
						),
					},
					{
						prop: "homeGroup",
						title: "Home Group",
						isFilterable: true,
						isSortable: true,
						cell: row => (
							<div>
								<p className="d-inline-block">{row.homeGroup}</p>
							</div>
						),
					},
					{
						prop: "homeTeacher",
						title: "Home Teacher",
						isFilterable: true,
						isSortable: true,
						cell: row => (
							<div>
								<p className="d-inline-block">{row.homeTeacher}</p>
							</div>
						),
					},
					{
						prop: "appointments",
						title: "Appointments",
						isFilterable: true,
						isSortable: true,
						cell: row => (
							<div>
								<p className="d-inline-block">{row.appointments}</p>
							</div>
						),
					},
				];
			case 7:
				return [
					{
						prop: "studentCode",
						title: "Student code",
						isFilterable: true,
						isSortable: true,
						cell: row => (
							<div>
								<p className="d-inline-block">{row.studentCode}</p>
							</div>
						),
					},
					{
						prop: "externalCode",
						title: "External code",
						isFilterable: true,
						isSortable: true,
						cell: row => (
							<div>
								<p className="d-inline-block">{row.externalCode}</p>
							</div>
						),
					},
					{
						prop: "studentName",
						title: "Student Name",
						isFilterable: true,
						isSortable: true,
						cell: row => (
							<div>
								<p className="d-inline-block">{row.studentName}</p>
							</div>
						),
					},
					{
						prop: "house",
						title: "House",
						isFilterable: true,
						isSortable: true,
						cell: row => (
							<div>
								<p className="d-inline-block">{row.house}</p>
							</div>
						),
					},
					{
						prop: "homeGroup",
						title: "Home Group",
						isFilterable: true,
						isSortable: true,
						cell: row => (
							<div>
								<p className="d-inline-block">{row.homeGroup}</p>
							</div>
						),
					},
					{
						prop: "homeTeacher",
						title: "Home Teacher",
						isFilterable: true,
						isSortable: true,
						cell: row => (
							<div>
								<p className="d-inline-block">{row.homeTeacher}</p>
							</div>
						),
					},
					{
						prop: "courseCounsellor",
						title: "Course Counsellor",
						isFilterable: true,
						isSortable: true,
						cell: row => (
							<div>
								<p className="d-inline-block">{row.courseCounsellor}</p>
							</div>
						),
					},
					{
						prop: "section",
						title: "Section",
						isFilterable: true,
						isSortable: true,
						cell: row => (
							<div>
								<p className="d-inline-block">{row.section}</p>
							</div>
						),
					},
					{
						prop: "instruction",
						title: "Instruction",
						isFilterable: true,
						isSortable: true,
						cell: row => (
							<div>
								<p className="d-inline-block">{row.instruction}</p>
							</div>
						),
					},
					{
						prop: "selection",
						title: "Selection",
						isFilterable: true,
						isSortable: true,
						cell: row => (
							<div>
								<p className="d-inline-block">{row.selection}</p>
							</div>
						),
					},
					{
						prop: "preferenceOrder",
						title: "Preference Order",
						isFilterable: true,
						isSortable: true,
						cell: row => (
							<div>
								<p className="d-inline-block">{row.preferenceOrder}</p>
							</div>
						),
					},
					{
						prop: "selectionDateOreTime",
						title: "Selection Date / Time",
						isFilterable: true,
						isSortable: true,
						cell: row => (
							<div>
								<p className="d-inline-block">{row.selectionDateOreTime}</p>
							</div>
						),
					},
					{
						prop: "waitlisted",
						title: "Waitlisted",
						isFilterable: true,
						isSortable: true,
						cell: row => (
							<div>
								<p className="d-inline-block">{row.waitlisted}</p>
							</div>
						),
					},
					{
						prop: "weighting",
						title: "Weighting",
						isFilterable: true,
						isSortable: true,
						cell: row => (
							<div>
								<p className="d-inline-block">{row.weighting}</p>
							</div>
						),
					},
					{
						prop: "fee",
						title: "Fee",
						isFilterable: true,
						isSortable: true,
						cell: row => (
							<div>
								<p className="d-inline-block">{row.fee}</p>
							</div>
						),
					},
				];
			default:
				break;
		}
	};

	const onChangeRadio = (event, fieldName, isBoolean) => {
		if (isBoolean) {
			setValues(values => ({
				...values,
				[fieldName]: JSON.parse(event.target.value),
			}));
			return true;
		}
		setValues(values => ({
			...values,
			[fieldName]: +event.target.value,
		}));
	};

	return (
		<>
			<CustomBreadcrumb
				boxClassName="shadow"
				data={[
					{
						name: "Checklist",
						url: "/admin/checklist",
					},
					{
						name: "Extracts",
						url: `/admin/extracts/${yearId}`,
					},
					{
						name: "Extracts",
						url: "/",
					},
				]}>
				<div className="d-flex align-items-center gap-2">
					<Link
						to={"#"}
						target="_blank"
						className="btn btn-outline-blue btn-extra_small">
						Help Documentation
					</Link>
				</div>
			</CustomBreadcrumb>
			<Container className="mt-3">
				<Card>
					<CardBody>
						<CardTitle title={"Year 2024 Extracts"} />
						<Row className="mt-4">
							<Col md={12} lg={6} className="mt-3">
								<div className="f-flex flex-column gap-1">
									<RadioBox
										id={"fullExtract"}
										name={"type"}
										size={"lg"}
										value={1}
										label={"Full Extract"}
										checked={values.type === 1}
										onChange={event => {
											onChangeRadio(event, "type");
										}}
									/>
									<RadioBox
										id={"forMaze"}
										name={"type"}
										size={"lg"}
										value={2}
										label={"For Maze"}
										checked={values.type === 2}
										onChange={event => {
											onChangeRadio(event, "type");
										}}
									/>
									<RadioBox
										id={"forTimetabler"}
										name={"type"}
										size={"lg"}
										value={3}
										label={"For Timetabler"}
										checked={values.type === 3}
										onChange={event => {
											onChangeRadio(event, "type");
										}}
									/>
									<RadioBox
										id={"barcodes"}
										name={"type"}
										size={"lg"}
										value={4}
										label={"Barcodes"}
										checked={values.type === 4}
										onChange={event => {
											onChangeRadio(event, "type");
										}}
									/>
									<RadioBox
										id={"grid"}
										name={"type"}
										size={"lg"}
										value={5}
										label={"Grid"}
										checked={values.type === 5}
										onChange={event => {
											onChangeRadio(event, "type");
										}}
									/>
									<RadioBox
										id={"studentInformation"}
										name={"type"}
										size={"lg"}
										value={6}
										label={"Student Information"}
										checkClassName={"mt-4"}
										checked={values.type === 6}
										onChange={event => {
											onChangeRadio(event, "type");
										}}
									/>
									<RadioBox
										id={"tickOreFlick"}
										name={"type"}
										size={"lg"}
										value={7}
										label={"Tick/Flick"}
										checkClassName={"mt-3"}
										checked={values.type === 7}
										onChange={event => {
											onChangeRadio(event, "type");
										}}
									/>
								</div>
							</Col>
							<Col md={12} lg={6} className="mt-3">
								<div className="d-flex flex-column justify-content-between h-100 gap-3">
									<Row>
										<Col md={3}>
											<p className="d-inline-block m-0">
												<strong>Section:</strong>
											</p>
										</Col>
										<Col md={3} className="">
											<RadioBox
												id={"showOnReportsyes"}
												name={"showOnReports"}
												value={true}
												label={"Include"}
												// size={"lg"}
												// checked={values?.showOnReports}
												// onChange={event => {
												// 	onChangeRadio(event, "showOnReports");
												// }}
											/>
											<RadioBox
												id={"showOnReportsyes"}
												name={"showOnReports"}
												value={true}
												label={"Exclude"}
												// size={"lg"}
												// checked={values?.showOnReports}
												// onChange={event => {
												// 	onChangeRadio(event, "showOnReports");
												// }}
											/>
										</Col>
										<Col md={6}>
											<MultiReactSelectOption />
										</Col>
									</Row>
									<Row>
										<Col md={3}>
											<p className="d-inline-block m-0">
												<strong>Instruction:</strong>
											</p>
										</Col>
										<Col md={3}>
											<RadioBox
												id={"showOnReportsyes"}
												name={"showOnReports"}
												value={true}
												label={"Include"}
												// size={"lg"}
												// checked={values?.showOnReports}
												// onChange={event => {
												// 	onChangeRadio(event, "showOnReports");
												// }}
											/>
											<RadioBox
												id={"showOnReportsyes"}
												name={"showOnReports"}
												value={true}
												label={"Exclude"}
												// size={"lg"}
												// checked={values?.showOnReports}
												// onChange={event => {
												// 	onChangeRadio(event, "showOnReports");
												// }}
											/>
										</Col>
										<Col md={6}>
											<MultiReactSelectOption />
										</Col>
									</Row>
									<Row>
										<Col md={3}>
											<p className="d-inline-block m-0">
												<strong>Selection:</strong>
											</p>
										</Col>
										<Col md={3}>
											<RadioBox
												id={"showOnReportsyes"}
												name={"showOnReports"}
												value={true}
												label={"Include"}
												// size={"lg"}
												// checked={values?.showOnReports}
												// onChange={event => {
												// 	onChangeRadio(event, "showOnReports");
												// }}
											/>
											<RadioBox
												id={"showOnReportsyes"}
												name={"showOnReports"}
												value={true}
												label={"Exclude"}
												// size={"lg"}
												// checked={values?.showOnReports}
												// onChange={event => {
												// 	onChangeRadio(event, "showOnReports");
												// }}
											/>
										</Col>
										<Col md={6}>
											<MultiReactSelectOption />
										</Col>
									</Row>
								</div>
							</Col>
							{values.type === 4 && (
								<Col md={12}>
									<hr />
									<div className="information_block">
										<h4 className="block">Explanation:</h4>
										<div>
											<Row>
												<Col md={2}>
													<p>
														<strong>Code 39</strong>
													</p>
												</Col>
												<Col md={6}>
													<ul className="d-block">
														<li>
															This font works with uppercase letters,numbers and
															the characters -.$/+% and space
														</li>
														<li>Pro: Easy to change codes if required</li>
														<li>
															Con: Limited range of character compatibility
														</li>
													</ul>
												</Col>
											</Row>
											<Row>
												<Col md={2}>
													<p>
														<strong>Code 128</strong>
													</p>
												</Col>
												<Col md={6}>
													<ul className="d-block">
														<li>
															Works for all of the characters that Code 39 does,
															plus additional characters
														</li>
														<li>Pro: Includes a large amount of characters</li>
														<li>
															Con: Extremely difficult to make manual changes
														</li>
													</ul>
												</Col>
											</Row>
										</div>
									</div>
									<div>
										<h4 className="fw-500 fs-18">
											Choose format below to download barcode font
										</h4>
										<Row className="justify-content-between">
											<Col md={12} lg={6} className="d-flex flex-column gap-2 ">
												<RadioBox
													id={"grid"}
													name={"type"}
													size={"lg"}
													value={5}
													label={"Grid"}
													checked={values.type === 5}
													onChange={event => {
														onChangeRadio(event, "type");
													}}
												/>
												<RadioBox
													id={"studentInformation"}
													name={"type"}
													size={"lg"}
													value={6}
													label={"Student Information"}
													checked={values.type === 6}
													onChange={event => {
														onChangeRadio(event, "type");
													}}
												/>
											</Col>
											<Col
												md={12}
												lg={4}
												className="d-flex flex-column justify-content-end mt-2 mt-md-0">
												<div className="d-flex gap-2 flex-wrap flex-md-nowrap">
													<ReactSelectOption />
													<button
														onClick={reactToPrintFn}
														className="btn btn-outline-btn-default  btn-sm">
														<FaPrint />
														Print
													</button>
												</div>
											</Col>
										</Row>
									</div>
									<div className="mt-3" ref={contentRef}>
										<Row>
											<Col md={12}>
												<h1 className="fw-500 fs-36">Mindalay</h1>
												<p>Year 2024 selections, sorted by ....</p>
												<p>Report Created 25th Oct 2024 at 17:59:56</p>
											</Col>
										</Row>
									</div>
								</Col>
							)}
							{tableTypes.includes(values.type) && (
								<Col md={12} className="mt-3">
									<DatatableWrapper
										body={data}
										headers={columns(values.type)}
										options={{
											emptyText: "Здесь нет данных для отображения.", // Ваш текст для пустого блока
										}}
										paginationOptionsProps={{
											initialState: {
												rowsPerPage: 100,
												options: [5, 10, 15, 20],
											},
										}}>
										<Row className="justify-content-between">
											<Col md={12}>
												<hr />
											</Col>
											{values.type === 7 && (
												<Col md={12}>
													<div className="information_block">
														<h4 className="block">Explanation:</h4>
														<div>
															<p className="fs-13">
																This extract will generate the following
																information{" "}
																<strong>
																	"StudentCode, ExternalCode, StudentName,
																	CourseCode, CourseName, PreReqFormula, Result,
																	RequestDate, Student Notes, Outcome,
																	InternalNotes, NotesToStudent, StaffID,
																	DecisionMade"
																</strong>
															</p>
														</div>
													</div>
												</Col>
											)}
											{values.type === 6 && (
												<Col md={12}>
													<div className="information_block">
														<h4 className="block">Explanation:</h4>
														<div>
															<p className="fs-13">
																This extract will generate the following
																information{" "}
																<strong>
																	"StudentCode, StudentName, ExternalCode,
																	StudentEmail, YearLevel, HomeGroup,
																	HomeTeacher, Counsellor"
																</strong>
															</p>
														</div>
													</div>
												</Col>
											)}
											{values.type === 5 && (
												<Col md={12}>
													<div className="information_block">
														<h4 className="block">Explanation:</h4>
														<div>
															<p className="fs-13">
																This will generate a report that has one student
																per row, and every selection has a column. Where
																the student has made the selection for a column,
																the word 'true' will appear. If they haven't
																made that selection, the cell will be empty
															</p>
														</div>
													</div>
												</Col>
											)}
											{values.type === 3 && (
												<>
													<Col md={12} lg={5}></Col>
													<Col md={12} lg={7}>
														<Row>
															<Col md={4}>
																<p className="d-inline-block m-0">
																	<strong>Selection per row?</strong>
																</p>
															</Col>
															<Col md={5} className="">
																<RadioBox
																	id={"grid"}
																	name={"type"}
																	size={"lg"}
																	value={5}
																	label={"One Selection per row"}
																	checked={values.type === 5}
																	onChange={event => {
																		onChangeRadio(event, "type");
																	}}
																/>
																<RadioBox
																	id={"studentInformation"}
																	name={"type"}
																	size={"lg"}
																	value={6}
																	label={" Multiple selection per row"}
																	checked={values.type === 6}
																	onChange={event => {
																		onChangeRadio(event, "type");
																	}}
																/>
															</Col>
														</Row>
														<Row className="mt-2">
															<Col md={4}>
																<p className="d-inline-block m-0 text-end">
																	<strong>Use which code?</strong>
																</p>
															</Col>
															<Col md={4} className="">
																<RadioBox
																	id={"grid"}
																	name={"type"}
																	size={"lg"}
																	value={5}
																	label={"Student Code"}
																	checked={values.type === 5}
																	onChange={event => {
																		onChangeRadio(event, "type");
																	}}
																/>
																<RadioBox
																	id={"studentInformation"}
																	name={"type"}
																	size={"lg"}
																	value={6}
																	label={"External Code"}
																	checked={values.type === 6}
																	onChange={event => {
																		onChangeRadio(event, "type");
																	}}
																/>
															</Col>
														</Row>
													</Col>
												</>
											)}
											{values.type === 2 && (
												<>
													<Col md={12} lg={5}></Col>
													<Col md={12} lg={7}>
														<Row>
															<Col md={4}>
																<p className="d-inline-block m-0">
																	<strong>Timetabling Period:</strong>
																</p>
															</Col>
															<Col md={4} className="">
																<Input
																	placeholder={"Year"}
																	id={"date"}
																	value={values.date || ""}
																	// errorMessage={
																	// 	errors && errors[`${shortKey}.bPointMerchantNumber`]
																	// }
																	// onChange={event => {
																	// 	onChange(event, "date");
																	// }}
																/>
															</Col>
														</Row>
														<Row className="mt-2">
															<Col md={4}>
																<p className="d-inline-block m-0 text-end">
																	<strong>Use which code?</strong>
																</p>
															</Col>
															<Col md={4} className="">
																<RadioBox
																	id={"grid"}
																	name={"type"}
																	size={"lg"}
																	value={5}
																	label={"Student Code"}
																	checked={values.type === 5}
																	onChange={event => {
																		onChangeRadio(event, "type");
																	}}
																/>
																<RadioBox
																	id={"studentInformation"}
																	name={"type"}
																	size={"lg"}
																	value={6}
																	label={"External Code"}
																	checked={values.type === 6}
																	onChange={event => {
																		onChangeRadio(event, "type");
																	}}
																/>
															</Col>
														</Row>
													</Col>
												</>
											)}

											<Col md={2} className="mb-3">
												<Filter
													placeholder="search"
													classes={{
														input: "sso_input h_34",
														clearButton: "clearButton",
													}}
												/>
											</Col>
											<Col
												md={5}
												className="d-flex gap-2 justify-content-end flex-md-nowrap flex-wrap mb-3">
												<button
													to={"#"}
													target="_blank"
													className="btn btn-outline-btn-default  btn-sm">
													<FaDownload />
													Export as displayeds
												</button>
											</Col>
											<Col md={12} className="mb-2">
												<Table className="sso_tabel" responsive bordered>
													<TableHeader />
													<TableBody>
														{!data.length && (
															<tr>
																<td
																	colSpan={columns(values.type).length}
																	className="dataTables_empty">
																	There are currently no selections available
																	for this year level
																</td>
															</tr>
														)}
													</TableBody>
												</Table>
											</Col>
										</Row>
									</DatatableWrapper>
								</Col>
							)}
						</Row>
					</CardBody>
				</Card>
			</Container>
		</>
	);
}

export default Extracts;
