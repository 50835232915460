import React, { useEffect, useState } from 'react';
import {
  DatatableWrapper,
  TableBody,
  Pagination,
  PaginationOptions,
} from 'react-bs-datatable';
import 'bootstrap/dist/css/bootstrap.min.css'; // Подключение стилей Bootstrap

const header = [
  { title: '#', prop: 'select' },
  { title: 'ID', prop: 'id', sortable: true },
  { title: 'Name', prop: 'name', sortable: true },
  { title: 'Age', prop: 'age', sortable: true },
  { title: 'Email', prop: 'email', sortable: true },
  { title: 'Position', prop: 'position', sortable: true },
  { title: 'Department', prop: 'department', sortable: true },
  { title: 'Location', prop: 'location', sortable: true },
  { title: 'Phone', prop: 'phone', sortable: true },
  { title: 'Salary', prop: 'salary', sortable: true },
  { title: 'Date Hired', prop: 'dateHired', sortable: true },
  { title: 'Status', prop: 'status', sortable: true },
];

const initialBodyDefault = [
  { id: 1, name: 'John Doe', age: 28, email: 'john.doe@example.com', position: 'Software Engineer', department: 'Engineering', location: 'New York', phone: '123-456-7890', salary: '$100,000', dateHired: '2020-01-15', status: 'Active' },
  { id: 2, name: 'Jane Smith', age: 32, email: 'jane.smith@example.com', position: 'Product Manager', department: 'Product', location: 'San Francisco', phone: '234-567-8901', salary: '$120,000', dateHired: '2019-04-22', status: 'Active' },
  { id: 3, name: 'Alice Johnson', age: 24, email: 'alice.johnson@example.com', position: 'UX Designer', department: 'Design', location: 'Seattle', phone: '345-678-9012', salary: '$80,000', dateHired: '2021-08-30', status: 'Inactive' },
];

const initialBody = [...initialBodyDefault];

function ExampleTable() {
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState(initialBodyDefault);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [selectedRows, setSelectedRows] = useState([]);

  // Функция сортировки данных
  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  // Применение сортировки к данным
  const sortedData = React.useMemo(() => {
    if (sortConfig.key) {
      return [...filteredData].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
    return filteredData;
  }, [filteredData, sortConfig]);

  // Обновляем отфильтрованные данные при изменении строки поиска
  useEffect(() => {
    if (search === "") {
      setFilteredData(initialBodyDefault);
    } else {
      setFilteredData(
        initialBody.filter((row) =>
          Object.values(row).some(
            (value) => value.toString().toLowerCase().includes(search.toLowerCase())
          )
        )
      );
    }
  }, [search]);

    const handleCheckboxChange = (id) => {
      setSelectedRows((prevSelected) =>
        prevSelected.includes(id)
          ? prevSelected.filter((rowId) => rowId !== id)
          : [...prevSelected, id]
      );
    };

  return (
    <div className="mt-5">
      {/* Поле для ввода строки поиска */}
      <input
        placeholder="Search"
        value={search}
        onChange={(event) => setSearch(event.target.value)}
        className="form-control mb-3"
      />
      <DatatableWrapper
        headers={header}
        body={sortedData} // Используем отсортированные данные
        paginationOptionsProps={{
          initialState: { rowsPerPage: 5, options: [5, 10, 15] },
        }}
      >
        <PaginationOptions />

        {/* Таблица */}
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              {header.map((col) => (
                <th key={col.prop} onClick={() => col.sortable && handleSort(col.prop)}>
                  {col.title}
                  {col.sortable && (
                    <span>
                      {sortConfig.key === col.prop && (sortConfig.direction === 'asc' ? ' ▲' : ' ▼')}
                    </span>
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <TableBody>
            {sortedData.map((row) => (
              <tr key={row.id}>
                <td>
                  <input
                    type="checkbox"
                    checked={selectedRows.includes(row.id)}
                    onChange={() => handleCheckboxChange(row.id)}
                  />
                </td>
                <td>{row.id}</td>
                <td>{row.name}</td>
                <td>{row.age}</td>
                <td>{row.email}</td>
                <td>{row.position}</td>
                <td>{row.department}</td>
                <td>{row.location}</td>
                <td>{row.phone}</td>
                <td>{row.salary}</td>
                <td>{row.dateHired}</td>
                <td>{row.status}</td>
              </tr>
            ))}
          </TableBody>
        </table>

        {/* Пагинация */}
        <Pagination />
      </DatatableWrapper>
    </div>
  );
}

export default ExampleTable;
