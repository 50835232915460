import React, { useEffect, useState, createContext, useContext } from "react";
import Popup from "../../components/modals/Popup";
import { FaBars, FaPlus } from "react-icons/fa";
import { TiArrowSortedUp, TiArrowSortedDown } from "react-icons/ti";
import {
	Card,
	CardBody,
	Col,
	Container,
	Row,
	Accordion,
	useAccordionButton,
} from "react-bootstrap";
import Input from "../../components/inputs/Input";
import CheckBox from "../../components/inputs/CheckBox";
import ReactSelectOption from "../../components/inputs/ReactSelectOption";
import CustomBreadcrumb from "../../components/breadcrumbs/Breadcrumb";
import { Link, useParams } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { SlLogin, SlLogout } from "react-icons/sl";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import MainService from "../../helpers/services/mainService";
import { ApiService } from "../../helpers/services/apiSrevice";
import SelectionProcesTabs from "./components/SelectionProcesTabs";
import CardTitle from "../../components/cardTitle/CardTitle";
import { useDrag, useDrop } from "react-dnd";
import SelectionCreateTabs from "./components/SelectionCreateTabs";
import AlertService from "../../helpers/services/alertService";
import HelpModal from "../../components/modals/HelpModal";
const ItemType = {
	SECTION: "SECTION",
	SELECTION: "SELECTION",
	INSTRUCTION: "INSTRUCTION",
};

export const SelectionProcessContext = createContext();

function SelectionProcess(props) {
	const { yearId } = useParams();
	const [openHelpModal, setOpenHelpMoadal] = useState(false);
	const [show, setShow] = useState(false);
	const [activTabe, setActivTabe] = useState("textImage");
	const [isShowLeftMenu, setIsShowLeftMenu] = useState(false);
	const matches = useMediaQuery("(min-width:992px)");
	const [isReOrder, setIsReOrder] = useState(false);
	const [navigation, setNavigation] = useState([]);
	const [activeSection, setActiveSection] = useState(null);
	const [formType, setFormType] = useState("section");
	const [data, setData] = useState(null);
	const [sectionIndex, setSectionIndex] = useState(null);
	const [sectionData, setSectionData] = useState(null);
	const [copyNavigation, setCopyNavigation] = useState(null);
	const [isDragSection, setIsDragSection] = useState(false);
	const [isDragSelection, setIsDragSelection] = useState(false);
	const [isDragInstruction, setIsDragInstruction] = useState(false);
	const [isCreateSelection, setIsCreateSelection] = useState(false);
	const [selectValues, setSelectValues] = useState([]);
	const [indexs, setIndexs] = useState(null);
	const [deleteId, setDeleteId] = useState(null);
	const [isCopy, setIsCopy] = useState(null);
	const [isFileChange, setIsFileChange] = useState({
		textImage: false,
		settings: false,
		reOrder: false,
		// delete: false,
	});
	const [isConfirm, setIsConfirm] = useState({
		textImage: false,
		settings: false,
		reOrder: false,
		// delete: false,
	});
	const [nextTabe, setNextTabe] = useState(null);

	const [values, setValues] = useState({
		parentId: yearId,
		reportName: "",
		copy: false,
		entityId: null,
	});

	useEffect(() => {
		MainService.callApi({
			api: ApiService.getNavigation,
			data: yearId,
			cb: response => {
				if (response && response.data && response.data.length) {
					setNavigation(response.data);
					setCopyNavigation(response.data);
					setActiveSection(response.data[0]);
					getNavigationById(response.data[0].id);
					setSectionData(response.data);
				}
			},
		});
	}, []);

	const onOpen = (data, type, indexs = {}, sectionId, sectionIndex) => {
		setIsCopy(type);
		setIndexs(indexs);
		if (sectionId) {
			setSectionIndex(sectionIndex);
			setValues(values => ({
				...values,
				parentId: sectionId,
			}));
		}
		if (data && data.length) {
			generateSelectValues(data, !!sectionId);
		}
		setShow(true);
	};
	const onClose = () => {
		setShow(false);
		setValues({
			parentId: yearId,
			reportName: "",
			copy: false,
			entityId: null,
		});
		setIsCopy(null);
	};

	const changeTabe = key => {
		if (key === "reOrder") {
			switch (formType) {
				case "section":
					setIsDragSection(true);

					break;
				case "instruction":
					setIsDragInstruction(true);

					break;
				case "selection":
					setIsDragSelection(true);
					break;
				default:
					break;
			}
			setIsReOrder(true);
		} else if (key !== "reOrder" && isReOrder) {
			setIsReOrder(false);
			setIsDragSection(false);
			setIsDragInstruction(false);
			setIsDragSelection(false);
		}
		setActivTabe(key);
	};

	const generateSelectValues = (array, isInstruction = false) => {
		const result = [];

		array.forEach(item => {
			if (isInstruction && item.instructions && item.instructions.length > 0) {
				item.instructions.forEach(instruction => {
					result.push({
						label: `${item.reportName} > ${instruction.reportName}`,
						value: instruction.id,
					});
				});
			} else if (!isInstruction) {
				result.push({
					label: item.reportName,
					value: item.id,
				});
			}
		});

		setSelectValues(result);
	};

	const getInstructionById = id => {
		if (!id) return false;
		MainService.callApi({
			api: ApiService.getInstructionById,
			data: id,
			cb: response => {
				if (response && response.data) {
					setData(response.data);
				}
			},
		});
	};

	const selectionGetById = id => {
		if (!id) return false;
		MainService.callApi({
			api: ApiService.selectionGetById,
			data: id,
			cb: response => {
				if (response && response.data) {
					setData(response.data);
				}
			},
		});
	};

	const getNavigationById = id => {
		if (!id) return false;
		MainService.callApi({
			api: ApiService.getNavigationById,
			data: id,
			cb: response => {
				if (response && response.data) {
					setData(response.data);
				}
			},
		});
	};

	const changeActivSection = (item, key, data, indexs = {}) => {
		if (!item) return false;
		if (key) setFormType(key);
		setActiveSection(item);
		if (data) {
			setSectionData(data);
		}
		setIndexs(indexs);
		switch (key) {
			case "section":
				setIsCreateSelection(false);
				getNavigationById(item.id);
				changeTabe("textImage");
				break;
			case "instruction":
				setIsCreateSelection(false);
				getInstructionById(item.id);
				changeTabe("textImage");
				break;
			case "selection":
				setIsCreateSelection(false);
				selectionGetById(item.id);
				changeTabe("settings");
				break;
			case "selection_create":
				setIsCreateSelection(true);
				break;
			default:
				break;
		}
	};

	const onSave = () => {
		let clonVlaues = {};
		Object.entries(values).forEach(([key, value]) => {
			if (key !== "clone" && value) {
				clonVlaues[key] = value;
			}
		});
		if (isCopy !== null) {
			clonVlaues.type = 1;
		}
		let data = {};
		if (
			(isCopy === "instruction" && values.copy) ||
			(isCopy === "section" && values.copy)
		) {
			data = clonVlaues;
		} else if (isCopy === "instruction") {
			data = {
				sectionId: values.parentId,
				reportName: values.reportName,
			};
		} else {
			data = {
				academicYearId: values.parentId,
				reportName: values.reportName,
			};
		}

		MainService.callApi({
			api:
				isCopy === "instruction" && values.copy
					? ApiService.instructioniCopy
					: isCopy === "instruction" && !values.copy
					? ApiService.instructionCreate
					: isCopy === "section" && values.copy
					? ApiService.sectionCopy
					: ApiService.sectionCreate,

			data: data,
			cb: response => {
				if (response && response.data) {
					if (isCopy === "instruction" && indexs) {
						MainService.callApi({
							api: ApiService.getNavigation,
							data: yearId,
							cb: responseNavigation => {
								if (
									responseNavigation &&
									responseNavigation.data &&
									responseNavigation.data.length
								) {
									setNavigation(responseNavigation.data);
									changeActivSection(
										response.data,
										"instruction",
										responseNavigation[indexs?.section],
									);
									setIndexs(null);
								}
							},
						});
					} else {
						const navigationClone = [...navigation, response.data];
						setNavigation(navigationClone);
						setCopyNavigation(navigationClone);
						setActiveSection(response.data);
						getNavigationById(response.data.id);
						setSectionData(response.data);
						changeActivSection(response.data, "section", navigationClone);
						setIndexs(null);
					}
				}
				onClose({});
			},
		});
	};

	const onChange = (event, fieldName, errorfieldName) => {
		let regex = /^\S*$/;
		if (fieldName === "studentCode") {
			if (!regex.test(event.target.value)) {
				return false;
			}
		}
		setValues(values => ({
			...values,
			[fieldName]: event.target.value,
		}));
	};

	const moveSection = (fromIndex, toIndex) => {
		if (fromIndex === toIndex) return;
		setIsFileChange(prev => ({
			...prev,
			reOrder: true,
		}));
		const updatedSections = [...navigation];
		const [movedSection] = updatedSections.splice(fromIndex, 1);
		updatedSections.splice(toIndex, 0, movedSection);
		setSectionData(updatedSections);
		setNavigation(updatedSections);
	};

	const moveInstruction = (fromIndex, toIndex, sectionIndex) => {
		if (fromIndex === toIndex) return;
		setIsFileChange(prev => ({
			...prev,
			reOrder: true,
		}));
		const cloneNavigation = [...navigation];
		const updatedInstructions = [...cloneNavigation[sectionIndex].instructions];
		const [movedInstruction] = updatedInstructions.splice(fromIndex, 1);
		updatedInstructions.splice(toIndex, 0, movedInstruction);
		setSectionData(updatedInstructions);
		cloneNavigation[sectionIndex] = {
			...cloneNavigation[sectionIndex],
			instructions: updatedInstructions,
		};
		setNavigation(cloneNavigation);
	};

	const moveSelection = (
		fromIndex,
		toIndex,
		sectionIndex,
		instructionsIndex,
	) => {
		if (fromIndex === toIndex) return;
		const cloneNavigation = [...navigation];
		const cloneInstructions = [...cloneNavigation[sectionIndex].instructions];
		const updatedSelections = [
			...cloneInstructions[instructionsIndex].selections,
		];
		if (fromIndex === toIndex) return;
		setIsFileChange(prev => ({
			...prev,
			reOrder: true,
		}));
		const [movedSelection] = updatedSelections.splice(fromIndex, 1);
		updatedSelections.splice(toIndex, 0, movedSelection);
		setSectionData(updatedSelections);
		cloneInstructions[instructionsIndex] = {
			...cloneInstructions[instructionsIndex],
			selections: updatedSelections,
		};
		cloneNavigation[sectionIndex] = {
			...cloneNavigation[sectionIndex],
			instructions: cloneInstructions,
		};
		setNavigation(cloneNavigation);
	};

	const onSelectOptionChange = (item, fieldName) => {
		if (!item) return false;
		AlertService.alertConfirm(
			"Please confirm",
			<Row>
				<Col md={12}>
					<span>
						Structural elements will be copied, with some exceptions including
						the following:
					</span>
				</Col>
				<Col md={12}>
					<ul className="modal_ul">
						<li>Automatic Selections / Grouped Selections</li>
						<li>Disallowed Selections</li>
						<li>Global Rules</li>
						<li>Capacity Limits</li>
					</ul>
				</Col>
				<Col md={12}>
					<span>
						Once the instruction has been created, you can make any required
						changes, and add automatic selections, disallowed sections, and
						global rules as required.
					</span>
				</Col>
				<Col md={12}>
					Test thoroughly before releasing to students. Settings not copied can
					be re-applied afterwards.
				</Col>
			</Row>,
			"Confirm",
			"Cancel",
		)
			.then(() => {
				setValues(values => ({
					...values,
					entityId: item.value,
				}));
			})
			.catch(() => {});
	};

	const changeDleteId = id => {
		setDeleteId(id);
	};

	return (
		<>
			<Popup
				show={show}
				title={
					<div className="d-flex align-items-center gap-1">
						<FaPlus />
						<span>Add new {isCopy}</span>
					</div>
				}
				handleClose={onClose}
				action={onSave}
				buttonTitle="Save">
				<Row className="gap-3">
					<Col md={12}>
						<Input
							label={"Enter the report name for the new Section:"}
							placeholder={"Section name"}
							id={"reportName"}
							value={values.reportName || ""}
							helperText={"Max 32 symbols"}
							// errorMessage={
							// 	errors && errors[`${shortKey}.bPointMerchantNumber`]
							// }
							onChange={event => {
								onChange(event, "reportName");
								// onNumberChange(event, "academicYearNumber");
							}}
						/>
					</Col>
					{selectValues && !!selectValues.length && (
						<>
							<Col md={12}>
								<CheckBox
									label={"Copy an existing section"}
									value={values.copy}
									onChange={event => {
										setValues(values => ({
											...values,
											copy: event.target.checked,
											entityId: null,
										}));
									}}
								/>
							</Col>
							{values.copy && (
								<Col>
									<ReactSelectOption
										value={values.entityId}
										selectedValue={(() => {
											const selectedItem = {
												...selectValues?.find(
													data => data.value === values?.entityId,
												),
											};
											if (Object.keys(selectedItem).length) {
												return selectedItem;
											} else {
												return {
													value: null,
													label: "Choose",
												};
											}
										})()}
										items={selectValues.filter(
											item => item.value !== values?.entityId,
										)}
										onChange={item => onSelectOptionChange(item, "parentId")}
									/>
								</Col>
							)}
						</>
					)}
				</Row>
			</Popup>
			<HelpModal
				show={openHelpModal}
				handleClose={() => {
					setOpenHelpMoadal(!openHelpModal);
				}}
			/>
			<CustomBreadcrumb
				boxClassName="shadow"
				data={[
					{
						name: "Checklist",
						url: "/admin/checklist",
					},
					{ name: " Selection Process", url: "/" },
				]}>
				<div className="d-flex align-items-center gap-2">
					<Link
						to={"#"}
						target="_blank"
						className="btn btn-outline-blue btn-extra_small">
						Help Documentation
					</Link>
					<Link
						to={"#"}
						onClick={() => {
							setOpenHelpMoadal(!openHelpModal);
						}}
						// target="_blank"

						className="btn btn-outline-blue btn-extra_small">
						help ?
					</Link>
				</div>
			</CustomBreadcrumb>

			<Container
				className="mt-3"
				style={{
					minHeight: 428,
				}}>
				<Card style={{ minHeight: 428 }}>
					<CardBody>
						<Row className="mt-2 align-items-center">
							<Col md={12} lg={6}>
								<h3 className="fw-700 fs-24 mb-0 mt-2">
									Year 2024 Selection Process
								</h3>
							</Col>
							{/* <Col
								md={12}
								lg={6}
								className="d-flex gap-1 justify-content-end flex-column flex-md-row mt-2">
								<Link to={"#"} className="btn btn-outline-blue ">
									Selection Matrix
								</Link>
								<Link
									to={`/admin/capacities/${yearId}`}
									className="btn btn-outline-blue ">
									Capacities
								</Link>
								<Link
									to={`/admin/global_rules/${yearId}`}
									className="btn btn-outline-blue ">
									Global Rules
								</Link>
							</Col> */}
						</Row>
						<hr />
						{!isShowLeftMenu ? (
							<Link
								className="d-lg-none d-block sso_settings_tab_show_button "
								to={"#"}
								onClick={() => {
									setIsShowLeftMenu(!isShowLeftMenu);
								}}>
								{!isShowLeftMenu ? <SlLogin /> : null}
							</Link>
						) : null}

						<div className="settings position-relative">
							<div
								className={`checklist_menu ${isShowLeftMenu ? "active" : ""}`}>
								<Card className="user-dashboard-section height_false">
									<CardBody
										className={`sso-checklist-card-body ${
											isShowLeftMenu ? "active" : ""
										}`}>
										<div className="d-flex flex-column gap-1 pb-5">
											<div className="px-2 ">
												<CardTitle title="NAVIGATION">
													<div className="sso_settings_tab_show_button w-auto d-lg-none d-flex">
														<Link
															to={"#"}
															onClick={() => {
																setIsShowLeftMenu(!isShowLeftMenu);
															}}>
															{!isShowLeftMenu ? <FaBars /> : <SlLogout />}
														</Link>
													</div>
												</CardTitle>
											</div>

											<Accordion className="d-flex flex-column gap-1">
												{navigation &&
													!!navigation.length &&
													navigation.map((section, index) => {
														return (
															<div
																key={index}
																className={`px-2  ${
																	formType === "section" &&
																	activeSection?.id === section.id &&
																	section.id === deleteId
																		? "delete_bg py-2"
																		: ""
																}`}>
																<DraggableSection
																	key={section.id}
																	section={section}
																	index={index}
																	isDrag={isDragSection}
																	disabled={isDragSection}
																	moveSection={moveSection}>
																	<CustomToggle
																		className={"fs-18"}
																		name={section.reportName}
																		isDrag={isDragSection}
																		eventKey={section.id.toString()}
																		action={() =>
																			changeActivSection(
																				section,
																				"section",
																				navigation,
																				{ sectionIndex: index },
																			)
																		}
																		active={
																			formType === "section" &&
																			activeSection?.id === section.id
																		}
																		isDelete={
																			formType === "section" &&
																			activeSection?.id === section.id &&
																			section.id === deleteId
																		}
																		isShowButton={true}
																	/>

																	<Accordion.Collapse
																		eventKey={section.id.toString()}>
																		<Accordion>
																			<div className="d-flex flex-column gap-1  ps-2 mt-2">
																				{section.instructions &&
																					!!section.instructions.length &&
																					section.instructions.map(
																						(
																							instruction,
																							instructionsIndex,
																						) => {
																							return (
																								<div
																									key={instructionsIndex}
																									className={`px-2  ${
																										formType ===
																											"instruction" &&
																										activeSection?.id ===
																											instruction.id &&
																										instruction.id === deleteId
																											? "delete_bg py-2"
																											: ""
																									}`}>
																									<DraggableInstruction
																										instruction={instruction}
																										index={instructionsIndex}
																										disabled={isDragInstruction}
																										moveInstruction={(
																											fromIndex,
																											toIndex,
																										) => {
																											moveInstruction(
																												fromIndex,
																												toIndex,
																												index,
																											);
																										}}>
																										<CustomToggle
																											className={"md fs-16"}
																											name={
																												instruction.reportName
																											}
																											isDrag={isDragInstruction}
																											action={() => {
																												changeActivSection(
																													instruction,
																													"instruction",
																													section,
																												);
																											}}
																											active={
																												(formType ===
																													"instruction" ||
																													formType ===
																														"selection_create") &&
																												activeSection?.id ===
																													instruction.id
																											}
																											isDelete={
																												formType ===
																													"instruction" &&
																												activeSection?.id ===
																													instruction.id &&
																												instruction.id ===
																													deleteId
																											}
																											eventKey={`${section.id}-${instruction.id}`}
																											isShowButton={true}
																										/>
																										<Accordion.Collapse
																											eventKey={`${section.id}-${instruction.id}`}>
																											<div className="d-flex flex-column gap-1  ps-2 mt-2">
																												{instruction.selections &&
																													!!instruction
																														.selections
																														.length &&
																													instruction.selections.map(
																														(
																															selection,
																															selectionsIndex,
																															arr,
																														) => {
																															return (
																																<div
																																	key={
																																		selection.id
																																	}
																																	className={`px-2  ${
																																		formType ===
																																			"selection" &&
																																		activeSection?.id ===
																																			selection.id &&
																																		selection.id ===
																																			deleteId
																																			? "delete_bg py-2"
																																			: ""
																																	}`}>
																																	<DraggableSelection
																																		disabled={
																																			isDragSelection
																																		}
																																		selection={
																																			selection
																																		}
																																		index={
																																			selectionsIndex
																																		}
																																		moveSelection={(
																																			fromIndex,
																																			toIndex,
																																		) =>
																																			moveSelection(
																																				fromIndex,
																																				toIndex,
																																				index,
																																				instructionsIndex,
																																			)
																																		}>
																																		<CustomToggle
																																			isLast={
																																				true
																																			}
																																			isDrag={
																																				isDragSelection
																																			}
																																			className="sm fs-14"
																																			active={
																																				formType ===
																																					"selection" &&
																																				activeSection?.id ===
																																					selection.id
																																			}
																																			isDelete={
																																				formType ===
																																					"selection" &&
																																				activeSection?.id ===
																																					selection.id &&
																																				selection.id ===
																																					deleteId
																																			}
																																			key={
																																				selectionsIndex
																																			}
																																			action={() => {
																																				changeActivSection(
																																					selection,
																																					"selection",
																																					instruction,
																																				);
																																			}}
																																			name={
																																				selection.courseFullName
																																			}
																																			isShowButton={
																																				false
																																			}
																																		/>
																																	</DraggableSelection>
																																</div>
																															);
																														},
																													)}
																												<button
																													className={`btn btn-gren btn-sm d-flex align-items-center mt-2 me-3`}
																													onClick={() => {
																														changeActivSection(
																															instruction,
																															"selection_create",
																														);
																													}}>
																													<FaPlus />
																													<span>
																														Add Selection
																													</span>
																												</button>
																											</div>
																										</Accordion.Collapse>
																									</DraggableInstruction>
																								</div>
																							);
																						},
																					)}
																				<button
																					className={`btn btn-gren btn-sm d-flex align-items-center me-3`}
																					onClick={() => {
																						onOpen(
																							navigation,
																							"instruction",
																							{
																								section: index,
																							},
																							section.id,
																							{ section: index },
																						);
																					}}>
																					<FaPlus />
																					<span>Add Instruction</span>
																				</button>
																			</div>
																		</Accordion>
																	</Accordion.Collapse>
																</DraggableSection>
															</div>
														);
													})}
											</Accordion>

											<button
												className={`btn btn-gren btn-sm d-flex align-items-center mx-2`}
												onClick={() => {
													onOpen(navigation, "section");
												}}>
												<FaPlus />
												<span>Add Section</span>
											</button>
										</div>
									</CardBody>
								</Card>
							</div>
							<div className={`sso_checklist_content`}>
								<Card style={{ minHeight: 546 }}>
									<CardBody>
										<SelectionProcessContext.Provider
											value={{
												data,
												setData,
												copyNavigation,
												setCopyNavigation,
												isDragInstruction,
												isDragSection,
												isDragSelection,
												setIsDragInstruction,
												setIsDragSection,
												setIsDragSelection,
												sectionData,
												setSectionData,
												navigation,
												setNavigation,
												activeSection,
												changeDleteId,
												formType,
												changeActivSection,
												changeTabe,
												isFileChange,
												setIsFileChange,
												isConfirm,
												setIsConfirm,
												activTabe,
												nextTabe,
												setNextTabe,
											}}>
											{isCreateSelection ? (
												<SelectionCreateTabs />
											) : (
												<SelectionProcesTabs
													changeTabe={changeTabe}
													activTabe={activTabe}
													type={formType}
												/>
											)}
										</SelectionProcessContext.Provider>
									</CardBody>
								</Card>
							</div>
						</div>
					</CardBody>
				</Card>
			</Container>
		</>
	);
}

function CustomToggle({
	name,
	eventKey,
	action,
	isShowButton,
	className,
	active,
	isLast,
	isDrag,
	isDelete,
}) {
	const decoratedOnClick = useAccordionButton(eventKey, () => {
		setShow(!show);
	});

	const [show, setShow] = useState(false);

	return (
		<div
			className={`d-flex justify-content-between align-items-center ${
				isDelete ? "delete_selection" : ""
			}`}>
			<Link
				to={"#"}
				style={isLast ? { maxWidth: 185 } : {}}
				className={`toggle  selection   ${className ? className : ""}  ${
					active ? "active_tabe" : ""
				}`}
				title={name}
				onClick={() => {
					action && action();
				}}>
				{isDrag && (
					<div className="drag_buttons">
						<TiArrowSortedUp style={{ marginTop: 0 }} />
						<TiArrowSortedDown />
					</div>
				)}

				{name}
			</Link>

			{isShowButton && (
				<Link
					to={"#"}
					className={`toggle_button ${className ? className : ""} ${
						active ? "active" : ""
					}`}
					style={{
						transform: show ? "rotate(269deg)" : "rotate(0deg)",
					}}
					onClick={decoratedOnClick}>
					<MdOutlineArrowBackIosNew
						fill={`${show ? "#217ebd" : "#ccc"}`}
						style={{
							width: className ? 15 : 20,
							height: className ? 15 : 20,
						}}
					/>
				</Link>
			)}
		</div>
	);
}

const DraggableSection = ({
	section,
	index,
	moveSection,
	children,
	disabled,
}) => {
	const [{ isDragging }, dragRef] = useDrag({
		type: ItemType.SECTION,
		item: { index },
		collect: monitor => ({
			isDragging: monitor.isDragging(),
		}),
	});

	const [, dropRef] = useDrop({
		accept: ItemType.SECTION,
		// hover: draggedItem => {
		// 	if (draggedItem.index !== index) {
		// 		moveSection(draggedItem.index, index);
		// 		draggedItem.index = index;
		// 	}
		// },
		drop: draggedItem => {
			if (draggedItem.index !== index) {
				moveSection(draggedItem.index, index);
				draggedItem.index = index;
			}
		},
	});

	return (
		<div
			ref={disabled ? node => dragRef(dropRef(node)) : null}
			style={{ opacity: isDragging ? 0.5 : 1 }}>
			{children}
		</div>
	);
};

const DraggableSelection = ({
	selection,
	index,
	moveSelection,
	children,
	disabled,
}) => {
	const [{ isDragging }, dragRef] = useDrag({
		type: ItemType.SELECTION,
		item: { index },
		collect: monitor => ({
			isDragging: monitor.isDragging(),
		}),
	});

	const [, dropRef] = useDrop({
		accept: ItemType.SELECTION,
		// hover: draggedItem => {
		// 	if (draggedItem.index !== index) {
		// 		moveSelection(draggedItem.index, index);
		// 		draggedItem.index = index;
		// 	}
		// },
		drop: draggedItem => {
			if (draggedItem.index !== index) {
				moveSelection(draggedItem.index, index);
				draggedItem.index = index;
			}
		},
	});

	return (
		<div
			ref={disabled ? node => dragRef(dropRef(node)) : null}
			style={{ opacity: isDragging ? 0.5 : 1 }}>
			{children}
		</div>
	);
};

const DraggableInstruction = ({
	instruction,
	index,
	moveInstruction,
	children,
	disabled,
}) => {
	const [{ isDragging }, dragRef] = useDrag({
		type: ItemType.INSTRUCTION,
		item: { index },
		collect: monitor => ({
			isDragging: monitor.isDragging(),
		}),
	});

	const [, dropRef] = useDrop({
		accept: ItemType.INSTRUCTION,
		// hover: draggedItem => {
		// 	if (draggedItem.index !== index) {
		// 		moveInstruction(draggedItem.index, index);
		// 		draggedItem.index = index;
		// 	}
		// },
		drop: draggedItem => {
			if (draggedItem.index !== index) {
				moveInstruction(draggedItem.index, index);
				draggedItem.index = index;
			}
		},
	});

	return (
		<div
			ref={disabled ? node => dragRef(dropRef(node)) : null}
			style={{ opacity: isDragging ? 0.5 : 1 }}>
			{children}
		</div>
	);
};

export default SelectionProcess;
