import React, { useEffect, useState } from "react";
import CustomBreadcrumb from "../../components/breadcrumbs/Breadcrumb";
import { Link, useParams } from "react-router-dom";
import { Card, CardBody, Col, Container, Row, Table } from "react-bootstrap";
import CardTitle from "../../components/cardTitle/CardTitle";
import {
	DatatableWrapper,
	Filter,
	TableBody,
	TableHeader,
} from "react-bs-datatable";
import ReactSelectOption from "../../components/inputs/ReactSelectOption";
import CheckBox from "../../components/inputs/CheckBox";
import MainService from "../../helpers/services/mainService";
import { ApiService } from "../../helpers/services/apiSrevice";

function WholeYearLevelAccess(props) {
	const [data, setData] = useState([]);
	const [staffProfiles, setStaffProfiles] = useState([]);
	const { yearId } = useParams();

	useEffect(() => {
		if (yearId) {
			MainService.callApi({
				api: ApiService.staffProfileAccessGetInfoByType,
				data: {
					yearId,
					userTypeId: -1,
					data: {
						pageNumber: 1,
						pageSize: 100,
					},
				},
				cb: response => {
					if (
						response &&
						response.data &&
						response.data.pagedData &&
						response.data.pagedData.length
					) {
						setData(response.data.pagedData);
					}
				},
			});
		}
	}, [yearId]);

	const onCheckBoxChange = (event, type, item) => {
		let values = {
			staffProfileId: item.staffProfileId,
			userTypeId: item.allYear[type].id,
			academicYearId: item.academicYearId,
		};
		MainService.callApi({
			api: ApiService.staffProfileAccessCreate,
			data: values,
			cb: response => {
				let newdata = data.map(el => {
					if (el.data.staffProfileId === item.staffProfileId) {
						return {
							data: {
								...el.data,
								allYear: {
									...el.data.allYear,
									[type]: {
										...el.data.allYear[type],
										isActiv: !event.target.checked,
									},
								},
							},
						};
					}
					return el;
				});
				setData(newdata);
			},
		});
	};

	const columns = [
		{
			prop: "data.allYear.HoLA.isActiv",
			title: "Hola",
			isSortable: true,

			cell: row => {
				return (
					<div className="d-flex">
						<CheckBox
							checked={row.data.allYear.HoLA.isActiv}
							onChange={event => {
								onCheckBoxChange(event, "HoLA", row.data);
							}}
						/>
					</div>
				);
			},
		},
		{
			prop: "data.allYear.Counseller.isActiv",
			title: "CC",
			isSortable: true,
			cell: row => {
				return (
					<div className="d-flex">
						<CheckBox
							checked={row.data.allYear.Counseller.isActive}
							onChange={event => {
								onCheckBoxChange(event, "Counseller", row.data);
							}}
						/>
					</div>
				);
			},
		},
		{
			prop: "data.allYear.HomeTeacher.isActiv",
			title: "Ht",
			isSortable: true,
			cell: row => {
				return (
					<div className="d-flex">
						<CheckBox
							checked={row.data.allYear.HomeTeacher.isActive}
							onChange={event => {
								onCheckBoxChange(event, "HomeTeacher", row.data);
							}}
						/>
					</div>
				);
			},
		},
		{
			prop: "data.stafCode",
			title: "Staff Code",
			isSortable: true,
			isFilterable: true,

			cell: row => <div>{row.data.staffCode}</div>,
		},
		{
			prop: "data.stafName",
			title: "Staff Name",
			isFilterable: true,
			isSortable: true,
			cell: row => <div>{row.data.staffName}</div>,
		},
	];

	return (
		<>
			<CustomBreadcrumb
				boxClassName="shadow"
				data={[
					{
						name: "Checklist",
						url: "/admin/checklist",
					},
					{ name: "Staff", url: `/admin/teachers/${yearId}` },
					{ name: "Course Counsellors", url: `` },
				]}>
				<div className="d-flex align-items-center gap-2">
					<Link
						to={"#"}
						target="_blank"
						className="btn btn-outline-blue btn-extra_small">
						Help Documentation
					</Link>
				</div>
			</CustomBreadcrumb>
			<Container className="mt-3">
				<Card>
					<CardBody>
						<CardTitle title="Course Counsellors for Year 2024" />
						<Row className="mt-3 gap-3">
							<Col md={12} className="d-flex gap-2">
								<span className="coustem-label">Fix</span>
								<Link className="in_hover_underline fs-16 fw-700" to={"#"}>
									Course Counsellors for Year 2024
								</Link>
							</Col>
							<Col>
								<DatatableWrapper
									body={data}
									headers={columns}
									paginationOptionsProps={{
										initialState: {
											rowsPerPage: 100,
											options: [5, 10, 15, 20],
										},
									}}>
									<Row className="my-3 justify-content-between gap-3">
										<Col md={2}>
											<Filter
												placeholder="search"
												classes={{
													input: "input-sm sso_input",
													clearButton: "clearButton",
												}}
											/>
										</Col>
										<Col md={12} className="mb-2">
											<Table className="sso_tabel" responsive bordered>
												<TableHeader />
												<TableBody />
											</Table>
										</Col>
									</Row>
								</DatatableWrapper>
							</Col>
						</Row>
					</CardBody>
				</Card>
			</Container>
		</>
	);
}

export default WholeYearLevelAccess;
