import React, { useState } from "react";
import { Button, Card, CardBody, Col, Form, Row } from "react-bootstrap";
import CardTitle from "../../../components/cardTitle/CardTitle";
import RadioBox from "../../../components/inputs/RadioBox";
import MainService from "../../../helpers/services/mainService";
import { ApiService } from "../../../helpers/services/apiSrevice";
import AlertService from "../../../helpers/services/alertService";

function Appointment({ values, setValues }) {
	const [isChange, setIsChange] = useState(false);

	const onSubmit = event => {
		event.preventDefault();
		if (!isChange) return false;
		const formData = new FormData();
		Object.entries(values).forEach(([key, values]) => {
			formData.append(key, values);
		});
		MainService.callApi({
			api: ApiService.updateSchoolBrandingSettings,
			data: formData,
			cb: response => {
				if (response && response.data) {
					setValues(values => ({ ...values, ...response.data }));
				}
				if (response && response.success === true) {
					AlertService.alert("success", "Data seved");
					setIsChange(false);
				}
			},
		});
	};

	const onChange = (event, fieldName) => {
		setIsChange(true);
		setValues(values => ({
			...values,
			[fieldName]: +event.target.value
				? +event.target.value
				: event.target.value,
		}));
	};

	return (
		<Card style={{ minHeight: 478 }}>
			<CardBody>
				<CardTitle
					title="APPOINTMENT CONFIGURATION"
					titleClassname={"color_bleck fw-700 uppercase"}></CardTitle>
				<Row>
					<Col md={12} lg={8}>
						<form className="d-flex flex-column gap-3" onSubmit={onSubmit}>
							<Row className="align-items-center gap-3">
								<Col md={12}>
									<label className="sso_label">Appointments will be for</label>
								</Col>
								<Col md={12} className="d-flex flex-column">
									<RadioBox
										id={"appointmentConfiguration_1"}
										name={"appointmentConfiguration"}
										value={1}
										label={
											"The Course Counsellor assigned to the student in SSO."
										}
										checked={values.appointmentConfiguration === 1}
										onChange={event => {
											onChange(event, "appointmentConfiguration");
										}}
									/>
									<RadioBox
										id={"appointmentConfiguration_2"}
										name={"appointmentConfiguration"}
										value={2}
										label={
											"An unassigned Course Counsellor who is available at the time."
										}
										checked={values.appointmentConfiguration === 2}
										onChange={event => {
											onChange(event, "appointmentConfiguration");
										}}
									/>
								</Col>
							</Row>

							<div className="d-flex justify-content-end">
								<Button
									type="submit"
									disabled={!isChange}
									className="btn-primary ">
									Save
								</Button>
							</div>
						</form>
					</Col>
				</Row>
			</CardBody>
		</Card>
	);
}

export default Appointment;
