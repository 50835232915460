import { RouterProvider } from "react-router-dom";
import { ToastContainer, Zoom } from "react-toastify";
import { router } from "./pages/router";
import PageLoader from "./components/loadre/PageLoader";
import "react-data-table-component-extensions/dist/index.css";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

function App() {
	return (
		<>
			<DndProvider backend={HTML5Backend}>
				<RouterProvider router={router()} />
			</DndProvider>

			<PageLoader />
			<ToastContainer
				position="bottom-left"
				autoClose={4000}
				hideProgressBar
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="colored"
				transition={Zoom}
			/>
		</>
	);
}

export default App;
