import React, { useEffect, useState } from "react";
import CustomBreadcrumb from "../../components/breadcrumbs/Breadcrumb";
import { Link } from "react-router-dom";
import {
	Card,
	CardBody,
	Col,
	Container,
	OverlayTrigger,
	Popover,
	Row,
} from "react-bootstrap";
import { FaBars, FaEdit, FaLongArrowAltRight, FaPlus } from "react-icons/fa";
import { SlLogout, SlLogin } from "react-icons/sl";
import { useMediaQuery } from "@mui/material";
import CheckBox from "../../components/inputs/CheckBox";
import { FaRegTrashCan } from "react-icons/fa6";
import { CiSettings } from "react-icons/ci";
import AlertService from "../../helpers/services/alertService";
import Popup from "../../components/modals/Popup";
import ReactSelectOption from "../../components/inputs/ReactSelectOption";
import Input from "../../components/inputs/Input";
import RadioBox from "../../components/inputs/RadioBox";
import MainService from "../../helpers/services/mainService";
import { ApiService } from "../../helpers/services/apiSrevice";
import HelpModal from "../../components/modals/HelpModal";

// const year = [
// 	{ name: 2019, id: 1 },
// 	{ name: 2020, id: 2 },
// 	{ name: 2024, id: 3 },
// 	{ name: 2023, id: 4 },
// 	{ name: 2022, id: 5 },
// 	{ name: 2020, id: 6 },
// 	{ name: 2017, id: 7 },
// ];

function Checklist() {
	const [isShowLeftMenu, setIsShowLeftMenu] = useState(false);
	const matches = useMediaQuery("(min-width:992px)");
	const [activ, setActiv] = useState(null);
	const [values, setValues] = useState({
		schoolId: 7,
		academicYearNumber: "",
		entityId: null,
		// isSelection: true,
	});
	const [show, setShow] = useState(false);
	const [showPopover, setShowPopover] = useState(false);
	const [years, setYears] = useState([]);
	const [isCopy, setIsCopy] = useState(false);
	const [openHelpModal, setOpenHelpModal] = useState(false);

	useEffect(() => {
		MainService.callApi({
			api: ApiService.getAcademicYears,
			cb: response => {
				if (response && response.data && response.data.length) {
					setYears(response.data);
					setActiv(response.data[0]);
				}
			},
		});
	}, []);

	useEffect(() => {
		if (matches && isShowLeftMenu) {
			setIsShowLeftMenu(false);
		}
	}, [matches, isShowLeftMenu]);

	const deleteYera = item => {
		if (!item) return false;
		AlertService.alertConfirm(
			"Please confirm",
			<Row>
				<Col md={12}>
					<span className="text-danger">
						If you proceed, you will delete year level, and all associated data.
						It will be purged from SSO and you will not be able to retrieve the
						information.
					</span>
				</Col>
				<Col md={12} className="py-3">
					<span className="d-flex justify-content-center">
						Are you sure you want to delete all data for Year 2023 ?
					</span>
				</Col>
			</Row>,
			"Confirm",
			"Cancel",
		)
			.then(() => {
				MainService.callApi({
					api: ApiService.deleteAcademicYear,
					data: item.id,
					cb: response => {
						if (response && response.success) {
							setYears(years => years.filter(el => item.id !== el.id));
						}
					},
				});
			})
			.catch(() => { });
	};

	const onSave = () => {
		// let data;
		// if (!isCopy) {
		// 	data = {
		// 		entityId: values.entityId,
		// 		academicYearNumber: values.academicYearNumber,
		// 		// isSelection: values.isSelection,
		// 	};
		// } else {
		// 	data = values;
		// }
		MainService.callApi({
			api: isCopy
				? ApiService.copyAcademicYear
				: !values.id
					? ApiService.createAcademicYear
					: ApiService.updateAcademicYear,
			data: values,
			cb: response => {
				if (response && response.data && !values.id) {
					let data = [response.data, ...years].sort((a, b) => a - b);
					setYears(data);
				} else if (response && response.success) {
					setYears(years =>
						years
							.map(item => {
								if (item.id === values.id) {
									return {
										...item,
										...values,
									};
								}
								return item;
							})
							.sort((a, b) => a - b),
					);
				}
				onClose({});
			},
		});
	};

	const onClose = () => {
		setShow(false);
		setIsCopy(false);
		setValues({
			schoolId: 7,
			academicYearNumber: "",
			entityId: null,
		});
	};

	const openPopup = item => {
		if (item) {
			setValues(item);
		}
		setShow(true);
	};

	const handleToggle = () => {
		setShowPopover(!showPopover);
	};

	const closePopover = () => {
		setShowPopover(false);
	};

	const onNumberChange = (event, fieldName, errorfieldName) => {
		const value = event.target.value;
		if (value.includes("e") || value.includes(".") || +value < 0) {
			return false;
		}
		if (value === "" || Number.isInteger(+value)) {
			setValues(values => ({
				...values,
				[fieldName]: +value,
			}));
		}
	};

	const onSelectOptionChange = (item, fieldName) => {
		if (!item) return false;
		setValues(values => ({
			...values,
			[fieldName]: item.value,
		}));
	};

	const confirmCopy = event => {
		if (!event.target.checked) {
			setIsCopy(event.target.checked);
			return;
		}
		AlertService.alertConfirm(
			"Please confirm",
			<Row>
				<Col md={12}>
					<span>
						Copying this year level will take all attached sections and
						instructions. Selections can not be copied from one year level to
						another. Structural elements will be copied, with some exceptions
						including the following:
					</span>
					<div className="sso-alert danger ">
						<ul className="disclosure_closed">
							<li>Selections Automatic Selections / Grouped Selections</li>
							<li>Disallowed Selections</li>
							<li>Global Rules</li>
							<li>Capacity Limits</li>
						</ul>
					</div>
					<span>
						Once the new year level has been created, you can add selections,
						make any required changes, and add automatic selections, disallowed
						sections, and global rules as required.
					</span>
				</Col>
			</Row>,
			"Confirm",
			"Cancel",
		)
			.then(() => {
				setIsCopy(event.target.value);
			})
			.catch(() => { });
	};

	return (
		<>
			<Popup
				show={show}
				title={
					<div className="d-flex align-items-center gap-1">
						<FaPlus />
						<span>Add New</span>
					</div>
				}
				handleClose={onClose}
				action={onSave}
				buttonTitle="Save">
				<Row className="gap-3">
					<Col md={12}>
						<Input
							label={"Enter the new Year Level below as a number:"}
							placeholder={"Year"}
							id={"academicYearNumber"}
							helperText={"Max 6 digits"}
							value={values.academicYearNumber || ""}
							// errorMessage={
							// 	errors && errors[`${shortKey}.bPointMerchantNumber`]
							// }
							onChange={event => {
								onNumberChange(event, "academicYearNumber");
							}}
						/>
					</Col>
					{years && !!years.length && !values?.id && (
						<>
							<Col md={12}>
								<CheckBox
									size={"lg"}
									label={"Copy an existing year"}
									value={true}
									checked={isCopy}
									onChange={event => {
										confirmCopy(event);
										// setIsCopy(event.target.checked);
									}}
								/>
							</Col>
							{isCopy && (
								<>
									<Col md={12}>
										<ReactSelectOption
											placeholder={"Choose the Year here..."}
											value={values.entityId}
											selectedValue={(() => {
												const selectedItem = {
													...years.find(data => data.id === values.entityId),
												};
												if (Object.keys(selectedItem).length) {
													selectedItem.value = selectedItem.id;
													selectedItem.label = `Year ${selectedItem.academicYearNumber}`;
													return selectedItem;
												}
											})()}
											items={years
												.filter(item => item.id !== values.entityId)
												.map(data => ({
													...data,
													label: `Year ${data.academicYearNumber}`,
													value: data.id,
												}))}
											onChange={item => onSelectOptionChange(item, "entityId")}
										/>
									</Col>
									{/* <Col md={12}>
								<RadioBox
									id={"withSelections"}
									name={"isSelection"}
									value={true}
									label={"With Selections"}
									checked={values.isSelection}
									onChange={event => {
										onChangeRadio(event, "isSelection");
									}}
								/>
							</Col>
							<Col md={12}>
								<RadioBox
									id={"withoutSelections"}
									name={"isSelection"}
									value={false}
									checked={!values.isSelection}
									label={"Without Selections"}
									onChange={event => {
										onChangeRadio(event, "isSelection");
									}}
								/>
							</Col> */}
								</>
							)}
						</>
					)}
				</Row>
			</Popup>
			<HelpModal
				show={openHelpModal}
				handleClose={() => {
					setOpenHelpModal(!openHelpModal);
				}}
			/>
			<CustomBreadcrumb
				boxClassName="shadow"
				data={[
					{
						name: "Checklist",
						url: "/",
					},
				]}>
				<div className="d-flex align-items-center gap-2">
					<Link
						to={"#"}
						target="_blank"
						className="btn btn-outline-blue btn-extra_small">
						Help Documentation
					</Link>
					<Link
						to={"#"}
						onClick={() => {
							setOpenHelpModal(!openHelpModal);
						}}
						className="btn btn-outline-blue btn-extra_small">
						help ?
					</Link>
				</div>
			</CustomBreadcrumb>
			<Container
				className="mt-3"
				style={{
					minHeight: 428,
				}}>
				<div className="settings position-relative">
					<div className={`checklist_menu ${isShowLeftMenu ? "active" : ""}`}>
						<Card className="user-dashboard-section">
							<CardBody
								className={`sso-checklist-card-body ${isShowLeftMenu ? "active" : ""
									}`}>
								<div className="flex-column sso_settings_tab pb-3">
									<div className="sso_settings_tab_show_button d-lg-none d-flex">
										<Link
											to={"#"}
											onClick={() => {
												setIsShowLeftMenu(!isShowLeftMenu);
											}}>
											{!isShowLeftMenu ? <FaBars /> : <SlLogout />}
										</Link>
									</div>
									<div className="sso_check_list_card">
										<h3>SELECT THE YEAR LEVEL</h3>
										{years.map((item, index) => (
											<div
												key={index}
												onClick={() => {
													setActiv(item);
													if (isShowLeftMenu) {
														setIsShowLeftMenu(false);
													}
												}}
												className={`sso_sso_check_list_year ${activ?.id === item.id ? "activ" : ""
													}`}>
												{activ?.id === item.id ? (
													<div className="trigger_container">
														<OverlayTrigger
															// trigger="focus"
															trigger="click"
															rootClose
															placement="bottom"
															show={showPopover}
															onToggle={handleToggle}
															overlay={
																<Popover
																	id="popover-positioned-bottom"
																	className="sso_popover">
																	<div className="sso_popover_container">
																		<Link
																			to={"#"}
																			onClick={() => {
																				openPopup(item);
																				closePopover();
																			}}
																			className="d-flex gap-1 sso_popover_link">
																			<FaEdit />
																			Edit
																		</Link>
																		<Link
																			to={"#"}
																			className="d-flex gap-1 sso_popover_link"
																			onClick={() => {
																				deleteYera(item);
																				closePopover();
																			}}>
																			<FaRegTrashCan />
																			Delete
																		</Link>
																	</div>
																</Popover>
															}>
															<Link
																to={"#"}
																className="sso_popover_button"
																onClick={e => {
																	e.stopPropagation();
																	handleToggle();
																}}>
																<CiSettings />
															</Link>
														</OverlayTrigger>
													</div>
												) : null}

												<span>Year {item.academicYearNumber}</span>
												<FaLongArrowAltRight className="sso_FaLongArrowAltRight" />
											</div>
										))}
									</div>
								</div>

								<div className="d-flex flex-column gap-3 px-2 pb-5">
									<button
										className={`btn btn-gren btn-sm`}
										onClick={() => {
											openPopup();
										}}>
										<FaPlus />
										<span>Add New</span>
									</button>
								</div>
							</CardBody>
						</Card>
					</div>
					<div className={`sso_checklist_content`}>
						<Card style={{ minHeight: 546 }}>
							<CardBody>
								<div className="sso_settings_tab_show_button px-0 d-flex  justify-content-start align-items-center gap-2">
									{!isShowLeftMenu ? (
										<Link
											className="d-lg-none d-block "
											to={"#"}
											onClick={() => {
												setIsShowLeftMenu(!isShowLeftMenu);
											}}>
											{!isShowLeftMenu ? <SlLogin /> : null}
										</Link>
									) : null}
									{!!activ && (
										<h3 className="m-0 fs-24 fw-500">
											Year {activ.academicYearNumber}
										</h3>
									)}
								</div>
								{!!activ && (
									<Row className="gap-3">
										<Col md={12}>
											<span className="caption">
												Click the area you wish to mark on, then tick when
												completed.
											</span>
											<hr className="mt-2" />
										</Col>
										<Col md={12} className="d-flex flex-column gap-4">
											<Row className="">
												<Col
													md={12}
													lg={5}
													className="d-flex gap-2 align-items-center">
													<CheckBox
														size={"lg"}
														checked={true}
													// value={isOn}
													// onChange={event => {
													// 	setIsOn(event.target.checked);
													// }}
													/>
													<Link
														to={`/admin/selection_matrix/year/${activ.id}`}
														className="fs-20">
														Selection Matrix
													</Link>
												</Col>
												<Col md={12} lg={7}>
													<span className="text-muted">
														Upload the course information for this year level
													</span>
												</Col>
											</Row>
											<Row className="">
												<Col
													md={12}
													lg={5}
													className="d-flex gap-2 align-items-center">
													<CheckBox
														size={"lg"}
													// value={isOn}
													// onChange={event => {
													// 	setIsOn(event.target.checked);
													// }}
													/>
													<Link
														to={`/admin/selection_process/${activ.id}`}
														className="fs-20">
														{" "}
														Selection Process
													</Link>
												</Col>
												<Col md={12} lg={7}>
													<span className="text-muted">
														This is where you edit what students will see
													</span>
												</Col>
											</Row>
											<Row className="">
												<Col
													md={12}
													lg={5}
													className="d-flex gap-2 align-items-center">
													<CheckBox
														size={"lg"}
													// value={isOn}
													// onChange={event => {
													// 	setIsOn(event.target.checked);
													// }}
													/>
													<Link
														to={`/admin/students/${activ.id}`}
														className="fs-20">
														Students
													</Link>
												</Col>
												<Col md={12} lg={7}>
													<span className="text-muted">
														Upload and manage student profiles here
													</span>
												</Col>
											</Row>
											<Row className="">
												<Col
													md={12}
													lg={5}
													className="d-flex gap-2 align-items-center">
													<CheckBox
														size={"lg"}
													// value={isOn}
													// onChange={event => {
													// 	setIsOn(event.target.checked);
													// }}
													/>
													<Link
														to={`/admin/results/${activ.id}`}
														className="fs-20">
														Results
													</Link>
												</Col>
												<Col md={12} lg={7}>
													<span className="text-muted">
														Upload previously completed subjects and marks
													</span>
												</Col>
											</Row>

											<Row className="">
												<Col
													md={12}
													lg={5}
													className="d-flex gap-2 align-items-center">
													<CheckBox
														size={"lg"}
													// value={isOn}
													// onChange={event => {
													// 	setIsOn(event.target.checked);
													// }}
													/>
													<Link
														to={`/admin/teachers/${activ.id}`}
														className="fs-20">
														Staff
													</Link>
												</Col>
												<Col md={12} lg={7}>
													<span className="text-muted">
														See which staff members are set up for this year
														level
													</span>
												</Col>
											</Row>

											{/* <Row className="">
												<Col
													md={12}
													lg={5}
													className="d-flex gap-2 align-items-center">
													<CheckBox
														size={"lg"}
													// value={isOn}
													// onChange={event => {
													// 	setIsOn(event.target.checked);
													// }}
													/>
													<Link
														to={`/admin/tick_flick/${activ.id}`}
														className="fs-20">
														Tick/Flick
													</Link>
												</Col>
												<Col md={12} lg={7}>
													<span className="text-muted">
														Override pre-requisite data for selection
														eligibility
													</span>
												</Col>
											</Row> */}
											{/* <Row className="">
												<Col
													md={12}
													lg={5}
													className="d-flex gap-2 align-items-center">
													<CheckBox
														size={"lg"}
													// value={isOn}
													// onChange={event => {
													// 	setIsOn(event.target.checked);
													// }}
													/>
													<Link
														to={`/admin/schedule/${activ.id}`}
														className="fs-20">
														Schedule
													</Link>
												</Col>
												<Col md={12} lg={7}>
													<span className="text-muted">
														Check the dates that student access will be on and
														off
													</span>
												</Col>
											</Row> */}
											{/* <Row className="">
												<Col
													md={12}
													lg={5}
													className="d-flex gap-2 align-items-center">
													<CheckBox
														size={"lg"}
														// value={isOn}
														// onChange={event => {
														// 	setIsOn(event.target.checked);
														// }}
													/>
													<Link
														to={`/admin/extracts/${activ.id}`}
														className="fs-20">
														Extract
													</Link>
												</Col>
												<Col md={12} lg={7}>
													<span className="text-muted">
														Generate selection information for timetabling
														software
													</span>
												</Col>
											</Row> */}
										</Col>
									</Row>
								)}
							</CardBody>
						</Card>
					</div>
				</div>
			</Container>
		</>
	);
}

export default Checklist;
