import React, { useState } from "react";
import { Accordion, useAccordionButton } from "react-bootstrap";
import { Link } from "react-router-dom";
import { MdOutlineArrowBackIosNew } from "react-icons/md";

function CustomToggle({
	name,
	eventKey,
	action,
	children,
	second,
	active,
	width,
}) {
	const [show, setShow] = useState(false);

	const decoratedOnClick = useAccordionButton(eventKey, () => {
		setShow(!show);
	});

	return (
		<div
			className="d-flex  align-items-center"
			style={{
				width: second ? `${width + 15}px` : `${width + 20}px`,
			}}>
			<Link
				to={"#"}
				style={{
					width: `${width}px`,
				}}
				className={`toggle ${second ? "sm" : ""} ${
					active === eventKey ? "active" : ""
				}`}
				onClick={() => {
					action && action(eventKey, name);
				}}>
				{name}
			</Link>
			{children && !!children.length && (
				<Link
					to={"#"}
					className={`toggle_button ${second ? "sm" : ""} ${
						eventKey === active ? "active" : "active"
					}`}
					style={{
						transform: show ? "rotate(269deg)" : "rotate(0deg)",
					}}
					onClick={decoratedOnClick}>
					<MdOutlineArrowBackIosNew
						fill={`${show ? "#217ebd" : "#ccc"}`}
						style={{
							width: second ? 15 : 20,
							height: second ? 15 : 20,
						}}
					/>
				</Link>
			)}
		</div>
	);
}

function MenuAccordion({
	children,
	className = "",
	second = false,
	active,
	setActive,
	width = 175,
	setIsShowLeftMenu,
	action = () => {},
}) {
	if (children && !children.length) {
		return null;
	}

	return (
		<Accordion className="d-flex flex-column gap-2">
			{children &&
				!!children.length &&
				children.map((item, index) => {
					return (
						<React.Fragment key={index}>
							<CustomToggle
								second={second}
								eventKey={item.id.toString()}
								setIsShowLeftMenu={setIsShowLeftMenu}
								name={item.name}
								active={active}
								setActive={setActive}
								children={item.children}
								width={width}
								action={action}
							/>
							<Accordion.Collapse eventKey={item.id.toString()}>
								<div style={{ marginLeft: "10px" }}>
									<MenuAccordion
										className="px-2"
										second={true}
										active={active}
										setActive={setActive}
										setIsShowLeftMenu={setIsShowLeftMenu}
										children={item.children}
										action={action}
										width={width - 10}></MenuAccordion>
								</div>
							</Accordion.Collapse>
						</React.Fragment>
					);
				})}
		</Accordion>
	);
}

export default MenuAccordion;
