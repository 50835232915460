export const bankPoints = [
	{
		name: "CommBank Bpoint",
		id: 1,
		bPointAuthorisationURL:
			"https://www.bpoint.com.au/webapi/v2/txns/processtxnauthkey",
		bPointPaymentURL: "https://www.bpoint.com.au/payments/",
		bPointVerificationURL:
			"https://www.bpoint.com.au/webapi/v2/txns/withauthkey/",
	},
	{
		name: "Bankwest Bpoint",
		id: 2,
		bPointAuthorisationURL:
			"https://www.bpoint.com.au/bw/webapi/v2/txns/processtxnauthkey",
		bPointPaymentURL: "https://www.bpoint.com.au/bw/payments/",
		bPointVerificationURL:
			"https://www.bpoint.com.au/bw/webapi/v2/txns/withauthkey/",
	},
];
export const defaultBillecharges = [
	{ chargeType: 1, value: "", isBillableCharge: true },
	{ chargeType: 2, value: "", isBillableCharge: false },
	{ chargeType: 3, value: "", isBillableCharge: false },
	{ chargeType: 4, value: "", isBillableCharge: false },
	{ chargeType: 5, value: "", isBillableCharge: false },
];
export const billableChargesInputTypes = [2, 5];

export const translationPageType = [
	{
		id: 1,
		name: "Admin",
	},
	{
		id: 2,
		name: "Client",
	},
	// {
	// 	id: 1,
	// 	name: "Admin",
	// },
];
export const VALID_FILE_TYPES_KEYS = ["csv", "xls", "xlsx"];
