import React from "react";

function CheckBox({
	id,
	onChange,
	checkClassName,
	value,
	label,
	name,
	onKeyDown,
	onFocus,
	onBlur,
	size,
	disabled,
	boxdisabled,
	checked,
	labelClassName,
}) {
	return (
		<>
			<div
				className={`form-check  sso_checkBox d-flex align-items-center ${
					boxdisabled ? "boxdisabled" : ""
				} ${checkClassName ? checkClassName : ""} `}>
				<input
					className={`form-check-input ${size ? `sso_checkBox-${size}` : ""}`}
					type="checkbox"
					value={value}
					id={id}
					onChange={onChange}
					onBlur={onBlur}
					onKeyDown={onKeyDown}
					checked={checked}
					onFocus={onFocus}
					name={name}
					disabled={disabled}
				/>
				{label && (
					<label
						className={`form-check-label sso_label ${
							labelClassName ? labelClassName : ""
						}`}
						htmlFor={id}>
						{label}
					</label>
				)}
			</div>
		</>
	);
}

export default CheckBox;
