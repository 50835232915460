import React from "react";
import { Modal } from "react-bootstrap";
import { IoClose } from "react-icons/io5";
import { useSelector } from "react-redux";
import ModalLoader from "../loadre/ModalLoader";

function Popup({
	show,
	handleClose,
	title,
	action,
	children,
	size = "md",
	iscloseButoon = true,
	buttonTitle = "Save Changes",
	no = "Close",
	disabled,
	footerClassNeme,
	footerChildren,
}) {
	const { modalSpinners } = useSelector(state => state.spinners);

	return (
		<Modal
			show={!!show}
			onHide={handleClose}
			className={"sso_modal"}
			dialogClassName={`sso_modal_dialog modal-90w"`}>
			<Modal.Header className="sso_modal_header">
				<Modal.Title>{title}</Modal.Title>
				<div>
					<button className="btn-close mt-0 pt-0" onClick={handleClose}>
						<IoClose />
					</button>
				</div>
			</Modal.Header>
			{children && (
				<Modal.Body>
					{modalSpinners && modalSpinners.length ? (
						<ModalLoader modalSpinners={modalSpinners} />
					) : (
						children
					)}
				</Modal.Body>
			)}
			{!!action && (
				<Modal.Footer
					className={`sso_modal_footer ${
						footerClassNeme ? footerClassNeme : ""
					}`}>
					{iscloseButoon && (
						<button
							size="sm"
							disabled={modalSpinners.length}
							className="btn btn-default btn-sm"
							variant="secondary"
							onClick={handleClose}>
							{no}
						</button>
					)}
					{footerChildren ? footerChildren : null}
					{action && (
						<button
							className="btn btn-primary btn-sm"
							disabled={modalSpinners.length || disabled}
							onClick={action}>
							{buttonTitle}
						</button>
					)}
				</Modal.Footer>
			)}
		</Modal>
	);
}

export default Popup;
