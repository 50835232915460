import timezonesList from "timezones-list";

const listTimezones = timezonesList.reduce((array, data) => {
	try {
		const date = new Date().toLocaleTimeString("en-us", {
			timeZone: data.tzCode,
			timeZoneName: "short",
		});

		if (!date) {
			return true;
		}

		array.push({
			value: data.tzCode,
			label: data.name,
		});
	} catch {}

	return array;
}, []);

export default listTimezones;
