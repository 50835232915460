import React from "react";
import { Outlet } from "react-router-dom";
import AdminHeader from "../header/AdminHeader";
import ClientHeader from "../header/ClientHeader";
import StudentHeader from "../header/StudentHeader";

function Layaut(props) {
	return (
		<>
			<AdminHeader />
			<ClientHeader />
			{/* <StudentHeader /> */}
			<div>
				<Outlet />
			</div>
		</>
	);
}

export default Layaut;
