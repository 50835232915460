import React, { useContext, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import Input from "../../../components/inputs/Input";
import { FaCheck } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { SelectionProcessContext } from "../SelectionProcess";
import MainService from "../../../helpers/services/mainService";
import { ApiService } from "../../../helpers/services/apiSrevice";

function AddTextInput({}) {
	const [values, setValues] = useState({
		schoolId: 1,
		courseCode: "",
		courseName: "",
		courseType: 1,
	});
	const { activeSection, setNavigation } = useContext(SelectionProcessContext);
	const { yearId } = useParams();
	const [isChange, setIsChange] = useState(false);

	const onChange = (event, fieldName) => {
		setIsChange(true);
		setValues(values => ({
			...values,
			[fieldName]: event.target.value,
		}));
	};

	const onSubmit = event => {
		event.preventDefault();
		MainService.callApi({
			api: ApiService.createSelectionWithCourse,
			data: {
				...values,
				academicYearId: yearId,
				instructionId: activeSection.id,
			},
			cb: response => {
				if (response) {
					MainService.callApi({
						api: ApiService.getNavigation,
						data: yearId,
						cb: response => {
							if (response && response.data && response.data.length) {
								setNavigation(response.data);
							}
						},
					});
				}
			},
		});
	};

	return (
		<form onSubmit={onSubmit}>
			<Row>
				<Col md={12}>
					<h4 className="font-blue-madison fw-500 fs-18 my-5">
						Use this option for a text-only response to your instruction.
					</h4>
				</Col>
				<Col md={12} lg={6}>
					<Input
						label={"Course Code"}
						id={"courseCode"}
						value={values?.courseCode || ""}
						// errorMessage={errors && errors[`studentCode`]}
						onChange={event => {
							onChange(event, "courseCode");
						}}
					/>
				</Col>
				<Col md={12} lg={6}>
					<Input
						label={"Course Name"}
						id={"courseName"}
						value={values?.courseName || ""}
						// errorMessage={errors && errors[`studentCode`]}
						onChange={event => {
							onChange(event, "courseName");
						}}
					/>
				</Col>
				<Col md={12} className="d-flex justify-content-end mt-3">
					<Button
						disabled={!isChange}
						type="submit"
						className="btn-primary d-flex gap-2 align-items-center justify-content-center">
						<FaCheck />
						Add one-off selection
					</Button>
				</Col>
			</Row>
		</form>
	);
}

export default AddTextInput;
