import React, { useState } from "react";
import { Button, Card, CardBody, Col, Row, Tab, Tabs } from "react-bootstrap";
import CardTitle from "../../../components/cardTitle/CardTitle";
import Textarea from "../../../components/inputs/Textarea";
import MainService from "../../../helpers/services/mainService";
import AlertService from "../../../helpers/services/alertService";
import { ApiService } from "../../../helpers/services/apiSrevice";

function Messages({ values, setValues }) {
	const [activTabe, setActivTabe] = useState("login_messages");

	const changeTabe = key => {
		setActivTabe(key);
		setIsChange(false);
	};

	const [isChange, setIsChange] = useState(false);

	const onSubmit = event => {
		event.preventDefault();
		if (!isChange) return false;
		const formData = new FormData();
		Object.entries(values).forEach(([key, value]) => {
			formData.append(key, value);
		});
		MainService.callApi({
			api: ApiService.updateSchoolBrandingSettings,
			data: formData,
			cb: response => {
				if (response && response.success === true) {
					AlertService.alert("success", "Data seved");
					setIsChange(false);
				}
			},
		});
	};

	const onChange = (event, fieldName) => {
		setIsChange(true);
		setValues(values => ({
			...values,
			[fieldName]: event.target.value,
		}));
	};

	return (
		<Card style={{ minHeight: 478 }}>
			<CardBody>
				<CardTitle
					title="SCHOOL NAME"
					titleClassname={"color_bleck fw-700 uppercase"}></CardTitle>
				<Row>
					<Col md={12}>
						<Tabs
							activeKey={activTabe}
							onSelect={k => changeTabe(k)}
							className="sso-tabs">
							<Tab
								className="sso-tab"
								eventKey="login_messages"
								title="Login Messages">
								<Row className="mt-3">
									<Col md={12} lg={6}>
										<form
											className="d-flex flex-column gap-3"
											onSubmit={onSubmit}>
											<Textarea
												label={"Heading"}
												id={"loginHeading"}
												rows={2}
												value={values.loginHeading || ""}
												onChange={event => {
													onChange(event, "loginHeading");
												}}
											/>
											<Textarea
												label={"Messages"}
												id={"loginMessages"}
												value={values.loginMessages || ""}
												// errorMessage={
												// 	errors && errors[`${shortKey}.bPointMerchantNumber`]
												// }
												onChange={event => {
													onChange(event, "loginMessages");
												}}
											/>
											<div className="d-flex justify-content-end">
												<Button
													type="submit"
													disabled={!isChange}
													className="btn-primary ">
													Save
												</Button>
											</div>
										</form>
									</Col>
								</Row>
							</Tab>
							<Tab
								className="sso-tab"
								eventKey="selection_screen_message"
								title="Selection Screen Message">
								<Row className="mt-3">
									<Col md={12} lg={5}>
										<form
											className="d-flex flex-column gap-3"
											onSubmit={onSubmit}>
											<Textarea
												id={"selectionScreenMessage"}
												value={values.selectionScreenMessage}
												// errorMessage={
												// 	errors && errors[`${shortKey}.bPointMerchantNumber`]
												// }
												onChange={event => {
													onChange(event, "selectionScreenMessage");
												}}
											/>

											<div className="d-flex justify-content-end">
												<Button
													type="submit"
													disabled={!isChange}
													className="btn-primary ">
													Save
												</Button>
											</div>
										</form>
									</Col>
								</Row>
							</Tab>
						</Tabs>
					</Col>
				</Row>
			</CardBody>
		</Card>
	);
}

export default Messages;
