import React, { useState } from "react";
import CustomBreadcrumb from "../../components/breadcrumbs/Breadcrumb";
import { Link } from "react-router-dom";
import {
	Card,
	CardBody,
	Col,
	Container,
	OverlayTrigger,
	Row,
	Table,
	Tooltip,
} from "react-bootstrap";
import CardTitle from "../../components/cardTitle/CardTitle";
import ReadOnlyCheckBox from "../../components/readOnlyCheckBox/ReadOnlyCheckBox";
import ReactSelectOption from "../../components/inputs/ReactSelectOption";
import { FaDownload } from "react-icons/fa";
import { DatatableWrapper, TableBody, TableHeader } from "react-bs-datatable";
import { HiRefresh } from "react-icons/hi";
import RefreshButton from "../../components/buttons/RefreshButton";

function Monitor(props) {
	const [data, setData] = useState([
		{ studentName: "", studentCode: "", year: "", globalRules: [] },
	]);

	const columns = [
		{
			prop: "code",
			title: "Act as",
			isFilterable: true,
			isSortable: true,
			cell: row => (
				<div>
					<span className="coustem-label blue_label d-inline-block mx-2">
						Act as
					</span>
				</div>
			),
		},
		{
			prop: "studentName",
			title: "Student Name",
			isFilterable: true,
			isSortable: true,
			cell: row => (
				<div>
					<p className="d-inline-block">{row.studentName}</p>
				</div>
			),
		},
		{
			prop: "studentCode",
			title: "Student Code",
			isFilterable: true,
			isSortable: true,
			cell: row => (
				<div>
					<p className="d-inline-block">{row.studentCode}</p>
				</div>
			),
		},
		{
			prop: "year",
			title: "Year",
			isFilterable: true,
			isSortable: true,
			cell: row => (
				<div>
					<p className="d-inline-block">{row.year}</p>
				</div>
			),
		},
		{
			prop: "globalRules",
			title: "Global Rules",

			cell: row => (
				<div>
					<p className="d-inline-block">{row.year}</p>
				</div>
			),
		},
		{
			prop: "globalRules",
			title: "Global Rules",

			cell: row => (
				<div>
					<p className="d-inline-block">{row.year}</p>
				</div>
			),
		},
		{
			prop: "globalRules",
			title: "Global Rules",

			cell: row => (
				<div>
					<span>1</span>
					<span>2</span>
					<span>3</span>
					<span>4</span>
					<span>5</span>
					<span>6</span>
				</div>
			),
		},
	];
	return (
		<>
			<CustomBreadcrumb
				boxClassName="shadow"
				data={[{ name: "Monitor", url: "/" }]}>
				<div className="d-flex align-items-center gap-2">
					<Link
						to={"#"}
						target="_blank"
						className="btn btn-outline-blue btn-extra_small">
						Help Documentation
					</Link>
				</div>
			</CustomBreadcrumb>
			<Container className="mt-3">
				<Card>
					<CardBody>
						<CardTitle title={"Monitor"} />
						<Row className="mt-3">
							<Col md={12} lg={6} className="mt-2">
								<label className="mt-4">
									View the status of students' selections process below.
								</label>
								<label className="w-lg-50 w-100">
									Use the filter(s) above the table to refine your search.
								</label>
								<label className="w-lg-50 w-100">
									SSO Co-ordinators and Course Counsellors can make changes to
									selections, with the student's consent.
								</label>
							</Col>
							<Col md={12} lg={6} className="mt-2">
								<b>Key</b>
								<ReadOnlyCheckBox
									type="success"
									label={"Compliant with any(all) criteria"}
								/>
								<ReadOnlyCheckBox
									type="danger"
									label={"Not yet compliant with criteria"}
								/>
								<ReadOnlyCheckBox
									type="nothing"
									label={"Section not required for this student"}
								/>
								<ReadOnlyCheckBox
									label={"Section has not yet been accessed (viewed)"}
								/>
							</Col>
							<Col md={12} lg={6} className="mt-3">
								<ReactSelectOption />
								<button
									to={"#"}
									target="_blank"
									className="btn btn-outline-btn-default margin-top-10 btn-sm">
									<FaDownload />
									Export as displayeds
								</button>
							</Col>
							<Col md={12} lg={6} className="mt-3">
								<ReactSelectOption />
								<label className="text-danger d-block margin-top-10">
									Show tallies for all students
								</label>
							</Col>
							<Col md={12}>
								<DatatableWrapper
									body={data}
									headers={columns}
									paginationOptionsProps={{
										initialState: {
											rowsPerPage: 100,
											options: [5, 10, 15, 20],
										},
									}}>
									<div className="d-flex justify-content-end margin-top-10 margin-bottom-10">
										<RefreshButton>
											<HiRefresh />
										</RefreshButton>
									</div>
									<div>
										<Table className="sso_tabel" responsive bordered>
											<TableHeader />
											<TableBody />
										</Table>
									</div>
								</DatatableWrapper>
							</Col>
						</Row>
					</CardBody>
				</Card>
			</Container>
		</>
	);
}

export default Monitor;
