import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import CheckBox from "../../../components/inputs/CheckBox";
import {
	DatatableWrapper,
	Filter,
	TableBody,
	TableHeader,
} from "react-bs-datatable";
import { FaCheck } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { SelectionProcessContext } from "../SelectionProcess";
import MainService from "../../../helpers/services/mainService";
import { ApiService } from "../../../helpers/services/apiSrevice";

function AddFromMatrix({}) {
	const [tableData, setTableData] = useState([]);
	const [isChange, setIsChange] = useState(false);
	const [values, setValues] = useState({
		courseIds: [],
	});
	const { activeSection, setNavigation } = useContext(SelectionProcessContext);
	const { yearId } = useParams();

	useEffect(() => {
		if (activeSection && activeSection.id && yearId) {
			getInstructionCourses(yearId, activeSection.id);
		}
	}, [activeSection, yearId]);

	const getInstructionCourses = (academicYearId, instructionId) => {
		if (!academicYearId && !instructionId) return false;
		MainService.callApi({
			api: ApiService.getInstructionCourses,
			data: {
				academicYearId,
				instructionId,
			},
			cb: response => {
				if (response && response.data && response.data.length) {
					setTableData(response.data);
					// setValues(values => ({
					// 	...values,
					// 	courseIds: response.data
					// 		.filter(item => item.isChecked)
					// 		.map(item => item.id),
					// }));
				}
			},
		});
	};

	const onChange = (event, row) => {
		if (!row) return false;
		setIsChange(true);
		if (event.target.checked && !row.isChecked) {
			setValues(values => ({
				...values,
				courseIds: [...values.courseIds, row.id],
			}));
		} else if (!event.target.checked && !row.isChecked) {
			setValues(values => ({
				...values,
				courseIds: values.courseIds.filter(item => item !== row.id),
			}));
		}
	};

	const columns = [
		{
			prop: "courseCode",
			title: "Course Code",
			isFilterable: true,
			isSortable: true,
			cell: row => (
				<div>
					<div>{row.courseCode}</div>
				</div>
			),
		},
		{
			prop: "courseName",
			title: "Course Name",
			isFilterable: true,
			isSortable: true,
			cell: row => (
				<div>
					<div>{row.courseName}</div>
				</div>
			),
		},
		{
			prop: "select",
			title: (
				<div>
					{/* <CheckBox
                        size={"lg"}
                        checked={students?.length === deleteIds?.length}
                        onChange={event => {
                            if (event.target.checked) {
                                setDeleteFiles(students?.map((_, index) => index));
                            } else {
                                setDeleteFiles([]);
                            }
                        }}
                    /> */}
				</div>
			),
			cell: row => (
				<td>
					<CheckBox
						checked={row.isChecked || values.courseIds.includes(row.id)}
						onChange={event => {
							onChange(event, row);
						}}
					/>
				</td>
			),
		},
	];

	const onSubmit = event => {
		event.preventDefault();
		MainService.callApi({
			api: ApiService.selectionCreateBulk,
			data: {
				...values,
				instructionId: activeSection.id,
			},
			cb: response => {
				if (response) {
					MainService.callApi({
						api: ApiService.getNavigation,
						data: yearId,
						cb: response => {
							if (response && response.data && response.data.length) {
								setNavigation(response.data);
							}
						},
					});
					setValues(values => ({
						...values,
						courseIds: [],
					}));
				}
			},
		});
	};

	return (
		<form onSubmit={onSubmit}>
			<Row>
				<Col md={12}>
					<h4 className="font-blue-madison fw-500 fs-18 mt-5">
						Choose courses from the list below, or change to a different option
						above.
					</h4>
				</Col>
				<Col md={12}>
					<DatatableWrapper
						body={tableData}
						headers={columns}
						paginationOptionsProps={{
							initialState: {
								rowsPerPage: 100,
								options: [5, 10, 15, 20],
							},
						}}>
						<Row className="my-3 justify-content-between gap-3">
							<Col md={2}>
								<Filter
									placeholder="search"
									classes={{
										input: "input-sm sso_input",
										clearButton: "clearButton",
									}}
								/>
							</Col>
							<Col md={12} className="mb-2">
								<Table responsive bordered className="sso_tabel">
									<TableHeader />
									<TableBody />
								</Table>
							</Col>
						</Row>
					</DatatableWrapper>
				</Col>
				<Col md={12} className="d-flex justify-content-end mt-3">
					<Button
						disabled={!values.courseIds.length}
						type="submit"
						className="btn-primary d-flex gap-2 align-items-center justify-content-center">
						<FaCheck />
						Add one-off selection
					</Button>
				</Col>
			</Row>
		</form>
	);
}

export default AddFromMatrix;
