import React from "react";
import { Form, Row } from "react-bootstrap";
import { FaDollarSign, FaPercentage } from "react-icons/fa";

function Input({
	id = "",
	onChange,
	boxClasname,
	inputClassName,
	value,
	label,
	type = "text",
	placeholder,
	onKeyDown,
	onFocus,
	onBlur,
	size,
	small,
	required,
	labelCol = "md-12",
	inputCol = "md-12",
	action,
	boxdisabled,
	disabled,
	currency = null,
	errorMessage = "",
	helperText,
	boxStyles = {},
	width = true,
	bottomLabel,
	labelClassname,
}) {
	return (
		<Form.Group
			style={boxStyles}
			className={` ${boxClasname ? boxClasname : ""}  ${
				boxdisabled ? "boxdisabled" : ""
			} ${width ? "w-100" : ""}`}>
			<Row className="align-items-center">
				{label && (
					<div className={labelCol}>
						<Form.Label
							className={`sso_label ${labelClassname ? labelClassname : ""}`}
							htmlFor={id}>
							{label}
							{required && <span className="sso_required">*</span>}
						</Form.Label>
					</div>
				)}
				<div className={`${inputCol}  d-flex gap-1 align-items-center`}>
					{currency !== null && !!!currency && <FaDollarSign />}
					<div className={`${width ? "w-100" : ""}`}>
						<Form.Control
							size={size}
							onChange={onChange}
							onBlur={onBlur}
							onKeyDown={onKeyDown}
							onFocus={onFocus}
							id={id}
							className={`sso_input  ${inputClassName ? inputClassName : ""} ${
								inputClassName === "border_none" && !value ? "error" : ""
							}  ${small ? "input-sm" : ""} `}
							value={value}
							type={type}
							disabled={disabled}
							placeholder={placeholder}
						/>
						{!!bottomLabel && (
							<div className={labelCol}>
								<Form.Label className="sso_label" htmlFor={id}>
									{bottomLabel}
									{required && <span className="sso_required">*</span>}
								</Form.Label>
							</div>
						)}
						{!!action && (
							<button
								to={"add"}
								disabled={!value}
								style={{ right: "13px", padding: "6px 10px" }}
								className="btn btn-primary btn-sm border_left_none position-absolute top-50  translate-middle-y"
								onClick={() => {
									action();
								}}>
								Get Info
							</button>
						)}
						{!!helperText && (
							<small className="text-muted d-block mt-1">{helperText}</small>
						)}
						{!!errorMessage && !!value && !!value.length && (
							<small className="text-danger d-block mt-1">{errorMessage}</small>
						)}
					</div>
					{currency !== null && currency && <FaPercentage />}
				</div>
			</Row>
		</Form.Group>
	);
}

export default Input;
