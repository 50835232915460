import React, { useEffect, useState } from "react";
import CustomBreadcrumb from "../../components/breadcrumbs/Breadcrumb";
import { Link, useParams } from "react-router-dom";
import { Button, Card, CardBody, Col, Container, Row, Table } from "react-bootstrap";
import CardTitle from "../../components/cardTitle/CardTitle";
import { DatatableWrapper, TableBody, TableHeader } from "react-bs-datatable";
import MultiReactSelectOption from "../../components/inputs/MultiReactSelectOption";
import DateHourMinuteSelector from "../../components/dateTimePicker/DateTimePicker";
import dayjs from "dayjs";
import { FaRegTrashAlt } from "react-icons/fa";
import { MdOutlineCalendarMonth } from "react-icons/md";
import CheckBox from "../../components/inputs/CheckBox";
import HelpModal from "../../components/modals/HelpModal";
import moment from "moment";
import { ApiService } from "../../helpers/services/apiSrevice";
import MainService from "../../helpers/services/mainService";
import AlertService from "../../helpers/services/alertService";
import { SUCCESS_KEY } from "../../helpers/constants/constants";

function parseDate(dateStr) {
	const parts = dateStr.split(/[- :]/); // Разбиваем строку по разделителям
	if (parts.length !== 5) {
		return "";
		throw new Error("Неверный формат даты. Ожидается формат: DD-MM-YYYY HH:mm");
	}

	const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}T${parts[3]}:${parts[4]}`;
	const date = new Date(formattedDate);

	if (isNaN(date)) {
		return;
	}

	return date;
}

function Schedule(props) {
	const { yearId } = useParams();
	const [data, setData] = useState([
		{
			status: null,
			startDateTime: "",
			endDateTime: "",
			viewOnly: false,
		},
	]);
	const [timeExtensions, setTimeExtensions] = useState([
		{
			selectStudents: [],
			scheduleEnd: null,
			viewonly: true,
		},
	]);
	const [selectedOptions, setSelectedOptions] = useState([]);
	const [dateValue, setDateValue] = useState("");
	const [open, setOpen] = useState(null);
	const [isDataChanged, setIsDataChanged] = useState(false);

	const options = [
		{ value: "option1", label: "Опция 1" },
		{ value: "option2", label: "Опция 2" },
		{ value: "option3", label: "Опция 3" },
		{ value: "option4", label: "Опция 4" },
		{ value: "option1", label: "Опция 1" },
		{ value: "option2", label: "Опция 2" },
		{ value: "option3", label: "Опция 3" },
		{ value: "option4", label: "Опция 4" },
	];

	useEffect(() => {
		if (!yearId) {
			return false;
		}
		MainService.callApi({
			api: ApiService.scheduleGetByAcademicYear,
			data: yearId,
			cb: response => {
				if (response && response.data) {
					const _data = [response.data];
					setData(_data);
				}
			},
		});
	}, [yearId]);

	// Функция обработки изменения выбора
	const handleChange = (selected, action) => {
		setSelectedOptions(selected || []);
	};

	const onSaveSchedule = values => {
		console.log(values);
		MainService.callApi({
			api: data[0].id ? ApiService.scheduleUpdate : ApiService.scheduleCreate,
			data: data[0],
			cb: response => {
				if (response && response.data) {
					const _data = [response.data];
					setData(_data);
				}
				AlertService.alert(SUCCESS_KEY, `Successfully ${data[0].id ? "Updated" : "Created"}`,);
			},
		});
	};

	const columns = [
		{
			prop: "status",
			title: "Status",
			cell: row => (
				<div className="d-flex justify-content-center align-items-center" >
					<span
						className={`schedule-status ${row.status === "Off" || !row.status ? "" : row.status === "On" ? "on" : "set"
							}`}>
						{row.status || "Off"}
					</span>
				</div>
			),
		},
		{
			prop: "Schedule",
			title: "Schedule start-end",
			cell: row => (
				<Row>
					<Col md={6}>
						<div className="dete_viwe">
							<label>Start</label>
							<Link
								to={"#"}
								onClick={e => {
									e.preventDefault();
									onOpen("startDateTime");
									if (row.startDateTime) {
										setDateValue(row.startDateTime);
									}
								}}>
								{
									row.startDateTime ?
										<span className="ms-2">
											{moment(row.startDateTime).format("DD-MM-YYYY HH:mm")}
										</span>
										: <label className="ms-2">Date</label>
								}
								<MdOutlineCalendarMonth />
							</Link>
						</div>
					</Col>
					<Col md={6}>
						<div className="dete_viwe">
							<label>End</label>
							<Link
								to={"#"}
								onClick={e => {
									e.preventDefault();
									onOpen("endDateTime");
									if (row.endDateTime) {
										setDateValue(row.endDateTime);
									}
								}}>
								{
									row.endDateTime ?
										<span className="ms-2">
											{moment(row.endDateTime).format("DD-MM-YYYY HH:mm")}
										</span>
										: <label className="ms-2">Date</label>
								}
								<MdOutlineCalendarMonth />
							</Link>
						</div>
					</Col>
				</Row>
			),
		},
		{
			prop: "viewonly",
			title: "View only",
			cell: row => (
				<div className="d-flex justify-content-center align-items-center">
					<CheckBox
						checked={row.viewOnly}
						onChange={event => {
							setData(prevData => {
								const updatedData = [...prevData];
								updatedData[0] = {
									...updatedData[0],
									viewOnly: event.target.checked
								};
								return updatedData;
							});
							setIsDataChanged(true);
						}}
					/>
				</div>
			),
		},
	];

	console.log(data);


	const timeExtensionColmns = [
		{
			prop: "selectStudents",
			title: "Select Students	",
			cell: row => (
				<div className="">
					<MultiReactSelectOption
						name="exampleSelect"
						items={options}
						selectedValue={selectedOptions}
						onChange={handleChange}
						placeholder="Select students..."
						isMulti={true} // Включаем мультивыбор
						isClearable={true} // Позволяет удалять выбранные опции
					/>
				</div>
			),
		},
		{
			prop: "Schedule",
			title: "Schedule end",
			cell: row => (
				<div md={6}>
					<div className="dete_viwe">
						<label>End</label>
						<Link
							to={"#"}
							onClick={e => {
								e.preventDefault();
								onOpen(new Date(), false);
							}}>
							<span>
								{dayjs(new Date(), "DD-MM-YYYY HH:mm").format(
									"DD-MM-YYYY HH:mm",
								)}
							</span>
							<MdOutlineCalendarMonth />
						</Link>
					</div>
				</div>
			),
		},
		{
			prop: "viewonly",
			title: "View only",
			cell: row => (
				<div className="d-flex justify-content-center align-items-center">
					<CheckBox
						checked={row.viewonly}
						onChange={event => {
							if (event.target.checked) {
								// setDeleteIds(ids => {
								// 	return [...ids, row.id];
								// });
							} else {
								// setDeleteIds(ids => {
								// 	return ids.filter(el => el !== row.id);
								// });
							}
						}}
					/>
				</div>
			),
		},
		{
			prop: "action",
			title: "Action",
			cell: row => (
				<div className="d-flex justify-content-center align-items-center">
					<Link to={"#"} className="table_button btn-red">
						<FaRegTrashAlt />
						Delete
					</Link>
				</div>
			),
		},
	];

	const onOpen = (field) => {
		setOpen(field);
	};

	const onClose = () => {
		setOpen(null);
		setDateValue("");
	};

	const onSetDataDate = (date) => {
		if (!open) {
			return false;
		}
		let formatedData = dayjs(date?.$d)?.format();
		setData(prevData => {
			const updatedData = [...prevData];
			updatedData[0] = {
				...updatedData[0],
				[open]: formatedData
			};
			return updatedData;
		});
		onClose();
		setIsDataChanged(true);

	};

	return (
		<>
			<DateHourMinuteSelector open={open} onClose={onClose} dateValue={dateValue} cb={onSetDataDate} />
			<CustomBreadcrumb
				boxClassName="shadow"
				data={[
					{
						name: "Checklist",
						url: "/admin/checklist",
					},
					{ name: "Schedule", url: `` },
				]}>
				<div className="d-flex align-items-center gap-2">
					<Link
						to={"#"}
						target="_blank"
						className="btn btn-outline-blue btn-extra_small">
						Help Documentation
					</Link>
				</div>
			</CustomBreadcrumb>
			<Container className="mt-3">
				<Card>
					<CardBody>
						<CardTitle title="Schedule Year 2024" />
						<Row>
							<Col md={12}>
								<DatatableWrapper
									body={data}
									headers={columns}
									paginationOptionsProps={{
										initialState: {
											rowsPerPage: 100,
											options: [5, 10, 15, 20],
										},
									}}>

									<Row>
										<Col md={12} className="mb-2">
											<Table
												className="sso_tabel timeExtensions_data-table"
												responsive
												bordered>
												<TableHeader />
												<TableBody />
											</Table>
											<div className="d-flex justify-content-end">
												<Button
													size="sm"
													type="button"
													disabled={!isDataChanged}
													className="btn-primary"
													onClick={onSaveSchedule}
												>
													Save
												</Button>
											</div>
										</Col>
									</Row>
								</DatatableWrapper>
							</Col>
							<Col className="mt-4">
								<h6 className="fs-18 fw-500">Time Extensions</h6>
								<DatatableWrapper
									body={timeExtensions}
									headers={timeExtensionColmns}
									paginationOptionsProps={{
										initialState: {
											rowsPerPage: 100,
											options: [5, 10, 15, 20],
										},
									}}>
									<Row>
										<Col md={12} className="mb-2">
											<Table
												className="sso_tabel timeExtensions-table"
												responsive
												bordered
												style={{ marginBottom: "220px" }}>
												<TableHeader />
												<TableBody />
											</Table>
										</Col>
									</Row>
								</DatatableWrapper>
							</Col>
						</Row>
					</CardBody>
				</Card>
			</Container>
			{/* <TextEditor /> */}
		</>
	);
}

export default Schedule;
