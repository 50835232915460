// Contenttypes

// Text = 1,
// Title = 2,
// Image = 3,
// DynamicContent = 4,
// Paragraph = 5
// Footer=6

import React, { useEffect, useState } from "react";
import CustomBreadcrumb from "../../components/breadcrumbs/Breadcrumb";
import {
	Button,
	Card,
	CardBody,
	Col,
	Container,
	OverlayTrigger,
	Row,
	Tooltip,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import CardTitle from "../../components/cardTitle/CardTitle";
import RadioBox from "../../components/inputs/RadioBox";
import { HiMiniClipboardDocument } from "react-icons/hi2";
import { FaRegArrowAltCircleRight } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import CheckBox from "../../components/inputs/CheckBox";
import MainService from "./../../helpers/services/mainService";
import { ApiService } from "../../helpers/services/apiSrevice";
import DocumentRow from "./components/DocumentRow";
import AlertService from "../../helpers/services/alertService";
import ReportViewModal from "./components/ReportViewModal";

function Report(props) {
	const [activeInputId, setActiveInputId] = useState(null);
	const [variables, setVariables] = useState([]);
	const [cursorPosition, setCursorPosition] = useState(null);
	const [reportData, setReportData] = useState([]);
	const [reportDataView, setReportDataView] = useState([]);
	const [id, setId] = useState(null);
	const [values, setValues] = useState({
		paymentReportCodeNameDisplayMode: 0,
		paymentReportPrintWhereSelectionCostsPresent: true,
		paymentReportOnlyWhenSelectionsValid: true,
	});

	useEffect(() => {
		getReportData();
		getVariables();
		getSelectionReportSettings();
	}, []);

	const getSelectionReportSettings = () => {
		let requestData = {
			api: ApiService.getSelectionReportSettings,
			data: 11,
			cb: response => {
				if (response && response.data) {
					setValues(response.data);
				}
			},
		};
		MainService.callApi(requestData);
	};

	const getReportData = () => {
		let requestData = {
			api: ApiService.getReportData,
			data: 7,
			cb: response => {
				if (response && response.data && response.data.mainContents) {
					setReportData(response.data.mainContents);
					setId(response.data.id);
				} else {
					setReportData([]);
				}
			},
		};
		MainService.callApi(requestData);
	};

	const getReportDataView = () => {
		let requestData = {
			api: ApiService.getReportDataView,
			data: 7,
			cb: response => {
				if (response && response.data && response.data.mainContents) {
					setReportDataView(response.data.mainContents);
				} else {
					setReportDataView([]);
				}
			},
		};
		MainService.callApi(requestData);
	};

	const resetDocument = () => {
		let requestData = {
			api: ApiService.resetDocument,
			data: 7,
			cb: response => {
				if (response && response.data && response.data.mainContents) {
					setReportData(response.data.mainContents);
					setId(response.data.id);
				} else {
					setReportData([]);
				}
			},
		};
		MainService.callApi(requestData);
	};

	const getVariables = () => {
		let requestData = {
			api: ApiService.getVariables,
			cb: response => {
				if (response && response.data && response.data) {
					setVariables(response.data);
				} else {
					setVariables([]);
				}
			},
		};
		MainService.callApi(requestData);
	};

	useEffect(() => {
		const changeReportData = () => {
			let isUpdated = false;

			const updatedReportData = reportData.map(row => {
				const contents = row.contents || [];
				const newContents = [];

				for (let i = 0; i < contents.length; i++) {
					const current = contents[i];
					const next = contents[i + 1];

					if (
						current.isVariable === false &&
						next &&
						next.isVariable === false
					) {
						newContents.push({
							id: 0,
							isEditable: true,
							isVariable: false,
							content: `${current.content}${next.content}`,
						});
						i++;
						isUpdated = true;
					} else {
						newContents.push(current);

						if (
							current.isVariable === true &&
							next &&
							next.isVariable === true
						) {
							newContents.push({
								id: 0,
								isEditable: true,
								isVariable: false,
								content: "",
							});
							isUpdated = true;
						}
					}
				}

				if (
					contents.length > 0 &&
					contents[contents.length - 1].isVariable === true
				) {
					newContents.push({
						id: 0,
						isEditable: true,
						isVariable: false,
						content: "",
					});
					isUpdated = true;
				}

				return {
					...row,
					contents: newContents,
				};
			});

			if (isUpdated) {
				setReportData(updatedReportData);
			}
		};

		changeReportData();
	}, [reportData]);

	useEffect(() => {
		const handleFocus = event => {
			if (event.target.tagName === "INPUT") {
				setTimeout(() => {
					setActiveInputId(event.target.id);
					setCursorPosition(event.target.selectionStart);
				}, 150);
			}
		};

		const handleFocusOut = event => {
			if (event.target.tagName === "INPUT") {
				setTimeout(() => {
					setActiveInputId(null);
					setCursorPosition(null);
				}, 100);
			}
		};

		const handleMouseDown = () => {
			const activeElement = document.activeElement;
			if (activeElement && activeElement.tagName === "INPUT") {
				setActiveInputId(activeElement.id);
				setCursorPosition(activeElement.selectionStart);
			}
		};

		window.addEventListener("focusin", handleFocus);
		window.addEventListener("mousedown", handleMouseDown);
		window.addEventListener("focusout", handleFocusOut);

		return () => {
			window.removeEventListener("focusin", handleFocus);
			window.removeEventListener("mousedown", handleMouseDown);
			window.removeEventListener("focusout", handleFocusOut);
		};
	}, []);

	const handleContentChange = (rowIndex, contentIndex, newValue) => {
		const updatedReportData = reportData.map((r, rIndex) => {
			if (rIndex === rowIndex) {
				const updatedContents = r.contents.map((content, cIndex) =>
					cIndex === contentIndex ? { ...content, content: newValue } : content,
				);
				return { ...r, contents: updatedContents };
			}
			return r;
		});
		return new Promise((res, rej) => {
			res({
				data: updatedReportData,
			});
		});
	};

	const onInputChange = (rowIndex, contentIndex, newValue) => {
		const updatedReportData = reportData.map((r, rIndex) => {
			if (rIndex === rowIndex) {
				const updatedContents = r.contents.map((content, cIndex) =>
					cIndex === contentIndex ? { ...content, content: newValue } : content,
				);
				return { ...r, contents: updatedContents };
			}
			return r;
		});
		setReportData(updatedReportData);
	};

	const handleAddNewLine = (
		data,
		rowIndex,
		contentIndex,
		newContent = { isVariable: false, content: "", id: 0 },
	) => {
		const row = data[rowIndex];
		const contents = row.contents;

		const updatedRow = {
			...row,
			contents: contents.slice(0, contentIndex + 1),
		};

		const newRow = {
			...row,
			id: 0,
			isChecked: null,
			path: "",
			position: "",
			displayOrder: 0,
			contentType: 1,
			contents: [newContent, ...contents.slice(contentIndex + 1)],
		};

		const updatedReportData = [...data];
		updatedReportData[rowIndex] = updatedRow;
		updatedReportData.splice(rowIndex + 1, 0, newRow);
		onInputFocus(`${rowIndex + 1}_0`);
		setReportData(updatedReportData);
	};

	const onInputFocus = id => {
		setTimeout(() => {
			let _input = document.getElementById(id);
			_input && _input.focus();
		}, 1);
	};

	const removeVar = (rowIndex, index) => {
		let reportDataCopy = [...reportData];
		let currentObj = reportDataCopy[rowIndex];
		if (currentObj && currentObj.contents && currentObj.contents.length) {
			currentObj.contents = currentObj.contents.filter(
				(_, idx) => idx !== index,
			);
		}
		reportDataCopy[rowIndex] = currentObj;
		setReportData(reportDataCopy);
	};

	const handleRemoveEmptyRow = rowIndex => {
		let contents = reportData[rowIndex - 1]?.contents;
		if (contents && contents.length) {
			const result = contents.reduce((acc, item, index) => {
				if (!item.isVariable) {
					acc = { item, index };
				}
				return acc;
			}, null);

			if (result) {
				if (typeof result.index === "number") {
					onInputFocus(`${rowIndex - 1}_${result.index}`);
				}
			}
		}

		const updatedReportData = reportData.filter(
			(_, index) => index !== rowIndex,
		);
		setReportData(updatedReportData);
	};

	const setVarInReportData = variable => {
		if (activeInputId && cursorPosition !== null) {
			const [rowIndex, contentIndex] = activeInputId.split("_").map(Number);
			const inputElement = document.getElementById(activeInputId);
			const currentValue = inputElement.value;

			const beforeCursor = currentValue.substring(0, cursorPosition);
			const afterCursor = currentValue.substring(cursorPosition);

			const updatedReportData = reportData.map((r, rIndex) => {
				if (rIndex === rowIndex) {
					const updatedContents = r.contents.reduce((acc, content, cIndex) => {
						if (cIndex === contentIndex) {
							if (beforeCursor) {
								acc.push({
									isVariable: false,
									content: beforeCursor,
									id: 0,
									isEditable: true,
								});
							}
							acc.push({ isVariable: true, content: variable });
							if (afterCursor) {
								acc.push({
									isVariable: false,
									content: afterCursor,
									id: 0,
									isEditable: true,
								});
							}
						} else {
							acc.push(content);
						}
						return acc;
					}, []);
					return { ...r, contents: updatedContents };
				}
				return r;
			});

			setReportData(updatedReportData);

			setActiveInputId(null);
			setCursorPosition(null);
		}
	};

	const onCheckboxChange = (event, rowIndex) => {
		let reportDataCopy = [...reportData];
		let currentRow = reportDataCopy[rowIndex];
		currentRow.isChecked = event.target.checked;
		reportDataCopy[rowIndex] = currentRow;
		setReportData(reportDataCopy);
	};

	const updateReportData = () => {
		MainService.callApi({
			api: ApiService.updateReportData,
			data: {
				id: id,
				mainContents: reportData,
			},
			cb: response => {
				if (response.success)
					AlertService.alert("success", "Data has been updated successfully !");
			},
		});
	};

	const onChange = (event, name) => {
		if (name === "selectionReportCodeNameDisplayMode") {
			setValues(values => ({ ...values, [name]: +event.target.value }));
		} else if (event.target.value === "false") {
			setValues(values => ({ ...values, [name]: false }));
		} else {
			setValues(values => ({ ...values, [name]: true }));
		}
	};

	const onSubmit = e => {
		e.preventDefault();
		MainService.callApi({
			api: ApiService.updateSelectionReportSettings,
			data: values,
			cb: response => {
				// if (response && response.data) {
				// 	setValues(response.data);
				// }
				if (response && response.success === true) {
					AlertService.alert("success", "Data seved");
				}
			},
		});
	};

	return (
		<>
			<CustomBreadcrumb
				data={[
					{
						name: "Settings",
						url: "/admin/settings",
					},
					{
						name: "Selections Report ",
						url: "/",
					},
				]}>
				<div className="align-items-center d-flex gap-2">
					<Link
						to={"#"}
						target="_blank"
						className="btn btn-outline-blue btn-extra_small">
						Help Documentation
					</Link>
				</div>
			</CustomBreadcrumb>
			<Container className="pt-3 document-form">
				<Card>
					<CardBody>
						<CardTitle title={"Selections Report"} />
						<form onSubmit={onSubmit}>
							<Row className="gap-3">
								<Col md={12} className="d-flex flex-column gap-2">
									<label className=" blue_label">
										Only allow students to create a report when all selections
										are valid:
									</label>
									<div className="d-flex gap-4 flex-wrap">
										<RadioBox
											id={"selectionReportOnlyWhenSelectionsValidYes"}
											name={"selectionReportOnlyWhenSelectionsValid"}
											value={true}
											label={"Yes"}
											checked={values.selectionReportOnlyWhenSelectionsValid}
											onChange={event => {
												onChange(
													event,
													"selectionReportOnlyWhenSelectionsValid",
												);
											}}
										/>
										<RadioBox
											id={"selectionReportOnlyWhenSelectionsValidNo"}
											name={"selectionReportOnlyWhenSelectionsValid"}
											value={false}
											label={"No"}
											checked={!values.selectionReportOnlyWhenSelectionsValid}
											onChange={event => {
												onChange(
													event,
													"selectionReportOnlyWhenSelectionsValid",
												);
											}}
										/>
									</div>
								</Col>
								<Col md={12} className="d-flex flex-column gap-2">
									<label className="blue_label">Show selections as:</label>
									<div className="d-flex gap-4 flex-wrap">
										<RadioBox
											id={"code_and_name"}
											name={"selectionReportCodeNameDisplayMode"}
											value={0}
											label={"Code and name"}
											checked={values.selectionReportCodeNameDisplayMode === 0}
											onChange={event => {
												onChange(event, "selectionReportCodeNameDisplayMode");
											}}
										/>
										<RadioBox
											id={"name_only"}
											name={"selectionReportCodeNameDisplayMode"}
											value={1}
											label={"Name only"}
											checked={values.selectionReportCodeNameDisplayMode === 1}
											onChange={event => {
												onChange(event, "selectionReportCodeNameDisplayMode");
											}}
										/>
										<RadioBox
											id={"code_only"}
											name={"selectionReportCodeNameDisplayMode"}
											value={2}
											label={"Code only"}
											checked={values.selectionReportCodeNameDisplayMode === 2}
											onChange={event => {
												onChange(event, "selectionReportCodeNameDisplayMode");
											}}
										/>
									</div>
								</Col>
								<Col md={12} className="d-flex flex-column gap-2">
									<label className=" blue_label">
										Show costs on this report:
									</label>
									<div className="d-flex gap-4 flex-wrap">
										<RadioBox
											id={"show_costs1"}
											name={"selectionReportShowCosts"}
											value={true}
											label={"Yes"}
											checked={values.selectionReportShowCosts}
											onChange={event => {
												onChange(event, "selectionReportShowCosts");
											}}
										/>
										<RadioBox
											id={"show_costs2"}
											name={"selectionReportShowCosts"}
											value={false}
											label={"No"}
											checked={!values.selectionReportShowCosts}
											onChange={event => {
												onChange(event, "selectionReportShowCosts");
											}}
										/>
									</div>
								</Col>
								<Col md={12} className="d-flex justify-content-end">
									<Button type="submit" className="btn-primary">
										Save
									</Button>
								</Col>
							</Row>
						</form>
						<Row className="mt-3 ">
							<Col md={12}>
								<Col md={12}>
									<div className="information_block">
										<h4 className="block">Instructions:</h4>
										<ul>
											<ol>Use the checkboxes to decide what fields you want</ol>
											<ol>
												You can edit the text content of anything with a
												checkbox before it, by overwriting the text
											</ol>
											<ol>
												Click items from the Toolbar, and will appear in the
												Report fields as required
											</ol>
											<ol>
												The toolbar items will adjust for each individual report
												as required
											</ol>
										</ul>
									</div>
								</Col>
							</Col>
						</Row>
						<Row>
							<Col md={12} className="text-center my-4 report">
								<div className="report_tools_items">
									<h2 className=" text-center fw-500 fs-30">Toolbar</h2>
									<Link to={"#"} className="revert " onClick={resetDocument}>
										Revert to default template <HiMiniClipboardDocument />
									</Link>
									<div className="items">
										{variables.map((item, index) => {
											if (activeInputId && typeof cursorPosition === "number") {
												return (
													<Button
														key={index}
														className={
															"btn-outline-blue report_tools_item_button"
														}
														onClick={() => {
															setVarInReportData(item);
														}}>
														{item}
														<FaRegArrowAltCircleRight />
													</Button>
												);
											} else {
												return (
													<OverlayTrigger
														key={index}
														placement="top"
														overlay={
															<Tooltip
																className="item_tooltip"
																id="button-tooltip-2">
																There is not active input
															</Tooltip>
														}>
														{({ ref, ...triggerHandler }) => (
															<Button
																{...triggerHandler}
																ref={ref}
																// disabled={true}
																className={
																	"btn-outline-blue report_tools_item_button item-disabled"
																}>
																{item}
																<FaRegArrowAltCircleRight />
															</Button>
														)}
													</OverlayTrigger>
												);
											}
										})}
									</div>
								</div>
								<div className="report_template_form ">
									<h2 className=" text-center fw-500 fs-30  w-100">Report</h2>
									<div className="d-flex justify-content-end">
										<Link
											to={"#"}
											className="revert margin-right-30"
											onClick={getReportDataView}>
											View result <FaEye />
										</Link>
									</div>
									<div className="report_page size_A4">
										<div>
											<div className="docoment-top-content">
												{reportData.map((row, rowIndex) => {
													return (
														<div className={` ${row.position}`} key={rowIndex}>
															{row.contentType === 2 ||
															row.contentType === 3 ? (
																row.contentType === 2 ? (
																	<h2 className="inline-block text-justify fw-500 fs-30">
																		{row.contents && row.contents.length
																			? row.contents[0].content
																			: null}
																	</h2>
																) : (
																	<div className="d-flex gap-2">
																		<CheckBox
																			size={"md"}
																			checkClassName={"inline-block"}
																			checked={row.isChecked}
																			onChange={event =>
																				onCheckboxChange(event, rowIndex)
																			}
																		/>
																		<div className="thumbnail">
																			<div className="image_logo text-center">
																				{row.contents && row.contents.length
																					? row.contents[0].content
																					: null}
																			</div>
																		</div>
																	</div>
																)
															) : null}
														</div>
													);
												})}
											</div>
											{reportData.map((row, rowIndex) => (
												<div key={rowIndex} className="mb-2">
													<DocumentRow
														row={row}
														rowIndex={rowIndex}
														onContentChange={handleContentChange}
														onInputChange={onInputChange}
														onAddNewLine={handleAddNewLine}
														onRemoveEmptyRow={handleRemoveEmptyRow}
														setReportData={setReportData}
														onInputFocus={onInputFocus}
														reportData={reportData}
														removeVar={removeVar}
													/>
												</div>
											))}
											<br />
										</div>
									</div>
								</div>
							</Col>
							<Col sm={12} className="d-flex justify-content-end">
								<Button
									type="submit"
									className="btn-primary"
									onClick={updateReportData}>
									Save
								</Button>
							</Col>
						</Row>
					</CardBody>
				</Card>
			</Container>
			<ReportViewModal
				reportData={reportDataView}
				onHide={() => setReportDataView([])}
			/>
		</>
	);
}

export default Report;
