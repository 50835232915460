import React, { useEffect, useState } from "react";
import {
	Accordion,
	Button,
	Card,
	CardBody,
	Col,
	Container,
	Row,
	useAccordionButton,
} from "react-bootstrap";
import CardTitle from "../../components/cardTitle/CardTitle";
import Textarea from "../../components/inputs/Textarea";
import { Link, useParams } from "react-router-dom";
import MainService from "../../helpers/services/mainService";
import { ApiService } from "../../helpers/services/apiSrevice";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import AlertService from "../../helpers/services/alertService";

function CustomToggle({ name, eventKey, children, width, second, active }) {
	const [show, setShow] = useState(false);

	const decoratedOnClick = useAccordionButton(eventKey, () => {
		setShow(!show);
	});

	return (
		<div
			className="d-flex  align-items-center justify-content-between"
			style={{
				width: second ? `${175 + 15}px` : `${175 + 20}px`,
			}}
			onClick={decoratedOnClick}>
			<Link
				to={"#"}
				style={{
					width: `${175}px`,
				}}
				className={`toggle `}>
				{name}
			</Link>
			<Link
				to={"#"}
				className={`toggle_button ${second ? "sm" : ""} ${
					eventKey === active ? "active" : "active"
				}`}
				style={{
					transform: show ? "rotate(269deg)" : "rotate(0deg)",
				}}>
				<MdOutlineArrowBackIosNew
					fill={`${show ? "#217ebd" : "#ccc"}`}
					style={{
						width: second ? 15 : 20,
						height: second ? 15 : 20,
					}}
				/>
			</Link>
		</div>
	);
}

function Edit(props) {
	const [activeButton, setActiveButton] = useState(null);
	const [isShowLeftMenu, setIsShowLeftMenu] = useState(false);
	const [errors, setErrors] = useState({});
	const [values, setValues] = useState([]);
	const { languageId } = useParams();
	const [translationPageType, setTranslationPageType] = useState([]);
	const [pages, setPages] = useState(null);
	const [activeLink, setActiveLink] = useState("");
	const [pageInfo, setPageInfo] = useState("");

	useEffect(() => {
		MainService.callApi({
			api: ApiService.getAreTypes,
			cb: response => {
				if (response && response.data && response.data.length) {
					setTranslationPageType(response.data);
					setActiveButton(response.data[0].areaType);
					getPages(response.data[0].areaType, languageId);
				}
			},
		});
	}, []);

	const getPages = (areaType, languageId) => {
		if (!areaType && !languageId) return false;
		MainService.callApi({
			api: ApiService.getAllTranslationsByArea,
			data: { languageId, areaType },
			cb: response => {
				if (response && response.data) {
					setPages(response.data);
					if (response.data.backend && response.data.backend.length) {
						getValues(response.data.backend[0]);
					} else if (response.data.frontEnd && response.data.frontEnd.length) {
						getValues(response.data.frontEnd[0]);
					}
					// getValues()
				}
			},
		});
	};

	const show = () => {
		setIsShowLeftMenu(!isShowLeftMenu);
	};

	const changePage = item => {
		if (item.areaType === activeButton) return false;
		setActiveButton(item.areaType);
		getPages(item.areaType, languageId);
	};

	const getValues = item => {
		if (!item || item.pageId === activeLink || item.categoryId === activeLink)
			return false;
		if (item.categoryId) {
			setPageInfo("backend");
		} else {
			setPageInfo("frontEnd");
		}
		setActiveLink(item.pageId || item.categoryId);
		setValues(
			item.translations.map(item => ({
				...item,
				isChange: false,
			})),
		);
	};

	const onChange = (event, index, errorfieldName) => {
		if (errorfieldName && errors && errors[errorfieldName]) {
			delete errors[errorfieldName];
			setErrors(errors);
		}
		setValues(values =>
			values.map((item, i) => {
				if (i === index) {
					return {
						...item,
						value: event.target.value,
						isChange: true,
					};
				}
				return item;
			}),
		);
	};

	const onSubmit = e => {
		e.preventDefault();
		const data = values.filter(item => item.isChange);
		if (!data.length) {
			return false;
		}
		MainService.callApi({
			api: ApiService.updateListTranslation,
			data: data,
			cb: response => {
				if (response && response.success === true) {
					AlertService.alert("success", "Data seved");
					setPages(pages => ({
						...pages,
						[pageInfo]: pages[pageInfo].map(item => {
							if (
								item.categoryId === activeLink ||
								item.pageId === activeLink
							) {
								return {
									...item,
									translations: values,
								};
							}
							return item;
						}),
					}));
				}
			},
		});
	};

	return (
		<Container
			style={{ overflowX: isShowLeftMenu ? "hidden" : "inherit" }}
			className="pt-2">
			<Row>
				<Col md={12}>
					<Card>
						<CardBody>
							<CardTitle
								title={"TRANSLATIONS"}
								boxClassname={"flex-md-row flex-column"}>
								<div className="d-flex  gap-2">
									{translationPageType.map((item, index) => (
										<Button
											key={index}
											onClick={() => {
												changePage(item);
											}}
											className={`${
												item.areaType === activeButton
													? "btn-primary pe-none"
													: "btn-outline-blue"
											}`}>
											{item.areaName}
										</Button>
									))}
								</div>
							</CardTitle>
						</CardBody>
					</Card>
				</Col>
			</Row>
			<Row className="mt-3">
				<Col
					md={12}
					className="page-content-inner d-flex position-relative gap-3 ">
					<div className={`help_sidebar_menu ${isShowLeftMenu ? "show" : ""}`}>
						<Card>
							<CardBody style={{}}>
								<CardTitle title="NAVIGATION" />
								{pages && pages.backend && ""}
								<Accordion>
									{pages && pages.backend && !!pages.backend.length && (
										<>
											<CustomToggle eventKey={"backend"} name={"Back end"} />
											<Accordion.Collapse eventKey="backend">
												<div style={{ marginLeft: "10px" }}>
													{pages.backend.map((item, index) => (
														<Link
															to={"#"}
															key={index}
															className={`toggle  sm w-100 ${
																item.categoryId === activeLink ? "active" : ""
															}`}
															onClick={() => {
																getValues(item);
															}}>
															{item.name}
														</Link>
													))}
												</div>
											</Accordion.Collapse>
										</>
									)}
									{pages && pages.frontEnd && !!pages.frontEnd.length && (
										<>
											<CustomToggle eventKey={"frontEnd"} name={"Front end"} />
											<Accordion.Collapse eventKey="frontEnd">
												<div style={{ marginLeft: "10px" }}>
													{pages.frontEnd.map((item, index) => (
														<Link
															to={"#"}
															key={index}
															className={`toggle  sm w-100 ${
																item.pageId === activeLink ? "active" : ""
															}`}
															onClick={() => {
																getValues(item);
															}}>
															{item.name}
														</Link>
													))}
												</div>
											</Accordion.Collapse>
										</>
									)}
								</Accordion>
							</CardBody>
						</Card>
					</div>
					<div
						className={`help_content ${isShowLeftMenu ? "show" : ""}`}
						style={{}}>
						<Card>
							<CardBody>
								<CardTitle
									action={() => {
										show();
									}}
									title="TRANSLATIONS"
								/>
								<div className={`${isShowLeftMenu ? "content_block" : ""}`}>
									<form onSubmit={onSubmit}>
										<Row className="gap-3">
											{values && values.length ? (
												<>
													{values.map((item, index) => (
														<Col md={12} key={index}>
															<Textarea
																label={item.defaultValue}
																id={`Textarea_${index}`}
																value={item.value}
																onChange={event => {
																	onChange(event, index);
																}}
															/>
														</Col>
													))}
													<Col md={12} className="d-flex justify-content-end">
														<Button
															type="submit"
															disabled={
																!values.filter(item => item.isChange).length
															}
															className="btn-primary">
															Save
														</Button>
													</Col>
												</>
											) : null}
										</Row>
									</form>
								</div>
							</CardBody>
						</Card>
					</div>
				</Col>
			</Row>
		</Container>
	);
}

export default Edit;
