import React, { useEffect, useState } from "react";
import { Card, Container, Dropdown } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import { FaGlobeAmericas } from "react-icons/fa";
import { IoMdMenu } from "react-icons/io";
import { FaPowerOff } from "react-icons/fa6";
import useWindowSize from "../../hooks/useWindowSize";
import { IoMdArrowDropdown } from "react-icons/io";
import { FaLock } from "react-icons/fa";

export default function ClientHeader() {
	const [showDropdown, setShowDropdown] = useState(false);
	const [showLanguageDropdown, setShowLanguageDropdown] = useState(false);
	const [isShowMobileNav, setIsShowMobileNav] = useState(false);
	const windowSize = useWindowSize();

	useEffect(() => {
		if (windowSize.width > 991) {
			setIsShowMobileNav(false);
		}
	}, [windowSize]);

	const setMenu = cb => {
		return (
			<ul className="h-100">
				<li className="h-100 d-flex align-items-center">
					<NavLink
						to="/admin/access"
						className={({ isActive }) => (isActive ? "active" : "")}
						onClick={() => {
							cb && cb(false);
						}}>
						Access
					</NavLink>
				</li>
				<li className="h-100 d-flex align-items-center">
					<NavLink
						to="/admin/checklist"
						className={({ isActive }) => (isActive ? "active" : "")}
						onClick={() => {
							cb && cb(false);
						}}>
						Checklist
					</NavLink>
				</li>
				<li className="h-100 d-flex align-items-center">
					<NavLink
						to="/admin/staff"
						className={({ isActive }) => (isActive ? "active" : "")}
						onClick={() => {
							cb && cb(false);
						}}>
						Staff
					</NavLink>
				</li>
				{/* <li className="h-100 d-flex align-items-center">
					<NavLink
						to="/admin/monitor"
						className={({ isActive }) => (isActive ? "active" : "")}
						onClick={() => {
							cb && cb(false);
						}}>
						Monitor
					</NavLink>
				</li> */}
				{/* <li className="h-100 d-flex align-items-center">
					<NavLink
						to="/admin/appointments"
						className={({ isActive }) => (isActive ? "active" : "")}
						onClick={() => {
							cb && cb(false);
						}}>
						Appointment
					</NavLink>
				</li> */}
				<li className="h-100 d-flex align-items-center">
					<NavLink
						to="/admin/settings"
						className={({ isActive }) => (isActive ? "active" : "")}
						onClick={() => {
							cb && cb(false);
						}}>
						Settings
					</NavLink>
				</li>
			</ul>
		);
	};
	const setLanguagesMenu = cb => {
		return (
			<Dropdown
				onMouseLeave={() => setShowLanguageDropdown(false)}
				onMouseOver={() => setShowLanguageDropdown(true)}>
				<li className="h-100 d-flex align-items-center">
					<Link to="#">
						<FaGlobeAmericas
							size={20}
							color="#ffffff"
							className="language-icon"
						/>
					</Link>
				</li>
				<Dropdown.Menu
					show={showLanguageDropdown}
					className={`p-0 ${showLanguageDropdown ? "show" : ""}`}
					style={{ borderRadius: "4px" }}
					onMouseLeave={() => setShowLanguageDropdown(false)}>
					<Dropdown.Item
						className="py-2 px-1"
						onClick={() => setShowLanguageDropdown(false)}>
						<div
							className="px-3"
							style={{ fontSize: "14px", fontWeight: "300" }}>
							Englsh
						</div>
					</Dropdown.Item>
					<Dropdown.Item
						className="py-2 px-1"
						onClick={() => setShowLanguageDropdown(false)}>
						<div
							className="px-3"
							style={{ fontSize: "14px", fontWeight: "300" }}>
							Russian
						</div>
					</Dropdown.Item>
					<Dropdown.Item
						className="py-2 px-1"
						onClick={() => setShowLanguageDropdown(false)}>
						<div
							className="px-3"
							style={{ fontSize: "14px", fontWeight: "300" }}>
							French
						</div>
					</Dropdown.Item>
					<Dropdown.Item
						className="py-2 px-1"
						onClick={() => setShowLanguageDropdown(false)}>
						<div
							className="px-3"
							style={{ fontSize: "14px", fontWeight: "300" }}>
							Arabic
						</div>
					</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
		);
	};

	const setSettingsMenu = () => {
		return (
			<Dropdown
				drop="start"
				onMouseLeave={() => setShowDropdown(false)}
				onMouseOver={() => setShowDropdown(true)}>
				<li className="h-100 d-flex align-items-center">
					<Link className="pr-0" to="#">
						SSO Admin <IoMdArrowDropdown size={18} />
					</Link>
				</li>
				<Dropdown.Menu
					show={showDropdown}
					className={`p-0 ${showDropdown ? "show" : ""}`}
					style={{ borderRadius: "4px" }}
					onMouseLeave={() => setShowDropdown(false)}>
					<Dropdown.Item
						className="py-2 px-1"
						onClick={() => setShowDropdown(false)}>
						<div
							className="px-3"
							style={{ fontSize: "14px", fontWeight: "300" }}>
							{" "}
							<span>
								<FaLock size={14} color="#26c281" />{" "}
							</span>
							Change Password
						</div>
					</Dropdown.Item>
					<Dropdown.Item
						className="py-2 px-1"
						onClick={() => setShowDropdown(false)}>
						<div
							className="px-3"
							style={{ fontSize: "14px", fontWeight: "300" }}>
							{" "}
							<span>
								<FaPowerOff size={14} color="red" />{" "}
							</span>
							Log Out
						</div>
					</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
		);
	};

	const setMenuIcon = () => {
		return (
			<li
				className="h-100 align-items-center d-flex d-lg-none"
				onClick={() => setIsShowMobileNav(!isShowMobileNav)}>
				<Link to="#">
					<div>
						<IoMdMenu size={27} color="#8C99A3" />
					</div>
				</Link>
			</li>
		);
	};

	return (
		<div className="client-header header">
			<div className="page-header-top">
				<Container className="h-100 d-flex justify-content-between align-items-center flex-wrap">
					<Link to="#" className="d-none d-lg-block">
						<img
							className="logo-default pull-right client-logo "
							src="https://client-test.subjectselectiononline.com.au/public/images/sso_new_logo.png"
							alt="logo"
						/>
					</Link>
					<Link to="#">
						<img
							className="logo-default pull-right school_banner_logo "
							src="https://client-test.subjectselectiononline.com.au/public/images/school/258/20240627122319.jpg"
							alt="logo"
						/>
					</Link>
					<div className="d-block d-lg-none h-100 client-menu-languages-settings">
						<ul className="h-100">
							{setLanguagesMenu()}
							{setSettingsMenu()}
							{setMenuIcon()}
						</ul>
					</div>
				</Container>
			</div>
			<div className="page-header-menu h-100 d-none d-lg-block">
				<Container className="h-100 d-flex justify-content-between align-items-center">
					<div className="hor-menu h-100">{setMenu()}</div>
					<div className="top-menu">
						<ul className="h-100">
							{setLanguagesMenu()}
							{setSettingsMenu()}
							{setMenuIcon()}
						</ul>
					</div>
				</Container>
			</div>
			<div
				className={`mobile-nav  ${
					isShowMobileNav ? "my-3 mobile-nav-show" : ""
				}`}>
				<Container>
					<Card>{setMenu(setIsShowMobileNav)}</Card>
				</Container>
			</div>
		</div>
	);
}
