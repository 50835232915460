import React, { forwardRef, useEffect, useState } from "react";
import { Cropper } from "react-advanced-cropper";
import "react-advanced-cropper/dist/style.css";

const CoustemCropper = forwardRef(
	({ image, onChange, stencilProps = {} }, ref) => {
		return (
			<Cropper
				ref={ref}
				src={image}
				onChange={onChange}
				className="example__cropper"
				backgroundClassName="example__cropper-background"
				zoom={false} // Disable zooming
				autoZoom={false}
				resizeImage={false}
				scalable={false} // Disable image zooming
				stencilProps={stencilProps}
				stencilOptions={{
					zoomImage: false,
					rotateImage: false,
					movable: true,
					resizable: true,
				}}
			/>
		);
	},
);

export default CoustemCropper;
