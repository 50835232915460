import React from "react";
import { useSelector } from "react-redux";
// import { useSelector } from "react-redux";

function ModalLoader({ modalSpinners }) {
	return (
		<div className="modal_loader  py-5">
			<div className="loader">
				<div className="loder_dots sm loder_dots_1"></div>
				<div className="loder_dots sm loder_dots_2"></div>
				<div className="loder_dots sm loder_dots_3"></div>
			</div>
		</div>
	);
}

export default ModalLoader;
