import React from "react";
import { useSelector } from "react-redux";
// import { useSelector } from "react-redux";

function PageLoader({}) {
	const { pageSpinners } = useSelector(state => state.spinners);

	if (!pageSpinners.length) {
		return null;
	}

	return (
		pageSpinners &&
		pageSpinners.length && (
			<div className="page_loader">
				<div className="loader">
					<div className="loder_dots loder_dots_1"></div>
					<div className="loder_dots loder_dots_2"></div>
					<div className="loder_dots loder_dots_3"></div>
				</div>
			</div>
		)
	);
}

export default PageLoader;
