import React from "react";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import {
	ERROR_KEY,
	INFO_KEY,
	OBJECT_KEY,
	SUCCESS_KEY,
	WARNING_KEY,
} from "../constants/constants";
import Auxiliary from "../../components/hoc/Auxiliary";
import { FaCheck } from "react-icons/fa";
import { HiShieldExclamation } from "react-icons/hi";
import { IoWarningSharp } from "react-icons/io5";
import { FaInfoCircle } from "react-icons/fa";
import AreYouShure from "../../components/modals/AreYouShure";

const getAlertType = (type, cb, error) => {
	if (!type) {
		cb.error(error);
	}
	switch (type) {
		case "info":
			return cb.info(error, { icon: <FaInfoCircle size={20} /> });
		case "success":
			return cb.success(error, { icon: <FaCheck size={22} /> });
		case "warning":
			return cb.warning(error, { icon: <IoWarningSharp size={25} /> });
		case "error":
			return cb.error(error, { icon: <HiShieldExclamation size={25} /> });
		default:
			break;
	}
};

class AlertService {
	static checkMessageType = respcode => {
		switch (respcode) {
			case 0:
				return SUCCESS_KEY;
			case 1:
				return ERROR_KEY;
			case 2:
				return WARNING_KEY;
			case 3:
				return INFO_KEY;
			case 4:
				return WARNING_KEY;
			default:
				return ERROR_KEY;
		}
	};

	static alert = (type, error) => {
		const respMessage =
			typeof error === OBJECT_KEY ? error.message || error.respmess : error;
		if (!respMessage) {
			return false;
		}
		getAlertType(type, toast, respMessage);
	};

	static alertConfirm = (title, message, yes, no) => {
		return new Promise((resolve, reject) => {
			confirmAlert({
				customUI: ({ onClose }) => {
					return (
						<AreYouShure
							show={true}
							title={title}
							iscloseButoon={true}
							yes={yes}
							no={no}
							action={() => {
								resolve();
								onClose();
							}}
							handleClose={() => {
								reject();
								onClose();
							}}>
							{message}
						</AreYouShure>
					);
				},
			});
		});
	};

	static removeAlertBlock = () => {
		var currentBlock = document.getElementById("alertBlockCloseButton");
		if (currentBlock && currentBlock.parentNode) {
			currentBlock.parentNode.removeChild(currentBlock);
		} else {
			return;
		}
	};
}

export default AlertService;
