import React, { useCallback, useEffect, useState } from "react";
import CustomBreadcrumb from "../../components/breadcrumbs/Breadcrumb";
import { Button, Card, CardBody, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import CardTitle from "../../components/cardTitle/CardTitle";
import RadioBox from "../../components/inputs/RadioBox";
import { useDropzone } from "react-dropzone";
import { ApiService } from "../../helpers/services/apiSrevice";
import MainService from "../../helpers/services/mainService";
import AlertService from "../../helpers/services/alertService";

function PaymentReport(props) {
	const [reportData, setReportData] = useState([]);
	const [imagePath, setImagePath] = useState(null);
	const [image, setImage] = useState(null);
	const [reportDataId, setReportDataId] = useState(null);
	const [reportDataFooterImage, setReportDataFooterImage] = useState(null);
	const [values, setValues] = useState({
		paymentReportCodeNameDisplayMode: 1,
		paymentReportPrintWhereSelectionCostsPresent: true,
		paymentReportOnlyWhenSelectionsValid: true,
	});

	useEffect(() => {
		getReportData();
		getPaymentReportSettings();
	}, []);

	const getPaymentReportSettings = () => {
		let requestData = {
			api: ApiService.getPaymentReportSettings,
			data: 11,
			cb: response => {
				if (response && response.data) {
					setValues(response.data);
				}
			},
		};
		MainService.callApi(requestData);
	};

	const getReportData = () => {
		let requestData = {
			api: ApiService.getPaymentReportData,
			data: 11,
			cb: response => {
				if (response && response.data && response.data.mainContents) {
					setReportDataId(response.data.id);
					setReportData(response.data.mainContents);
					const reportDataFooterImage = response.data.mainContents.find(
						item => item.contentType === 6,
					)?.contents[0];

					if (!reportDataFooterImage.content.toLowerCase().includes("tr")) {
						setImagePath(reportDataFooterImage.content);
					}
					setReportDataFooterImage(reportDataFooterImage);
				} else {
					setReportData([]);
				}
			},
		};
		MainService.callApi(requestData);
	};

	const onDrop = acceptedFiles => {
		const file = acceptedFiles[0];
		const reader = new FileReader();
		reader.onload = () => {
			setImagePath(reader.result);
		};
		reader.readAsDataURL(file);
		setImage(file);
	};

	const { getRootProps, getInputProps, fileRejections } = useDropzone({
		onDrop,
		accept: "image/jpeg, image/png",
		onDropRejected: rejectedFiles => {
			console.error("Invalid file format:", rejectedFiles);
		},
	});

	const renderContent = (item, index) => {
		switch (item.contentType) {
			case 1:
				return (
					<div key={index} className="d-flex gap-2 justify-content-center">
						{item.contents.map((content, index) => (
							<p key={index} style={{ fontSize: "16px", marginBottom: "10px" }}>
								{content.isVariable ? `[${content.content}]` : content.content}
							</p>
						))}
					</div>
				);

			case 2:
				return (
					<div key={index} className="mt-3" style={{ textAlign: "center" }}>
						<h3 style={{ fontWeight: "500", fontSize: "24px" }}>
							{item.contents[0].content}
						</h3>
					</div>
				);

			case 3:
				return (
					<img
						key={index}
						width="70"
						height="70"
						// src={item.contents[0].content}
						src={item.content}
						alt="Logo"
					/>
				);

			case 6:
				return (
					<div key={index} className="footer_content ">
						<div
							className={`drag_drop_block mt-4 position-relative ${
								imagePath ? "has_image" : ""
							} `}>
							<input
								className="w-100 h-100"
								{...getInputProps()}
								type="file"
								accept="image/png, image/jpeg"
								multiple={false}
								style={{
									position: "absolute",
									left: "0",
									top: "0",
									opacity: "0",
									zIndex: "2",
								}}
							/>
							{imagePath ? (
								<img className="footer_image" src={imagePath} alt="Footer" />
							) : null}
							<div className="drag_drop_block_text">
								<p style={{ fontWeight: "700" }}>
									Drag and drop, or click here to add a new footer for the
									Payment Report
								</p>
								<p>Use .jpg, .png</p>
							</div>
						</div>
					</div>
				);

			default:
				return null;
		}
	};

	const getContentByPosition = (position, data) =>
		data
			.filter(item => item.position === position)
			.sort((a, b) => a.displayOrder - b.displayOrder)
			.map((item, index) => renderContent(item, index));

	const getContentByType = (contentType, data) =>
		data
			.filter(item => item.contentType === contentType)
			.map((item, index) => renderContent(item, index));

	const resetDocument = () => {
		let requestData = {
			api: ApiService.resetPaymentReport,
			data: 7,
			cb: response => {
				if (response && response.data && response.data.mainContents) {
					setReportData(response.data.mainContents);
				} else {
					setReportData([]);
				}
				AlertService.alert("success", "Success.");
			},
		};
		MainService.callApi(requestData);
	};

	const updatePaymentReport = () => {
		if (!image) return false;
		const formData = new FormData();
		formData.append("id", reportDataFooterImage?.id);
		formData.append("content", reportDataFooterImage.content);
		formData.append("image", image);
		let requestData = {
			api: ApiService.updatePaymentReport,
			data: formData,
			cb: response => {
				if (response) {
					AlertService.alert("success", "Data seved");
				}
			},
		};
		MainService.callApi(requestData);
	};

	const onChange = (event, name) => {
		if (name === "paymentReportCodeNameDisplayMode") {
			setValues(values => ({ ...values, [name]: +event.target.value }));
		} else if (event.target.value === "false") {
			setValues(values => ({ ...values, [name]: false }));
		} else {
			setValues(values => ({ ...values, [name]: true }));
		}
	};

	const onSubmit = e => {
		e.preventDefault();
		MainService.callApi({
			api: ApiService.updatePaymentReportSettings,
			data: values,
			cb: response => {
				// if (response && response.data) {
				// 	setValues(response.data);
				// }
				if (response && response.success === true) {
					AlertService.alert("success", "Data seved");
				}
			},
		});
	};

	return (
		<>
			<CustomBreadcrumb
				data={[
					{
						name: "Settings",
						url: "/admin/settings",
					},
					{
						name: "Payment Report",
						url: "/",
					},
				]}>
				<div className="d-flex align-items-center gap-2">
					<Link
						to={"#"}
						target="_blank"
						className="btn btn-outline-blue btn-extra_small">
						Help Documentation
					</Link>
					<Link
						to={"#"}
						target="_blank"
						className="btn btn-outline-blue btn-extra_small">
						help ?
					</Link>
				</div>
			</CustomBreadcrumb>
			<Container className="pt-3">
				<Card>
					<CardBody>
						<CardTitle title={"Selections Report"} />
						<form onSubmit={onSubmit}>
							<Row className="gap-3">
								<Col md={12} className="d-flex flex-column gap-2">
									<label className=" blue_label">
										Only allow students to create a report when all selections
										are valid:
									</label>
									<div className="d-flex gap-4 flex-wrap">
										<RadioBox
											id={"paymentReportOnlyWhenSelectionsValid"}
											name={"paymentReportOnlyWhenSelectionsValid"}
											value={true}
											label={"Yes"}
											checked={values.paymentReportOnlyWhenSelectionsValid}
											onChange={event => {
												onChange(event, "paymentReportOnlyWhenSelectionsValid");
											}}
										/>
										<RadioBox
											id={"paymentReportOnlyWhenSelectionsValiddNo"}
											name={"paymentReportOnlyWhenSelectionsValid"}
											value={false}
											label={"No"}
											checked={!values.paymentReportOnlyWhenSelectionsValid}
											onChange={event => {
												onChange(event, "paymentReportOnlyWhenSelectionsValid");
											}}
										/>
									</div>
								</Col>
								<Col md={12} className="d-flex flex-column gap-2">
									<label className=" blue_label">
										Show costs on this report:
									</label>
									<div className="d-flex gap-4 flex-wrap">
										<RadioBox
											id={"show_costs1"}
											name={"paymentReportPrintWhereSelectionCostsPresent"}
											value={true}
											label={"Yes"}
											checked={
												values.paymentReportPrintWhereSelectionCostsPresent
											}
											onChange={event => {
												onChange(
													event,
													"paymentReportPrintWhereSelectionCostsPresent",
												);
											}}
										/>
										<RadioBox
											id={"show_costs2"}
											name={"paymentReportPrintWhereSelectionCostsPresent"}
											value={false}
											label={"No"}
											checked={
												!values.paymentReportPrintWhereSelectionCostsPresent
											}
											onChange={event => {
												onChange(
													event,
													"paymentReportPrintWhereSelectionCostsPresent",
												);
											}}
										/>
									</div>
								</Col>
								<Col md={12} className="d-flex flex-column gap-2">
									<label className="blue_label">Show selections as:</label>
									<div className="d-flex gap-4 flex-wrap">
										<RadioBox
											id={"name_only"}
											name={"paymentReportCodeNameDisplayMode"}
											value={1}
											label={"Name only"}
											checked={values.paymentReportCodeNameDisplayMode === 1}
											onChange={event => {
												onChange(event, "paymentReportCodeNameDisplayMode");
											}}
										/>
										<RadioBox
											id={"code_only"}
											name={"paymentReportCodeNameDisplayMode"}
											value={2}
											label={"Code only"}
											checked={values.paymentReportCodeNameDisplayMode === 2}
											onChange={event => {
												onChange(event, "paymentReportCodeNameDisplayMode");
											}}
										/>
									</div>
								</Col>
								<Col md={12} className="d-flex justify-content-end">
									<Button type="submit" className="btn-primary">
										Save
									</Button>
								</Col>
							</Row>
						</form>

						<Row>
							<Col md={12} className="text-center mt-4 ">
								<h2 className="fs-30 fw-500" style={{ marginBottom: "20px" }}>
									Payment Report
								</h2>
							</Col>
							<Col sm={12} className="d-flex justify-content-center">
								<div
									className="report_page size_A4 w-100"
									style={{ maxWidth: "740px" }}>
									{reportData && reportData.length ? (
										<div>
											<div className="document-top-content d-flex">
												<div className="top_left">
													{getContentByPosition("top_left", reportData)}
												</div>

												<div
													className="top_center"
													style={{
														display: "inline-block",
														textAlign: "center",
														margin: "0 auto",
													}}>
													{getContentByPosition("top_center", reportData)}
												</div>

												<div className="top_right">
													{getContentByPosition("top_right", reportData)}
												</div>
											</div>

											<div
												className="mb-4 mt-3"
												style={{
													height: "1px",
													backgroundColor: "#A6A6A6",
												}}></div>

											<div>
												<div className="d-flex justify-content-between">
													<p>Code/Name A</p>
													<p>[Amount]</p>
												</div>
												<div className="d-flex justify-content-between">
													<p>Code/Name A</p>
													<p>[Amount]</p>
												</div>
												<div className="d-flex justify-content-between">
													<p>Code/Name A</p>
													<p>[Amount]</p>
												</div>
												<div className="d-flex justify-content-between">
													<p>Code/Name A</p>
													<p>[Amount]</p>
												</div>
												<div className="d-flex justify-content-between">
													<p>Code/Name A</p>
													<p>[Amount]</p>
												</div>
											</div>

											<div
												className="mb-4 mt-3"
												style={{
													height: "1px",
													backgroundColor: "#A6A6A6",
												}}></div>

											<div>
												<div className="docoment-top-content d-flex justify-content-between">
													<strong>TOTAL ESTIMATED COSTS:</strong>
													<strong>[Amount]</strong>
												</div>
											</div>
											{getContentByType(6, reportData)}
										</div>
									) : null}
								</div>
							</Col>
							<Col sm={12} className="mt-3 d-flex justify-content-end gap-2">
								<Button
									type="button"
									variant="secondary"
									onClick={resetDocument}>
									Clear
								</Button>
								<Button
									type="button"
									variant="primary"
									onClick={updatePaymentReport}>
									Save
								</Button>
							</Col>
						</Row>
					</CardBody>
				</Card>
			</Container>
		</>
	);
}

export default PaymentReport;
