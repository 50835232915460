import React from "react";
import { FaCheck, FaTimes, FaMinus } from "react-icons/fa";

function ReadOnlyCheckBox({ type, label }) {
	const checkType = type => {
		switch (type) {
			case "success":
				return <FaCheck className="sso_icon sso_redad_only_success" />;
			case "danger":
				return <FaTimes className="sso_icon sso_redad_only_danger" />;
			case "nothing":
				return <FaMinus className="sso_icon sso_redad_only_nothing" />;
			default:
				return <span></span>;
		}
	};

	return (
		<div className="d-flex gap-2 align-items-center w-100">
			<div className={`sso_redad_only_check`}>{checkType(type)}</div>
			<span>{label}</span>
		</div>
	);
}

export default ReadOnlyCheckBox;
