import React, { useState } from "react";
import {
	Card,
	Col,
	Container,
	OverlayTrigger,
	Popover,
	Row,
} from "react-bootstrap";
import { FaEdit } from "react-icons/fa";
import { FaRegTrashCan } from "react-icons/fa6";
import { CiSettings } from "react-icons/ci";
import { Link } from "react-router-dom";
import { FaLongArrowAltRight } from "react-icons/fa";

const popoverBottom = (
	<Popover id="popover-positioned-bottom" className="sso_popover">
		<div className="sso_popover_container">
			<Link to={"#"} className="d-flex gap-1 sso_popover_link">
				<FaEdit />
				Редактировать
			</Link>
			<Link to={"#"} className="d-flex gap-1 sso_popover_link">
				<FaRegTrashCan />
				Удалить
			</Link>
		</div>
	</Popover>
);

const year = [
	{ name: 2019, id: 1 },
	{ name: 2020, id: 2 },
	{ name: 2024, id: 3 },
	{ name: 2023, id: 4 },
	{ name: 2022, id: 5 },
	{ name: 2020, id: 6 },
	{ name: 2017, id: 7 },
];

function ChecklistLayaut(props) {
	const [activ, setActiv] = useState(null);

	return (
		<Container>
			<Row>
				<Col md={3}>
					<Row className="justify-content-between">
						<Col md={3}>
							<Card className="sso_check_list_card">
								<h3>SELECT THE YEAR LEVEL</h3>
								{year.map((item, index) => (
									<Link
										to={"#"}
										key={index}
										onClick={() => {
											setActiv(item.id);
										}}
										className={`sso_sso_check_list_year ${
											activ === item.id ? "activ" : ""
										}`}>
										{activ === item.id && (
											<div className="trigger_container">
												<OverlayTrigger
													// trigger="focus"
													trigger="click"
													rootClose
													placement="bottom"
													overlay={
														<Popover
															id="popover-positioned-bottom"
															className="sso_popover">
															<div className="sso_popover_container">
																<Link
																	to={"#"}
																	className="d-flex gap-1 sso_popover_link">
																	<FaEdit />
																	Редактировать
																</Link>
																<Link
																	to={"#"}
																	className="d-flex gap-1 sso_popover_link">
																	<FaRegTrashCan />
																	Удалить
																</Link>
															</div>
														</Popover>
													}>
													<Link
														to={"#"}
														className="sso_popover_button"
														onClick={e => {
															e.stopPropagation();
														}}>
														<CiSettings />
													</Link>
												</OverlayTrigger>
											</div>
										)}
										<span>Year {item.name}</span>
										<FaLongArrowAltRight className="sso_FaLongArrowAltRight" />
									</Link>
								))}
							</Card>
						</Col>
						{/* <Col md={12} lg={9}>
							<Card></Card>
						</Col> */}
					</Row>
				</Col>
				<Col md={12} lg={9}></Col>
			</Row>
		</Container>
	);
}

export default ChecklistLayaut;
