import React, { useContext, useEffect, useRef, useState } from "react";
import { SelectionProcessContext } from "../SelectionProcess";
import { Button, Col, Row } from "react-bootstrap";
import { FaCheck } from "react-icons/fa";
import MainService from "../../../helpers/services/mainService";
import { ApiService } from "../../../helpers/services/apiSrevice";
import AlertService from "../../../helpers/services/alertService";
import { useParams } from "react-router-dom";

function ReOrder(props) {
	const {
		sectionData,
		formType,
		activeSection,
		changeTabe,
		isFileChange,
		setIsFileChange,
		isConfirm,
		setIsConfirm,
		nextTabe,
		setNextTabe,
		setNavigation,
		copyNavigation,
	} = useContext(SelectionProcessContext);
	const buttonRef = useRef(null);
	const { yearId } = useParams();
	const [id, setId] = useState(null);

	useEffect(() => {
		if (isConfirm.reOrder) {
			AlertService.alertConfirm(
				"Did you want to Save?",
				<Row>
					<Col md={12}>
						<span>
							Click Save to keep the changes you have made, or click Discard to
							ignore the changes.
						</span>
					</Col>
				</Row>,
				"Save",
				"Discard",
			)
				.then(() => {
					if (buttonRef && buttonRef.current) {
						buttonRef.current?.click();
						setIsFileChange({
							textImage: false,
							settings: false,
							reOrder: false,
						});
						setIsConfirm({
							textImage: false,
							settings: false,
							reOrder: false,
						});
						changeTabe(nextTabe);
					}
				})
				.catch(() => {
					setIsFileChange({
						textImage: false,
						settings: false,
						reOrder: false,
					});
					setIsConfirm({
						textImage: false,
						settings: false,
						reOrder: false,
					});
					changeTabe(nextTabe);
					setNextTabe(null);
					setNavigation(copyNavigation);
				});
		}
	}, [isConfirm]);

	useEffect(() => {
		if (sectionData && sectionData.id) {
			setId(sectionData.id);
		}
	}, [sectionData]);

	const generetData = array => {
		if (Array.isArray(array) && array.length) {
			return array.map((item, index) => {
				return (
					<ul
						key={`${index}-${item.reportName || item.courseFullName}`}
						className="reorder_section_item">
						<li>{item.reportName || item.courseFullName}</li>
						{item.instructions && !!item.instructions.length
							? generetData(item.instructions)
							: item.selections && !!item.selections.length
							? generetData(item.selections)
							: null}
					</ul>
				);
			});
		} else if (sectionData && Object.keys(sectionData).length) {
			return generetData(sectionData.instructions || sectionData.selections);
		} else {
			return null;
		}
	};

	const reOrder = data => {
		if (formType === "section") {
			MainService.callApi({
				api: ApiService.sectionDisplayOrder,
				data: {
					parentId: yearId,
					orderedItemIds: sectionData.map(el => el.id),
				},
				cb: response => {
					if (response && response.success) {
						// updatesection(navigation, data.id);
						// changeTabe("textImage");
						// setYears(years => years.filter(el => item.id !== el.id));
					}
				},
			});
		} else if (formType === "instruction") {
			MainService.callApi({
				api: ApiService.instructionDisplayOrder,
				data: {
					parentId: id,
					orderedItemIds: sectionData.map(el => el.id),
				},
				cb: response => {
					if (response && response.success) {
						// updateInstruction(navigation, data.id);
						// changeTabe("textImage");
						// setYears(years => years.filter(el => item.id !== el.id));
					}
				},
			});
		} else if (formType === "selection") {
			MainService.callApi({
				api: ApiService.selectionDisplayOrder,
				data: {
					parentId: id,
					orderedItemIds: sectionData.map(el => el.id),
				},
				cb: response => {
					if (response && response.success) {
						// updateSelection(navigation, data.id);
						// changeTabe("settings");
						// setYears(years => years.filter(el => item.id !== el.id));
					}
				},
			});
		}
		// )
		// .catch(() => {});
	};
	return (
		<div className="">
			<Button
				type="submit"
				ref={buttonRef}
				disabled={!isFileChange?.reOrder}
				onClick={() => {
					reOrder();
				}}
				className="btn-primary d-flex gap-2 align-items-center justify-content-center mx-3 my-4">
				<FaCheck />
				Save
			</Button>
			<div className="reorder_section">{generetData(sectionData)}</div>
		</div>
	);
}

export default ReOrder;
