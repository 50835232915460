import React, { useState, useRef, useEffect } from "react";
import { Button, Col, Row } from "react-bootstrap";
import Cropper from "react-cropper";
import DropZone from "../inputs/DropZone";
import CoustemCropper from "./CoustemCropper";
const defaultSrc = "";

function ImageCrop({
	values,
	stencilProps = {},
	action = () => {},
	croppedPath,
	imagePath,
	width,
	height,
	cropedWidth,
	cropedHeight,
	dropZoneTitele = "Drop image here or click to upload",
	error = false,
}) {
	const [image, setImage] = useState(null);
	const cropperRef = useRef(null);
	const [imageError, setImageError] = useState(true);
	const [imageFile, setImageFile] = useState(null);
	const [file, setFile] = useState(null);
	const [croppedImage, setCroppedImage] = useState(null);

	useEffect(() => {
		setImage(imagePath);
	}, [imagePath]);

	const onDrop = acceptedFiles => {
		if (acceptedFiles.length > 0) {
			const file = acceptedFiles[0];
			const reader = new FileReader();

			reader.onload = () => {
				const img = new Image();
				img.src = reader.result;
				if (img.width < 1919 || img.height < 1079) {
					setImageError(true);
				} else {
					setImage(reader.result);
					setImageFile(file);
					setImageError(false);
				}
			};
			reader.readAsDataURL(file);
		}
	};

	// const getCropData = () => {
	// 	if (typeof cropperRef.current?.cropper !== "undefined") {
	// 		return base64ToFile(
	// 			cropperRef.current?.cropper.getCroppedCanvas().toDataURL(),
	// 		);
	// 	}
	// };

	function base64ToFile(base64) {
		if (!base64) return null;
		// Extract the MIME type and base64 data
		const [metadata, base64Data] = base64.split(",");
		const mimeType = metadata.match(/:(.*?);/)[1];

		// Decode the base64 string to a binary string
		const binaryString = atob(base64Data);

		// Convert the binary string to a Uint8Array
		const len = binaryString.length;
		const bytes = new Uint8Array(len);
		for (let i = 0; i < len; i++) {
			bytes[i] = binaryString.charCodeAt(i);
		}

		// Create a Blob from the Uint8Array
		const blob = new Blob([bytes], { type: mimeType });

		// Generate a dynamic filename with the correct extension
		const timestamp = new Date().toISOString().replace(/[:.]/g, "-");
		const extension = mimeType.split("/")[1];
		const filename = `file_${timestamp}.${extension}`;

		// Convert the Blob to a File
		const file = new File([blob], filename, { type: blob.type });
		return file;
		// setFile(file);
	}

	const onChange = cropper => {
		const cropperData = cropper.getCoordinates();

		// if (cropedHeight && cropedWidth) {
		// 	if (cropedWidth && cropperData.width > cropedWidth) {
		// 		cropperData.width = cropedWidth;
		// 	}

		// 	if (cropedHeight && cropperData.height > cropedHeight) {
		// 		cropperData.height = cropedHeight;
		// 	}

		// 	cropper.setCoordinates(cropperData);
		// }

		if (cropperRef.current) {
			const canvas = cropperRef.current.getCanvas();
			if (canvas) {
				// Convert the canvas to a data URL
				const dataUrl = canvas.toDataURL("image/jpeg");
				setCroppedImage(base64ToFile(dataUrl));
			}
		}
	};

	return (
		<Row>
			<Col
				md={12}
				lg={6}
				className="d-flex align-items-center justify-content-center ">
				<CoustemCropper
					image={image}
					onChange={onChange}
					ref={cropperRef}
					stencilProps={stencilProps}
				/>
			</Col>
			<Col
				md={12}
				lg={6}
				className="d-flex flex-column mt-3 mt-lg-0 gap-3 justify-content-between justify-content-center align-items-center ">
				{croppedPath && (
					<div className="cropped_img">
						<img
							src={croppedPath}
							style={{
								maxWidth: width,
								maxHeight: height,
							}}
							alt="croppedPath"
						/>
					</div>
				)}

				<DropZone
					error={error && imageError}
					configs={{
						onDrop,
						multiple: false, // Accept only one file
						accept: {
							"image/jpeg": [],
							"image/png": [],
						},
					}}
					boxClassname={"settings_dropzone"}
					title={dropZoneTitele}
				/>
				<div className="d-flex justify-content-end">
					<Button
						type="submit"
						onClick={() => {
							action(imageFile, croppedImage);
						}}
						// disabled={errors && Object.keys(errors).length}
						className="btn-primary ">
						Save
					</Button>
				</div>
			</Col>
		</Row>
	);
}

export default ImageCrop;
