import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Nav, Row, Tab } from "react-bootstrap";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
	FaUniversity,
	FaRegEnvelope,
	FaSignInAlt,
	FaRegIdCard,
	FaRegCalendarAlt,
	FaDollarSign,
} from "react-icons/fa";
import { AiOutlinePicture } from "react-icons/ai";
import SchoolName from "./components/SchoolName";
import CustomBreadcrumb from "../../components/breadcrumbs/Breadcrumb";
import Messages from "./components/Messages";
import LoginPrompt from "./components/LoginPrompt";
import Images from "./components/Images";
import StudentExperience from "./components/StudentExperience";
import Appointment from "./components/Appointment";
import MainService from "../../helpers/services/mainService";
import { ApiService } from "../../helpers/services/apiSrevice";
import { IoIosArrowForward } from "react-icons/io";
import { FaBars } from "react-icons/fa";
import { SlLogout } from "react-icons/sl";
import { useMediaQuery } from "@mui/material";
import { FiFileText } from "react-icons/fi";
import { LiaUniversitySolid } from "react-icons/lia";

function Settings() {
	const navigate = useNavigate();
	let [searchParams, setSearchParams] = useSearchParams();
	const [activKey, setActiveKey] = useState("school_name");
	const [isShowLeftMenu, setIsShowLeftMenu] = useState(false);
	const [values, setValues] = useState({
		appointmentConfiguration: "",
		backgroundImage: "",
		croppedBackgroundImage: "",
		croppedBackgroundImagePath: "",
		backgroundImagePath: "",
		banner: "",
		croppedBanner: "",
		bannerPath: "",
		croppedBannerPath: "",
		costUnitDisplayMode: 1,
		displayCourseCode: true,
		id: null,
		loginHeading: "",
		loginMessages: "",
		loginPrompt: "",
		logo: "",
		croppedLogoPath: "",
		croppedLogo: "",
		passwordPrompt: "",
		requireSectionCompletion: true,
		schoolId: 8,
		schoolName: "",
		selectionScreenMessage: "",
	});
	const matches = useMediaQuery("(min-width:992px)");

	useEffect(() => {
		if (matches && isShowLeftMenu) {
			setIsShowLeftMenu(false);
		}
	}, [matches, isShowLeftMenu]);

	useEffect(() => {
		MainService.callApi({
			api: ApiService.getSchoolBrandingSettingsId,
			data: 11,
			cb: response => {
				if (response && response.data) {
					setValues(values => ({ ...values, ...response.data }));
				}
			},
		});
	}, []);

	useEffect(() => {
		const page = searchParams.get("page");
		if (page) {
			setActiveKey(page);
		}
	}, [searchParams]);

	const handleSelect = key => {
		setActiveKey(key);
		navigate(`?page=${key}`);
	};

	return (
		<>
			<CustomBreadcrumb
				boxClassName="shadow"
				data={[
					{
						name: "Settings",
						url: "/",
					},
				]}
			/>
			<Container
				style={{
					minHeight: 478,
				}}
				className="pt-3">
				<Tab.Container
					id="left-tabs-example"
					activeKey={activKey}
					onSelect={handleSelect}>
					<div className="settings position-relative">
						<div className={`tabs_menu ${isShowLeftMenu ? "active" : ""}`}>
							<Card className="user-dashboard-section">
								<CardBody
									className={`sso-tebs-card-body ${
										isShowLeftMenu ? "active" : ""
									}`}>
									<div>
										<Nav
											variant="pills"
											className="flex-column sso_settings_tab pb-3">
											<div className="sso_settings_tab_show_button d-lg-none d-flex">
												<Link
													to={"#"}
													onClick={() => {
														setIsShowLeftMenu(!isShowLeftMenu);
													}}>
													{!isShowLeftMenu ? <FaBars /> : <SlLogout />}
												</Link>
											</div>
											<Nav.Item>
												<Nav.Link eventKey="school_name">
													<div>
														<FaUniversity />
														<span>School name</span>
													</div>
													<IoIosArrowForward />
												</Nav.Link>
											</Nav.Item>
											<Nav.Item>
												<Nav.Link eventKey="messages">
													<div>
														<FaRegEnvelope />
														<span>Messages</span>
													</div>
													<IoIosArrowForward />
												</Nav.Link>
											</Nav.Item>
											<Nav.Item>
												<Nav.Link eventKey="login_prompt">
													<div>
														<FaSignInAlt />
														<span>Login Prompt</span>
													</div>
													<IoIosArrowForward />
												</Nav.Link>
											</Nav.Item>
											<Nav.Item>
												<Nav.Link eventKey="images">
													<div>
														<AiOutlinePicture />
														<span>Images</span>
													</div>
													<IoIosArrowForward />
												</Nav.Link>
											</Nav.Item>
											<Nav.Item>
												<Nav.Link eventKey="student_experience">
													<div>
														<FaRegIdCard />
														<span>Student Experience</span>
													</div>
													<IoIosArrowForward />
												</Nav.Link>
											</Nav.Item>
											<Nav.Item>
												<Nav.Link eventKey="appointment">
													<div>
														<FaRegCalendarAlt />
														<span>Appointment</span>
													</div>
													<IoIosArrowForward />
												</Nav.Link>
											</Nav.Item>
										</Nav>
									</div>
									<div className="d-flex flex-column gap-3 px-2 pb-5">
										<Link
											to={"/admin/report"}
											className={`btn btn-outline-blue-hoki text_responzisv ${
												isShowLeftMenu ? "show" : ""
											}`}>
											<FiFileText />
											<span>Selections Report</span>
										</Link>
										<Link
											to={"/admin/payment_report"}
											className={`btn btn-outline-blue-hoki text_responzisv ${
												isShowLeftMenu ? "show" : ""
											}`}>
											<FaDollarSign />
											<span>Payment Report</span>
										</Link>
									</div>
								</CardBody>
							</Card>
						</div>
						<div className={`sso_tabs_content`}>
							<Tab.Content>
								<Tab.Pane eventKey="school_name">
									<SchoolName values={values} setValues={setValues} />
								</Tab.Pane>
								<Tab.Pane eventKey="messages">
									<Messages values={values} setValues={setValues} />
								</Tab.Pane>
								<Tab.Pane eventKey="login_prompt">
									<LoginPrompt values={values} setValues={setValues} />
								</Tab.Pane>
								<Tab.Pane eventKey="images">
									<Images values={values} setValues={setValues} />
								</Tab.Pane>
								<Tab.Pane eventKey="student_experience">
									<StudentExperience values={values} setValues={setValues} />
								</Tab.Pane>
								<Tab.Pane eventKey="appointment">
									<Appointment values={values} setValues={setValues} />
								</Tab.Pane>
							</Tab.Content>
						</div>
					</div>
				</Tab.Container>
			</Container>
		</>
	);
}

export default Settings;
