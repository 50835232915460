import { combineReducers, configureStore } from "@reduxjs/toolkit";
import SpinnersReducer from "./reducers/SpinnersReducer";

const rootReducer = combineReducers({
	spinners: SpinnersReducer,
});

const index = configureStore({
	reducer: rootReducer,
});

export default index;
