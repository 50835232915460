import React, { useState } from "react";
import CustomBreadcrumb from "../../components/breadcrumbs/Breadcrumb";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Row, Table } from "react-bootstrap";
import CardTitle from "../../components/cardTitle/CardTitle";
import RadioBox from "../../components/inputs/RadioBox";
import ReactSelectOption from "../../components/inputs/ReactSelectOption";
import {
	DatatableWrapper,
	Filter,
	TableBody,
	TableHeader,
} from "react-bs-datatable";
import CheckBox from "../../components/inputs/CheckBox";
import Switch from "../../components/inputs/Switch";

function TickOrFlick(props) {
	const [likeStatus, setLikeStatus] = useState(0);

	const onRadioChange = event => {
		setLikeStatus(+event.target.value);
	};

	return (
		<>
			<CustomBreadcrumb
				boxClassName="shadow"
				data={[
					{
						name: "Checklist",
						url: "/admin/checklist",
					},
					{ name: "Tick/Flick", url: `` },
				]}>
				<div className="d-flex align-items-center gap-2">
					<Link
						to={"#"}
						target="_blank"
						className="btn btn-outline-blue btn-extra_small">
						Help Documentation
					</Link>
				</div>
			</CustomBreadcrumb>
			<Container className="mt-3">
				<Card>
					<CardBody>
						<CardTitle title="Year 2024 Tick / Flick" />
						<Row className="mt-5 gap-1">
							<Col md={12}>
								<p>
									When there is a pre-requisite, SSO will determine which
									students can make a selection, based on results in the student
									profile.
								</p>
							</Col>
							<Col md={12}>
								<div className="information_block">
									<h6 className="fs-18 fw-500 mb-3">This section will:</h6>
									<ul className="disclosure_closed">
										<li>
											Show you students' eligibility for selections, based on
											pre-requisites
										</li>
										<li>
											Allow you to override eligibility, based on
											pre-requisities
										</li>
										<li>Recommend a student to make a particular selection</li>
									</ul>
								</div>
							</Col>
							<Col md={12}>
								<div className="d-flex flex-column gap-1">
									<label className="blue_label">
										Choose how you would like to view the data:
									</label>
									<RadioBox
										id={"likeStatus-1"}
										name={"likeStatus"}
										value={0}
										label={
											"By Student (no notification will be sent to the student)"
										}
										checked={likeStatus === 0}
										onChange={event => {
											onRadioChange(event);
										}}
									/>
									<RadioBox
										id={"likeStatus-2"}
										name={"likeStatus"}
										value={1}
										label={
											"By Selection (no notification will be sent to the student)"
										}
										checked={likeStatus === 1}
										onChange={event => {
											onRadioChange(event);
										}}
									/>
									<RadioBox
										id={"likeStatus-3"}
										name={"likeStatus"}
										value={2}
										label={
											"By Override Requests (the student will be notified of tick or flick)"
										}
										checked={likeStatus === 2}
										onChange={event => {
											onRadioChange(event);
										}}
									/>
								</div>
							</Col>
							<Col md={12}>
								<TickFlickData type={likeStatus} />
							</Col>
						</Row>
					</CardBody>
				</Card>
			</Container>
		</>
	);
}

const TickFlickData = ({ type }) => {
	switch (type) {
		case 0:
			return <TickFlickDataByType_1 />;
		case 1:
			return <TickFlickDataByType_2 />;
		case 2:
			return <TickFlickDataByType_3 />;
		default:
			return null;
	}
};

const TickFlickDataByType_3 = () => {
	const [data, setData] = useState([]);

	const columns = [
		{
			prop: "code",
			title: "Code",
			isFilterable: true,
			isSortable: true,
			cell: row => (
				<div>
					{!row.isrequired && (
						<span className="coustem-label d-inline-block mx-2">Fix</span>
					)}
					<p className="d-inline-block">{row.code}</p>
				</div>
			),
		},
		{
			prop: "name",
			title: "Name",
			isFilterable: true,
			isSortable: true,
			cell: row => (
				<div>
					{!row.isrequired && (
						<span className="coustem-label d-inline-block mx-2">Fix</span>
					)}
					<p className="d-inline-block">{row.code}</p>
				</div>
			),
		},
		{
			prop: "result",
			title: "Result",
			isFilterable: true,
			isSortable: true,
			cell: row => (
				<div>
					{!row.isrequired && (
						<span className="coustem-label d-inline-block mx-2">Fix</span>
					)}
					<p className="d-inline-block">{row.code}</p>
				</div>
			),
		},
		{
			prop: "eligible",
			title: "Eligible?",
			isFilterable: true,
			isSortable: true,
			cell: row => (
				<div>
					{!row.isrequired && (
						<span className="coustem-label d-inline-block mx-2">Fix</span>
					)}
					<p className="d-inline-block">{row.code}</p>
				</div>
			),
		},
		{
			prop: "override",
			title: "Override",
			isFilterable: true,
			isSortable: true,
			cell: row => (
				<div>
					<Switch
						id={"hola"}
						label={"hola"}
						size={"sm"}
						// value={isOn}
						// onChange={event => {
						// 	setIsOn(event.target.checked);
						// }}
					/>
				</div>
			),
		},
		{
			prop: "intNotes",
			title: "Int Notes",
			isFilterable: true,
			isSortable: true,
			cell: row => (
				<div>
					{!row.isrequired && (
						<span className="coustem-label d-inline-block mx-2">Fix</span>
					)}
					<p className="d-inline-block">{row.code}</p>
				</div>
			),
		},

		{
			prop: "recommend",
			title: "Recommend",
			cell: row => (
				<div className="d-flex">
					<CheckBox checked={row.isrequired} />
				</div>
			),
		},
	];

	return (
		<Row className="mt-3">
			<Col md={12}>
				<DatatableWrapper
					body={data}
					headers={columns}
					paginationOptionsProps={{
						initialState: {
							rowsPerPage: 100,
							options: [5, 10, 15, 20],
						},
					}}>
					<Row className="my-3 justify-content-between gap-3">
						<Col md={2}>
							<Filter
								placeholder="search"
								classes={{
									input: "input-sm sso_input",
									clearButton: "clearButton",
								}}
							/>
						</Col>
						<Col md={12} className="mb-2">
							<Table className="sso_tabel" responsive bordered>
								<TableHeader />
								<TableBody />
							</Table>
						</Col>
					</Row>
				</DatatableWrapper>
			</Col>
		</Row>
	);
};

const TickFlickDataByType_2 = () => {
	const [value, setValue] = useState(null);
	const [data, setData] = useState([]);

	const columns = [
		{
			prop: "code",
			title: "Code",
			isFilterable: true,
			isSortable: true,
			cell: row => (
				<div>
					{!row.isrequired && (
						<span className="coustem-label d-inline-block mx-2">Fix</span>
					)}
					<p className="d-inline-block">{row.code}</p>
				</div>
			),
		},
		{
			prop: "name",
			title: "Name",
			isFilterable: true,
			isSortable: true,
			cell: row => (
				<div>
					{!row.isrequired && (
						<span className="coustem-label d-inline-block mx-2">Fix</span>
					)}
					<p className="d-inline-block">{row.code}</p>
				</div>
			),
		},
		{
			prop: "result",
			title: "Result",
			isFilterable: true,
			isSortable: true,
			cell: row => (
				<div>
					{!row.isrequired && (
						<span className="coustem-label d-inline-block mx-2">Fix</span>
					)}
					<p className="d-inline-block">{row.code}</p>
				</div>
			),
		},
		{
			prop: "eligible",
			title: "Eligible?",
			isFilterable: true,
			isSortable: true,
			cell: row => (
				<div>
					{!row.isrequired && (
						<span className="coustem-label d-inline-block mx-2">Fix</span>
					)}
					<p className="d-inline-block">{row.code}</p>
				</div>
			),
		},
		{
			prop: "override",
			title: "Override",
			isFilterable: true,
			isSortable: true,
			cell: row => (
				<div>
					<Switch
						id={"hola"}
						label={"hola"}
						size={"sm"}
						// value={isOn}
						// onChange={event => {
						// 	setIsOn(event.target.checked);
						// }}
					/>
				</div>
			),
		},
		{
			prop: "intNotes",
			title: "Int Notes",
			isFilterable: true,
			isSortable: true,
			cell: row => (
				<div>
					{!row.isrequired && (
						<span className="coustem-label d-inline-block mx-2">Fix</span>
					)}
					<p className="d-inline-block">{row.code}</p>
				</div>
			),
		},

		{
			prop: "recommend",
			title: "Recommend",
			cell: row => (
				<div className="d-flex">
					<CheckBox checked={row.isrequired} />
				</div>
			),
		},
	];

	return (
		<Row className="mt-3">
			<Col md={12} className="d-flex gap-3 flex-column flex-lg-row">
				<label className="w-lg-50 w-100">Show on reports</label>
				<div className="d-flex align-items-center gap-3 flex-wrap w-lg-50 w-100">
					<ReactSelectOption />
				</div>
			</Col>
			<Col md={12}>
				<DatatableWrapper
					body={data}
					headers={columns}
					paginationOptionsProps={{
						initialState: {
							rowsPerPage: 100,
							options: [5, 10, 15, 20],
						},
					}}>
					<Row className="my-3 justify-content-between gap-3">
						<Col md={2}>
							<Filter
								placeholder="search"
								classes={{
									input: "input-sm sso_input",
									clearButton: "clearButton",
								}}
							/>
						</Col>
						<Col md={12} className="mb-2">
							<Table className="sso_tabel" responsive bordered>
								<TableHeader />
								<TableBody />
							</Table>
						</Col>
					</Row>
				</DatatableWrapper>
			</Col>
		</Row>
	);
};

const TickFlickDataByType_1 = () => {
	const [value, setValue] = useState(null);
	const [data, setData] = useState([]);

	const columns = [
		{
			prop: "code",
			title: "Code",
			isFilterable: true,
			isSortable: true,
			cell: row => (
				<div>
					{!row.isrequired && (
						<span className="coustem-label d-inline-block mx-2">Fix</span>
					)}
					<p className="d-inline-block">{row.code}</p>
				</div>
			),
		},
		{
			prop: "name",
			title: "Name",
			isFilterable: true,
			isSortable: true,
			cell: row => (
				<div>
					{!row.isrequired && (
						<span className="coustem-label d-inline-block mx-2">Fix</span>
					)}
					<p className="d-inline-block">{row.code}</p>
				</div>
			),
		},
		{
			prop: "result",
			title: "Result",
			isFilterable: true,
			isSortable: true,
			cell: row => (
				<div>
					{!row.isrequired && (
						<span className="coustem-label d-inline-block mx-2">Fix</span>
					)}
					<p className="d-inline-block">{row.code}</p>
				</div>
			),
		},
		{
			prop: "eligible",
			title: "Eligible?",
			isFilterable: true,
			isSortable: true,
			cell: row => (
				<div>
					{!row.isrequired && (
						<span className="coustem-label d-inline-block mx-2">Fix</span>
					)}
					<p className="d-inline-block">{row.code}</p>
				</div>
			),
		},
		{
			prop: "override",
			title: "Override",
			isFilterable: true,
			isSortable: true,
			cell: row => (
				<div>
					<Switch
						id={"hola"}
						label={"hola"}
						size={"sm"}
						// value={isOn}
						// onChange={event => {
						// 	setIsOn(event.target.checked);
						// }}
					/>
				</div>
			),
		},
		{
			prop: "intNotes",
			title: "Int Notes",
			isFilterable: true,
			isSortable: true,
			cell: row => (
				<div>
					{!row.isrequired && (
						<span className="coustem-label d-inline-block mx-2">Fix</span>
					)}
					<p className="d-inline-block">{row.code}</p>
				</div>
			),
		},

		{
			prop: "recommend",
			title: "Recommend",
			cell: row => (
				<div className="d-flex">
					<CheckBox checked={row.isrequired} />
				</div>
			),
		},
	];

	return (
		<Row className="mt-3">
			<Col md={12} className="d-flex gap-3 flex-column flex-lg-row">
				<label className="w-lg-50 w-100">Show on reports</label>
				<div className="d-flex align-items-center gap-3 flex-wrap w-lg-50 w-100">
					<ReactSelectOption />
				</div>
			</Col>
			<Col md={12}>
				<DatatableWrapper
					body={data}
					headers={columns}
					paginationOptionsProps={{
						initialState: {
							rowsPerPage: 100,
							options: [5, 10, 15, 20],
						},
					}}>
					<Row className="my-3 justify-content-between gap-3">
						<Col md={2}>
							<Filter
								placeholder="search"
								classes={{
									input: "input-sm sso_input",
									clearButton: "clearButton",
								}}
							/>
						</Col>
						<Col md={12} className="mb-2">
							<Table className="sso_tabel" responsive bordered>
								<TableHeader />
								<TableBody />
							</Table>
						</Col>
					</Row>
				</DatatableWrapper>
			</Col>
		</Row>
	);
};

export default TickOrFlick;
