import {
	Navigate,
	Route,
	createBrowserRouter,
	createRoutesFromElements,
} from "react-router-dom";
import Layaut from "../components/layaut/Layaut";
import Home from "./home/Home";
import PageLoader from "../components/loadre/PageLoader";
import ChecklistLayaut from "../components/layaut/ChecklistLayaut";
import DateTimePickerComponent from "../components/dateTimePicker/DateTimePicker";
import Schools from "./schools/Schools";
import Help from "./help/Help";
import Enterprise from "./enterprise/Enterprise";
import Translate from "./translate/Translate";
import Edit from "./translate/Edit";
import SchoolForm from "./schools/SchoolForm";
import Settings from "./settings/Settings";
import PaymentReport from "./payment_report/PaymentReport";
import Report from "./report/Report";
import Checklist from "./checklist/Checklist";
import SelectionMatrix from "./selection_matrix/SelectionMatrix";
import Students from "./students/Students";
import Results from "./results/Results";
import SelectionProcess from "./selection_process/SelectionProcess";
import Teachers from "./teachers/Teachers";
import Hola from "./hola/Hola";
import CourseCounsellors from "./courseCounsellors/CourseCounsellors";
import HomeTeachers from "./homeTeachers/HomeTeachers";
import WholeYearLevelAccess from "./wholeYearLevelAccess/WholeYearLevelAccess";
import TickOrFlick from "./tickOrFlick/TickOrFlick";
import Staff from "./staff/Staff";
import Student from "./student/Student";
import Logs from "./logs/Logs";
import Schedule from "./schedule/Schedule";
import Monitor from "./monitor/Monitor";
import Appointments from "./appointments/Appointments";
import Access from "./access/Access";
import Capacities from "./capacities/Capacities";
import GlobalRules from "./globalRules/GlobalRules";
import ExtractsView from "./extractsView/ExtractsView";
import QuickView from "./quickView/QuickView";
import Fees from "./fees/Fees";
import Extracts from "./extracts/Extracts";

const router = type => {
	return createBrowserRouter(
		createRoutesFromElements(
			<Route path="/" element={<Layaut />}>
				<Route index element={<Home />} />
				<Route path={`about_us`} element={<PageLoader />} />
				<Route path={`date`} element={<DateTimePickerComponent />} />
				<Route path={"checklist"} element={<ChecklistLayaut />} />
				<Route path={"schools"}>
					<Route index={true} element={<Schools />} />
					<Route path="add" element={<SchoolForm />} />
					<Route path="add/:schoolId" element={<SchoolForm />} />
				</Route>
				<Route path={"enterprise"} element={<Enterprise />}></Route>
				<Route path={"help"} element={<Help />}></Route>
				<Route path={"translate"}>
					<Route index={true} element={<Translate />}></Route>
					<Route path={"edit/:languageId/admin"} element={<Edit />} />
				</Route>
				<Route path="admin">
					<Route path="access" element={<Access />} />
					<Route path="monitor" element={<Monitor />} />
					<Route path="appointments" element={<Appointments />} />
					<Route path="settings" element={<Settings />} />
					<Route path="report" element={<Report />} />
					<Route path="staff" element={<Staff />} />
					<Route path="payment_report" element={<PaymentReport />} />
					<Route path="checklist" element={<Checklist />} />
					<Route path="logs" element={<Logs />} />
					<Route
						path="selection_matrix/year/:yearId"
						element={<SelectionMatrix />}
					/>
					<Route path="capacities/:yearId" element={<Capacities />} />
					<Route path="global_rules/:yearId" element={<GlobalRules />} />
					<Route path="students/:yearId" element={<Students />} />
					<Route path="results/:yearId" element={<Results />} />
					<Route path="extracts/:yearId">
						<Route index={true} element={<ExtractsView />} />
						<Route path="quick_view" element={<QuickView />} />
						<Route path="fees" element={<Fees />} />
						<Route path="extracts" element={<Extracts />} />
					</Route>
					<Route path="teachers/:yearId">
						<Route index={true} element={<Teachers />} />
						<Route path="hola/:userTypeId" element={<Hola />} />
						<Route
							path="course_counsellors/:userTypeId"
							element={<CourseCounsellors />}
						/>
						<Route
							path="home_teachers/:userTypeId"
							element={<HomeTeachers />}
						/>
						<Route
							path="whole_year_level_access"
							element={<WholeYearLevelAccess />}
						/>
					</Route>
					<Route path="tick_flick/:yearId" element={<TickOrFlick />} />
					<Route path="schedule/:yearId" element={<Schedule />} />

					<Route
						path="selection_process/:yearId"
						element={<SelectionProcess />}
					/>
				</Route>
				<Route path="student" element={<Student />} />
				<Route path="*" element={<Navigate to="/schools" replace />} />
			</Route>,
		),
	);
};

export { router };
