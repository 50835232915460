import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Nav, Row, Tab, Table } from "react-bootstrap";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import CustomBreadcrumb from "../../components/breadcrumbs/Breadcrumb";
import {
	DatatableWrapper,
	TableHeader,
	TableBody,
	Filter,
} from "react-bs-datatable";
import CardTitle from "../../components/cardTitle/CardTitle";


function Logs() {
	const navigate = useNavigate();

	const [data, setData] = useState(
		[
			{
				datetime: "asdad asd sada d"
			},
		]
	);

	const columns = [
		{
			prop: "data.dateTime",
			title: "Date & Time",
			isFilterable: true,
			isSortable: true,
			cell: row => <td>111 </td>,
		},
		{
			prop: "data.ipAddress",
			title: "Ip Address",
			isFilterable: true,
			isSortable: true,
			cell: row => <td>222 </td>,
		},
		{
			prop: "data.userCode",
			title: "User Code",
			isFilterable: true,
			isSortable: true,
			cell: row => <td>333 </td>,
		},
		{
			prop: "data.userRole",
			title: "User Role",
			isFilterable: true,
			isSortable: true,
			cell: row => <td>444 </td>,
		},
		{
			prop: "data.details",
			title: "Details",
			isFilterable: true,
			isSortable: true,
			cell: row => <td>555 </td>,
		},
		{
			prop: "data.studentCode",
			title: "Student Code",
			isFilterable: true,
			isSortable: true,
			cell: row => <td>555 </td>,
		},
		{
			prop: "",
			title: "Log Type",
			isFilterable: true,
			isSortable: true,
			cell: row => <td>666 </td>,
		},
	];

	return (
		<>
			<CustomBreadcrumb
				boxClassName=""
				data={[
					{
						name: "Logs",
						url: "/",
					},
				]}
			/>
			<Container
				style={{
					minHeight: 478,
				}}
				className="pt-0 pt-lg-3"
			>
				<Card>
					<CardBody>
						<CardTitle title="LOGS"></CardTitle>
						<DatatableWrapper
							body={data}
							headers={columns}
							paginationOptionsProps={{
								initialState: {
									rowsPerPage: 100,
									options: [5, 10, 15, 20],
								},
							}}>
							<Row className="pb-4 justify-content-between gap-2">
								<Col sm={12} className="mb-3">
									<div id="time" className="ng-binding" style={{ textAlign: "right" }}>00:04:34 12/10/2024</div>
								</Col>
								<Col sm={12}>
									<Row>
										<Col md={12} lg={2}>
											<Filter
												placeholder="search"
												classes={{
													input: "input-sm sso_input",
													clearButton: "clearButton",
												}}
											/>
										</Col>
										<Col >
											<div className="d-flex justify-content-end mt-1">
												<div className="dataTables_info" id="logsTable_info" role="status" aria-live="polite">Showing 1 to 10 of 816 entries</div>
											</div>
										</Col>

									</Row>
								</Col>

								<Col md={12} className="mb-2">
									<Table responsive bordered className="sso_tabel">
										<TableHeader />
										<TableBody />
									</Table>
								</Col>
							</Row>
						</DatatableWrapper>
					</CardBody>
				</Card>


			</Container>
		</>
	);
}

export default Logs;
