import axios from "axios";
import AlertService from "./alertService";
import { ERROR_KEY } from "../constants/constants";

const adminApi = axios.create({
	baseURL: process.env.REACT_APP_ADMIN_API_URL,
	headers: {
		"ngrok-skip-browser-warning": true,
	},
});

const clientApi = axios.create({
	baseURL: process.env.REACT_APP_CLIENT_API_URL,
	headers: {
		"ngrok-skip-browser-warning": true,
	},
});

const showError = errorMsg => {
	errorMsg && AlertService.alert(ERROR_KEY, errorMsg);
};

adminApi.interceptors.request.use(async config => {
	// config.headers = {
	// 	Authorization: localStorage.getItem(TOKEN_KEY)
	// 		? localStorage.getItem(TOKEN_KEY)
	// 		: "",
	// 	language: localStorage.getItem(LANGUAGE_KEY),
	// 	currency: localStorage.getItem(CURRENCY_KEY)
	// 		? localStorage.getItem(CURRENCY_KEY)
	// 		: DEFAULT_CURRENCY_KEY,
	// };
	return config;
});

adminApi.interceptors.response.use(
	response => {
		if (response && response.data && response.data.errors) {
			const errors = response.data.errors;
			Object.entries(errors).forEach(([key, value]) => {
				if (Array.isArray(value)) {
					value.forEach(error => {
						if (error.params.Message) {
							showError(error.params.Message);
						} else if (error.message) {
							showError(error.message);
						} else if (error.key && error.params && error.params[error.key]) {
							showError(error.params[error.key]);
						}
					});
				}
			});
		}
		// if (response && response.data && response.data?.status === 500) {
		//   AlertService.alert(ERROR_KEY, serverErrorText, true);
		//   let alertBlock = document.getElementById("alertBlockCloseButton");
		//   if (alertBlock) {
		//     let contactUs = alertBlock.querySelector("#contact-us");
		//     contactUs && contactUs.addEventListener("click", function () {
		//       removeAlertBlock();
		//     });
		//   }
		// }

		if (response && response.data && response.data?.status >= 500) {
			if (response.data?.message) {
				return Promise.reject(response.data?.message);
			}
			return Promise.reject("Sorry, something went wrong!");
		}

		if (response && response.data) {
			if (
				response.data.respmess === "User is not Authenticated" ||
				response.data.respmess === "This course is not belong to you!"
			) {
				// window.location.href = `/${
				// 	localStorage.getItem(LANGUAGE_KEY)
				// 		? localStorage.getItem(LANGUAGE_KEY)
				// 		: DEFAULT_LANGUAGE_KEY
				// }`;
				localStorage.clear();
			}
			return !response.data.respcode
				? response.data
				: Promise.reject({
					respmess: response.data.respmess,
					respcode: response.data.respcode,
				});
		}
	},
	error => {
		if (error.response && error.response.status === 401) {
			// const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
			// const user = localStorage.getItem(USER_KEY);
			// const token = localStorage.getItem(TOKEN_KEY);
			// if (!refreshToken || !user || !token) {
			// 	// to do logout();
			// } else {
			// 	getUserDataByRefreshToken(refreshToken, user);
			// }
		}
		if (error.response) {
			const errors = error.response.data.errors;

			return Promise.reject(errors || error.response.data.ExcMessage || error);
		} else {
			// Server error
			// AlertService.alert(ERROR_KEY, error.message);
			return Promise.reject(error);
		}
	},
);

clientApi.interceptors.request.use(async config => {
	// config.headers = {
	// 	Authorization: localStorage.getItem(TOKEN_KEY)
	// 		? localStorage.getItem(TOKEN_KEY)
	// 		: "",
	// 	language: localStorage.getItem(LANGUAGE_KEY),
	// 	currency: localStorage.getItem(CURRENCY_KEY)
	// 		? localStorage.getItem(CURRENCY_KEY)
	// 		: DEFAULT_CURRENCY_KEY,
	// };
	return config;
});

clientApi.interceptors.response.use(
	response => {
		if (response && response.data && response.data.errors) {
			const errors = response.data.errors;
			Object.entries(errors).forEach(([key, value]) => {
				if (Array.isArray(value)) {
					value.forEach(error => {
						if (error.params.Message) {
							showError(error.params.Message);
						} else if (error.message) {
							showError(error.message);
						} else if (error.key && error.params && error.params[error.key]) {
							showError(error.params[error.key]);
						}
					});
				}
			});
		}
		// if (response && response.data && response.data?.status === 500) {
		//   AlertService.alert(ERROR_KEY, serverErrorText, true);
		//   let alertBlock = document.getElementById("alertBlockCloseButton");
		//   if (alertBlock) {
		//     let contactUs = alertBlock.querySelector("#contact-us");
		//     contactUs && contactUs.addEventListener("click", function () {
		//       removeAlertBlock();
		//     });
		//   }
		// }

		if (response && response.data && response.data?.status >= 500) {
			if (response.data?.message) {
				return Promise.reject(response.data?.message);
			}
			return Promise.reject("Sorry, something went wrong!");
		}

		if (response && response.data) {
			if (
				response.data.respmess === "User is not Authenticated" ||
				response.data.respmess === "This course is not belong to you!"
			) {
				// window.location.href = `/${
				// 	localStorage.getItem(LANGUAGE_KEY)
				// 		? localStorage.getItem(LANGUAGE_KEY)
				// 		: DEFAULT_LANGUAGE_KEY
				// }`;
				localStorage.clear();
			}
			return !response.data.respcode
				? response.data
				: Promise.reject({
					respmess: response.data.respmess,
					respcode: response.data.respcode,
				});
		}
	},
	error => {
		if (error.response && error.response.status === 401) {
			// const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
			// const user = localStorage.getItem(USER_KEY);
			// const token = localStorage.getItem(TOKEN_KEY);
			// if (!refreshToken || !user || !token) {
			// 	// to do logout();
			// } else {
			// 	getUserDataByRefreshToken(refreshToken, user);
			// }
		}

		if (error.response) {
			const errors = error.response.data.errors;

			return Promise.reject(errors || error.response.data.ExcMessage || error);
		} else {
			// Server error
			// AlertService.alert(ERROR_KEY, error.message);
			return Promise.reject(error);
		}
	},
);

// const getUserDataByRefreshToken = (refreshToken, user) => {
// 	const formData = new FormData();
// 	formData.append(`refreshToken`, refreshToken);
// 	MainService.callApi({
// 		adminApi: () => {
// 			ApiService.getUserDataByRefreshToken(formData);
// 		},
// 		cb: response => {
// 			if (response.data && response.data.accessToken) {
// 				// const data = { ...response.data };
// 				// localStorage.setItem(TOKEN_KEY, data?.accessToken);
// 				// localStorage.setItem(REFRESH_TOKEN_KEY, data?.refreshToken);
// 				// localStorage.setItem(USER_KEY, user);
// 			} else {
// 				logout();
// 			}
// 		},
// 		getFail: error => {
// 			logout();
// 		},
// 	});
// };

const logout = () => {
	// window.location.href = `/${
	// 	localStorage.getItem(LANGUAGE_KEY)
	// 		? localStorage.getItem(LANGUAGE_KEY)
	// 		: DEFAULT_LANGUAGE_KEY
	// }`;
	localStorage.clear();
};

class ApiService {
	//#chargetypes
	static getChargeTypes() {
		return adminApi.get(`/school/getChargeTypes?languageId=1`);
	}

	//#endchargetypes

	//#school
	static schoolCreate(data) {
		return adminApi.post(`/school/create`, data);
	}

	static schoolUpdate(data) {
		return adminApi.put(`/school/update`, data);
	}

	static getAllSchools() {
		return adminApi.get(`/school/getAll`);
	}

	static getSchool(id) {
		return adminApi.get(`/school/get?id=${id}`);
	}

	static schoolDeactivate = id => {
		return adminApi.get(`/school/deactivate?id=${id}`);
	};

	static schoolActivate = id => {
		return adminApi.get(`/school/activate?id=${id}`);
	};

	static deleteSchool(id) {
		return adminApi.delete(`/school/delete?id=${id}`);
	}

	static updateSchoolName(data) {
		return adminApi.put(`/school/updateSchoolName`, data);
	}
	//#endschool

	//#language
	static getAllLnguages() {
		return adminApi.get(`/language/getAll`);
	}

	static createLnguage(data) {
		return adminApi.post(`/language/create`, data);
	}

	static getAllActiveaLnguage() {
		return adminApi.get(`/language/getAllActive`);
	}

	static languageDeactivate = id => {
		return adminApi.get(`/language/deactivate?id=${id}`);
	};

	static languageActivate = id => {
		return adminApi.get(`/language/activate?id=${id}`);
	};
	//#endlanguage

	//page
	static getAllStruct = () => {
		return adminApi.get(`/page/getAllStruct`);
	};

	static getPages = () => {
		return adminApi.get(`/page/getAll`);
	};
	//#endpage

	//#help

	static getHelpById = id => {
		return adminApi.get(`/help/getByPageId?id=${id}`);
	};

	static getHelp = id => {
		return adminApi.get(`/help/get?id=${id}`);
	};

	static getHelps = () => {
		return adminApi.get(`/help/getAll`);
	};

	static createHelp = data => {
		return adminApi.post(`/help/create`, data);
	};

	static updateHelp = data => {
		return adminApi.put(`/help/update`, data);
	};

	static deleteHelp = id => {
		return adminApi.delete(`/help/delete?id=${id}`);
	};

	static getAreTypes = () => {
		return adminApi.get(`/translation/getAreTypes`);
	};

	static getAllTranslationsByArea({ areaType, languageId }) {
		return adminApi.get(
			`/translation/getAllTranslationsByArea?areaType=${areaType}&languageId=${languageId}`,
		);
	}

	//#endhelp

	//#translationPage

	static getTranslationPageById(id) {
		return adminApi.get(`/translationPage/get?id=${id}`);
	}

	static createTranslationPage(data) {
		return adminApi.get(`/translationPage/create`, data);
	}

	static updateTranslationPage(data) {
		return adminApi.put(`/translationPage/update`, data);
	}

	static deleteTranslationPage(id) {
		return adminApi.delete(`/translationPage/delete?id=${id}`);
	}

	//#endtranslationPage

	//#translation
	static getTranslationById(id) {
		return adminApi.get(`/translation/get?id=${id}`);
	}
	static createTranslation(data) {
		return adminApi.get(`/translation/create`, data);
	}

	static updateTranslation(data) {
		return adminApi.put(`/translation/update`, data);
	}

	static updateListTranslation(data) {
		return adminApi.put(`/translation/updateList`, data);
	}

	static deleteTranslation(id) {
		return adminApi.delete(`/translation/delete?id=${id}`);
	}
	//#endtranslation

	//#schoolBrandingSettings
	static getSchoolBrandingSettingsId(id = 3) {
		return clientApi.get(`/schoolBrandingSettings/getBySchoolId?id=${id}`);
	}
	static createSchoolBrandingSettings(data) {
		return clientApi.post(`/schoolBrandingSettings/create`, data);
	}
	static updateSchoolBrandingSettings(data) {
		return clientApi.put(`/schoolBrandingSettings/update`, data);
	}
	static deleteSchoolBrandingSettings(id) {
		return clientApi.delete(`/schoolBrandingSettings/delete?id=${id}`);
	}
	static getSchoolBrandingSettings() {
		return clientApi.get(`/schoolBrandingSettings/getAll`);
	}

	//#endschoolBrandingSettings

	//#academicYear
	static getAcademicYearId(id) {
		return clientApi.get(`/academicYear/get?id=${id}`);
	}
	static createAcademicYear(data) {
		return clientApi.post(`/academicYear/create`, data);
	}
	static updateAcademicYear(data) {
		return clientApi.put(`/academicYear/update`, data);
	}
	static deleteAcademicYear(id) {
		return clientApi.delete(`/academicYear/delete?id=${id}`);
	}
	static getAcademicYears() {
		return clientApi.get(`/academicYear/getAll`);
	}
	static copyAcademicYear(data) {
		return clientApi.post(`/academicYear/copy`, data);
	}
	//#endacademicYear

	//#course
	static getCourseId(id) {
		return clientApi.get(`/course/get?id=${id}`);
	}

	static createBase(data) {
		return clientApi.post(`/course/createBase`, data);
	}

	static getInstructionCourses({ academicYearId, instructionId }) {
		return clientApi.get(
			`/course/getInstructionCourses?academicYearId=${academicYearId}&instructionId=${instructionId}`,
		);
	}

	static createCourse(data) {
		return clientApi.post(`/course/create`, data);
	}
	static updateCourse(data) {
		return clientApi.put(`/course/update`, data);
	}
	static deleteCourse(data) {
		return clientApi.delete(`/course/delete`, data);
	}
	static checkCourse(data) {
		return clientApi.post(`/course/check`, data);
	}

	static createBulkCoures(data) {
		return clientApi.post(`/course/createBulk`, data);
	}

	static getCourses({ data, academicYearId }) {
		return clientApi.post(
			`/course/GetPagedList?academicYearId=${academicYearId}`,
			data,
		);
	}

	static courseUploadExcel(data) {
		return clientApi.post(`/course/uploadExcel`, data);
	}

	//#endcourse

	//#student
	static geStudentId(id) {
		return clientApi.get(`/student/get?id=${id}`);
	}
	static creatStudent(data) {
		return clientApi.post(`/student/create`, data);
	}
	static updateStudent(data) {
		return clientApi.put(`/student/update`, data);
	}
	static deletStudent(data) {
		return clientApi.delete(`/student/delete`, data);
	}
	static checkStudent(data) {
		return clientApi.post(`/student/check`, data);
	}

	static createBulkStudent(data) {
		return clientApi.post(`/student/createBulk`, data);
	}

	static getStudents(data) {
		return clientApi.post(`/student/getAll`, data);
	}

	static studentUploadExcel(data) {
		return clientApi.post(`/student/uploadExcel`, data);
	}

	//#endstudent

	//#results
	static geResultId(id) {
		return clientApi.get(`/result/get?id=${id}`);
	}
	static createResult(data) {
		return clientApi.post(`/result/create`, data);
	}
	static updatResult(data) {
		return clientApi.put(`/result/update`, data);
	}
	static deletResult(data) {
		return clientApi.delete(`/result/delete`, data);
	}
	static checkResult(data) {
		return clientApi.post(`/result/check`, data);
	}

	static createBulkResult(data) {
		return clientApi.post(`/result/createBulk`, data);
	}

	static getResults(data) {
		return clientApi.post(
			`/Result/GetPagedList?academicYearId=${data.academicYearId}`,
			data.data,
		);
	}

	static resultUploadExcel(data) {
		return clientApi.post(`/result/uploadExcel`, data);
	}

	//#endresults

	static getUserDataByRefreshToken() {
		return adminApi.get(``);
	}

	//#report
	static getReportData(schoolId) {
		return clientApi.get(
			`/SchoolBrandingSettings/GetSelectionReport?schoolId=${schoolId}`,
		);
	}
	static getPaymentReportData(schoolId) {
		return clientApi.get(
			`/SchoolBrandingSettings/GetPaymentReport?schoolId=${schoolId}`,
		);
	}
	static getReportDataView(schoolId) {
		return clientApi.get(
			`/SchoolBrandingSettings/GetSelectionReportView?schoolId=${schoolId}`,
		);
	}
	static resetDocument(schoolId) {
		return clientApi.post(
			`/SchoolBrandingSettings/CreateSelectionReport?schoolId=${schoolId}`,
		);
	}
	static getVariables() {
		return clientApi.get(
			`/SchoolBrandingSettings/GetAllSelectionReportVariables`,
		);
	}
	static updateReportData(data) {
		return clientApi.put(`/SchoolBrandingSettings/UpdateSelectionReport`, data);
	}
	static resetPaymentReport(schoolId) {
		return clientApi.post(
			`/SchoolBrandingSettings/CreatePaymentReport?schoolId=${schoolId}`,
		);
	}
	static updatePaymentReport(data) {
		return clientApi.put(`/schoolBrandingSettings/updateContentItem`, data);
	}

	static getSelectionReportSettings(schoolId) {
		return clientApi.get(
			`/schoolBrandingSettings/getSelectionReportSettings?schoolId=${schoolId}`,
		);
	}

	static updateSelectionReportSettings(data) {
		return clientApi.put(
			`/schoolBrandingSettings/updateSelectionReportSettings`,
			data,
		);
	}

	static getPaymentReportSettings(schoolId) {
		return clientApi.get(
			`/SchoolBrandingSettings/GetPaymentReportSettings?schoolId=${schoolId}`,
		);
	}

	static updatePaymentReportSettings(data) {
		return clientApi.put(
			`/schoolBrandingSettings/updatePaymentReportSettings`,
			data,
		);
	}

	//#endreport

	//#section
	static getNavigation(academycYearId) {
		return clientApi.get(
			`/section/getNavigation?academycYearId=${academycYearId}`,
		);
	}

	static getNavigationById(id) {
		return clientApi.get(`/section/Get?id=${id}`);
	}
	static sectionCreate(data) {
		return clientApi.post(`/section/create`, data);
	}

	static sectionUpdate(data) {
		return clientApi.put(`/section/update`, data);
	}

	static sectionDelete(id) {
		return clientApi.delete(`/section/delete?id=${id}`);
	}

	static sectionDisplayOrder(data) {
		return clientApi.put(`/section/displayOrder`, data);
	}
	static sectionCopy(data) {
		return clientApi.post(`/Section/Copy`, data);
	}

	//#endsection

	//#instruction

	static getInstructionById(id) {
		return clientApi.get(`/instruction/get?id=${id}`);
	}
	static instructionCreate(data) {
		return clientApi.post(`/instruction/create`, data);
	}

	static instructionUpdate(data) {
		return clientApi.put(`/instruction/update`, data);
	}

	static instructionDelete(id) {
		return clientApi.delete(`/instruction/delete?id=${id}`);
	}

	static instructionDisplayOrder(data) {
		return clientApi.put(`/instruction/displayOrder`, data);
	}

	static instructionCreateView() {
		return clientApi.get(`/instruction/createView`);
	}

	static instructionGetAll() {
		return clientApi.get(`/instruction/getAll`);
	}

	static instructioniCopy(data) {
		return clientApi.post(`/Instruction/Copy`, data);
	}

	//#endinstruction

	//#selection
	static createSelectionWithCourse(data) {
		return clientApi.post(`/selection/createWithCourse`, data);
	}

	static selectionCreateBulk(data) {
		return clientApi.post(`/selection/createBulk`, data);
	}

	static updateSelction(data) {
		return clientApi.put(`/selection/update`, data);
	}

	static selectionGetById(id) {
		return clientApi.get(`/selection/get?id=${id}`);
	}
	static selectionDelete(id) {
		return clientApi.delete(`/selection/delete?id=${id}`);
	}
	static selectionDisplayOrder(data) {
		return clientApi.put(`/selection/displayOrder`, data);
	}

	//#endselection

	//#linkedSelection

	static linkedSelectionCreate(data) {
		return clientApi.post(`/linkedSelection/create`, data);
	}

	static linkedSelectionDelete(id) {
		return clientApi.delete(`linkedSelection/Delete?id=${id}`);
	}

	//#endlinkedSelection

	//#staff
	static createStaff(data) {
		return clientApi.post(`/staffProfile/create`, data);
	}

	static getAllStaff(data) {
		return clientApi.post(`/StaffProfile/GetPagedList`, data);
	}

	static staffProfileGetAll() {
		return clientApi.get(`/staffProfile/GetAll`);
	}

	static updateStaff(data) {
		return clientApi.put(`/staffProfile/update`, data);
	}
	static deleteStaff(data) {
		return clientApi.post(`/staffProfile/delete`, data);
	}

	static createStaffProfile(data) {
		return clientApi.post(`/staffProfile/createStaffProfile`, data);
	}
	static updateStaffProfile(data) {
		return clientApi.put(`/staffProfile/updateStaffProfile`, data);
	}

	static staffProfileCheck(data) {
		return clientApi.post(`/staffProfile/check`, data);
	}

	static staffProfileUploadExcel(data) {
		return clientApi.post(`/staffProfile/uploadExcel`, data);
	}
	static staffProfileCreateBulk(data) {
		return clientApi.post(`/StaffProfile/CreateBulk`, data);
	}

	//#endstaff

	//#library

	static libraryLanguages(id = 2) {
		return adminApi.get(`/Library/GetLanguages?id=${id}`);
	}

	//endLibrary

	//#staffProfileAccess
	static staffProfileAccessGetYear(yearId) {
		return clientApi.get(`staffProfileAccess/getYearData?yearId=${yearId}`);
	}
	static staffProfileAccessGetInfoByType({ yearId, userTypeId, data }) {
		return clientApi.post(
			`staffProfileAccess/getInfoByType?yearId=${yearId}&userTypeId=${userTypeId}`,
			data,
		);
	}

	static staffProfileAccessCreate(data) {
		return clientApi.post(`/staffProfileAccess/create`, data);
	}

	static staffProfileAccessUpdate(data) {
		return clientApi.put(`/staffProfileAccess/update`, data);
	}

	static staffProfileAccessUpdateNotRequired(data) {
		return clientApi.put(`/staffProfileAccess/updateNotRequired`, data);
	}
	//staffProfileAccess

	//globalAccess
	static globalAccessGetAll() {
		return clientApi.get(`/globalAccess/getAll`);
	}
	static globalAccessUpdate(data) {
		return clientApi.put(`/globalAccess/update`, data);
	}
	//globalAccess

	//Schedule
	static scheduleGetByAcademicYear(id) {
		return clientApi.get(`/schedule/getByAcademicYear?academicYearId=${id}`);
	}
	static scheduleGetAll(id) {
		return clientApi.get(`/schedule/getAll`);
	}
	static scheduleCreate(data) {
		return clientApi.post(`/schedule/create`, data);
	}
	static scheduleUpdate(data) {
		return clientApi.put(`/schedule/update`, data);
	}
	static scheduleDelete(id) {
		return clientApi.delete(`/schedule/delete?id=${2}`);
	}
	//Schedule
}

export { ApiService, logout };
