import * as Yup from "yup";

export const emailRegex = new RegExp(
	/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
);

const allFalseValidation = value => {
	if (!value) return false;
	const { isMainCoordinator, canAdministerCoordinators, noComms } = value;
	return isMainCoordinator || canAdministerCoordinators || noComms;
};

export const schoolSchema = Yup.object().shape({
	schoolDetails: Yup.object().shape({
		languageId: Yup.number().required("Language is required."),
		administeredFrom: Yup.number().required().positive().integer().max(10),
		schoolCode: Yup.string()
			.required("School Code is a required.")
			.matches(/^\S*$/, "Student Code must not contain spaces")
			.max(6, "School Code cannot be longer than 6 characters."),
		schoolName: Yup.string()
			.required("School Name is a required.")
			.max(50, "School Name cannot be longer than 50 characters."),
		timeZone: Yup.string().required("Time Zone is a required."),
		postCode: Yup.string()
			.required("Post Code is a required.")
			.matches(/^\S*$/, "Student Code must not contain spaces")
			.max(10, "Post Code cannot be longer than 10 characters."),
	}),
	staffProfiles: Yup.array().of(
		Yup.object()
			.shape({
				staffFirstName: Yup.string()
					.required("First name is required.")
					.max(20, "First name cannot be longer than 20 characters."),
				staffLastName: Yup.string()
					.required("Surnamee is required")
					.max(30, "Surname cannot be longer than 30 characters."),
				staffEmail: Yup.string()
					.required("Email is required.")
					.email("Invalid email.")
					.matches(emailRegex, "Invalid email."),
				isMainCoordinator: Yup.boolean(),
				canAdministerCoordinators: Yup.boolean(),
				noComms: Yup.boolean(),
			})
			.test(
				"allFalseCheck",
				"At least one coordinator permission must be active.",
				value => allFalseValidation(value),
			),
	),
	paymentGateway: Yup.lazy((value, { context }) => {
		if (context.showPaymentGateWay) {
			return Yup.object().shape({
				gateWayType: Yup.string().required("Gateway Type is required."),
				bPointMerchantNumber: Yup.string().required(
					"BPoint Merchant Number is required.",
				),
				bPointShortName: Yup.string().required(
					"BPoint Short Name is required.",
				),
				bPointUserName: Yup.string().required("BPoint User Name is required."),
				bPointPassword: Yup.string().required("BPoint Password is required."),
				bPointBillerCode: Yup.string().required(
					"BPoint Biller Code is required.",
				),
				bPointAuthorisationURL: Yup.string().required(
					"BPoint Authorisation URL is required.",
				),
				bPointPaymentURL: Yup.string().required(
					"BPoint Payment URL is required.",
				),
				bPointVerificationURL: Yup.string().required(
					"BPoint Verification URL is required.",
				),
				paymentTermsText: Yup.string().required(
					"Payment Terms Text is required.",
				),
				minPaymentCalculationPercentage: Yup.boolean(),
				amount: Yup.number()
					.required("Amount is required.")
					.typeError("Amount must be a number.")
					.positive("Amount must be a positive number."),
			});
		} else {
			return Yup.object().shape({});
		}
	}),
	billingDetails: Yup.object().shape({
		currencyType: Yup.string().required("Currency is required."),
		sendInvoicesTo: Yup.string().required("Send invoices to is required."),
		billingNotes: Yup.string().notRequired(),
		billableCharges: Yup.array().of(
			Yup.object().shape({
				chargeType: Yup.number()
					.required()
					.positive("Charge Type must be a positive number."),
				value: Yup.string().test(
					"value-required",
					"Value is required.",
					function (value) {
						const { chargeType, isBillableCharge } = this.parent;
						return (
							!([2, 5].includes(chargeType) && isBillableCharge) ||
							(value && value.trim() !== "")
						);
					},
				),
				isBillableCharge: Yup.boolean().required(),
			}),
		),
	}),
	otherCharges: Yup.array().of(
		Yup.object().shape({
			chargeType: Yup.number()
				.required()
				.positive("Other Charges must be a positive number."),
			value: Yup.string().test(
				"value-required",
				"Value is required.",
				function (value) {
					const { chargeType, checked } = this.parent;
					return (
						!([2, 4, 5].includes(chargeType) && checked) ||
						(value && value.trim() !== "")
					);
				},
			),
			checked: Yup.boolean().required(),
		}),
	),
});

export const selectionMatrixSchema = Yup.object().shape({
	courseCode: Yup.string()
		.required("Course Code is required")
		.matches(/^\S*$/, "Course Code must not contain spaces"),
	courseName: Yup.string().trim().required("Course Name is required"),
});

export const studentSchema = Yup.object().shape({
	studentCode: Yup.string()
		.required("Student Code is required")
		.matches(/^\S*$/, "Student Code must not contain spaces"),
	// .test(
	// 	"studentCode_upload-check",
	// 	"Duplicate student code",
	// 	function (value) {
	// 		const { uploadData, fileIndex } = this.options.context || {};
	// 		if (!uploadData || !value) {
	// 			return true; // If there's no uploadData or value, validation passes
	// 		}
	// 		// Check for exact duplicate first name in the uploadData, excluding the current fileIndex
	// 		const isDuplicate = uploadData.some(
	// 			(el, i) => i !== el.rowId && el.studentCode === value,
	// 		);
	// 		return !isDuplicate; // Return true if no duplicates are found
	// 	},
	// ),
	studentEmail: Yup.string()
		.nullable() // Allows the field to be null or empty
		.test("email-validation", "Invalid email.", function (value) {
			if (value && value.length > 0) {
				return Yup.string().email().isValidSync(value);
			}
			return true;
		}),
	// .notRequired(),
	studentPIN: Yup.string().test(
		"nullable",
		"Student PIN must be at least 5 characters long",
		function (value) {
			if (!value || value.length > 4) {
				return true;
			}
			return false;
		},
	),
	parentPIN: Yup.string().test(
		"nullable",
		"Parent PIN must be at least 5 characters long",
		function (value) {
			if (!value || value.length > 4) {
				return true;
			}
			return false;
		},
	),
	studentFirstName: Yup.string().required("First Name is required"),
	studentLastName: Yup.string().required("Last Name is required"),
});

export const resultSchema = Yup.object().shape({
	studentCode: Yup.string()
		.required("Student Code is required")
		.matches(/^\S*$/, "Student Code must not contain spaces"),
	preReqCode: Yup.string()
		.required("Course Code is required")
		.matches(/^\S*$/, "Course Code must not contain spaces"),
});

export const staffSchema = Yup.object().shape({
	staffCode: Yup.string()
		.required("Staff code is required")
		.matches(/^\S*$/, "Staff code must not contain spaces"),
	staffFirstName: Yup.string()
		.trim()
		.required("Staff first name is required.")
		.max(20, "Staff first name cannot be longer than 20 characters."),
	staffLastName: Yup.string()
		.trim()
		.required("Staff last name is required")
		.max(30, "Staff last name cannot be longer than 30 characters."),
	staffEmail: Yup.string()
		.required("Email is required.")
		.email("Invalid email.")
		.matches(emailRegex, "Invalid email."),
});

// requiredIfChecked([2, 4, 5]),

// Yup.lazy((value, { context }) => {
// 	if (context.uploadData) {
// 		return Yup.string()
// 			.required("First Name is required")
// 			.test("upload-check", "Duplicate First Name", function (value) {
// 				const { uploadData, fileIndex } = context;
// 				console.log("====================================");
// 				console.log(uploadData, fileIndex);
// 				return (
// 					value &&
// 					uploadData.some(
// 						(el, i) => i !== fileIndex && el.studentFirstName.includes(value),
// 					)
// 				);
// 			});
// 	}
// 	return Yup.string().required("First Name is required");
// }),

// Yup.lazy((value, { context }) => {
// 		if (context.uploadData) {
// 			return Yup.string()
// 				.required("Last Name is required")
// 				.test("upload-check", "Duplicate Last Name", function (value) {
// 					const { uploadData, fileIndex } = context;
// 					return (
// 						value &&
// 						uploadData.some(
// 							(el, i) => i !== fileIndex && el.studentLastName.includes(value),
// 						)
// 					);
// 				});
// 		}
// 		return Yup.string().required("Last Name is required");
// 	})
