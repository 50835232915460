import React, { useEffect, useState } from "react";
import CustomBreadcrumb from "../../components/breadcrumbs/Breadcrumb";
import { Link, useParams } from "react-router-dom";
import { Card, CardBody, Col, Container, Row, Table } from "react-bootstrap";
import CardTitle from "../../components/cardTitle/CardTitle";
import {
	DatatableWrapper,
	Filter,
	TableBody,
	TableHeader,
} from "react-bs-datatable";
import ReactSelectOption from "../../components/inputs/ReactSelectOption";
import CheckBox from "../../components/inputs/CheckBox";
import MainService from "../../helpers/services/mainService";
import { ApiService } from "../../helpers/services/apiSrevice";
import AlertService from "../../helpers/services/alertService";
import { SUCCESS_KEY } from "../../helpers/constants/constants";

function HomeTeachers(props) {
	const [data, setData] = useState([]);
	const [staffProfiles, setStaffProfiles] = useState([]);
	const { yearId, userTypeId } = useParams();

	useEffect(() => {
		if (yearId && userTypeId) {
			MainService.callApi({
				api: ApiService.staffProfileAccessGetInfoByType,
				data: {
					yearId,
					userTypeId,
					data: {
						pageNumber: 1,
						pageSize: 100,
					},
				},
				cb: response => {
					if (
						response &&
						response.data &&
						response.data.pagedData &&
						response.data.pagedData.length
					) {
						setData(response.data.pagedData);
					}
				},
			});
		}
	}, [yearId, userTypeId]);

	useEffect(() => {
		MainService.callApi({
			api: ApiService.staffProfileGetAll,

			cb: response => {
				if (response && response.data && response.data.length) {
					setStaffProfiles(response.data);
				}
			},
		});
	}, []);

	const onSelectOptionChange = (item, row) => {
		const values = {
			staffProfileId: item.id,
			userTypeId: +userTypeId,
			academicYearId: +yearId,
			houseId: row.data.id,
		};
		if (row.staffProfileAccess && row.staffProfileAccess.id) {
			values.id = row.staffProfileAccess.id;
		}
		MainService.callApi({
			api: values.id
				? ApiService.staffProfileAccessUpdate
				: ApiService.staffProfileAccessCreate,
			data: values,
			cb: response => {
				AlertService.alert(SUCCESS_KEY, "Data saved");
				MainService.callApi({
					api: ApiService.staffProfileAccessGetInfoByType,
					data: {
						yearId,
						userTypeId,
						data: {
							pageNumber: 1,
							pageSize: 100,
						},
					},
					cb: response => {
						if (
							response &&
							response.data &&
							response.data.pagedData &&
							response.data.pagedData.length
						) {
							setData(response.data.pagedData);
						}
					},
				});
			},
		});
	};

	const onCheckBoxChange = (event, item) => {
		let values = {
			academicYearId: yearId,
			// courseId: item.data.id,
			houseId: item.data.id,
			// studentYearId: item.data.studentYearId,
			isActiv: event.target.checked,
		};
		if (item?.staffProfileAccess?.id) {
			values.staffProfileAccessId = item.staffProfileAccess.id;
		}
		MainService.callApi({
			api: ApiService.staffProfileAccessUpdateNotRequired,
			data: values,
			cb: response => {
				if (response && response.success) {
					AlertService.alert(
						SUCCESS_KEY,
						`The "No HoLA Required" assigned successfully.`,
					);
					MainService.callApi({
						api: ApiService.staffProfileAccessGetInfoByType,
						data: {
							yearId,
							userTypeId,
							data: {
								pageNumber: 1,
								pageSize: 100,
							},
						},
						cb: response => {
							if (
								response &&
								response.data &&
								response.data.pagedData &&
								response.data.pagedData.length
							) {
								setData(response.data.pagedData);
							}
						},
					});
				}
			},
		});
	};

	const columns = [
		{
			prop: "data.name",
			title: "Code",
			isFilterable: true,
			isSortable: true,
			cell: row => (
				<div className=" gap-1 align-items-center">
					{!row.staffProfileAccess && !row.data.homeTeacherNotRequired && (
						<span className="coustem-label d-inline-block mx-2">Fix</span>
					)}
					<p className="d-inline-block">{row.data.name}</p>
				</div>
			),
		},
		{
			prop: "preReqCode",
			title: "HoLA",
			cell: row => (
				<div>
					<ReactSelectOption
						selectedValue={(() => {
							const selectedItem = {
								...staffProfiles.find(
									data =>
										row.staffProfileAccess &&
										row.staffProfileAccess.staffProfileId &&
										data.id === row.staffProfileAccess.staffProfileId,
								),
							};
							if (Object.keys(selectedItem).length) {
								selectedItem.label = `${selectedItem.staffCode} ${selectedItem.staffFullName}`;
								selectedItem.value = selectedItem.id;
								return selectedItem;
							} else {
								return null;
							}
						})()}
						items={staffProfiles
							// .filter(item => item.value !== values[shortKey].timeZone)
							.map(item => ({
								...item,
								label: `${item.staffFullName}`,
								value: item.id,
							}))}
						onChange={item => onSelectOptionChange(item, row)}
					/>
				</div>
			),
		},
		{
			prop: "data.homeTeacherNotRequired",
			title: "No HoLA required",
			isSortable: true,
			cell: row => (
				<div className="d-flex">
					<CheckBox
						checked={row.data.homeTeacherNotRequired}
						onChange={event => {
							onCheckBoxChange(event, row);
						}}
					/>
				</div>
			),
		},
	];

	return (
		<>
			<CustomBreadcrumb
				boxClassName="shadow"
				data={[
					{
						name: "Checklist",
						url: "/admin/checklist",
					},
					{ name: "Staff", url: `/admin/teachers/${yearId}` },
					{ name: "Home Teachers", url: `` },
				]}>
				<div className="d-flex align-items-center gap-2">
					<Link
						to={"#"}
						target="_blank"
						className="btn btn-outline-blue btn-extra_small">
						Help Documentation
					</Link>
				</div>
			</CustomBreadcrumb>
			<Container className="mt-3">
				<Card>
					<CardBody>
						<CardTitle title="Home Teachers for Year 2024" />
						<Row className="mt-3 gap-3">
							<Col md={12} className="d-flex gap-2">
								<span className="coustem-label">Fix</span>
								<Link className="in_hover_underline fs-16 fw-700" to={"#"}>
									Home Teachers
								</Link>
							</Col>
							<Col md={12}>
								<div className="information_block danger_block">
									<p>
										The students rows which have FIX notifications do not have a
										Home Teacher assigned, or have a code for a staff member who
										does not have a profile.
									</p>
									<p>
										Click the field below to assign a student to a staff member
										with a profile, or select the box to indicate a Home Teacher
										is not required for that student.
									</p>
								</div>
							</Col>
							<Col md={12}>
								<p>
									You can add staff profiles by clicking the Staff link in the
									top menu bar.
								</p>
							</Col>
							<Col>
								<DatatableWrapper
									body={data}
									headers={columns}
									paginationOptionsProps={{
										initialState: {
											rowsPerPage: 100,
											options: [5, 10, 15, 20],
										},
									}}>
									<Row className="my-3 justify-content-between gap-3">
										<Col md={2}>
											<Filter
												placeholder="search"
												classes={{
													input: "input-sm sso_input",
													clearButton: "clearButton",
												}}
											/>
										</Col>
										<Col md={12} className="mb-2">
											<Table className="sso_tabel" responsive bordered>
												<TableHeader />
												<TableBody />
											</Table>
										</Col>
									</Row>
								</DatatableWrapper>
							</Col>
						</Row>
					</CardBody>
				</Card>
			</Container>
		</>
	);
}

export default HomeTeachers;
