import React from "react";
import { Col, Row } from "react-bootstrap";
import Switch from "../../../components/inputs/Switch";
import ReactSelectOption from "../../../components/inputs/ReactSelectOption";
import Input from "../../../components/inputs/Input";
import RadioBox from "../../../components/inputs/RadioBox";
import { bankPoints } from "../../../helpers/constants/arreys";

function PaymentGateWay({
	values,
	setValues,
	shortKey,
	showPaymentGateWay,
	setShowPaymentGateWay,
	errors,
	setErrors,
}) {
	const defaultValue = {
		isPaymentGateWay: false,
		gateWayType: null,
		bPointMerchantNumber: "",
		bPointShortName: "",
		bPointUserName: "",
		bPointPassword: "",
		bPointBillerCode: "",
		bPointAuthorisationURL: "",
		bPointPaymentURL: "",
		bPointVerificationURL: "",
		// bPointAuthorisationURL:
		// 	"https://www.bpoint.com.au/webapi/v2/txns/processtxnauthkey",
		// bPointPaymentURL: "https://www.bpoint.com.au/payments/",
		// bPointVerificationURL:
		// 	"https://www.bpoint.com.au/webapi/v2/txns/withauthkey/",
		paymentTermsText: "",
		minPaymentCalculationPercentage: false,
		amount: 0.0,
	};

	const onNumberChange = (event, fieldName, errorfieldName) => {
		if (
			event.target.value.includes("e") ||
			event.target.value.includes(".") ||
			event.target.value.includes("-")
		) {
			return false;
		}
		if (errorfieldName && errors && errors[errorfieldName]) {
			delete errors[errorfieldName];
			setErrors(errors);
		}
		if (event.target.value === "" || Number.isInteger(+event.target.value)) {
			setValues(values => ({
				...values,
				[shortKey]: {
					...values[shortKey],
					[fieldName]: event.target.value,
				},
			}));
		}
	};

	const onChange = (event, fieldName, errorfieldName) => {
		if (errorfieldName && errors && errors[errorfieldName]) {
			delete errors[errorfieldName];
			setErrors(errors);
		}
		if (fieldName === "minPaymentCalculationPercentage") {
			setValues(values => ({
				...values,
				[shortKey]: {
					...values[shortKey],
					[fieldName]: event.target.value === "1" ? true : false,
				},
			}));
			return;
		}
		setValues(values => ({
			...values,
			[shortKey]: {
				...values[shortKey],
				[fieldName]: event.target.value,
			},
		}));
	};

	const onSelectOptionChange = (
		item,
		fieldName,
		errorfieldName,
		changeUrl = false,
	) => {
		if (!item) {
			return false;
		}
		if (errorfieldName && errors && errors[errorfieldName]) {
			delete errors[errorfieldName];
			setErrors(errors);
		}

		if (changeUrl) {
			setValues(values => ({
				...values,
				[shortKey]: {
					...values[shortKey],
					[fieldName]: item.value,
					bPointAuthorisationURL: item.bPointAuthorisationURL,
					bPointPaymentURL: item.bPointPaymentURL,
					bPointVerificationURL: item.bPointVerificationURL,
				},
			}));
			return true;
		}
		setValues(values => ({
			...values,
			[shortKey]: {
				...values[shortKey],
				[fieldName]: item.value,
			},
		}));
	};

	const onChangePaymentGateWay = event => {
		setShowPaymentGateWay(event.target.checked);
		setValues(values => ({
			...values,
			[shortKey]: defaultValue,
		}));
	};
	return (
		<Row className="gap-3 py-4">
			<Col md={12}>
				<Row className="align-items-center">
					<Col md={12} lg={3}>
						<label htmlFor="processCopy" className="sso_label">
							Payment GateWay
						</label>
					</Col>
					<Col md={12} lg={4} className="d-flex gap-3">
						<div>
							<Switch
								id={"processCopy"}
								value={showPaymentGateWay}
								onChange={event => {
									onChangePaymentGateWay(event);
								}}
							/>
						</div>
					</Col>
				</Row>
			</Col>
			{showPaymentGateWay && (
				<>
					<Col md={12}>
						<Row className="align-items-center">
							<Col md={12} lg={3}>
								<label className="sso_label">GateWay Type</label>
								<span className="sso_required">*</span>
							</Col>
							<Col md={12} lg={4} className="d-flex gap-3">
								<ReactSelectOption
									errorMessage={errors && errors[`${shortKey}.gateWayType`]}
									value={values[shortKey].gateWayType}
									selectedValue={(() => {
										const selectedItem = {
											...bankPoints.find(
												data => data.id === values[shortKey].gateWayType,
											),
										};
										if (Object.keys(selectedItem).length) {
											selectedItem.value = selectedItem.id;
											selectedItem.label = selectedItem.name;
											return selectedItem;
										} else {
											return {
												value: null,
												label: "Choose",
											};
										}
									})()}
									items={bankPoints
										.filter(item => item.id !== values[shortKey].gateWayType)
										.map(data => ({
											...data,
											label: data.name,
											value: data.id,
										}))}
									onChange={item =>
										onSelectOptionChange(
											item,
											"gateWayType",
											`${shortKey}.gateWayType`,
											true,
										)
									}
								/>
							</Col>
						</Row>
					</Col>
					<Col md={12}>
						<hr />
					</Col>
					<Col md={12}>
						<Input
							label={"BPoint Merchant Number"}
							required={true}
							labelCol={"col-md-12 col-lg-3"}
							inputCol={"col-md-12 col-lg-4"}
							id={"bPointMerchantNumber"}
							value={values[shortKey].bPointMerchantNumber}
							errorMessage={
								errors && errors[`${shortKey}.bPointMerchantNumber`]
							}
							onChange={event => {
								onChange(
									event,
									"bPointMerchantNumber",
									`${shortKey}.bPointMerchantNumber`,
								);
							}}
						/>
					</Col>
					<Col md={12}>
						<Input
							label={"BPoint Short Name"}
							required={true}
							labelCol={"col-md-12 col-lg-3"}
							inputCol={"col-md-12 col-lg-4"}
							id={"bPointShortName"}
							value={values[shortKey].bPointShortName}
							errorMessage={errors && errors[`${shortKey}.bPointShortName`]}
							onChange={event => {
								onChange(
									event,
									"bPointShortName",
									`${shortKey}.bPointShortName`,
								);
							}}
						/>
					</Col>
					<Col md={12}>
						<Input
							label={"BPoint User Name"}
							required={true}
							labelCol={"col-md-12 col-lg-3"}
							inputCol={"col-md-12 col-lg-4"}
							id={"bPointUserName"}
							value={values[shortKey].bPointUserName}
							errorMessage={errors && errors[`${shortKey}.bPointUserName`]}
							onChange={event => {
								onChange(event, "bPointUserName", `${shortKey}.bPointUserName`);
							}}
						/>
					</Col>
					<Col md={12}>
						<Input
							label={"BPoint Password"}
							required={true}
							labelCol={"col-md-12 col-lg-3"}
							inputCol={"col-md-12 col-lg-4"}
							id={"bPointPassword"}
							value={values[shortKey].bPointPassword}
							errorMessage={errors && errors[`${shortKey}.bPointPassword`]}
							onChange={event => {
								onChange(event, "bPointPassword", `${shortKey}.bPointPassword`);
							}}
						/>
					</Col>
					<Col md={12}>
						<Input
							label={"BPoint Biller Code"}
							required={true}
							labelCol={"col-md-12 col-lg-3"}
							inputCol={"col-md-12 col-lg-4"}
							id={"bPointBillerCode"}
							value={values[shortKey].bPointBillerCode}
							errorMessage={errors && errors[`${shortKey}.bPointBillerCode`]}
							onChange={event => {
								onChange(
									event,
									"bPointBillerCode",
									`${shortKey}.bPointBillerCode`,
								);
							}}
						/>
					</Col>
					<Col md={12}>
						<Input
							label={"BPoint Authorisation URL"}
							required={true}
							labelCol={"col-md-12 col-lg-3"}
							inputCol={"col-md-12 col-lg-4"}
							id={"bPointAuthorisationURL"}
							value={values[shortKey].bPointAuthorisationURL}
							errorMessage={
								errors && errors[`${shortKey}.bPointAuthorisationURL`]
							}
							onChange={event => {
								onChange(
									event,
									"bPointAuthorisationURL",
									`${shortKey}.bPointAuthorisationURL`,
								);
							}}
						/>
					</Col>
					<Col md={12}>
						<Input
							label={"BPoint Payment URL"}
							required={true}
							labelCol={"col-md-12 col-lg-3"}
							inputCol={"col-md-12 col-lg-4"}
							id={"bPointPaymentURL"}
							value={values[shortKey].bPointPaymentURL}
							errorMessage={errors && errors[`${shortKey}.bPointPaymentURL`]}
							onChange={event => {
								onChange(
									event,
									"bPointPaymentURL",
									`${shortKey}.bPointPaymentURL`,
								);
							}}
						/>
					</Col>
					<Col md={12}>
						<Input
							label={"BPoint Verifycation URL"}
							required={true}
							labelCol={"col-md-12 col-lg-3"}
							inputCol={"col-md-12 col-lg-4"}
							id={"bPointVerifycationURL"}
							value={values[shortKey].bPointVerificationURL}
							errorMessage={
								errors && errors[`${shortKey}.bPointVerificationURL`]
							}
							onChange={event => {
								onChange(
									event,
									"bPointVerificationURL",
									`${shortKey}.bPointVerificationURL`,
								);
							}}
						/>
					</Col>
					<Col md={12}>
						<hr />
					</Col>
					<Col md={12}>
						<Input
							label={"Payment Terms Text"}
							required={true}
							labelCol={"col-md-12 col-lg-3"}
							inputCol={"col-md-12 col-lg-4"}
							id={"paymentTermsText"}
							value={values[shortKey].paymentTermsText}
							errorMessage={errors && errors[`${shortKey}.paymentTermsText`]}
							onChange={event => {
								onChange(
									event,
									"paymentTermsText",
									`${shortKey}.paymentTermsText`,
								);
							}}
						/>
					</Col>
					<Col md={12} className="mb-3">
						<Row className="align-items-center">
							<Col md={12} lg={3}>
								<label className="sso_label">
									Min. Payment Calculation:
									<span className="sso_required">*</span>
								</label>
							</Col>
							<Col md={12} lg={4} className="d-flex gap-3">
								<RadioBox
									label={"Currency"}
									id={"currency"}
									name={"minPaymentCalculationPercentage"}
									value={0}
									checked={!values[shortKey].minPaymentCalculationPercentage}
									onChange={event => {
										onChange(event, "minPaymentCalculationPercentage");
									}}
								/>
								<RadioBox
									label={"Percentage"}
									id={"percentage"}
									name={"minPaymentCalculationPercentage"}
									value={1}
									checked={values[shortKey].minPaymentCalculationPercentage}
									onChange={event => {
										onChange(event, "minPaymentCalculationPercentage");
									}}
								/>
							</Col>
						</Row>
					</Col>
					<Col md={12}>
						<Input
							label={"Amount"}
							required={true}
							labelCol={"col-md-12 col-lg-3"}
							inputCol={"col-md-12 col-lg-2"}
							id={"amount"}
							value={values[shortKey].amount}
							errorMessage={errors && errors[`${shortKey}.amount`]}
							currency={values[shortKey].minPaymentCalculationPercentage}
							onChange={event => {
								onNumberChange(event, "amount", `${shortKey}.amount`);
							}}
						/>
					</Col>
				</>
			)}
		</Row>
	);
}

export default PaymentGateWay;
