import React from "react";
import Select from "react-select";

// multi select on change example
// const onChange = (_, action) => {
// 	if (action.action === "select-option") {
// 		setValue("dependantBadgeIds", newValue);
// 		return;
// 	}
// 	if (action.action === "remove-value") {
// 		if (
// 			data?.dependantBadges.find(el => el.id === action.removedValue.value)
// 		) {
// 			setValue("deleteBadgeIds", newDeleteBadgeIds);
// 		}
// 		return;
// 	}
// 	if (action.action === "clear") {
// 		setValue("dependantBadgeIds", []);
// 	}
// };

function MultiReactSelectOption({
	name,
	failedFields,
	isInvalidSubmit,
	value,
	classNamePrefix,
	selectedValue,
	defaultValue,
	placeholder,
	items = [],
	isDisabled,
	isSearchable = true,
	onChange,
	className,
	onFocus,
	isInvalidField,
	isLine = false,
	errorMessage,
	isMulti = true,
}) {
	let customStyles = {};

	customStyles = {
		menu: (provided, state) => ({
			...provided,
			backgroundColor: "#f8f9fa", // цвет фона меню
			zIndex: 2000, // z-index для выпадающего меню
			boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // тень под меню
			borderRadius: "4px", // закругленные углы
			padding: "5px", // внутренний отступ
		}),

		menuList: provided => ({
			...provided,
			maxHeight: "180px", // максимальная высота меню
			overflowY: "auto", // прокрутка при большом количестве элементов
			padding: 0, // убрать отступы у списка
		}),

		control: (provided, state) => ({
			...provided,
			minHeight: "34px",
			width: "100%",
			padding: "0 0 0 12px",
			borderColor: state.isFocused ? "#93a1bb" : "#c2cad8",
			"&:hover": {
				borderColor: "#c2cad8",
			},
		}),
		indicatorSeparator: base => ({
			...base,
			width: "0",
		}),
		dropdownIndicator: base => ({
			...base,
			padding: "6px 12px",
		}),

		indicatorContainer: base => ({
			...base,
		}),

		placeholder: provided => ({
			...provided,
			whiteSpace: "nowrap",
			overflow: "hidden",
			textOverflow: "ellipsis",
		}),
		input: provided => ({
			...provided,
			padding: 0,
			fontSize: "14px",
			fontWeight: 400,
			margin: 0,
		}),
		singleValue: provided => ({
			...provided,
			whiteSpace: "nowrap",
			overflow: "hidden",
			textOverflow: "ellipsis",
		}),
	};

	const failedFieldName = name
		? name.charAt(0).toUpperCase() + name.slice(1)
		: "";
	return (
		<div className="w-100">
			<Select
				className={`basic-single ${className ? className : ""} ${
					(failedFields && failedFields.hasOwnProperty(failedFieldName)) ||
					(isInvalidSubmit &&
						(value === null || value === undefined || value === false)) ||
					(isInvalidField && isLine)
						? "select-fail-laine"
						: (failedFields && failedFields.hasOwnProperty(failedFieldName)) ||
						  (isInvalidSubmit &&
								(value === null || value === undefined || value === false)) ||
						  isInvalidField
						? "select-fail"
						: ""
				}`}
				classNamePrefix={`sso-select ${classNamePrefix ? classNamePrefix : ""}`}
				isSearchable={isSearchable}
				placeholder={placeholder}
				name={name}
				defaultValue={defaultValue}
				value={selectedValue}
				options={items}
				isDisabled={isDisabled}
				onChange={onChange}
				styles={customStyles}
				onFocus={onFocus}
				maxMenuHeight={300}
				isMulti={isMulti} // Включаем мультивыбор
			/>
			{errorMessage && value && (
				<small className="text-danger d-block mt-1">{errorMessage}</small>
			)}
		</div>
	);
}

export default MultiReactSelectOption;
