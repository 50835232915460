import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import RadioBox from "../../../components/inputs/RadioBox";
import { FaCheck, FaDollarSign, FaEuroSign, FaTimes } from "react-icons/fa";
import { PiCurrencyGbpBold } from "react-icons/pi";
import ButtonsInput from "../../../components/inputs/ButtonsInput";
import { FaPencilAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import CheckBox from "../../../components/inputs/CheckBox";
import Input from "../../../components/inputs/Input";
import ReactSelectOption from "../../../components/inputs/ReactSelectOption";
import {
	AUD_KEY,
	EUR_KEY,
	GPB_KEY,
	PAYMENT_GATEWAY,
} from "../../../helpers/constants/constants";
import { MdDeleteOutline } from "react-icons/md";
import MainService from "../../../helpers/services/mainService";
import { ApiService } from "../../../helpers/services/apiSrevice";
import {
	billableChargesInputTypes,
	defaultBillecharges,
} from "../../../helpers/constants/arreys";

function BillingDetails({ values, setValues, shortKey, errors, setErrors }) {
	const [show, setShow] = useState(false);
	const [type, setType] = useState(null);
	const [otherCharges, setOtherCharges] = useState(null);
	const [showOtherCharges, setShowOtherCharges] = useState(false);
	const [chargeTypes, setChargeTypes] = useState([]);

	useEffect(() => {
		MainService.callApi({
			api: ApiService.getChargeTypes,
			cb: response => {
				if (response && response.data) {
					setChargeTypes(response.data);
				}
			},
		});
	}, []);

	const onSelectOptionChange = (item, fieldName) => {
		if (!item) {
			return false;
		}
		setType(item.value);
		setOtherCharges({
			chargeType: item.value,
			value: "",
			checked: true,
		});
	};

	const onChange = (event, fieldName) => {
		setValues(values => ({
			...values,
			[shortKey]: {
				...values[shortKey],
				[fieldName]: event.target.value,
			},
		}));
	};

	const onChangeBilleCharges = (event, type) => {
		setValues(values => ({
			...values,
			[shortKey]: {
				...values[shortKey],
				billableCharges: values[shortKey].billableCharges?.map(item => {
					if (item.chargeType === type) {
						return {
							...item,
							value: event.target.value,
						};
					}
					return item;
				}),
			},
		}));
	};

	const onCheckChange = (event, type) => {
		setValues(values => ({
			...values,
			[shortKey]: {
				...values[shortKey],
				billableCharges: values[shortKey].billableCharges?.map(item => {
					if (item.chargeType === type) {
						return {
							...item,
							isBillableCharge: event.target.checked,
						};
					}
					return item;
				}),
			},
		}));
	};

	const onChageOtherCharges = (value, index) => {
		setValues(values => ({
			...values,
			[shortKey]: {
				...values[shortKey],
				otherCharges: values[shortKey].otherCharges.map((item, ind) => {
					if (index === ind) {
						return { ...item, value: value };
					}
					return item;
				}),
			},
		}));
	};

	const onDelete = index => {
		setValues(values => ({
			...values,
			[shortKey]: {
				...values[shortKey],
				otherCharges: values[shortKey].otherCharges.filter(
					(_, ind) => ind !== index,
				),
			},
		}));
	};

	console.log(errors);

	return (
		<Row className="mt-5 gap-3">
			<Col md={12}>
				<Row className="align-items-center">
					<Col md={12} lg={3}>
						<label className="sso_label">
							Currency Type:
							{/* <span className="sso_required">*</span> */}
						</label>
					</Col>
					<Col md={12} lg={4} className="d-flex gap-3">
						<RadioBox
							id={"aud"}
							label={<FaDollarSign />}
							value={AUD_KEY}
							checked={values[shortKey].currencyType === AUD_KEY}
							name="currencyType"
							onChange={event => {
								onChange(event, "currencyType");
							}}
						/>
						<RadioBox
							id={"eur"}
							label={<FaEuroSign />}
							value={EUR_KEY}
							checked={values[shortKey].currencyType === EUR_KEY}
							errorMessage={errors && errors[`${shortKey}.currencyType`]}
							name="currencyType"
							onChange={event => {
								onChange(event, "currencyType");
							}}
						/>
						<RadioBox
							id={"gpb"}
							value={GPB_KEY}
							checked={values[shortKey].currencyType === GPB_KEY}
							label={<PiCurrencyGbpBold />}
							name="currencyType"
							onChange={event => {
								onChange(event, "currencyType");
							}}
						/>
					</Col>
				</Row>
			</Col>
			<Col md={12}>
				<ButtonsInput
					required={true}
					id={"sendInvoicesTo"}
					label={"Send Invoices To"}
					labelCol={"col-md-12 col-lg-3"}
					inputCol={"col-md-12 col-lg-4"}
					value={values[shortKey].sendInvoicesTo || ""}
					onSave={value => {
						setValues(values => ({
							...values,
							[shortKey]: {
								...values[shortKey],
								sendInvoicesTo: value,
							},
						}));
					}}
				/>
			</Col>
			<Col md={12}>
				<ButtonsInput
					id={"billingNotes"}
					label={"Billing Notes"}
					labelCol={"col-md-12 col-lg-3"}
					inputCol={"col-md-12 col-lg-4"}
					value={values[shortKey].billingNotes || ""}
					onSave={value => {
						setValues(values => ({
							...values,
							[shortKey]: {
								...values[shortKey],
								billingNotes: value,
							},
						}));
					}}
				/>
			</Col>
			<Col md={12} className="d-flex flex-column gap-3">
				<Row className="gap-3 position-relative">
					<Col md={12} className="d-flex gap-2 align-items-center gap-5">
						<h4 className="billable_charges_title">Billable Charges</h4>
						<div className="gap-2 d-flex wrap align-items-center">
							{!show ? (
								<>
									<Link
										to={"#"}
										onClick={() => {
											setShow(true);
										}}>
										<FaPencilAlt width={"12px"} height={"12px"} />
									</Link>
								</>
							) : (
								<>
									<Link
										to={"#"}
										className="close"
										onClick={() => {
											setShow(false);
											setValues(values => ({
												...values,
												[shortKey]: {
													...values[shortKey],
													billableCharges: defaultBillecharges,
												},
											}));
										}}>
										<FaTimes />
									</Link>

									<Link
										to={"#"}
										className="check"
										onClick={() => {
											if (
												errors &&
												Object.keys(errors) &&
												Object.keys(errors).length &&
												Object.keys(errors).filter(key =>
													key.includes(`${shortKey}.billeCharges`),
												).length
											)
												return false;
											setShow(false);
										}}>
										<FaCheck />
									</Link>
								</>
							)}
						</div>
					</Col>
					{chargeTypes &&
						!!chargeTypes.length &&
						chargeTypes.map((item, index) => {
							return (
								<Col sm={12} key={index}>
									<Row className="align-items-center">
										<Col md={12} lg={3}>
											<CheckBox
												disabled={!show}
												id={item.name?.replace(" ", "_") + index}
												label={item.name}
												checked={
													values[shortKey].billableCharges.filter(
														el => el.chargeType === item.chargeType,
													)[0]?.isBillableCharge
												}
												onChange={event => {
													onCheckChange(event, item.chargeType);
												}}
											/>
										</Col>

										{values[shortKey].billableCharges.filter(
											el => el.chargeType === item.chargeType,
										)[0]?.isBillableCharge &&
										billableChargesInputTypes.includes(item.chargeType) ? (
											<Col md={12} lg={4}>
												<Input
													inputClassName={"border_none"}
													errorMessage={
														errors &&
														errors[`${shortKey}.billeCharges[${index}].value`]
													}
													required={true}
													disabled={!show}
													value={
														values[shortKey].billableCharges.filter(
															el => el.chargeType === item.chargeType,
														)[0]?.value
													}
													onChange={event => {
														onChangeBilleCharges(event, item.chargeType);
													}}
												/>
											</Col>
										) : null}
									</Row>
								</Col>
							);
						})}
				</Row>
			</Col>
			{/* <Col md={12}>
				<Row className="gap-3">
					<Col md={12}>
						<h4 className="billable_charges_title">Additional Charges</h4>
					</Col>
					{!showOtherCharges && (
						<Col md={12}>
							<button
								type="button"
								className="btn btn-primary btn-sm "
								onClick={() => {
									setShowOtherCharges(true);
								}}>
								Add New
							</button>
						</Col>
					)}
					{showOtherCharges && (
						<Col md={12}>
							<Row>
								<Col md={12} lg={3}>
									<ReactSelectOption
										value={type}
										selectedValue={(() => {
											const selectedItem = {
												...chargeTypes.find(data => data.chargeType === type),
											};
											if (Object.keys(selectedItem).length) {
												selectedItem.value = selectedItem.chargeType;
												selectedItem.label = selectedItem.name;
												return selectedItem;
											} else {
												return {
													value: null,
													label: "Choose",
												};
											}
										})()}
										items={chargeTypes
											.filter(item => item.id !== type)
											.filter(item => item.canBeAsAdditionalCharge)
											.map(data => ({
												...data,
												label: data.name,
												value: data.chargeType,
											}))}
										onChange={item => onSelectOptionChange(item)}
									/>
								</Col>
								{otherCharges && (
									<Col md={12} lg={4} className="d-flex gap-2">
										{billableChargesInputTypes.includes(
											otherCharges.chargeType,
										) && (
											<Input
												inputClassName={"border_none"}
												id={"worktrackCode"}
												value={otherCharges.value}
												onChange={event => {
													setOtherCharges(values => ({
														...values,
														value: event.target.value,
													}));
												}}
											/>
										)}

										<Link
											to={"#"}
											className="close"
											onClick={() => {
												setOtherCharges(null);
												setType(null);
											}}>
											<FaTimes />
										</Link>
										<Link
											to={"#"}
											className="check"
											onClick={() => {
												if (
													billableChargesInputTypes.includes(
														otherCharges.chargeType,
													) &&
													!otherCharges.value
												) {
													return false;
												}
												setValues(values => ({
													...values,
													[shortKey]: {
														...values[shortKey],
														otherCharges: [
															...values[shortKey].otherCharges,
															otherCharges,
														],
													},
												}));
												setOtherCharges(null);
												setType(null);
											}}>
											<FaCheck />
										</Link>
									</Col>
								)}
							</Row>
						</Col>
					)}
					{values[shortKey].otherCharges &&
						!!values[shortKey].otherCharges?.length && (
							<Col md={12} className="d-flex flex-column gap-3">
								{values[shortKey].otherCharges.map((item, index) => {
									const name = chargeTypes.filter(
										el => el.chargeType === item.chargeType,
									)[0]?.name;

									if (item.chargeType === PAYMENT_GATEWAY) {
										return (
											<Row className="align-items-center" key={index}>
												<Col md={12} className="d-flex gap-3">
													<label className="sso_label">{name}</label>
													<Link
														to={"#"}
														className="close"
														onClick={() => {
															onDelete(index);
														}}>
														<MdDeleteOutline
															style={{
																height: "20px",
																width: "20px",
															}}
														/>
													</Link>
												</Col>
											</Row>
										);
									}
									return (
										<Row className="align-items-center" key={index}>
											<ButtonsInput
												label={name}
												value={item.value}
												onSave={value => {
													onChageOtherCharges(value);
												}}
												onDelete={() => {
													onDelete(index);
												}}
												id={`otherCharges${index}`}
												labelCol={"col-md-12 col-lg-3"}
												inputCol={"col-md-12 col-lg-4"}
											/>
										</Row>
									);
								})}
							</Col>
						)}
				</Row>
			</Col> */}
		</Row>
	);
}

export default BillingDetails;
