import React, { useEffect, useState } from "react";
import CustomBreadcrumb from "../../components/breadcrumbs/Breadcrumb";
import { Link, useParams } from "react-router-dom";
import {
	Button,
	ButtonGroup,
	Card,
	CardBody,
	Col,
	Container,
	OverlayTrigger,
	Popover,
	Row,
	Table,
	ToggleButton,
	Tooltip,
} from "react-bootstrap";
import CardTitle from "../../components/cardTitle/CardTitle";
import {
	DatatableWrapper,
	Filter,
	TableBody,
	TableHeader,
} from "react-bs-datatable";
import { FiPlus } from "react-icons/fi";
import { FaRegTrashCan } from "react-icons/fa6";
import { useMediaQuery } from "@mui/material";
import { FaCheck, FaMinus, FaRegEdit } from "react-icons/fa";
import { IoCloseSharp } from "react-icons/io5";
import CheckBox from "../../components/inputs/CheckBox";
import RadioBox from "../../components/inputs/RadioBox";
import ReactSelectOption from "../../components/inputs/ReactSelectOption";
import Input from "../../components/inputs/Input";
import Textarea from "../../components/inputs/Textarea";
import MainService from "../../helpers/services/mainService";
import { ApiService } from "../../helpers/services/apiSrevice";

function GlobalRules(props) {
	const { yearId } = useParams();
	const [data, setData] = useState([]);
	const [showPopover, setShowPopover] = useState(false);
	const [deleteIds, setDeleteIds] = useState([]);
	const matches575 = useMediaQuery("(max-width:575px)");
	const [showForm, setShowForm] = useState(false);
	const [navigation, setNavigation] = useState(null);
	const [values, setValues] = useState({});

	useEffect(() => {
		MainService.callApi({
			api: ApiService.getNavigation,
			data: yearId,
			cb: response => {
				if (response && response.data && response.data.length) {
					setNavigation(response.data);
				}
			},
		});
	}, []);

	const columns = [
		{
			prop: "select",
			title: (
				<CheckBox
					size={"lg"}
					checked={!!data.length && data?.length === deleteIds?.length}
					onChange={event => {
						if (event.target.checked) {
							setDeleteIds(data?.map(item => item.id));
						} else {
							setDeleteIds([]);
						}
					}}
				/>
			),
			cell: row => (
				<CheckBox
					checked={deleteIds?.includes(row.id)}
					onChange={event => {
						if (event.target.checked) {
							setDeleteIds(ids => {
								return [...ids, row.id];
							});
						} else {
							setDeleteIds(ids => {
								return ids.filter(el => el !== row.id);
							});
						}
					}}
				/>
			),
		},
		{
			title: "Name",
			prop: "courseCode",
			isFilterable: true,
			isSortable: true,
			cell: row => <div>{row.courseCode}</div>,
		},
		{
			title: "Description",
			prop: "description",
			isFilterable: true,
			isSortable: true,
			cell: row => <div>{row.courseName}</div>,
		},

		{
			title: "Action",
			name: "Edit",
			prop: "button",
			cell: row => (
				<Link
					to={`#`}
					onClick={() => {
						// onOpen(row);
					}}
					className="table_link d-flex align-items-center d-flex ">
					<FaRegEdit /> <span>Edit</span>
				</Link>
			),
		},
	];
	const handleToggle = () => {
		setShowPopover(!showPopover);
	};

	const onChangeRadio = (event, fieldName) => {
		if (event.target.value === "false") {
			setValues(values => ({
				...values,
				[fieldName]: false,
			}));
		} else {
			setValues(values => ({
				...values,
				[fieldName]: true,
			}));
		}
	};

	const renderTooltip = props => (
		<Tooltip id="button-tooltip" {...props}>
			You have already assigned this selection in another rule
		</Tooltip>
	);

	const renderTooltipSave = props => {
		<Tooltip id="button-tooltip" {...props}>
			{props.text}
		</Tooltip>;
	};

	return (
		<>
			<CustomBreadcrumb
				boxClassName="shadow"
				data={[
					{
						name: "Checklist",
						url: "/admin/checklist",
					},
					{
						name: "Selection Process",
						url: `/admin/selection_process/${yearId}`,
					},
					{
						name: "Global Rules",
						url: "/",
					},
				]}>
				<div className="d-flex align-items-center gap-2">
					<Link
						to={"#"}
						target="_blank"
						className="btn btn-outline-blue btn-extra_small">
						Help Documentation
					</Link>
				</div>
			</CustomBreadcrumb>
			<Container className="mt-3">
				<Card>
					<CardBody>
						<CardTitle
							title={"Year 2024 Global Rules"}
							eventKey={"0"}
							linkNmae={"Show spreadsheet requirements "}
						/>
						<Row className={"my-2"}>
							<Col md={12} className="mb-3">
								<span className="">
									Here you can apply ad-hoc inclusion of a rule outside of all
									selections in a section or instruction
								</span>
							</Col>

							<Col md={12} className="mb-3">
								<div className="information_block">
									<ul className="disclosure_closed">
										<li>A minimum and/or maximum number of selections; or</li>
										<li>
											A minimum and/or maximum weighting count (where selections
											have a weighting); or
										</li>
										<li>
											Prevent the same selection code being picked more than
											once, from the selections included in the rule.
										</li>
									</ul>
								</div>
							</Col>
							<Col md={12}>
								<h3 className="fs-24 fw-500">Existing Global Rules</h3>
							</Col>
							<Col md={12}>
								<DatatableWrapper
									body={data}
									headers={columns}
									paginationOptionsProps={{
										initialState: {
											rowsPerPage: 100,
											options: [5, 10, 15, 20],
										},
									}}>
									<Row className="my-3 justify-content-between gap-3">
										<Col md={2}>
											<Filter
												placeholder="search"
												classes={{
													input: "input-sm sso_input",
													clearButton: "clearButton",
												}}
											/>
										</Col>
										<Col md={2} className="d-flex gap-2 justify-content-end">
											{!!deleteIds?.length && (
												<OverlayTrigger
													// trigger="focus"
													trigger="click"
													rootClose
													placement={matches575 ? "top" : "left"}
													show={showPopover}
													// onToggle={handleToggle}
													overlay={
														<Popover id="popover-positioned-bottom">
															<div className="are_you_shureo_popover_container">
																<h3>Are you sure ?</h3>
																<div className="are_you_shureo_popover_button">
																	<ButtonGroup className="mb-2">
																		<ToggleButton
																			id={`radio-${1}`}
																			type="radio"
																			name="are_you_shureo_popover"
																			className="custom-toggle-button btn-success"
																			value={0}
																			onChange={
																				e => {
																					// MainService.callApi({
																					// 	api: ApiService.deletResult,
																					// 	data: { data: deleteIds },
																					// 	cb: response => {
																					// 		if (
																					// 			response &&
																					// 			response.success
																					// 		) {
																					// 			setResults(results =>
																					// 				results.filter(
																					// 					el =>
																					// 						!deleteIds.includes(
																					// 							el.id,
																					// 						),
																					// 				),
																					// 			);
																					// 			setDeleteIds([]);
																					// 		}
																					// 	},
																					// });
																				}
																				// setRadioValue(e.currentTarget.value)
																			}>
																			<FaCheck />
																			Yes
																		</ToggleButton>
																		<ToggleButton
																			id={`radio-${2}`}
																			type="radio"
																			name="are_you_shureo_popover"
																			className="custom-toggle-button btn-danger"
																			value={1}
																			onChange={e => {
																				setShowPopover(false);
																			}}>
																			<IoCloseSharp
																				style={{ height: 16, width: 16 }}
																			/>
																			No
																		</ToggleButton>
																	</ButtonGroup>
																</div>
															</div>
														</Popover>
													}>
													<button
														className={`btn btn-red btn-sm ${
															showPopover ? "disabled" : ""
														}`}
														onClick={e => {
															handleToggle();
														}}>
														<FaRegTrashCan />
														Delete
													</button>
												</OverlayTrigger>
											)}
											<button
												className="btn btn-primary btn-sm"
												onClick={() => {
													setShowForm(true);
												}}>
												<FiPlus />
												Add New
											</button>
										</Col>
										<Col md={12} className="mb-2">
											<Table className="sso_tabel" responsive bordered>
												<TableHeader />
												<TableBody />
											</Table>
										</Col>
									</Row>
								</DatatableWrapper>
							</Col>
							{showForm && (
								<Col md={10}>
									<form>
										<Row style={{ paddingBottom: 100 }} className="gap-3">
											<Col md={12}>
												<Row>
													<Col md={12} lg={5} className="mt-3">
														<Input
															labelClassname="fw-600"
															placeholder={"Year"}
															id={"date"}
															label={"Give the rule a name for your reference:"}
															// value={ || ""}
															// errorMessage={
															// 	errors && errors[`${shortKey}.bPointMerchantNumber`]
															// }
															onChange={event => {
																// onChange(event, "date");
															}}
														/>
													</Col>
													<Col md={12} lg={7} className="mt-3">
														<Textarea
															labelClassname="fw-600"
															label={
																"Give the rule a description for students to see:"
															}
															id={"loginHeading"}
															rows={3}
															// value={values.loginHeading || ""}
															onChange={event => {
																// onChange(event, "loginHeading");
															}}
														/>
													</Col>
												</Row>
											</Col>

											<Col
												md={12}
												className="d-flex gap-3 flex-column flex-lg-row">
												<label className="w-lg-50 w-100 fw-500">
													Set number of selections:
												</label>
												<div className="d-flex align-items-center w-lg-50 w-100">
													<Input
														width={false}
														id={"minSelections"}
														inputClassName={"input-xsmall"}
														placeholder={"min"}
														// type="number"
														small={true}
														// value={values?.sectionSettings.minSelections || ""}
														onChange={event => {
															// onNumberChange(event, "minSelections");
														}}
													/>
													<FaMinus />
													<Input
														width={false}
														id={"maxSelections"}
														placeholder={"max"}
														inputClassName={"input-xsmall"}
														// type="number"
														small={true}
														// value={values?.sectionSettings.maxSelections || ""}
														onChange={event => {
															// onNumberChange(event, "maxSelections");
														}}
													/>
												</div>
											</Col>
											<Col
												md={12}
												className="d-flex gap-3 flex-column flex-lg-row">
												<label className="w-lg-50 w-100 fw-500">
													Set weighting count (where selections have a
													weighting):
												</label>
												<div className="d-flex align-items-center w-lg-50 w-100">
													<Input
														width={false}
														id={"minSelections"}
														inputClassName={"input-xsmall"}
														// type="number"
														placeholder={"min"}
														small={true}
														// value={values?.sectionSettings.minSelections || ""}
														onChange={event => {
															// onNumberChange(event, "minSelections");
														}}
													/>
													<FaMinus />
													<Input
														width={false}
														id={"maxSelections"}
														inputClassName={"input-xsmall"}
														placeholder={"max"}
														// type="number"
														small={true}
														// value={values?.sectionSettings.maxSelections || ""}
														onChange={event => {
															// onNumberChange(event, "maxSelections");
														}}
													/>
												</div>
											</Col>
											<Col md={12}>
												<CheckBox
													size={"lg"}
													label={
														" Only allow any code included in this Global Rule to be selected once"
													}
													labelClassName="fw-500"
													// value={isOn}
													// onChange={event => {
													// 	setIsOn(event.target.checked);
													// }}
												/>
											</Col>
											<Col md={12}>
												<span className="font-blue-madison fs-20 fw-400">
													Assign Selections to this Global Rule
												</span>
											</Col>

											<Col
												md={12}
												className="d-flex justify-content-end mt-5 gap-2">
												<Link to={"/schools"} className="btn btn-default">
													Cancel
												</Link>
												<Button
													type="submit"
													// disabled={errors && Object.keys(errors).length}
													className="btn-primary">
													Save
												</Button>
											</Col>
										</Row>
									</form>
								</Col>
							)}
						</Row>
					</CardBody>
				</Card>
			</Container>
		</>
	);
}

export default GlobalRules;
