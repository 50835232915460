import React from "react";
import CustomBreadcrumb from "../../components/breadcrumbs/Breadcrumb";
import { Link, useParams } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "react-bootstrap";
import CardTitle from "../../components/cardTitle/CardTitle";
import RadioBox from "../../components/inputs/RadioBox";
import CheckBox from "../../components/inputs/CheckBox";

function QuickView(props) {
	const { yearId } = useParams();

	return (
		<>
			<CustomBreadcrumb
				boxClassName="shadow"
				data={[
					{
						name: "Checklist",
						url: "/admin/checklist",
					},
					{
						name: "Extracts",
						url: `/admin/extracts/${yearId}`,
					},
					{
						name: "Quick View",
						url: "/",
					},
				]}>
				<div className="d-flex align-items-center gap-2">
					<Link
						to={"#"}
						target="_blank"
						className="btn btn-outline-blue btn-extra_small">
						Help Documentation
					</Link>
				</div>
			</CustomBreadcrumb>
			<Container className="mt-3">
				<Card>
					<CardBody>
						<CardTitle title={"Year 2024 Extracts"} />
						<Row>
							<Col md={12} lg={6} className="mt-2 d-flex flex-column gap-2">
								<RadioBox
									id={"showOnReportsyes"}
									name={"showOnReports"}
									value={true}
									label={"All selections for this year level"}
									size={"lg"}
									// checked={values?.showOnReports}
									// onChange={event => {
									// 	onChangeRadio(event, "showOnReports");
									// }}
								/>
								<RadioBox
									id={"showOnReportsyes"}
									name={"showOnReports"}
									value={true}
									label={"All instances of a selection"}
									size={"lg"}
									// checked={values?.showOnReports}
									// onChange={event => {
									// 	onChangeRadio(event, "showOnReports");
									// }}
								/>
								<RadioBox
									id={"showOnReportsyes"}
									name={"All selections for a student"}
									value={true}
									label={"Individual"}
									size={"lg"}
									// checked={values?.showOnReports}
									// onChange={event => {
									// 	onChangeRadio(event, "showOnReports");
									// }}
								/>
							</Col>
							<Col md={12} lg={6} className="mt-2 d-flex flex-column gap-2">
								<CheckBox
									// value={isOn}
									// onChange={event => {
									// 	setIsOn(event.target.checked);
									// }}
									label={"Show Fees"}
									size={"lg"}
								/>
								<CheckBox
									// value={isOn}
									// onChange={event => {
									// 	setIsOn(event.target.checked);
									// }}
									label={"Show Student code"}
									size={"lg"}
								/>
								<CheckBox
									// value={isOn}
									// onChange={event => {
									// 	setIsOn(event.target.checked);
									// }}
									label={"Show Selection Code"}
									size={"lg"}
								/>
							</Col>
						</Row>
					</CardBody>
				</Card>
			</Container>
		</>
	);
}

export default QuickView;
