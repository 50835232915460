import React from "react";
import { Form } from "react-bootstrap";

function IconInput({
	id,
	onChange,
	boxClasname,
	inputClassName,
	icon,
	value,
	label,
	type = "text",
	placeholder,
	onKeyDown,
	onFocus,
	onBlur,
	size,
	required,
}) {
	return (
		<Form.Group className={`${boxClasname ? boxClasname : ""}`}>
			{label && (
				<Form.Label className="sso_label" htmlFor={id}>
					{label}
					{required && <span className="sso_required">*</span>}
				</Form.Label>
			)}
			<div className="input-icon-box">
				<Form.Control
					required
					size={size}
					onChange={onChange}
					onBlur={onBlur}
					onKeyDown={onKeyDown}
					onFocus={onFocus}
					id={id}
					className={`${inputClassName ? inputClassName : ""} sso_input `}
					value={value}
					type={type}
					placeholder={placeholder}
				/>

				{icon && <span>{icon}</span>}
			</div>
		</Form.Group>
	);
}

export default IconInput;
