import React, { useEffect, useState } from "react";
import { Form, Row } from "react-bootstrap";
import { FaTimes, FaCheck, FaPencilAlt } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";

import { Link } from "react-router-dom";

function ButtonsInput({
	id,
	boxClasname,
	inputClassName,
	value = "",
	label,
	type = "text",
	placeholder,
	onKeyDown,
	onFocus,
	onBlur,
	size,
	small,
	required,
	labelCol = "md-12",
	inputCol = "md-12",
	boxdisabled,
	onSave = () => {},
	onDelete,
	errorMessage,
}) {
	const [disabled, setDisabled] = useState(true);
	const [inputValue, setInputValue] = useState("");

	useEffect(() => {
		if (!inputValue && value) {
			setInputValue(value);
		}
	}, [value, inputValue]);

	const disabledChange = () => {
		setDisabled(!disabled);
	};

	const save = () => {
		setDisabled(true);
	};

	const onChange = event => {
		setInputValue(event.target.value);
	};

	const chanel = () => {
		let event = { target: { value: "" } };
		onChange(event);
		setInputValue(value);
		setDisabled(!disabled);
	};

	return (
		<Form.Group
			className={`${boxClasname ? boxClasname : ""} ${
				boxdisabled ? "boxdisabled" : ""
			} `}>
			<Row className="align-items-center">
				{label && (
					<div className={labelCol}>
						<Form.Label className="sso_label" htmlFor={id}>
							{label}
							{required && <span className="sso_required">*</span>}
						</Form.Label>
					</div>
				)}
				<div className={`${inputCol}`}>
					<div className="position-relative d-flex align-items-center gap-2">
						<Form.Control
							size={size}
							onChange={onChange}
							onBlur={onBlur}
							onKeyDown={onKeyDown}
							onFocus={onFocus}
							id={id}
							className={`${inputClassName ? inputClassName : ""} sso_input  ${
								small ? "input-sm" : ""
							}`}
							disabled={disabled}
							value={inputValue}
							type={type}
							placeholder={placeholder}
						/>
						<div className="d-flex gap-2">
							{!disabled ? (
								<>
									<Link
										to={"#"}
										className="close"
										onClick={() => {
											chanel();
										}}>
										<FaTimes />
									</Link>
									<Link
										to={"#"}
										className="check"
										onClick={() => {
											save();
											onSave(inputValue);
										}}>
										<FaCheck />
									</Link>
								</>
							) : (
								<>
									<Link
										to={"#"}
										className=""
										onClick={() => {
											disabledChange();
										}}>
										<FaPencilAlt />
									</Link>
									{onDelete && (
										<Link
											to={"#"}
											className="close"
											onClick={() => {
												onDelete();
											}}>
											<MdDeleteOutline
												style={{
													height: "20px",
													width: "20px",
												}}
											/>
										</Link>
									)}
								</>
							)}
						</div>
					</div>
					{errorMessage && value && value.length && (
						<small className="text-danger d-block mt-1">{errorMessage}</small>
					)}
				</div>
			</Row>
		</Form.Group>
	);
}

export default ButtonsInput;
