import React, { useRef, useEffect } from 'react';
import { FaTimes } from "react-icons/fa";
import report_content from "./../../../assets/images/report_content.png"
import CheckBox from '../../../components/inputs/CheckBox';



const DocumentRow = ({
  row,
  rowIndex,
  onContentChange,
  onInputChange,
  onAddNewLine,
  onRemoveEmptyRow,
  onInputFocus,
  reportData,
  removeVar,
  setReportData,
}) => {

  const inputRefs = useRef([]);

  useEffect(() => {
    Array.isArray(row.contents) && row.contents.forEach((item, index) => {
      updateInputWidth(index, item.content);
    });
  }, [row.contents]);

  const handleInputChange = (index, event) => {
    const newValue = event.target.value;
    onInputChange(rowIndex, index, newValue);
    updateInputWidth(index, newValue);
  };

  const handleKeyDown = (index, event, rowIndex) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      const input = event.target;
      const cursorPosition = input.selectionStart;
      const content = input.value;

      const beforeCursor = content.substring(0, cursorPosition);
      const afterCursor = content.substring(cursorPosition);

      onContentChange(rowIndex, index, beforeCursor).then(response => {
        if (response.data) {
          if (afterCursor.length > 0) {
            const newContent = { isVariable: false, content: afterCursor, id: 0, isEditable: true, };
            onAddNewLine(response.data, rowIndex, index, newContent);
          } else {
            onAddNewLine(response.data, rowIndex, index);
          }
        }
      });
    } else if (event.key === 'Backspace' && row.contents.length === 1 && row.contents[0].content === '') {
      event.preventDefault();
      onRemoveEmptyRow(rowIndex);
    } else if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {

      event.preventDefault();
      let contents = [];
      if (event.key === 'ArrowUp') {
        contents = reportData[rowIndex - 1]?.contents;
      } else {
        contents = reportData[rowIndex + 1]?.contents;
      }
      if (contents && contents.length) {
        const result = contents.reduce((acc, item, index) => {
          if (!item.isVariable) {
            acc = { item, index };
          }
          return acc;
        }, null);

        if (result) {
          if (typeof result.index === "number") {
            if (event.key === 'ArrowUp') {
              onInputFocus(`${rowIndex - 1}_${result.index}`)
            } else {
              onInputFocus(`${rowIndex + 1}_${result.index}`)
            }
          }
        }
      }
    }

  };

  const updateInputWidth = (index, value) => {
    const inputElement = inputRefs.current[index];
    if (inputElement) {
      const span = document.createElement('span');
      span.style.position = 'absolute';
      span.style.visibility = 'hidden';
      span.style.whiteSpace = 'pre';
      span.style.font = window.getComputedStyle(inputElement).font;
      span.textContent = value;

      document.body.appendChild(span);
      const width = span.getBoundingClientRect().width + 2;
      document.body.removeChild(span);

      inputElement.style.width = `${width}px`;
    }
  };

  const onCheckboxChange = (event, rowIndex) => {
    let reportDataCopy = [...reportData];
    let currentRow = reportDataCopy[rowIndex];
    currentRow.isChecked = event.target.checked;
    reportDataCopy[rowIndex] = currentRow;
    setReportData(reportDataCopy);
  };

  return (
    <div className='d-flex align-items-center flex-wrap'>

      {
        typeof row.isChecked === "boolean" && row.contentType !== 2 && row.contentType !== 3 ?
          <CheckBox size={"sm"} checkClassName={"inline-block"} checked={row.isChecked} onChange={(event) => onCheckboxChange(event, rowIndex)} />
          : null
      }
      {
        row.contentType === 2 || row.contentType === 3 ? null :

          row.contentType === 4 ?
            <div className='h-100 w-100'>
              <hr />
              <div className='report_conent_text' style={{ backgroundImage: `url(${report_content})` }} />
              <hr />
            </div>
            :
            Array.isArray(row.contents) && row.contents.map((item, index) => (
              item.isVariable
                ? <span key={index} className="variable-block text-nowrap">
                  {item.content}
                  <FaTimes className='ms-2' cursor="pointer" onClick={() => removeVar(rowIndex, index)} />
                </span>
                : <input
                  key={index}
                  ref={el => inputRefs.current[index] = el}
                  id={`${rowIndex}_${index}`}
                  type="text"
                  className='px-0 py-2'
                  value={item.content}
                  style={{ width: `${Math.max(item.content.length * 10, 20)}px`, minWidth: "15px" }}
                  onChange={(event) => handleInputChange(index, event)}
                  onKeyDown={(event) => handleKeyDown(index, event, rowIndex)}
                />
            ))
      }
    </div>
  );
};

export default DocumentRow