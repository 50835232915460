const Switch = ({
	isOn = true,
	handleToggle = () => {},
	id,
	onChange,
	boxClasname,
	inputClassName,
	value,
	label,
	onKeyDown,
	onFocus,
	onBlur,
	colorOne = "#5cb85c",
	colorTwo = "#bac3d0",
	on = "ON",
	off = "OFF",
	labelClassname,
	size = "",
	style = {},
}) => {
	return (
		<div className={`${boxClasname ? boxClasname : ""}`}>
			{label && (
				<label className="input-label sso_label" htmlFor={id}>
					{label}
				</label>
			)}

			<input
				checked={value ? true : false}
				onChange={onChange}
				onKeyDown={onKeyDown}
				onFocus={onFocus}
				onBlur={onBlur}
				value={value}
				className={`switch-checkbox ${inputClassName ? inputClassName : ""}`}
				id={id}
				type="checkbox"
			/>
			<label
				className={`switch-label ${labelClassname ? labelClassname : ""} ${
					size ? size : ""
				}`}
				htmlFor={id}
				style={style}>
				<div
					style={{ backgroundColor: value ? colorOne : colorTwo }}
					className={`switch-button d-flex justify-content-center d-flex align-items-center`}>
					{value ? <span>{on}</span> : <span>{off}</span>}
				</div>
				{/* <div className=" d-flex justify-content-between d-flex align-items-center w-100 p-2">
					{" "}
				</div> */}
			</label>
		</div>
	);
};

export default Switch;
