import React, { useEffect, useState } from "react";
import {
	Card,
	Container,
	Dropdown,
	DropdownMenu,
	OverlayTrigger,
	Popover,
	Tooltip,
} from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import { FaGlobeAmericas } from "react-icons/fa";
import { IoMdMenu } from "react-icons/io";
import { FaPowerOff } from "react-icons/fa6";
import useWindowSize from "../../hooks/useWindowSize";
import { IoMdArrowDropdown } from "react-icons/io";

export default function AdminHeader() {
	const [showDropdown, setShowDropdown] = useState(false);
	const [showLanguageDropdown, setShowLanguageDropdown] = useState(false);
	const [isShowMobileNav, setIsShowMobileNav] = useState(false);
	const windowSize = useWindowSize();

	useEffect(() => {
		if (windowSize.width > 991) {
			setIsShowMobileNav(false);
		}
	}, [windowSize]);

	const scrolTop = () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	};

	const getMenu = cb => {
		return (
			<ul className="h-100">
				<li className="h-100 d-flex align-items-center">
					<NavLink
						to="/schools"
						className={({ isActive }) => (isActive ? "active" : "")}
						onClick={() => {
							cb && cb(false);
						}}>
						Schools
					</NavLink>
				</li>
				{/* <li className='h-100 d-flex align-items-center'>
        <NavLink
          to="/enterprise"
          onClick={() => { cb && cb(false); }}
        >
          Enterprise
        </NavLink>
      </li> */}
				<li className="h-100 d-flex align-items-center">
					<NavLink
						to="/help"
						onClick={() => {
							cb && cb(false);
						}}>
						Help
					</NavLink>
				</li>
				<li className="h-100 d-flex align-items-center">
					<NavLink
						to="/translate"
						onClick={() => {
							cb && cb(false);
						}}>
						Translate
					</NavLink>
				</li>
			</ul>
		);
	};

	return (
		<div className="admin-header client-header header">
			{/* kaxvac lini paymanic u routingic tvyal blocky */}
			{/* <div className="page-header-top">
				<Container className="h-100 d-flex justify-content-between align-items-center flex-wrap">
					<Link to="#" className="d-none d-lg-block">
						<img
							className="logo-default pull-right client-logo "
							src="https://client-test.subjectselectiononline.com.au/public/images/sso_new_logo.png"
							alt="logo"
						/>
					</Link>
					<Link to="#">
						<img
							className="logo-default pull-right school_banner_logo "
							src="https://client-test.subjectselectiononline.com.au/public/images/school/258/20240627122319.jpg"
							alt="logo"
						/>
					</Link>
				</Container>
			</div> */}
			{/*  */}

			<div className="page-header-menu">
				<Container className="h-100 d-flex justify-content-between align-items-center">
					<div className="d-block d-lg-none">
						<ul className="h-100">
							<li className="h-100 d-flex align-items-center">
								<Link to="#" className="p-0">
									<img
										className="logo-default pull-right  admin-logo"
										src="https://admin-test.subjectselectiononline.com.au/public/images/sso_new_logo.png"
										alt="logo"
									/>
								</Link>
							</li>
						</ul>
					</div>
					<div className="hor-menu h-100">{getMenu()}</div>
					<div className="top-menu h-100">
						<ul className="h-100">
							<OverlayTrigger
								placement="bottom"
								show={showLanguageDropdown}
								delay={{ show: 1, hide: 1 }}
								onToggle={nextShow => setShowLanguageDropdown(nextShow)}
								overlay={
									<Popover
										onMouseLeave={() => setShowLanguageDropdown(false)}
										onMouseOver={() => setShowLanguageDropdown(true)}
										className="sso_dropdown">
										<Popover.Body className="">
											<ul>
												<li>
													<Link to={"#"}>Englsh</Link>
												</li>
												<li>
													<Link to={"#"}>Russian</Link>
												</li>
												<li>
													<Link to={"#"}>French</Link>
												</li>
											</ul>
										</Popover.Body>
									</Popover>
								}>
								<Link
									to={"#"}
									// onMouseLeave={() => setShowLanguageDropdown(false)}
									onMouseOver={() => setShowLanguageDropdown(true)}>
									<FaGlobeAmericas size={20} color="#ffffff" />
								</Link>
							</OverlayTrigger>

							{/* <Dropdown
								onMouseLeave={() => setShowLanguageDropdown(false)}
								onMouseOver={() => setShowLanguageDropdown(true)}>
								<li className="h-100 d-flex align-items-center">
									<Link to="#">
										<FaGlobeAmericas size={20} color="#ffffff" />
									</Link>
								</li>
								<Dropdown.Menu
									show={showLanguageDropdown}
									className={`p-0 ${showLanguageDropdown ? "show" : ""}`}
									style={{ borderRadius: "4px" }}
									onMouseLeave={() => setShowLanguageDropdown(false)}>
									<Dropdown.Item
										className="py-2 px-1"
										onClick={() => setShowLanguageDropdown(false)}>
										<div
											className="px-3"
											style={{ fontSize: "14px", fontWeight: "300" }}>
											Englsh
										</div>
									</Dropdown.Item>
									<Dropdown.Item
										className="py-2 px-1"
										onClick={() => setShowLanguageDropdown(false)}>
										<div
											className="px-3"
											style={{ fontSize: "14px", fontWeight: "300" }}>
											Russian
										</div>
									</Dropdown.Item>
									<Dropdown.Item
										className="py-2 px-1"
										onClick={() => setShowLanguageDropdown(false)}>
										<div
											className="px-3"
											style={{ fontSize: "14px", fontWeight: "300" }}>
											French
										</div>
									</Dropdown.Item>
									<Dropdown.Item
										className="py-2 px-1"
										onClick={() => setShowLanguageDropdown(false)}>
										<div
											className="px-3"
											style={{ fontSize: "14px", fontWeight: "300" }}>
											Arabic
										</div>
									</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown> */}

							<Dropdown
								onMouseLeave={() => setShowDropdown(false)}
								onMouseOver={() => setShowDropdown(true)}>
								<li className="h-100 d-flex align-items-center">
									<Link className="pr-0" to="#">
										SSO-Admin <IoMdArrowDropdown />
									</Link>
								</li>
								<Dropdown.Menu
									show={showDropdown}
									className={`p-0 ${showDropdown ? "show" : ""}`}
									style={{ borderRadius: "4px" }}
									onMouseLeave={() => setShowDropdown(false)}>
									<Dropdown.Item
										className="py-2 px-1"
										onClick={() => setShowDropdown(false)}>
										<div
											className="px-3"
											style={{ fontSize: "14px", fontWeight: "300" }}>
											{" "}
											<span>
												<FaPowerOff size={14} color="red" />{" "}
											</span>
											Log Out
										</div>
									</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>

							<li className="h-100 align-items-center d-none d-lg-flex">
								<Link>
									<img
										className="logo-default pull-right admin-logo "
										src="https://admin-test.subjectselectiononline.com.au/public/images/sso_new_logo.png"
										alt="logo"
									/>
								</Link>
							</li>
							<li
								className="h-100 align-items-center d-flex d-lg-none"
								onClick={() => {
									scrolTop();
									setIsShowMobileNav(!isShowMobileNav);
								}}>
								<Link to="#">
									<div>
										<IoMdMenu size={27} color="#8C99A3" />
									</div>
								</Link>
							</li>
						</ul>
					</div>
				</Container>
			</div>
			<div className={`mobile-nav ${isShowMobileNav ? "mobile-nav-show" : ""}`}>
				<Container>
					<Card>{getMenu(setIsShowMobileNav)}</Card>
				</Container>
			</div>
		</div>
	);
}
