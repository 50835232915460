import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'react-bootstrap';
import report_content from "./../../../assets/images/report_content.png";
import moment from 'moment/moment';

export default function ReportModal({ reportData, onHide }) {

  const drawReportDataTopContent = (row, rowIndex) => {
    switch (row.contentType) {
      case 2:
        return <div key={rowIndex} className={row.position}>
          {row.contents && row.contents.length ? (
            <h2 className="inline-block text-justify fw-500 fs-30">
              {row.contents[0].content}
            </h2>
          ) : null}
        </div>
      case 3:
        return <div key={rowIndex} className={row.position}>
          <div className="thumbnail">
            <img width="100" src='https://client-test.subjectselectiononline.com.au/public/images/school/258/20240807094509.jpg' />
            {/* todo */}
          </div>
        </div>

      default:
        return null;
    }
  };

  const drawReportData = (row) => {
    switch (row.contentType) {
      case 1:
        return (
          <div>
            {row.contents && row.contents.length ? (
              <p style={{ margin: "20px 0" }}>
                {row.contents.map((item, index) => {
                  if (item.content === "tr_signature_date_var") {
                    return <div className='signature d-flex gap-3'>
                      <span>Signature:<span className='line'></span></span>
                      <span>Date:<span className='line'></span></span>
                    </div>
                  } else if (item.content === "tr_date_time_var") {
                    return <span>
                      {moment().format("MMMM D, YYYY h:mm A")}
                    </span>
                  }
                  else {
                    return <span key={index}>
                      {item.isVariable ? `[${item.content}]` : item.content}
                    </span>
                  }
                })}
              </p>
            ) : null}
          </div>
        );
      case 4:
        return (
          <div className='h-100 w-100'>
            <hr />
            <div className='report_conent_text' style={{ backgroundImage: `url(${report_content})` }} />
            <hr />
          </div>
        );

      default:
        return null;
    }
  };



  return (
    <Modal
      show={reportData && reportData.length ? true : false}
      size="xl"
      centered
      className='document-view-modal'
      scrollable
      onHide={onHide}
    >
      <ModalHeader closeButton className="md-toolbar-tools">
        <h2>Report</h2>
      </ModalHeader>
      {reportData && reportData.length ? (
        <ModalBody className="mb-3 p-0">
          <div className="report_page size_A4 m-0" style={{ minWidth: "23cm" }}>
            <div>

              <div className='document-top-content'>
                {reportData.map((row, rowIndex) => (
                  drawReportDataTopContent(row, rowIndex)
                ))}
              </div>

              {reportData.map((row, rowIndex) => (
                <div key={rowIndex}>{drawReportData(row)}</div>
              ))}
            </div>
          </div>
        </ModalBody>
      ) : null}
    </Modal>
  );
}
