import React, { useState } from "react";
import { Button, Card, CardBody, Col, Form, Row } from "react-bootstrap";
import CardTitle from "../../../components/cardTitle/CardTitle";
import RadioBox from "../../../components/inputs/RadioBox";
import Switch from "../../../components/inputs/Switch";
import MainService from "../../../helpers/services/mainService";
import { ApiService } from "../../../helpers/services/apiSrevice";
import AlertService from "../../../helpers/services/alertService";

function StudentExperience({ values, setValues }) {
	const [isChange, setIsChange] = useState(false);

	const onSubmit = event => {
		event.preventDefault();
		if (!isChange) return false;
		const formData = new FormData();
		Object.entries(values).forEach(([key, values]) => {
			formData.append(key, values);
		});
		MainService.callApi({
			api: ApiService.updateSchoolBrandingSettings,
			data: formData,
			cb: response => {
				if (response && response.data) {
					setValues(values => ({ ...values, ...response.data }));
				}
				if (response && response.success === true) {
					AlertService.alert("success", "Data seved");
					setIsChange(false);
				}
			},
		});
	};

	const onChange = (event, fieldName) => {
		setIsChange(true);
		if (
			fieldName === "studentExpDisplayCourseCode" &&
			event.target.value === "true"
		) {
			setValues(values => ({
				...values,
				[fieldName]: true,
			}));
		} else if (
			fieldName === "studentExpDisplayCourseCode" &&
			event.target.value === "false"
		) {
			setValues(values => ({
				...values,
				[fieldName]: false,
			}));
		} else {
			setValues(values => ({
				...values,
				[fieldName]: +event.target.value
					? +event.target.value
					: event.target.value,
			}));
		}
	};

	const onCheckChange = (event, fieldName) => {
		setIsChange(true);
		setValues(values => ({
			...values,
			[fieldName]: event.target.checked,
		}));
	};

	return (
		<Card style={{ minHeight: 478 }}>
			<CardBody>
				<CardTitle
					title="STUDENT EXPERIENCE"
					titleClassname={"color_bleck fw-700 uppercase"}></CardTitle>
				<Row>
					<Col md={12} lg={12}>
						<form className="d-flex flex-column gap-3" onSubmit={onSubmit}>
							<Row className="align-items-center">
								<Col md={12} lg={6}>
									<label className="sso_label">
										Show selection course code in student page?
									</label>
								</Col>
								<Col md={12} lg={6} className="d-flex gap-3">
									<RadioBox
										id={"show_selection_course_yes"}
										name={"studentExpDisplayCourseCode"}
										value={true}
										label={"yes"}
										checked={values.studentExpDisplayCourseCode}
										onChange={event => {
											onChange(event, "studentExpDisplayCourseCode");
										}}
									/>
									<RadioBox
										id={"show_selection_course_no"}
										name={"studentExpDisplayCourseCode"}
										value={false}
										label={"no"}
										checked={!values.studentExpDisplayCourseCode}
										onChange={event => {
											onChange(event, "studentExpDisplayCourseCode");
										}}
									/>
								</Col>
							</Row>
							<Row className="align-items-center">
								<Col md={12} lg={6}>
									<label className="sso_label">
										Display costs and units as:
									</label>
								</Col>
								<Col md={12} lg={6} className="d-flex gap-3">
									<RadioBox
										id={"display_costs_icon"}
										name={"studentExpCostUnitDisplayMode"}
										value={1}
										label={"Icon"}
										checked={values.studentExpCostUnitDisplayMode === 1}
										onChange={event => {
											onChange(event, "studentExpCostUnitDisplayMode");
										}}
									/>
									<RadioBox
										id={"display_costs_text"}
										name={"studentExpCostUnitDisplayMode"}
										value={2}
										label={"Text"}
										checked={values.studentExpCostUnitDisplayMode === 2}
										onChange={event => {
											onChange(event, "studentExpCostUnitDisplayMode");
										}}
									/>
								</Col>
							</Row>
							<Row className="">
								<Col md={12} lg={6}>
									<Form.Label htmlFor="allow_students" className="sso_label">
										Only allow students to proceed to the next section, once the
										current one is completed
									</Form.Label>
								</Col>
								<Col md={12} lg={6} className="d-flex gap-3">
									<Switch
										id={"studentExpRequireSectionCompletion"}
										checked={values.studentExpRequireSectionCompletion}
										onChange={event => {
											onCheckChange(
												event,
												"studentExpRequireSectionCompletion",
											);
										}}
										value={values.studentExpRequireSectionCompletion || ""}
									/>
								</Col>
							</Row>
							<div className="d-flex justify-content-end">
								<Button
									type="submit"
									disabled={!isChange}
									className="btn-primary ">
									Save
								</Button>
							</div>
						</form>
					</Col>
				</Row>
			</CardBody>
		</Card>
	);
}

export default StudentExperience;
