import React, { useContext, useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import TextImage from "./TextImage";
import SettingsSelection from "./SettingsSelection";
import SettingsInstruction from "./SettingsInstruction";
import SettingsSection from "./SettingsSection";
import ReOrder from "./ReOrder";
import Delete from "./Delete";
import { SelectionProcessContext } from "../SelectionProcess";
import { NORMAL_KEY } from "../../../helpers/constants/constants";
import { useStepContext } from "@mui/material";
import { number } from "yup";
import AddFromMatrix from "./AddFromMatrix";
import AddOneOff from "./AddOneOff";
import AddTextInput from "./AddTextInput";
import { useParams } from "react-router-dom";
import MainService from "../../../helpers/services/mainService";
import { ApiService } from "../../../helpers/services/apiSrevice";

const SelectionCreateTabs = () => {
	const [activTabe, setActivTabe] = useState("addFromMatrix");

	const changeTabe = key => {
		setActivTabe(key);
	};

	return (
		<Tabs
			activeKey={activTabe}
			onSelect={k => changeTabe(k)}
			className="sso-tabs">
			<Tab className="sso-tab" eventKey="addFromMatrix" title="Add From Matrix">
				<AddFromMatrix />
			</Tab>
			<Tab className="sso-tab" eventKey="addOneOff" title="Add One Off">
				<AddOneOff />
			</Tab>
			<Tab
				className="sso-tab"
				eventKey={"addTextInput"}
				title={"Add Text Input"}>
				<AddTextInput />
			</Tab>
		</Tabs>
	);
};

export default SelectionCreateTabs;
