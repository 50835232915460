import React, { useState } from "react";
import CustomBreadcrumb from "../../components/breadcrumbs/Breadcrumb";
import { Link } from "react-router-dom";
import {
	ButtonGroup,
	Card,
	CardBody,
	Col,
	Container,
	OverlayTrigger,
	Popover,
	Row,
	Table,
	ToggleButton,
} from "react-bootstrap";
import CardTitle from "../../components/cardTitle/CardTitle";
import {
	DatatableWrapper,
	Filter,
	TableBody,
	TableHeader,
} from "react-bs-datatable";
import { useMediaQuery } from "@mui/material";
import { FaCheck, FaDownload } from "react-icons/fa";
import { IoCloseSharp } from "react-icons/io5";
import { FaRegTrashCan } from "react-icons/fa6";
import { FiPlus } from "react-icons/fi";
import ReactSelectOption from "../../components/inputs/ReactSelectOption";
import CheckBox from "../../components/inputs/CheckBox";
import { resultSchema } from "../../helpers/validation/validations";
import * as Yup from "yup";
import Input from "../../components/inputs/Input";
import Popup from "../../components/modals/Popup";

function Appointments(props) {
	const [data, setData] = useState([]);
	const [showPopover, setShowPopover] = useState(false);
	const [deleteIds, setDeleteIds] = useState(false);
	const matches575 = useMediaQuery("(max-width:575px)");
	const [show, setShow] = useState(false);
	const [errors, setErrors] = useState({});

	const [values, setValues] = useState({});

	const getValidationErrors = async () => {
		try {
			await resultSchema.validate(values, {
				abortEarly: false,
			});
			setErrors({});
		} catch (err) {
			if (err instanceof Yup.ValidationError) {
				const validationErrors = err.inner.reduce((acc, curr) => {
					acc[curr.path] = curr.message;
					return acc;
				}, {});
				setErrors(validationErrors);
			}
		}
	};

	const columns = [
		{
			prop: "select",
			title: (
				<CheckBox
					size={"lg"}
					checked={data?.length && data?.length === deleteIds?.length}
					onChange={event => {
						if (event.target.checked) {
							setDeleteIds(data?.map(item => item.id));
						} else {
							setDeleteIds([]);
						}
					}}
				/>
			),
			cell: row => (
				<CheckBox
					checked={deleteIds?.includes(row.id)}
					onChange={event => {
						if (event.target.checked) {
							setDeleteIds(ids => {
								return [...ids, row.id];
							});
						} else {
							setDeleteIds(ids => {
								return ids.filter(el => el !== row.id);
							});
						}
					}}
				/>
			),
		},
		{
			prop: "courseCounsellor",
			title: "Course Counsellor",
			isFilterable: true,
			isSortable: true,
			cell: row => (
				<div>
					{!row.isrequired && (
						<span className="coustem-label d-inline-block mx-2">Fix</span>
					)}
					<p className="d-inline-block">{row.code}</p>
				</div>
			),
		},

		{
			prop: "date",
			title: "Date",
			isFilterable: true,
			isSortable: true,
			cell: row => <div>{row.name}</div>,
		},
		{
			prop: "time",
			title: "Time",
			isFilterable: true,
			isSortable: true,
			cell: row => <div>{/* <ReactSelectOption /> */}</div>,
		},
		{
			prop: "duration",
			title: "Duration",
			isFilterable: true,
			isSortable: true,
			// isSortable: true,
			cell: row => (
				<div className="d-flex">
					{/* <CheckBox checked={row.isrequired} /> */}
				</div>
			),
		},
		{
			prop: "year",
			title: "Year",
			isFilterable: true,
			isSortable: true,
			// isSortable: true,
			cell: row => (
				<div className="d-flex">
					{/* <CheckBox checked={row.isrequired} /> */}
				</div>
			),
		},
		{
			prop: "Student",
			title: "student",
			isFilterable: true,
			isSortable: true,
			// isSortable: true,
			cell: row => (
				<div className="d-flex">
					{/* <CheckBox checked={row.isrequired} /> */}
				</div>
			),
		},
	];

	const onClose = () => {
		setShow(false);
		setValues({
			schoolId: 1,
			studentCode: "",
			preReqCode: "",
			preReqResult: "",
		});
	};

	const onOpen = item => {
		setShow(true);
	};

	const onChange = (event, fieldName, errorfieldName) => {
		let regex = /^\S*$/;
		if (fieldName === "studentCode") {
			if (!regex.test(event.target.value)) {
				return false;
			}
		}
		setValues(values => ({
			...values,
			[fieldName]: event.target.value,
		}));
	};

	const onSelectOptionChange = (item, fieldName) => {
		if (!item) return false;
		setValues(values => ({
			...values,
			[fieldName]: item.value,
		}));
	};

	const onSave = () => {};

	return (
		<>
			<Popup
				show={show}
				title={"Add new time slots"}
				size="lg"
				handleClose={onClose}
				action={onSave}
				footerChildren={
					<button
						className="btn btn-gren  btn-sm"
						disabled={true}
						onClick={() => {}}>
						Save and Add More
					</button>
				}
				disabled={!!Object.keys(errors).length}
				buttonTitle="Save">
				<Row className="gap-3">
					<Col
						md={12}
						className="d-flex gap-1 flex-column  align-items-center flex-lg-row">
						<label htmlFor="courseCounsellor" className="w-lg-50 w-100 ">
							Course Counsellor
						</label>
						<div className="d-flex align-items-center gap-3 flex-wrap w-lg-50 w-100">
							<ReactSelectOption />
						</div>
					</Col>
					<Col
						md={12}
						className="d-flex gap-1 flex-column  align-items-center flex-lg-row">
						<label htmlFor="courseCounsellor" className="w-lg-50 w-100 ">
							Students Entering Year
						</label>
						<div className="d-flex align-items-center gap-3 flex-wrap w-lg-50 w-100">
							<ReactSelectOption />
						</div>
					</Col>
					<Col
						md={12}
						className="d-flex gap-1 flex-column  align-items-center flex-lg-row">
						<label htmlFor="date" className="w-lg-50 w-100 ">
							Date
						</label>
						<div className="d-flex align-items-center gap-3 flex-wrap w-lg-50 w-100">
							<Input
								placeholder={"Year"}
								id={"date"}
								type="date"
								value={values.date || ""}
								// errorMessage={
								// 	errors && errors[`${shortKey}.bPointMerchantNumber`]
								// }
								onChange={event => {
									onChange(event, "date");
								}}
							/>
						</div>
					</Col>
					<Col
						md={12}
						className="d-flex gap-1 flex-column  align-items-center flex-lg-row">
						<label htmlFor="startTime" className="w-lg-50 w-100 ">
							Start Time:
						</label>
						<div className="d-flex align-items-center gap-3 flex-wrap w-lg-50 w-100">
							<Input
								placeholder={"Year"}
								id={"startTime"}
								type="time"
								value={values.startTime || ""}
								// errorMessage={
								// 	errors && errors[`${shortKey}.bPointMerchantNumber`]
								// }
								onChange={event => {
									onChange(event, "startTime");
								}}
							/>
						</div>
					</Col>
					<Col
						md={12}
						className="d-flex gap-1 flex-column  align-items-center flex-lg-row">
						<label htmlFor="startTime" className="w-lg-50 w-100 ">
							End Time:
						</label>
						<div className="d-flex align-items-center gap-3 flex-wrap w-lg-50 w-100">
							<Input
								placeholder={"Year"}
								id={"endTime"}
								type="time"
								value={values.endTime || ""}
								// errorMessage={
								// 	errors && errors[`${shortKey}.bPointMerchantNumber`]
								// }
								onChange={event => {
									onChange(event, "endTime");
								}}
							/>
						</div>
					</Col>
					<Col
						md={12}
						className="d-flex gap-1 flex-column  align-items-center flex-lg-row">
						<label htmlFor="startTime" className="w-lg-50 w-100 ">
							Duration:
						</label>
						<div className="d-flex align-items-center gap-1 w-lg-50 w-100">
							<Input
								placeholder={"duration"}
								id={"duration"}
								value={values.duration || ""}
								// errorMessage={
								// 	errors && errors[`${shortKey}.bPointMerchantNumber`]
								// }
								onChange={event => {
									onChange(event, "duration");
								}}
							/>
							<span>minutes</span>
						</div>
					</Col>
					<Col
						md={12}
						className="d-flex gap-1 flex-column  align-items-center flex-lg-row">
						<label htmlFor="startTime" className="w-lg-50 w-100 ">
							Gap between appointments:
						</label>
						<div className="d-flex align-items-center gap-1 w-lg-50 w-100">
							<Input
								placeholder={"duration"}
								id={"duration"}
								value={values.duration || ""}
								// errorMessage={
								// 	errors && errors[`${shortKey}.bPointMerchantNumber`]
								// }
								onChange={event => {
									onChange(event, "duration");
								}}
							/>
							<span>minutes</span>
						</div>
					</Col>
				</Row>
			</Popup>
			<CustomBreadcrumb
				boxClassName="shadow"
				data={[{ name: "Appointments", url: "/" }]}>
				<div className="d-flex align-items-center gap-2">
					<Link
						to={"#"}
						target="_blank"
						className="btn btn-outline-blue btn-extra_small">
						Help Documentation
					</Link>
				</div>
			</CustomBreadcrumb>
			<Container className="mt-3">
				<Card>
					<CardBody>
						<CardTitle title={"Appointments"} />
						<DatatableWrapper body={data} headers={columns}>
							<Row className="my-3 justify-content-between gap-3">
								<Col
									md={5}
									className="d-flex gap-2 justify-content-end flex-md-nowrap flex-wrap">
									<Filter
										placeholder="search"
										classes={{
											input: "sso_input h_34",
											clearButton: "clearButton",
										}}
									/>
									<ReactSelectOption />
								</Col>
								<Col
									md={5}
									className="d-flex gap-2 justify-content-end flex-md-nowrap flex-wrap">
									{deleteIds && !!deleteIds.length && (
										<OverlayTrigger
											// trigger="focus"
											trigger="click"
											rootClose
											placement={matches575 ? "top" : "left"}
											show={showPopover}
											// onToggle={handleToggle}
											overlay={
												<Popover id="popover-positioned-bottom">
													<div className="are_you_shureo_popover_container">
														<h3>Are you sure ?</h3>
														<div className="are_you_shureo_popover_button">
															<ButtonGroup className="mb-2">
																<ToggleButton
																	id={`radio-${1}`}
																	type="radio"
																	name="are_you_shureo_popover"
																	className="custom-toggle-button btn-success"
																	value={0}
																	onChange={e => {
																		// deleteIds();
																	}}>
																	<FaCheck />
																	Yes
																</ToggleButton>
																<ToggleButton
																	id={`radio-${2}`}
																	type="radio"
																	name="are_you_shureo_popover"
																	className="custom-toggle-button btn-danger"
																	value={1}
																	onChange={e => {
																		setShowPopover(false);
																		setDeleteIds([]);
																	}}>
																	<IoCloseSharp
																		style={{ height: 16, width: 16 }}
																	/>
																	No
																</ToggleButton>
															</ButtonGroup>
														</div>
													</div>
												</Popover>
											}>
											<button
												className={`btn btn-red btn-sm ${
													showPopover ? "disabled" : ""
												}`}
												onClick={e => {
													setShowPopover(true);
												}}>
												<FaRegTrashCan />
												Delete
											</button>
										</OverlayTrigger>
									)}
									<button
										to={"#"}
										target="_blank"
										className="btn btn-outline-btn-default  btn-sm">
										<FaDownload />
										Export as displayeds
									</button>
									<button
										className="btn btn-primary btn-sm"
										onClick={() => {
											onOpen();
										}}>
										<FiPlus />
										Add Time Slots
									</button>
								</Col>
								<Col md={12} className="mb-2">
									<Table className="sso_tabel" responsive bordered>
										<TableHeader />
										<TableBody />
									</Table>
								</Col>
							</Row>
						</DatatableWrapper>
					</CardBody>
				</Card>
			</Container>
		</>
	);
}

export default Appointments;
