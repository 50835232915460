import React, { useState } from "react";
import CustomBreadcrumb from "../../components/breadcrumbs/Breadcrumb";
import { Link, useParams } from "react-router-dom";
import {
	ButtonGroup,
	Card,
	CardBody,
	Col,
	Container,
	OverlayTrigger,
	Row,
	Table,
	ToggleButton,
} from "react-bootstrap";
import CardTitle from "../../components/cardTitle/CardTitle";
import {
	DatatableWrapper,
	Filter,
	TableBody,
	TableHeader,
} from "react-bs-datatable";
import { FiPlus } from "react-icons/fi";
import { FaRegTrashCan } from "react-icons/fa6";
import { Popover, useMediaQuery } from "@mui/material";
import { FaCheck, FaRegEdit } from "react-icons/fa";
import { IoCloseSharp } from "react-icons/io5";
import CheckBox from "../../components/inputs/CheckBox";
import CapacitiesForm from "./components/CapacitiesForm";

function Capacities(props) {
	const { yearId } = useParams();
	const [data, setData] = useState([]);
	const [showPopover, setShowPopover] = useState(false);
	const [deleteIds, setDeleteIds] = useState([]);
	const matches575 = useMediaQuery("(max-width:575px)");
	const [showForm, setShowForm] = useState(false);

	const columns = [
		{
			prop: "select",
			title: (
				<CheckBox
					size={"lg"}
					checked={!!data.length && data?.length === deleteIds?.length}
					onChange={event => {
						if (event.target.checked) {
							setDeleteIds(data?.map(item => item.id));
						} else {
							setDeleteIds([]);
						}
					}}
				/>
			),
			cell: row => (
				<CheckBox
					checked={deleteIds?.includes(row.id)}
					onChange={event => {
						if (event.target.checked) {
							setDeleteIds(ids => {
								return [...ids, row.id];
							});
						} else {
							setDeleteIds(ids => {
								return ids.filter(el => el !== row.id);
							});
						}
					}}
				/>
			),
		},
		{
			title: "Course Code",
			prop: "courseCode",
			isFilterable: true,
			isSortable: true,
			cell: row => <div>{row.courseCode}</div>,
		},
		{
			title: "Course Name",
			prop: "courseName",
			isFilterable: true,
			isSortable: true,
			cell: row => <div>{row.courseName}</div>,
		},
		{
			title: "Limit Type",
			prop: "limitType",
			isFilterable: true,
			isSortable: true,
			cell: row => <div>{row.limitType}</div>,
		},
		{
			title: "Capacity",
			prop: "capacity",
			isFilterable: true,
			isSortable: true,
			cell: row => <div>{row.capacity}</div>,
		},
		{
			title: "Waitlist",
			prop: "waitlist",
			isFilterable: true,
			isSortable: true,
			cell: row => <div>{row.waitlist}</div>,
		},
		{
			title: "Action",
			name: "Edit",
			prop: "button",
			cell: row => (
				<Link
					to={`#`}
					onClick={() => {
						// onOpen(row);
					}}
					className="table_link d-flex align-items-center d-flex ">
					<FaRegEdit /> <span>Edit</span>
				</Link>
			),
		},
	];
	const handleToggle = () => {
		setShowPopover(!showPopover);
	};

	return (
		<>
			<CustomBreadcrumb
				boxClassName="shadow"
				data={[
					{
						name: "Checklist",
						url: "/admin/checklist",
					},
					{
						name: "Selection Process",
						url: `/admin/selection_process/${yearId}`,
					},
					{
						name: "Capacities",
						url: "/",
					},
				]}>
				<div className="d-flex align-items-center gap-2">
					<Link
						to={"#"}
						target="_blank"
						className="btn btn-outline-blue btn-extra_small">
						Help Documentation
					</Link>
				</div>
			</CustomBreadcrumb>
			<Container className="mt-3">
				<Card>
					<CardBody>
						<CardTitle
							title={"Year 2024 Capacities"}
							eventKey={"0"}
							linkNmae={"Show spreadsheet requirements "}
						/>
						<Row className={"my-2"}>
							<Col md={12} className="mb-3">
								<h4 className="font-blue-madison fw-500 fs-18">
									Here you can apply capacity limits for selections that exist
									in your selection process.
								</h4>
							</Col>
							<Col md={12} className="mb-2">
								<div className="information_block danger_block">
									<h4 className="block">
										For each capacity, you will need to decide:
									</h4>
									<ul className="disclosure_closed">
										<li>
											If the capacity applies to each instance individually, or
											a cumulative total
										</li>
										<li>
											If you want to apply it to a single, selected, or all
											instances of that code
										</li>
										<li>Whether waitlists are permitted</li>
									</ul>
								</div>
							</Col>
							<Col md={12} className="mb-3">
								<div className="information_block">
									<ul className="disclosure_closed">
										<li>You can have one capacity rule per selection</li>
										<li>
											You can have different capacities for different instances
											of the same code
										</li>
									</ul>
								</div>
							</Col>
							<Col md={12}>
								<h3 className="fs-24 fw-500">Existing capacity limits</h3>
							</Col>
							<Col md={12}>
								<DatatableWrapper
									body={data}
									headers={columns}
									paginationOptionsProps={{
										initialState: {
											rowsPerPage: 100,
											options: [5, 10, 15, 20],
										},
									}}>
									<Row className="my-3 justify-content-between gap-3">
										<Col md={2} className="">
											<Filter
												placeholder="search"
												classes={{
													input: "input-sm sso_input",
													clearButton: "clearButton",
												}}
											/>
										</Col>
										<Col md={2} className="d-flex gap-2 justify-content-end">
											{!!deleteIds?.length && (
												<OverlayTrigger
													// trigger="focus"
													trigger="click"
													rootClose
													placement={matches575 ? "top" : "left"}
													show={showPopover}
													// onToggle={handleToggle}
													overlay={
														<Popover id="popover-positioned-bottom">
															<div className="are_you_shureo_popover_container">
																<h3>Are you sure ?</h3>
																<div className="are_you_shureo_popover_button">
																	<ButtonGroup className="mb-2">
																		<ToggleButton
																			id={`radio-${1}`}
																			type="radio"
																			name="are_you_shureo_popover"
																			className="custom-toggle-button btn-success"
																			value={0}
																			onChange={
																				e => {
																					// MainService.callApi({
																					// 	api: ApiService.deletResult,
																					// 	data: { data: deleteIds },
																					// 	cb: response => {
																					// 		if (
																					// 			response &&
																					// 			response.success
																					// 		) {
																					// 			setResults(results =>
																					// 				results.filter(
																					// 					el =>
																					// 						!deleteIds.includes(
																					// 							el.id,
																					// 						),
																					// 				),
																					// 			);
																					// 			setDeleteIds([]);
																					// 		}
																					// 	},
																					// });
																				}
																				// setRadioValue(e.currentTarget.value)
																			}>
																			<FaCheck />
																			Yes
																		</ToggleButton>
																		<ToggleButton
																			id={`radio-${2}`}
																			type="radio"
																			name="are_you_shureo_popover"
																			className="custom-toggle-button btn-danger"
																			value={1}
																			onChange={e => {
																				setShowPopover(false);
																			}}>
																			<IoCloseSharp
																				style={{ height: 16, width: 16 }}
																			/>
																			No
																		</ToggleButton>
																	</ButtonGroup>
																</div>
															</div>
														</Popover>
													}>
													<button
														className={`btn btn-red btn-sm ${
															showPopover ? "disabled" : ""
														}`}
														onClick={e => {
															handleToggle();
														}}>
														<FaRegTrashCan />
														Delete
													</button>
												</OverlayTrigger>
											)}
											<button
												className="btn btn-primary btn-sm"
												onClick={() => {
													setShowForm(true);
												}}>
												<FiPlus />
												Add New
											</button>
										</Col>
										<Col md={12} className="mb-2">
											<Table className="sso_tabel" responsive bordered>
												<TableHeader />
												<TableBody />
											</Table>
										</Col>
									</Row>
								</DatatableWrapper>
							</Col>
							{showForm && (
								<Col md={12}>
									<CapacitiesForm />
								</Col>
							)}
						</Row>
					</CardBody>
				</Card>
			</Container>
		</>
	);
}

export default Capacities;
