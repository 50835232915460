import React, { useEffect, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimeClock } from "@mui/x-date-pickers/TimeClock";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import { Button, ModalHeader } from "react-bootstrap";
import { DateCalendar } from "@mui/x-date-pickers";
import { Modal, ModalFooter } from "react-bootstrap";
import { IoClose } from "react-icons/io5";
import { Link } from "react-router-dom";

const DateHourMinuteSelector = ({ open, onClose, value = new Date(), cb }) => {

	const [step, setStep] = useState(1); // Current step: 1 for date, 2 for hour, 3 for minute
	const [selectedDate, setSelectedDate] = useState(dayjs(value)); // Initialize with current date/time

	useEffect(() => {
		setSelectedDate(dayjs(value));
	}, []);

	const handleDateChange = date => {
		setSelectedDate(dayjs(date));
	};

	const handleHourChange = hour => {
		setSelectedDate(selectedDate.hour(hour.hour()));
	};

	const handleMinuteChange = minute => {
		setSelectedDate(selectedDate.minute(minute.minute()));
	};

	const handleCancel = () => {
		if (step === 1) {
			onClose();
		}
		if (step > 1) {
			setStep(step - 1);
		}
	};

	const handleOk = () => {
		console.log(step);

		if (step < 3) {
			setStep(step + 1);
		} else {
			// Handle final confirmation action here (e.g., save data, close modal, etc.)
			// console.log("Date:", selectedDate.format("YYYY-MM-DD"));
			// console.log("Hour:", selectedDate.format("HH:mm"));
			// console.log("Minute:", selectedDate.format("HH:mm"));
			// Reset the step if needed
			if (cb) {
				cb(selectedDate);
			}
			onClose();
			setStep(1);
		}
	};

	return (
		<Modal show={open} size="sm" centered onHide={onClose}>
			<ModalHeader style={{ backgroundColor: "#8BC34A", padding: "0" }}>
				<div className="d-flex flex-column justify-content-center align-items-center w-100 position-relative">
					<div
						className=" w-100 text-center text-white"
						style={{ backgroundColor: "#689F38", padding: "3px" }}>
						{selectedDate.format("dddd")}
						<Link
							className="date_hour_minute_selector_close"
							to={"#"}
							onClick={() => {
								onClose();
							}}>
							<IoClose size={18} />
						</Link>
					</div>
					<div
						className="text-white text-uppercase"
						style={{ fontSize: "1.5rem" }}>
						{selectedDate.format("MMM")}
					</div>
					<div
						className="text-white"
						style={{ fontSize: "3rem", lineHeight: "40px" }}>
						{selectedDate.format("DD")}
					</div>
					<div className="text-white" style={{ fontSize: "1.5rem" }}>
						{selectedDate.format("YYYY")}
					</div>
				</div>
			</ModalHeader>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<Box display="flex" flexDirection="column" alignItems="center">
					{step === 1 && (
						<DateCalendar
							value={selectedDate}
							onChange={handleDateChange}
							sx={{
								margin: "0",
								padding: "0",
								maxWidth: "100%",
								"& .Mui-selected": {
									bgcolor: "#8BC34A !important",
								},

								// '& .MuiPickersDay-today': {
								//   border: 'none !important',
								// }
							}}
						/>
					)}
					{step === 2 && (
						<div>
							<div className="text-center mt-2">
								{selectedDate.format("HH:mm")}
							</div>
							<TimeClock
								value={selectedDate}
								onChange={handleHourChange}
								views={["hours"]}
								ampm={false} // 24-hour format
								sx={{
									margin: "0",
									padding: "0",
									"& .MuiClockPointer-root, .MuiClock-pin, .Mui-selected, .MuiClockPointer-thumb":
									{
										bgcolor: "#8BC34A",
										borderColor: "#8BC34A",
									},

									"& .MuiClockPointer-root": {
										width: "4px",
										left: "calc(50% - 2px)",
									},

									// '& .MuiClockPointer-thumb': {
									//   display: "none"
									// },
									"& .MuiClock-pin": {
										width: "15px",
										height: "15px",
										bgcolor: "#757575",
										zIndex: "1",
									},
								}}
							/>
						</div>
					)}
					{step === 3 && (
						<div>
							<div className="text-center mt-2">
								{selectedDate.format("HH:mm")}
							</div>
							<TimeClock
								value={selectedDate}
								onChange={handleMinuteChange}
								views={["minutes"]}
								minutesStep={1}
								sx={{
									margin: "0",
									padding: "0",
									"& .MuiClockPointer-root, .MuiClock-pin, .Mui-selected, .MuiClockPointer-thumb":
									{
										bgcolor: "#8BC34A",
										borderColor: "#8BC34A",
									},
									"& .MuiClock-pin": {
										width: "15px",
										height: "15px",
										bgcolor: "#757575",
										zIndex: "1",
									},
								}}
							/>
						</div>
					)}
				</Box>
			</LocalizationProvider>
			<ModalFooter>
				<Button
					variant="outline-secondary"
					onClick={handleCancel}
					className="px-3 text-uppercase">
					Cancel
				</Button>
				<Button
					variant="outline-secondary"
					onClick={handleOk}
					className="px-4 text-uppercase">
					OK
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default DateHourMinuteSelector;
