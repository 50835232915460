import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Row, Table } from "react-bootstrap";
import { FaRegEdit } from "react-icons/fa";
import { FiPlus } from "react-icons/fi";
import { Link } from "react-router-dom";

import CardTitle from "../../components/cardTitle/CardTitle";
import Switch from "../../components/inputs/Switch";
import AlertService from "../../helpers/services/alertService";
import MainService from "../../helpers/services/mainService";
import { ApiService } from "../../helpers/services/apiSrevice";
import {
	DatatableWrapper,
	TableHeader,
	TableBody,
	Filter,
} from "react-bs-datatable";
import Paginate from "../../components/paginate/Paginate";

export default function Schools() {
	const [schools, setSchools] = useState([]);

	useEffect(() => {
		MainService.callApi({ api: ApiService.getAllSchools, cb: getAllSchools });
	}, []);

	const getAllSchools = response => {
		if (response && response.data) {
			setSchools(response.data);
		}
	};

	const deleteSchool = item => {
		if (!item) return false;
		AlertService.alertConfirm("Are you sure", "", "Yes", "No")
			.then(() => {
				MainService.callApi({
					api: ApiService.deleteSchool,
					data: item.schoolDetails.id,
					cb: () => {},
				});
			})
			.catch(() => {});
	};

	const changeStatus = item => {
		if (!item) return false;
		MainService.callApi({
			api: !item.isActive
				? ApiService.schoolActivate
				: ApiService.schoolDeactivate,
			data: item.id,
			cb: response => {
				if (response && response.data === true && response.success === true) {
					AlertService.alert("success", "Data seved");
					let newSchools = schools.map(el => {
						if (el.schoolDetails.id === item.id) {
							return {
								...el,
								schoolDetails: {
									...el.schoolDetails,
									isActive: !item.isActive,
								},
							};
						}
						return el;
					});
					setSchools(newSchools);
				}
			},
		});
	};

	const columns = [
		{
			prop: "schoolDetails.schoolName",
			title: "School",
			isFilterable: true,
			isSortable: true,
			cell: row => <div>{row?.schoolDetails?.schoolName} </div>,
		},
		{
			prop: "schoolDetails.postCode",
			title: "Post Code",
			isFilterable: true,
			isSortable: true,
			cell: row => <div>{row?.schoolDetails.postCode} </div>,
		},
		{
			prop: "Status",
			title: "Status",
			cell: row => (
				<div>
					<Switch
						id={`status_${row.schoolDetails.id}`}
						value={row.schoolDetails.isActive}
						onChange={event => {
							changeStatus(row.schoolDetails);
						}}
						on="Active"
						off="Inactive"
						size={"sm"}
					/>
				</div>
			),
		},
		{
			name: "Action",
			title: "Action",
			prop: "button",
			cell: row => (
				<div className="d-flex justify-content-center gap-2">
					<Link
						to={`add/${row.schoolDetails.id}`}
						className="table_link d-flex align-items-center d-flex ">
						<FaRegEdit /> <span>Edit</span>
					</Link>
				</div>
			),
		},
	];

	return (
		<Container className="mt-3 mt-lg-0 pt-2">
			<Card>
				<CardBody>
					<CardTitle title="SCHOOLS"></CardTitle>
					<DatatableWrapper
						body={schools}
						headers={columns}
						paginationOptionsProps={{
							initialState: {
								rowsPerPage: 100,
								options: [5, 10, 15, 20],
							},
						}}>
						<Row className="pb-4 justify-content-between gap-2">
							<Col md={12} lg={2}>
								<Filter
									placeholder="search"
									classes={{
										input: "input-sm sso_input",
										clearButton: "clearButton",
									}}
								/>
							</Col>
							<Col md={12} lg={2} className="d-flex justify-content-end">
								<Link to={"add"} className="btn btn-primary btn-sm">
									<FiPlus />
									Add New
								</Link>
							</Col>
							<Col md={12} className="mb-2">
								<Table responsive bordered className="sso_tabel">
									<TableHeader />
									<TableBody />
								</Table>
							</Col>
							{/* <Col>
								<Paginate />
							</Col> */}
						</Row>
					</DatatableWrapper>
				</CardBody>
			</Card>
		</Container>
	);
}
