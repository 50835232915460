import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FiPlus } from "react-icons/fi";
import CheckBox from "../../../components/inputs/CheckBox";
import Input from "../../../components/inputs/Input";
import { emailRegex } from "../../../helpers/validation/validations";

function SSOCoOrdinators({ values, setValues, shortKey, errors, setErrors }) {
	const [disabledButton, setDisabledButton] = useState(true);

	useEffect(() => {
		if (values[shortKey] && values[shortKey].length) {
			values[shortKey].forEach(item => {
				if (
					item.staffFirstName &&
					item.staffLastName &&
					emailRegex.test(item.staffEmail) &&
					(item.isMainCoordinator ||
						item.canAdministerCoordinators ||
						item.noComms)
				) {
					setDisabledButton(false);
					return;
				} else {
					setDisabledButton(true);
				}
			});
		}
	}, [values, disabledButton]);

	const removeCoOrdinators = index => {
		if (values[shortKey] && values[shortKey]?.length === 1) {
			return false;
		}
		let filterData = values[shortKey].filter((_, ind) => ind !== index);
		if (!filterData.filter(item => item.isMainCoordinator).length) {
			filterData = filterData.map((item, index) => {
				if (index === 0) {
					return { ...item, isMainCoordinator: true };
				}
				return item;
			});
		}
		setValues(values => ({
			...values,
			[shortKey]: filterData,
		}));
	};
	// console.log(values);

	const addCoOrdinators = () => {
		const data = {
			staffFirstName: "",
			staffLastName: "",
			staffEmail: "",
			isMainCoordinator: false,
			canAdministerCoordinators: false,
			isCoordinator: true,
			noComms: false,
		};
		setDisabledButton(true);
		setValues(values => ({
			...values,
			[shortKey]: [...values[shortKey], data],
		}));
	};

	const onChange = (event, fieldName, index, errorfieldName) => {
		if (errorfieldName && errors && errors[errorfieldName]) {
			delete errors[errorfieldName];
			setErrors(errors);
		}
		const data = values[shortKey];
		if (!data[index]) return false;
		data[index][fieldName] = event.target.value;
		setValues(values => ({
			...values,
			[shortKey]: data,
		}));
	};

	const onCheckChange = (event, fieldName, index, errorfieldName) => {
		if (errorfieldName && errors && errors[errorfieldName]) {
			delete errors[errorfieldName];
			setErrors(errors);
		}
		if (fieldName === "isMainCoordinator") {
			let mainCoordinatorArr = values[shortKey].filter(
				item => item.isMainCoordinator === true,
			);
			if (mainCoordinatorArr.length === 1 && !event.target.checked) {
				return false;
			}

			let newArr = [...values[shortKey]];
			if (mainCoordinatorArr.length === 2 && event.target.checked === true) {
				let item = newArr.find(
					(item, idx) => item.isMainCoordinator && idx !== index,
				);
				item.isMainCoordinator = false;
				newArr[index][fieldName] = event.target.checked;
				setValues(values => ({
					...values,
					[shortKey]: newArr,
				}));
				return false;
			}
		}
		const data = values[shortKey];
		if (!data[index]) return false;
		data[index][fieldName] = event.target.checked;
		setValues(values => ({
			...values,
			[shortKey]: data,
		}));
	};

	return (
		<Row className="mt-5 gap-3">
			{values[shortKey] &&
				!!values[shortKey].length &&
				values[shortKey].map((item, index, arr) => (
					<Col md={12} key={index}>
						<Row className="gap-3">
							<Col md={12}>
								<Row className="">
									<Col md={12} lg={3}>
										<label className="sso_label">
											<h4>Co-ordinator {index + 1}</h4>
											<span className="sso_required">*</span>
										</label>
									</Col>
									<Col md={12} lg={4} className="">
										<div className="w-100 d-flex gap-2 align-items-center justify-content-between flex-wrap">
											<CheckBox
												value={item.isMainCoordinator}
												checked={item.isMainCoordinator}
												label={"Main Contact"}
												id={`isMainCoordinator${index}`}
												onChange={event => {
													onCheckChange(
														event,
														"isMainCoordinator",
														index,
														`${shortKey}[${index}]`,
													);
												}}
											/>
											<CheckBox
												label={"No Comms"}
												id={`noComms${index}`}
												checked={item.noComms}
												value={item.noComms}
												onChange={event => {
													onCheckChange(
														event,
														"noComms",
														index,
														`${shortKey}[${index}]`,
													);
												}}
											/>
											<CheckBox
												label={"Can Administer Co-ordinators"}
												id={`administer${index}`}
												checked={item.canAdministerCoordinators}
												value={item.canAdministerCoordinators}
												onChange={event => {
													onCheckChange(
														event,
														"canAdministerCoordinators",
														index,
														`${shortKey}[${index}]`,
													);
												}}
											/>
										</div>
										{errors && errors[`${shortKey}[${index}]`] && (
											<small className="text-danger d-block mt-1">
												{errors[`${shortKey}[${index}]`]}
											</small>
										)}
									</Col>
								</Row>
							</Col>
							<Col md={12}>
								<Input
									label={"First Name"}
									required={true}
									labelCol={"col-md-12 col-lg-3"}
									inputCol={"col-md-12 col-lg-4"}
									id={"staffFirstName"}
									value={item.staffFirstName || ""}
									onChange={event => {
										onChange(
											event,
											"staffFirstName",
											index,
											`${shortKey}[${index}].staffFirstName`,
										);
									}}
									errorMessage={
										errors && errors[`${shortKey}[${index}].staffFirstName`]
									}
								/>
							</Col>
							<Col md={12}>
								<Input
									label={"Surname"}
									required={true}
									labelCol={"col-md-12 col-lg-3"}
									inputCol={"col-md-12 col-lg-4"}
									id={"lastName"}
									value={item.staffLastName || ""}
									onChange={event => {
										onChange(
											event,
											"staffLastName",
											index,
											`${shortKey}[${index}].staffLastName`,
										);
									}}
									errorMessage={
										errors && errors[`${shortKey}[${index}].staffLastName`]
									}
								/>
							</Col>
							<Col md={12}>
								<Input
									label={"Email Address"}
									required={true}
									labelCol={"col-md-12 col-lg-3"}
									inputCol={"col-md-12 col-lg-4"}
									id={"Email Address"}
									value={item.staffEmail || ""}
									onChange={event => {
										onChange(
											event,
											"staffEmail",
											index,
											`${shortKey}[${index}].staffEmail`,
										);
									}}
									errorMessage={
										errors && errors[`${shortKey}[${index}].staffEmail`]
									}
								/>
							</Col>
							{arr.length > 1 && (
								<Col md={12}>
									<button
										type="button"
										className="btn btn-default "
										variant="secondary"
										onClick={() => {
											removeCoOrdinators(index);
										}}>
										Remove
									</button>
								</Col>
							)}

							<Col md={12}>
								<hr />
							</Col>
						</Row>
					</Col>
				))}
			<Col md={12}>
				<button
					disabled={disabledButton}
					type="button"
					className="btn btn-primary "
					onClick={() => {
						addCoOrdinators();
					}}>
					<FiPlus />
					Add New Co-ordinator
				</button>
			</Col>
		</Row>
	);
}

export default SSOCoOrdinators;
