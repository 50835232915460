import React from "react";

function RadioBox({
	id,
	onChange,
	checkClassName,
	value,
	label,
	name,
	onKeyDown,
	onFocus,
	onBlur,
	size,
	boxdisabled,
	checked,
	disabled,
}) {
	return (
		<>
			<div
				className={`form-check position-relative sso_redio d-flex align-items-center ${
					checkClassName ? checkClassName : ""
				} ${boxdisabled ? "boxdisabled" : ""} ${
					size ? `sso_redio-${size}` : ""
				}`}>
				<input
					className={`form-check-input position-relative`}
					type="radio"
					value={value}
					id={id}
					checked={checked}
					onChange={onChange}
					onBlur={onBlur}
					onKeyDown={onKeyDown}
					onFocus={onFocus}
					name={name}
					disabled={disabled}
				/>
				{label && (
					<label className="form-check-label sso_label" htmlFor={id}>
						{label}
					</label>
				)}
			</div>
		</>
	);
}

export default RadioBox;
